<template>
  <teleport to="footer">
    <section
      class="max-w-screen-xl mx-auto px-2 sm:px-4 md:px-6 py-2 flex items-center"
    >
      <slot />
    </section>
  </teleport>
</template>
<script>
export default {
  props: {
    bgVisible: {
      type: Boolean,
      required: false,
      default: false
    },
  }
}
</script>
