<i18n>
{
  "en": {
    "title": "News at prima.schule"
  },
  "de": {
    "title": "Neuigkeiten bei prima.schule"
  }
}
</i18n>
<template>
  <page-title :title="$t('title')" />
  <ul class="mt-4 md:mt-6">
    <li
      v-for="item in announcements.data"
      :key="item.id"
      class="mb-2 lg:my-8"
    >
      <expander
        :id="item.id"
        :label="item.name"
        :label-date-time="$filters.toDate(item.published_at, 'D.M.Y HH:mm')"
        :label-type="item.announcement_type"
        :current-id="current_id"
        @setCurrentId="setCurrentId"
      >
        <div
          v-dompurify-html="item.description"
          class="leading-normal"
        />
      </expander>
    </li>
  </ul>
  <pagination prop-name="announcements" />
</template>

<script>

export default {
  props: {
    announcements: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      current_id: null
    }
  },
  mounted() {
    if(this.announcements && this.announcements.data && this.announcements.data.length > 0) {
      this.current_id = this.announcements.data[0].id
    }
  },
  methods: {
    setCurrentId(id) {
      this.current_id = id
    }
  }
}
</script>
