<template>
  <div
    v-bind="$attrs"
  >
    <label
      v-if="label"
      class="form-label"
      :for="id"
    >
      {{ label }}
    </label>
    <div class="relative">
      <input
        :id="id"
        ref="input"
        :name="name"
        class="form-input"
        :class="{ error: errors.length }"
        :type="input_type"
        :value="modelValue"
        autocomplete="off"
        :placeholder="placeholder"
        @input="$emit('update:modelValue', $event.target.value)"
      >
      <button
        class="absolute right-0 bottom-0 w-6 h-6 m-2"
        :class="{'text-gray-400': is_password_field, 'text-yellow-dark': !is_password_field}"
        @click="is_password_field = !is_password_field"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
        >
          <path
            fill="currentColor"
            d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"
          ></path>
        </svg>
      </button>
    </div>
    <div
      v-if="errors.length"
      class="form-error"
    >
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `text-input-${parseInt(Math.random() * 10000)}`
      },
    },
    modelValue: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data: () => {
    return {
      is_password_field: true
    }
  },
  computed: {
    input_type() {
      if(this.is_password_field)
        return 'password'
      else
        return 'text'
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end)
    },
  },
}
</script>
