<i18n>
{
  "en": {
    "title": "Post",
    "restore": "restore",
    "deleted": "deleted",
    "delete": "delete permanently",
    "attachment": "Attachment"
  },
  "de": {
    "title": "Post",
    "restore": "wiederherstellen",
    "deleted": "gelöscht",
    "delete": "entgültig löschen",
    "attachment": "Anhang"
  }
}
</i18n>
<template>
  <header class="w-full flex items-center mb-8">
    <page-title :nav-back-url="$routes.posts()" :title="post.title">
      <template #action>
        <div v-if="post.discarded_at" class="text-red-dark text-base">
          {{ $t('deleted') }}
        </div>
      </template>
    </page-title>
  </header>
  <div
    id="board"
    class="mt-4 p-8 lg:px-16 bg-white border-2 md:border-4 border-primary-yellow rounded-lg"
  >
    <div class="text-right">
      {{ $filters.toDate(post.date_publication, 'DD.MM.YYYY') }}
    </div>
    <div
      v-dompurify-html="post.content"
      class="content leading-tight mt-6 overflow-y-auto"
    />
  </div>
  <div v-if="post.files.length > 0" class="">
    <div class="mt-8 mb-2">{{ $t('attachment') }}:</div>
    <ul class="flex">
      <li v-for="file in post.files" :key="file.id" class="btn">
        <a class="" :href="file.download_url" target="_blank">{{
          file.filename
        }}</a>
      </li>
    </ul>
  </div>

  <footer-actions>
    <div v-if="!post.discarded_at" class="flex ml-auto">
      <a
        class="btn-menu mr-4"
        :href="$routes.post_pdf(post.slug)"
        name="print_post"
        target="_blank"
      >
        <img class="w-8 h-8" :src="imagePath('download_pdf.svg')" />
      </a>
      <Link
        v-if="$page.props.auth.user.owner"
        class="btn-menu"
        :href="$routes.edit_post(post.slug)"
        name="edit_post"
        as="button"
        replace
      >
        <icon class="w-6 h-6" name="pencil-alt" />
      </Link>
    </div>
    <div v-else class="ml-auto">
      <button
        v-if="$page.props.auth.user.owner"
        class="btn-delete mr-2"
        @click="destroy()"
      >
        {{ $t('delete') }}
      </button>
      <Link
        v-if="$page.props.auth.user.owner"
        class="btn-green"
        :href="$routes.restore_post(post.slug)"
        method="put"
        as="button"
      >
        {{ $t('restore') }}
      </Link>
    </div>
  </footer-actions>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {}
  },
  methods: {
    destroy() {
      if (confirm('Soll Post entgültig gelöscht werden?')) {
        this.$inertia.delete(this.$routes.post(this.post.slug))
      }
    },
  },
}
</script>
<style scoped></style>
