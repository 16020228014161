<i18n>
{
  "en": {
    "title": "Weekly Schedules",
    "deleted": "deleted",
    "draft": "draft",
    "public": "public",
    "visible_from": "visible from"
  },
  "de": {
    "title": "Wochenpläne",
    "deleted": "gelöscht",
    "draft": "Entwurf",
    "public": "Öffentlich",
    "visible_from": "sichtbar ab"
  }
}
</i18n>
<template>
  <page-title :title="$t('title')" />
  <section>
    <ul class="mt-4 md:mt-8 mb-16">
      <li
        v-for="item in plans.data"
        :key="item.slug"
        class="border rounded-lg px-2 md:px-4 py-6 sm:py-8 mb-4 md:mb-6 lg:mb-8"
        :style="{
          'background-color': item.bg_color,
          color: item.fg_color,
          'border-color': getBorderColor(item),
        }"
      >
      <section class="flex items-center">
        <Link
          class="flex-grow flex items-center flex-wrap gap-2 md:gap-4"
          :class="{ 'line-through': item.discarded_at }"
          :href="$routes.plan(item.slug)"
        >
          <div class="md:text-2xl">{{ getDateLabel(item) }}</div>
          <div v-if="item.title" class="">({{ item.title }})</div>
        </Link>
        <div v-if="item.discarded_at" class="font-bold text-red-600">
          {{ $t('deleted') }}
        </div>
        <Link
          v-if="!item.discarded_at && $page.props.auth.user.owner"
          class="p-1 md:p-2 rounded-full bg-gray-500 hover:bg-yellow-dark text-white"
          :href="$routes.edit_plan(item.slug)"
        >
          <icon class="w-6 h-6" name="pencil-alt" />
        </Link>
        </section>
        <section v-if="$page.props.auth.user.owner" class="mt-2 w-full flex flex-wrap gap-x-2 md:gap-x-4 text-sm">
          <div v-for="share in item.shares" :key="share" class="border border-white rounded-md px-2 py-1">
            {{ share.name }}
          </div>
        </section>
      </li>
    </ul>
    <pagination prop-name="plans" />
    <footer-actions>
      <div class="w-full flex items-center justify-end">
        <Link
          v-if="$page.props.auth.user.owner"
          class="btn-menu-red"
          name="new_plan"
          :href="$routes.new_plan()"
        >
          <icon class="w-6 h-6" name="plus" />
        </Link>
      </div>
    </footer-actions>
  </section>
</template>

<script>
import moment from 'moment'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'

export default {
  props: {
    plans: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      sending: false,
      items: [],
      form: { search: '' },
    }
  },
  watch: {
    form: {
      handler: throttle(function () {
        let query = pickBy(this.form)
        this.$inertia.get(
          this.$routes.plans(
            Object.keys(query).length ? query : { remember: 'forget' },
          ),
          {
            preserveState: true,
            preserveScroll: true,
            only: ['plans'],
          },
        )
      }, 150),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
    getDateLabel(item) {
      // return item
      let week = moment().year(item.year).isoWeek(item.week)
      return `${week
        .isoWeekday(1)
        .locale('de')
        .format('ddd DD.MM.YYYY')} - ${week
        .isoWeekday(5)
        .locale('de')
        .format('ddd DD.MM.YYYY')}`
    },
    getStatus(status) {
      if (status === 'draft') {
        return this.$t('draft')
      } else {
        return this.$t('public')
      }
    },
    getBorderColor(item) {
      if (item.bg_color) {
        return 'transparent'
      }
      if (item.fg_color) {
        return item.fg_color
      } else {
        return '#6B7280'
      }
    },
  },
}
</script>
<style scoped>
.dotted:before {
  content: ' · ';
}
</style>
