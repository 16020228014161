<i18n>
{
  "en": {
    "avatar": "Avatar",
    "remove_avatar": "Are you sure to delete this image?",
    "user": "User",
    "user-me-description": "manage own account",
    "first_name": "First Name",
    "last_name": "Last Name",
    "email": "Email",
    "password": "Change Password",
    "password_confirmation": "Confirm changed Password"
  },
  "de": {
    "avatar": "Avatar",
    "remove_avatar": "Soll Bild tatsächlich gelöscht werden?",
    "user": "Anwender",
    "user-me-description": "Eigenes Konto verwalten",
    "first_name": "Vorname",
    "last_name": "Nachname",
    "email": "Email",
    "password": "Neues Passwort",
    "password_confirmation": "Neues Passwort bestätigen"
  }
}
</i18n>
<template>
  <section>
    <form-group
      :name="$t('user')"
      :description="$t('user-me-description')"
      :first="true"
      :last="true"
    >
      <text-input
        v-model="form.first_name"
        :errors="$page.props.errors && $page.props.errors.first_name"
        class="w-full"
        :label="$t('first_name')"
      />
      <text-input
        v-model="form.last_name"
        :errors="$page.props.errors && $page.props.errors.last_name"
        class="mt-2 md:mt-4 w-full"
        :label="$t('last_name')"
      />
      <text-input
        v-model="form.email"
        :errors="$page.props.errors && $page.props.errors.email"
        class="mt-2 md:mt-4 w-full"
        type="text"
        :label="$t('email')"
      />
      <div class="mt-2 md:mt-4 w-full flex gap-2">
        <password-input
          v-model="form.password"
          :errors="$page.props.errors && $page.props.errors.password"
          class="w-1/2"
          :label="$t('password')"
        />
        <password-input
          v-model="form.password_confirmation"
          :errors="$page.props.errors && $page.props.errors.password_confirmation"
          class="w-1/2"
          :label="$t('password_confirmation')"
        />
      </div>
      <div class="mt-2 md:mt-4 pb-4">
        <label class="form-label">
          {{ $t('avatar') }}
        </label>
        <div class="flex items-center mt-2 relative">
          <div class="w-20 h-20 overflow-hidden rounded-lg flex items-center justify-center">
            <img
              v-if="form.avatar_url"
              class="w-20 h-20"
              :src="form.avatar_url"
              :alt="$t('avatar')"
            >
            <div
              v-else
              class="w-20 h-20 rounded-full bg-gray-200"
            />
          </div>
          <a
            v-if="form.avatar_url"
            class="absolute cursor-pointer top-0 w-6 h-6 border border-gray-300 bg-gray-100 rounded-full flex items-center justify-center hover:bg-red-300"
            style="margin-left: 3.75em;"
            @click.prevent="removeAvatar()"
          >
            <icon
              class="w-3 h-3"
              name="cross"
            />
          </a>
          <span class="ml-5">
            <file-input
              v-model="form.avatar"
              class="w-full ml-4"
              :errors="$page.props.errors && $page.props.errors.avatar"
              type="file"
              accept="image/png,image/jpeg,image/jpg,image/gif"
            />
          </span>
        </div>
      </div>
    </form-group>
    <slot />
  </section>
</template>

<script>

export default {
  components: {
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
  mounted() {
  },
  methods: {
    removeAvatar() {
      if (confirm(this.$t('remove_avatar'))) {
        this.$inertia.delete(this.$routes.destroy_me_avatar())
      }
    }
  }
}
</script>
