<i18n>
{
  "en": {
    "title": "User Account",
    "title-description": "title and image for the user_account",
    "user_account": "User Account",
    "avatar": "User Account Image",
    "user-permissions": "Permissions",
    "user-permissions-description": "allowed permissions",
    "remove_avatar": "Are you sure to delete this image?"
  },
  "de": {
    "title": "Klasse",
    "title-description": "Bezeichnung und Maskottchen der Klasse",
    "user_account": "Klasse",
    "avatar": "Bild Maskottchen",
    "user-permissions": "Berechtigungen",
    "user-permissions-description": "erlaubte Zugriffe",
    "remove_avatar": "Soll Bild tatsächlich gelöscht werden?"
  }
}
</i18n>
<template>
    <section class="mt-4 md:mt-8 p-4 bg-gray-50 rounded">
      <form-group
        :name="$t('title')"
        :description="$t('title-description')"
        :first="true"
      >
        <text-input
          v-model="form.name"
          class="w-full"
          :errors="$page.props.errors && $page.props.errors.name"
          :label="$t('user_account')"
        />
        <div class="mt-5">
          <label class="form-label">
            {{ $t('avatar') }}
          </label>
          <div class="flex items-center mt-2 relative">
            <div class="w-20 h-20 overflow-hidden rounded-lg flex items-center justify-center">
              <img
                v-if="form.avatar_url"
                class="w-20 h-20"
                :src="form.avatar_url"
                :alt="$t('avatar')"
              >
              <div
                v-else
                class="w-20 h-20 rounded-full bg-gray-200"
              />
            </div>
            <a
              v-if="form.avatar_url"
              class="absolute cursor-pointer top-0 w-6 h-6 border border-gray-300 bg-gray-100 rounded-full flex items-center justify-center hover:bg-red-300"
              style="margin-left: 3.75em;"
              @click.prevent="removeAvatar()"
            >
              <icon
                class="w-3 h-3"
                name="cross"
              />
            </a>
            <span class="ml-5">
              <file-input
                v-model="form.avatar"
                class="w-full ml-4"
                :errors="$page.props.errors && $page.props.errors.avatar"
                type="file"
                accept="image/png,image/jpeg,image/jpg,image/gif"
              />
            </span>
          </div>
        </div>
      </form-group>
      <form-group
        :name="$t('user-permissions')"
        :description="$t('user-permissions-description')"
        :last="true"
      >
        <slot name="actionAddPermission" />
      </form-group>
    </section>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    can: {
      type: Object,
      required: true,
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
  methods: {
    removeAvatar() {
      if (confirm(this.$t('remove_avatar'))) {
        this.$inertia.delete(this.$routes.destroy_user_account_avatar(this.form.id),
          { preserveState: false })
      }
    }
  },
}
</script>
