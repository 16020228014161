<template>
  <teleport to="body">
    <section
      v-if="showModal"
      class="inset-0 z-50"
    >
      <div class="absolute bg-gray-200 opacity-80 left-0 right-0 top-0 bottom-0 flex items-center justify-center">
      </div>
      <div class="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center">
        <div class="bg-white flex gap-x-5 items-center justify-center w-64 h-32 rounded">
          <img
            class="animate-bounce w-16 h-16"
            :src="imagePath('check.svg')"
          >
          <div class="text-xl">bitte warten...</div>
        </div>
      </div>
    </section>
  </teleport>
</template>
<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showModal: false,
    }
  },
  watch: {
    open: {
      handler: function(newValue) {
        if (newValue) {
          this.show()
        } else {
          this.close()
        }
      },
    },
  },
  methods: {
    show() {
      console.log(this.showModal)
      this.showModal = true
      document.body.style.setProperty('overflow', 'hidden')
    },
    close() {
      document.body.style.removeProperty('overflow')
    },
  },
}
</script>
