<i18n>
{
  "en": {
    "title": "Tasks",
    "title-feedback": "Homework",
    "date": "Date",
    "search": "Search",
    "subject": "Subject",
    "task": "Task",
    "save": "save"
  },
  "de": {
    "title": "Aufgaben",
    "title-feedback": "HA-Korb",
    "date": "Datum",
    "search": "Suche",
    "subject": "Fach",
    "task": "Aufgabe",
    "save": "speichern"
  }
}
</i18n>
<template>
  <page-title :title="$t('title')">
    <template #action>
      <div class="flex items-center gap-x-2 md:gap-x-4">
        <button
          class="w-8 h-8"
          :class="{ 'text-blue-700': form.search.feedback_required }"
          @click="setFeedbackRequired()"
        >
          <icon name="basket" class="w-8 h-8" />
        </button>
        <button
          class="w-8 h-8 rounded-full border border-gray-800"
          :class="{ 'bg-blue-700': form.search.has_memos }"
          @click="setMemo()"
        >
          <img class="w-8 h-8" :src="imagePath('digiDog_sm.svg')" />
        </button>
        <multiselect
          v-model="form.search.subject_id"
          :options="subjects.map((item) => item.id)"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Fach"
          :preselect-first="false"
          :custom-label="getNameLabel"
          selectedLabel=""
          selectLabel=""
          deselectLabel=""
          class="w-32"
        />
        <div class="inline-block relative">
          <button
            v-if="
              form.search.search_string && form.search.search_string.length > 0
            "
            class="absolute right-0 mt-2 mr-4"
            @click="form.search.search_string = ''"
          >
            x
          </button>
          <input
            v-model="form.search.search_string"
            class="rounded-full border w-32 px-4 py-2"
            :placeholder="$t('search')"
          />
        </div>
      </div>
    </template>
  </page-title>
  <ul class="mt-4">
    <li
      v-for="item in tasks.data"
      :key="item.id"
      class="w-full flex items-center px-2 border-yellow-dark border-b gap-x-2 md:gap-x-4 hover:bg-gray-100 cursor-pointer"
      @click="openTask(item)"
    >
      <div class="w-32 py-2 sm:py-4">
        {{ $filters.toDate(item.date, 'ddd DD.MM.YYYY') }}
      </div>
      <div v-dompurify-html="item.subject" class="w-32 py-2 sm:py-4" />
      <div
        v-dompurify-html="item.content"
        class="content flex-grow py-2 sm:py-4"
      />
      <div class="w-6">
        <icon
          v-if="item.feedback_required"
          name="basket"
          class="w-6 h-6 text-gray-800"
        />
      </div>
      <div class="w-6">
        <img
          v-if="item.has_memos"
          class="w-6 h-6"
          :src="imagePath('digiDog.svg')"
        />
      </div>
    </li>
  </ul>
  <pagination prop-name="tasks" />
</template>

<script>
import Multiselect from 'vue-multiselect'
import throttle from 'lodash/throttle'
import pickBy from 'lodash/pickBy'

export default {
  components: { Multiselect },
  props: {
    tasks: {
      type: Object,
      required: true,
    },
    subjects: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: pickBy(this.filters),
    }
  },
  watch: {
    form: {
      handler: throttle(function () {
        let query = pickBy(this.form)
        this.$inertia.get(
          this.$routes.tasks(query),
          {},
          { preserveState: true },
        )
      }, 150),
      deep: true,
    },
  },
  methods: {
    openTask(item) {
      this.$inertia.get(this.$routes.task(item.id))
    },
    setFeedbackRequired() {
      if (this.form.search.feedback_required) {
        this.form.search.feedback_required = null
      } else {
        this.form.search.feedback_required = true
      }
    },
    setMemo() {
      if (this.form.search.has_memos) {
        this.form.search.has_memos = null
      } else {
        this.form.search.has_memos = true
      }
    },
    getNameLabel(index) {
      const item = this.subjects.find((item) => item.id == index)
      if (item) {
        return item.name
      }
      return ''
    },
  },
}
</script>
<style>
.multiselect__tags {
  padding: 8px 8px 0 8px;
  /* border-radius: 5px; */
  font-size: 20px;
}
</style>
