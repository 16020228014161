<i18n>
{
 "en": {
    "browse": "durchsuchen",
    "remove": "entfernen"
  },
  "de": {
    "browse": "durchsuchen",
    "remove": "entfernen"
  }
}
</i18n>
<template>
  <div>
    <label
      v-if="label"
      class="form-label"
    >{{ label }}:</label>
    <div
      class="p-0"
      :class="{ error: errors.length }"
    >
      <input
        ref="file"
        type="file"
        :accept="accept"
        :multiple="multiple"
        class="hidden"
        @change="change"
      >
      <div
        v-if="!modelValue"
        class="p-2"
      >
        <button
          type="button"
          class="px-1 py-1 bg-gray-700 hover:bg-gray-700 rounded-sm text-xs font-medium text-white"
          @click="browse"
        >
          {{ $t('browse') }}
        </button>
      </div>
      <div
        v-else
        class="flex items-center justify-between p-2"
      >
        <div class="flex-1 pr-1">
          {{ modelValue.name }} <span class="text-gray-600 text-xs">({{ filesize(modelValue.size) }})</span>
        </div>
        <button
          type="button"
          class="px-4 py-1 bg-gray-700 hover:bg-gray-700 rounded-sm text-xs font-medium text-white"
          @click="remove"
        >
          {{ $t('remove') }}
        </button>
      </div>
    </div>
    <div
      v-if="errors.length"
      class="form-error"
    >
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: File,
    label: {
      type: String,
      default: null,
    },
    accept: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    modelValue(modelValue) {
      if (!modelValue) {
        this.$refs.file.modelValue = ''
      }
    },
  },
  methods: {
    filesize(size) {
      let i = Math.floor(Math.log(size) / Math.log(1024))
      return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        ' ' +
        ['B', 'kB', 'MB', 'GB', 'TB'][i]
      )
    },
    browse() {
      this.$refs.file.click()
    },
    change(e) {
      this.$emit('update:modelValue', e.target.files[0])
    },
    remove() {
      this.$emit('update:modelValue', null)
    },
  },
}
</script>
