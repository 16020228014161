<template>
  <div
    id="reflection"
    class="w-full bg-white overflow-hidden"
  >
    <div
      id="reflection-container"
      class="flex relative overflow-hidden text-white bg-green-dark"
      style="min-height: 300px;"
    >
      <div
        class="reflection-slide absolute inset-0 flex justify-center items-center text-center transition-all ease-in-out duration-1000 transform translate-x-0"
        style="min-height: 300px;"
      >
        <icon
          name="smile"
          class="w-12 h-12 mx-2"
          fill="white"
        />
      </div>
      <div
        v-for="(reflection, id) in reflections_sorted"
        :id="'ref'+id"
        :key="'ref'+id"
        class="reflection-slide absolute inset-0 transition-all ease-in-out duration-1000 transform translate-x-full flex flex-wrap items-center justify-center"
        :class="{'bg-yellow-light text-black': !reflection.user_rate }"
        style="min-height: 300px;"
      >
        <div class="w-full text-center">
          <div class="w-full text-center text-3xl font-bold py-4 mb-2">
            {{ reflection.content }}
          </div>
          <div
            v-if="!reflection.user_rate"
            name="reflection-rates"
            class="w-full flex justify-center"
          >
            <button
              name="rate_1"
              class="hover:bg-yellow-400 rounded-full m-2"
              @click="setRate(reflection, 1)"
            >
              <img
                class="w-12 h-12"
                :src="imagePath('rate_1.svg')"
              >
            </button>
            <button
              name="rate_2"
              class="hover:bg-yellow-400 rounded-full m-2"
              @click="setRate(reflection, 2)"
            >
              <img
                class="w-12 h-12"
                :src="imagePath('rate_2.svg')"
              >
            </button>
            <button
              name="rate_3"
              class="hover:bg-yellow-400 rounded-full m-2"
              @click="setRate(reflection, 3)"
            >
              <img
                class="w-12 h-12"
                :src="imagePath('rate_3.svg')"
              >
            </button>
            <button
              name="rate_4"
              class="hover:bg-yellow-400 rounded-full m-2"
              @click="setRate(reflection, 4)"
            >
              <img
                class="w-12 h-12"
                :src="imagePath('rate_4.svg')"
              >
            </button>
          </div>
          <div
            v-else
            class="w-full flex justify-center text-black"
          >
            <img
              class="w-8 ml-2"
              :src="getImagePath(reflection)"
            >
          </div>
        </div>
      </div>
      <div
        id="refEnd"
        class="reflection-slide absolute inset-0 flex justify-center items-center text-center text-white bg-green-dark transition-all ease-in-out duration-1000 transform translate-x-full"
        style="min-height: 300px;"
      >
        <img
          class="w-12 h-12 mx-2 p-2 border-2 border-white rounded-full"
          :src="imagePath('check_w.svg')"
        >
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import sortBy from 'lodash/sortBy'

export default {
	props: {
		reflections: {
			type: Array,
			required: true,
		},
	},
  computed: {
    reflections_sorted() {
      return sortBy(this.reflections, ['position'])
    },
  },
	mounted() {
		window.addEventListener('resize', this.handleWindowResize)
		this.calculateWidth()

		setTimeout(() => {
			this.nextStep()
		},1250)
	},
  beforeDestroy: function() {
    window.removeEventListener('resize', this.handleWindowResize)
  },
	methods: {
    handleWindowResize() {
      this.calculateWidth()
    },
    calculateWidth() {
      let reflection = document.getElementById('reflection')
      if(reflection) {
        let width = reflection.offsetWidth
        let sum_width = 0
        document.querySelectorAll('.reflection-slide').forEach(item => {
          item.style.width= `${ width }px`
          sum_width+= width
        })
        document.getElementById('reflection-container').style.width= `${ sum_width }px`
      }
    },
    nextStep() {
      let activeSlide = document.querySelector('.reflection-slide.translate-x-0')
      let nextSlide = activeSlide.nextElementSibling

      if(!activeSlide || !nextSlide) {
        window.location.reload()
      }

      activeSlide.classList.remove('translate-x-0')
      activeSlide.classList.add('-translate-x-full')

      nextSlide.classList.remove('translate-x-full')
      nextSlide.classList.add('translate-x-0')
      if(nextSlide.getAttribute('id')==='refEnd'){
        // console.log('End reached')
        setTimeout(()=> {
          this.$emit('finished')
        },2500)
      }
    },
    setRate(reflection, rate) {
      if (this.$page.props.auth.user.owner) {
        this.$notify({
          title:
            'Hinweis: Selbstreflexionen können nur von Schülern durchgeführt werden.',
          type: 'warn',
        })
        return
      }
      // console.log(rate)
      axios
        .post(this.$routes.reflection_user_rate(reflection.id), { 'reflection': { 'user_rate': rate }}, {
          headers: { Accept: 'application/json' },
        } )
        .then(() => {
          reflection.user_rate=rate
          this.nextStep()
        })
        .catch(function() {
          window.location.reload()
        })
    },
    getImagePath(reflection){
      if(reflection && reflection.user_rate && reflection.user_rate > 0 && reflection.user_rate < 5) {
        return this.imagePath(`rate_${ reflection.user_rate }.svg`)
      }
    },
	},
}
</script>
<style scoped>
</style>
