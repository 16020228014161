<i18n>
{
  "en": {
    "aquarell": "Aquarell",
    "assume-changes": "assume changes",
    "cancel": "cancel",
    "cork": "Cork",
    "date_publication": "publish date",
    "draft": "draft",
    "public": "public",
    "empty": "No",
    "fabric": "Fabric",
    "from": "from",
    "title": "Title",
    "velvet": "Velvet",
    "visible": "visible",
    "week": "Cal.-Week",
    "year": "Year",
    "cw": "cw",
    "period": "Period",
    "period-description": "Year and calendar week (cw)",
    "state": "State and date of publication",
    "state-description": "date is optional",
    "state-label": "State",
    "publication-label": "Publication",
    "caption": "Caption",
    "caption-description": "is optional",
    "formatting": "Formatting",
    "formatting-description": "Color settings and background texture",
    "background-color": "Background color",
    "font-color": "Font color",
    "texture": "Texture"
  },
  "de": {
    "aquarell": "Aquarell",
    "assume-changes": "Änderungen übernehmen",
    "cancel": "Abbrechen",
    "cork": "Kork",
    "date_publication": "Veröffentlichungsdatum",
    "draft": "Entwurf",
    "public": "Veröffentlicht",
    "empty": "Keine",
    "fabric": "Stoff",
    "from": "ab",
    "title": "Titel",
    "velvet": "Samt",
    "visible": "Öffentlich",
    "week": "KW",
    "year": "Jahr",
    "cw": "KW",
    "period": "Zeitraum",
    "period-description": "Jahr und Kalenderwoche (KW)",
    "state": "Status und Datum für Veröffentlichung",
    "state-description": "Datum ist optional",
    "state-label": "Status",
    "publication-label": "Veröffentlichung",
    "caption": "Beschriftung",
    "caption-description": "ist optional",
    "formatting": "Formatierung",
    "formatting-description": "Einstellungen für Farbe und Hintergrund",
    "background-color": "Hintergrundfarbe",
    "font-color": "Schriftfarbe",
    "texture": "Textur"
  }
}
</i18n>
<template>
  <section class="bg-gray-100 p-4 md:px-8">
    <form-group
      :name="$t('period')"
      :description="$t('period-description')"
      :first="true"
    >
      <div class="flex flex-wrap gap-x-4 items-end">
        <text-input
          v-model="form.year"
          class="w-20"
          :label="$t('year')"
          type="integer"
        />
        <text-input
          v-model="form.week"
          class="w-20"
          :label="$t('cw')"
          type="number"
        />
        <div class="w-full sm:w-auto sm:pl-4 pt-3 sm:pb-3">
          {{ getDateIntervall }}
        </div>
      </div>
    </form-group>
    <form-group
      :name="$t('caption')"
      :description="$t('caption-description')"
    >
      <text-input
        v-model="form.title"
        class="w-full"
        :errors="$page.props.errors && $page.props.errors.title"
        :label="$t('caption')"
      />
    </form-group>
    <form-group
      :name="$t('formatting')"
      :description="$t('formatting-description')"
      :last="true"
    >
      <div class="flex flex-wrap gap-x-6">
        <label class="">
          <div class="mb-2">{{ $t('background-color') }}</div>
          <color-picker
            v-model="form.bg_color"
            class="ml-2"
            size="10"
          />
        </label>
        <label class="">
          <div class="mb-2">{{ $t('font-color') }}</div>
          <color-picker
            v-model="form.fg_color"
            class="ml-2"
            size="10"
          />
        </label>
        <select-box
          v-model="form.bg_texture"
          :options="textures"
          :showRemove="true"
          class="w-60"
          :label="$t('texture')"
          :errors="$page.props.errors && $page.props.errors.bg_texture"
        />
      </div>
    </form-group>
  </section>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    modelValue: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      statuses: [
        { id: 'draft', name: 'Entwurf'},
        { id: 'visible', name: 'veröffentlicht'}
      ]
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
    getDateIntervall() {
      if (!this.form.year && !this.form.week) {
        return ''
      }
      let week = moment().year(this.form.year).isoWeek(this.form.week)
      return `${week
        .isoWeekday(1)
        .locale('de')
        .format('ddd DD.MM.YYYY')} - ${week
        .isoWeekday(5)
        .locale('de')
        .format('ddd DD.MM.YYYY')}`
    },
    textures() {
      return [
        'texture_aquarell.jpg',
        'texture_velvet.jpg',
        'texture_fabric.jpg',
        'texture_cork.jpg'
      ]
    }
  }
}
</script>

<style scoped>
.incdec {
  display: block;
  width: 20px;
  margin-top: 29px;
  margin-right: 2px;
}
</style>
