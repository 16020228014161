<template>
  <dropdown>
    <div
      class="border border-gray-400 rounded-full flex items-center"
      :class="`w-${ size } h-${ size }`"
      :style="`background-color: ${ modelValue }`"
    >
      <div
        v-if="!modelValue"
        class="w-full"
      >
        <div
          class="w-full border-b border-gray-400 transform rotate-45"
        />
        <div
          class="w-full border-b border-gray-400 transform -rotate-45"
        />
      </div>
    </div>
    <template #dropdown>
    <div
      class="flex flex-wrap justify-center ml-4 p2 bg-white rounded-xl"
      style="max-width: 22rem;"
    >
      <div class="w-full flex pt-1 px-2">
        <button
          class="w-10 h-10 m-2 bg-white border-2 border-gray-400 rounded-full"
          @click.prevent="setColor('')"
        >
          <div
            class="w-full border-b border-gray-400 transform rotate-45"
          />
          <div
            class="w-full border-b border-gray-400 transform -rotate-45"
          />
        </button>
        <button
          class="w-10 h-10 m-2 bg-white border border-gray-400 rounded-full"
          @click.prevent="setColor('#ffffff')"
        >
          <span
            v-if="modelValue ==='#ffffff'"
            class="inline text-gray-600 text-2xl"
          >&#x2713;</span>
        </button>
      </div>
      <button
        v-for="color in colors"
        :key="color"
        class="w-10 h-10 m-2 border border-gray-400 rounded-full"
        :style="`background-color: ${ color }`"
        @click.prevent="setColor(color)"
      >
        <span
          v-if="color === modelValue && color != ''"
          class="inline text-white text-2xl"
        >&#x2713;</span>
      </button>
    </div>
    </template>
  </dropdown>
</template>
<script>

export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },
    size: {
      type: String,
      default: '8'
    }
  },
  computed: {
    colors() {
      return [
        '#000000',
        '#00161d',
        '#3c3935',
        '#586069',
        '#a8abad',
        '#dae0de',

        '#8d3a42',
        '#9b3737',
        '#b73409',
        '#dc2626',
        '#ef4444',
        '#fecaca',

        '#884d3d',
        '#92400e',
        '#eeb471',
        '#fcd4a4',
        '#eadac0',
        '#eae4d8',

        '#aa433a',
        '#e95925',
        '#d56b41',
        '#f59e0b',
        '#fcc511',
        '#fde68a',

        '#457067',
        '#426d59',
        '#428e70',
        '#81a25b',
        '#acc27a',
        '#d1fae5',

        '#3d6e8f',
        '#699caf',
        '#90abc9',
        '#8db4b9',
        '#a7c4c0',
        '#c7d4cb',

        '#0c306c',
        '#3956a2',
        '#2563eb',
        '#3b82f6',
        '#7aa6cb',
        '#92c3ea',

        '#be185d',
        '#db2777',
        '#ec4899',
        '#dca2ae',
        '#ea98b1',
        '#fce7f3'
      ]
    }
  },
  methods: {
    setColor(color) {
      this.$emit('update:modelValue', color)
    }
  }
}
</script>
