<i18n>
{
  "en": {
    "only-pen": "free drawing only with pen",
    "delete-all": "delete image",
    "lock-mode": "Lock-Mode"
  },
  "de": {
    "only-pen": "freies Zeichnen nur mit Stift",
    "delete-all": "Bild löschen",
    "lock-mode": "Korrektur-Modus"
  }
}
</i18n>
<template>
  <section
    class="w-full"
  >
    <div
      id="editor-wrapper"
      ref="wrapper"
      class="w-full"
    >
      <div class="w-full flex flex-wrap px-1 sm:px-2 pb-2 items-center gap-1 sm:gap-x-2">
        <button
          class="border border-gray-900 rounded-full h-8 md:h-10 w-8 md:w-10 flex items-center justify-center"
          @click="undo"
        >
          <img :src="imagePath('arrow-rotate-left.svg')" class="w-5 h-5" />
        </button>
        <button
          v-if="showHand"
          class="border border-gray-900 text-gray-900 rounded-full h-8 md:h-10 w-8 md:w-10 flex items-center justify-center hover:bg-yellow-dark"
          :class="{'bg-yellow-dark': !editMode}"
          @click="setEditMode(false)"
        >
          <img :src="imagePath('hand-pointer.svg')" class="w-5 h-5" />
        </button>
        <div
          class="flex items-center h-8 md:h-10 border border-gray-900 rounded-full"
          :class="{'border-yellow-dark': editMode}"
        >
          <button
            name="btnToggleEditMode"
            class="ml-2 sm:ml-3 sm:mr-2 h-8 w-8 flex items-center justify-center"
            :class="currentToolClass"
            :style="{color: getColorForSelected()}"
            @click="setEditMode(true)"
          >
            <tool-icon :name="currentToolClass" class="w-6 h-6" />
          </button>
          <dropdown name="tool_selection">
            <icon
              class="w-6"
              name="cheveron-down"
            />
            <template #dropdown>
              <div class="origin-top-right absolute right-0 w-12 border border-gray-300 bg-white mt-2 mr-4 px-2 rounded-lg">
                <button
                  class="w-8 h-8 mt-2 mb-2 md:mb-4"
                  :style="{ 'color': getColor('freeDrawing') }"
                  name="free_drawing_tool"
                  @click="setTool('freeDrawing')"
                >
                  <tool-icon name="pen" />
                </button>
                <button
                  class="w-8 h-8 mt-2 mb-2 md:mb-4"
                  :style="{ color: getColor('freeDrawingFinger') }"
                  name="free_drawing_tool_finger"
                  @click="setTool('freeDrawingFinger')"
                >
                  <tool-icon name="finger-draw" />
                </button>
                <button
                  class="w-8 h-8 mb-2 md:mb-4"
                  :style="{ color: getColor('erase') }"
                  name="erase_tool"
                  @click="setTool('erase')"
                >
                  <tool-icon name="rubber" />
                </button>
                <button
                  v-if="showTextTool"
                  class="w-8 h-8 mb-2 md:mb-4 flext items-center justify-center"
                  :style="{ color: getColor('text') }"
                  name="text_tool"
                  @click="setTool('text')"
                >
                  <tool-icon name="font" class="w-6 h-6" />
                </button>
                <button
                  v-if="showTextTool"
                  class="w-8 h-8 mb-2 md:mb-4 flext items-center justify-center border border-black"
                  :style="{ color: getColor('textbox') }"
                  name="text_tool"
                  @click="setTool('textbox')"
                >
                  <tool-icon name="font" class="w-6 h-6" />
                </button>
                <button
                  class="w-8 h-8 mb-2 md:mb-4 flex items-center justify-center"
                  :style="{ color: getColor('line') }"
                  name="line_tool"
                  @click="setTool('line')"
                >
                  <tool-icon name="line" class="w-6 h-6" />
                </button>
                <button
                  class="w-8 h-8 mb-2 md:mb-4 flex items-center justify-center"
                  :style="{ color: getColor('rect') }"
                  name="rectangle_tool"
                  @click="setTool('rect')"
                >
                  <tool-icon name="square" class="w-6 h-6" />
                </button>
                <button
                  class="w-8 h-8 mb-2 md:mb-4 flex items-center justify-center"
                  :style="{ color: getColor('rect_solid') }"
                  name="rectangle_solid_tool"
                  @click="setTool('rect_solid')"
                >
                  <tool-icon name="square-full" class="w-6 h-6" />
                </button>
                <button
                  class="w-8 h-8 mb-2 md:mb-4 flex items-center justify-center"
                  :style="{ color: getColor('circle') }"
                  name="circle_tool"
                  @click="setTool('circle')"
                >
                  <tool-icon name="circle" class="w-6 h-6" />
                </button>
                <button
                  class="w-8 h-8 mb-2 flex items-center justify-center"
                  :style="{ color: getColor('circle_solid') }"
                  name="circle_solid_tool"
                  @click="setTool('circle_solid')"
                >
                  <tool-icon name="circle-full" class="w-6 h-6" />
                </button>
              </div>
            </template>
          </dropdown>
        </div>
        <div class="flex h-8 md:h-10 sm:px-1 items-center border border-gray-900 rounded-full sm:gap-x-2">
          <button
            class="w-4 h-6 m-1 flex items-center justify-center"
            @click="setSize('sm')"
          >
            <div
              class="rounded-full border"
              style="width: 0.35rem; height: 0.35rem;"
              :style="getColorStyle(params.strokeWidth === 2)"
            />
          </button>
          <button
            class="w-4 h-6 m-1 flex items-center justify-center"
            @click="setSize('md')"
          >
            <div
              class="w-2 h-2 rounded-full border"
              :style="getColorStyle(params.strokeWidth === 4)"
            />
          </button>
          <button
            class="w-4 h-6 m-1 flex items-center justify-center"
            @click="setSize('lg')"
          >
            <div
              class="w-3 h-3 rounded-full border border-gray-900"
              :style="getColorStyle(params.strokeWidth === 8)"
            />
          </button>
          <button
            class="w-4 h-6 m-1 flex items-center justify-center"
            @click="setSize('xl')"
          >
            <div
              class="w-4 h-4 rounded-full border border-gray-900"
              :style="getColorStyle(params.strokeWidth === 12)"
            />
          </button>
        </div>
        <div class="border border-gray-900 rounded-full">
          <dropdown name="color_selection">
            <div class="flex h-8 md:h-10 items-center">
              <div
                class="mx-1 md:mx-2 w-6 h-6 rounded-full"
                :style="{backgroundColor: color}"
              />
              <icon
                class="w-6"
                name="cheveron-down"
              />
            </div>
            <template #dropdown>
              <div class="origin-top-right absolute right-0 w-12 bg-white border border-gray-300 mt-2 mr-2 pt-2 px-2 rounded-lg">
                <button
                  class="w-8 h-8 rounded-full mb-4"
                  style="background-color: #e40000"
                  @click="changeColor('#e40000')"
                />
                <button
                  class="w-8 h-8 rounded-full mb-4"
                  style="background-color: #e8eb34"
                  @click="changeColor('#e8eb34')"
                />
                <button
                  class="w-8 h-8 rounded-full mb-4"
                  style="background-color: #a834eb"
                  @click="changeColor('#a834eb')"
                />
                <button
                  class="w-8 h-8 rounded-full mb-4"
                  style="background-color: #65c31a"
                  @click="changeColor('#65c31a')"
                />
                <button
                  class="w-8 h-8 rounded-full mb-4"
                  style="background-color: #34b7eb"
                  @click="changeColor('#34b7eb')"
                />
                <button
                  class="w-8 h-8 rounded-full mb-4"
                  style="background-color: #eb34df"
                  @click="changeColor('#eb34df')"
                />
                <button
                  class="w-8 h-8 rounded-full mb-4"
                  style="background-color: #1a10ad"
                  @click="changeColor('#1a10ad')"
                />
                <button
                  class="w-8 h-8 rounded-full mb-2"
                  style="background-color: #000000"
                  @click="changeColor('#000000')"
                />
              </div>
            </template>
          </dropdown>
        </div>
        <dropdown name="btnMore">
          <div  class="h-8 md:h-10 w-8 md:w-10 flex items-center justify-center border border-gray-900 rounded-full">
            <icon
              class="w-6"
              name="ellipsis-v"
            />
          </div>
          <template #dropdown>
            <div class="bg-white border border-gray-300 mt-2 py-1 px-2 rounded">
              <button
                name="btnLines1"
                class="w-8 h-8 p-1 mb-2 flex items-center justify-center"
                @click="toggleBg4Lines_1()"
              >
                <img :src="imagePath('bg_lines_1.svg')">
              </button>
              <button
                name="btnLines2"
                class="w-8 h-8 p-1 mb-2 flex items-center justify-center"
                @click="toggleBg4Lines_2()"
              >
                <img :src="imagePath('bg_lines_2.svg')">
              </button>
              <button
                name="btnLines3"
                class="w-8 h-8 p-1 mb-2 flex items-center justify-center"
                @click="toggleBg2Lines()"
              >
                <img :src="imagePath('bg_lines_3.svg')">
              </button>
              <button
                name="btnLines"
                class="w-8 h-8 p-1 mb-2 flex items-center justify-center"
                @click="toggleBgLines()"
              >
                <img :src="imagePath('bg_lines.svg')">
              </button>
              <button
                name="btnSquares"
                class="w-8 h-8 p-1 flex items-center justify-center"
                @click="toggleBgSquares()"
              >
                <img :src="imagePath('bg_squares.svg')">
              </button>
            </div>
          </template>
        </dropdown>
        <div
          class="flex gap-1 sm:gap-x-2 transition-opacity duration-500"
          :class="{'opacity-0': !object_selected, 'opacity-100': object_selected }"
        >
          <button
            class="w-8 md:w-10 h-8 md:h-10 border border-gray-900 rounded-full flex items-center justify-center"
            @click="bringToFront"
          >
            <img class="w-6" :src="imagePath('object_frontward.svg')">
          </button>
          <button
            class="w-8 md:w-10 h-8 md:h-10 border border-gray-900 rounded-full flex items-center justify-center"
            @click="sendToBack"
          >
            <img class="w-6" :src="imagePath('object_backward.svg')">
          </button>
          <button
            class="w-8 md:w-10 h-8 md:h-10 border border-gray-900 rounded-full flex items-center justify-center"
            :class="frozenClassForSelected"
            @click="toggleFrozen()"
          >
            <ToolIcon name="vector-square" class="w-5 h-5" />
          </button>
          <button
            v-if="$page.props.auth.user.owner"
            class="w-8 md:w-10 h-8 md:h-10 border border-gray-900 rounded-full flex items-center justify-center"
            :class="lockedClassForSelected"
            @click="toggleLocked()"
          >
            <ToolIcon name="lock" class="w-5 h-5" />
          </button>
          <button
            class="w-8 md:w-10 h-8 md:h-10 border border-gray-900 rounded-full flex items-center justify-center"
            name="btnRemove"
            @click="removeSelected()"
          >
            <img :src="imagePath('trash-can.svg')" class="w-5 h-5">
          </button>
        </div>
        <button
          v-if="showRemoveAll"
          class="w-8 ml-auto md:w-10 h-8 md:h-10 border bg-red-dark text-white rounded-full flex items-center justify-center"
          @click="removeAll()"
        >
          <icon
            v-tippy
            :content="$t('delete-all')"
            class="w-4 h-4"
            name="cross"
          />
        </button>
        <slot />
      </div>
      <div
        id="canvas-wrapper"
        class="w-full overflow-y-auto bg-gray-400 scrollbar"
        :style="getHeightStyle()"
      >
        <canvas
          id="canvas" width="100" height="100"
          class="border-2 md:border-b-4 border-yellow-dark"
        />
      </div>
    </div>
  </section>
</template>

<script>
  import { round } from 'lodash'
  import { fabric } from 'fabric'
  import CanvasHelper from './canvasHelper'
  import CanvasUtils from './canvasUtils'
  import FabricUtils from './fabricUtils'
  import Shape from './shape'
  import Text from './text'
  import FontFaceObserver from 'fontfaceobserver'

  export default {
    name: 'Editor',
    props: {
      value: {
        type: Object,
        required: true,
      },
      clipUrl: {
        type: String,
        default: null,
        required: false
      },
      width: {
        type: Number,
        default: null,
        required: false
      },
      height: {
        type: Number,
        default: null,
        required: false
      },
      showHand: {
        type: Boolean,
        default: true,
        required: false
      },
      showTextTool: {
        type: Boolean,
        default: true,
        required: false
      },
      showRemoveAll: {
        type: Boolean,
        default: false,
        required: false
      },
    },
    data() {
      return {
        currentTool: null,
        editMode: false,
        currentViewTool: null,
        object_selected: null,
        params: {
          stroke: '',
          fill: '',
          strokeWidth: 2,
          fontSize: 32,
          fontFamily: 'Sassoon Montessori W01 Regular',
          fontStyle: '',
          placeholder: 'Text'
        },
        color: '#000000',
        page: {
          width: this.width ? this.width : 1080,
          height: this.height ? this.height : 1528
        },
        lock_mode: false,
        only_pen: true,
      }

    },
    computed: {
      form: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('update:modelValue', val)
        },
      },
      currentToolClass() {
        switch (this.currentTool) {
          case 'freeDrawing':
            return 'pen'
          case 'freeDrawingFinger':
            return 'finger-draw'
          case 'erase':
            return 'rubber'
          case 'text':
            return 'font'
          case 'textbox':
            return 'font'
          case 'line':
            return 'line'
          case 'rect':
            return 'square'
          case 'rect_solid':
            return 'square-full'
          case 'circle':
            return 'circle'
          case 'circle_solid':
            return 'circle-full'
          default:
            return ''
        }
      },
      frozenClassForSelected() {
        if(this.object_selected && this.object_selected.frozen === false) {
          return "bg-blue-800 text-white"
        } else {
          return "text-gray-900"
        }
      },
      lockedClassForSelected() {
        if(this.object_selected && this.object_selected.locked === true) {
          return "bg-red-800 text-white"
        } else {
          return "text-gray-900"
        }
      },
    },
    mounted() {
      let font = new FontFaceObserver(this.params.fontFamily)
      let inst = this
      font.load().then(function () {
        inst.init()
      })

      // set honeybadger context
      if(this.$honeybadger) {
        this.$honeybadger.setContext({
          user_id: this.$page.props.auth.user.id
        })
      }
    },
    beforeDestroy() {
      if(this.canvas) {
        this.canvas.setWidth(0)
        this.canvas.setHeight(0)
        this.canvas.clear()
        this.canvas.interactive && this.canvas.removeListeners()
      }
      this.tool_selected = null
    },
    methods: {
      init() {
        // init meta object
        // convert string to json
        if(this.form.meta) {
          if(typeof(this.form.meta) != 'object')
            this.form.meta = JSON.parse(this.form.meta)
        } else {
          this.form.meta = {}
        }

        // init fabric canvas
        this.canvas = FabricUtils.init(this.page.width, this.page.height)

        CanvasHelper.drawContent(this.canvas, this.form.content, this.form.file_urls, this.form.meta, this.$page.props.auth.user.owner)

        if(this.clipUrl) {
          CanvasHelper.createBackgroundImage(this.canvas, this.clipUrl, this.form.meta)
        }

        // set initial editor mode
        this.currentTool = 'freeDrawing'

        // set initial color selection
        this.color= '#1a10ad'

        let inst = this

        // event handling
        this.canvas.on('object:modified', function(options) {
          inst.$emit('dirtyChanged', true)
        })

        this.canvas.on('path:created', function(options) {
          if(options.path) {
            inst.$emit('dirtyChanged', true)
          }
        })

        this.canvas.on('selection:created', function(options) {
          // disable scroll only for unlocked objects, to be able to modify
          if(!options.selected[0].frozen) {
            inst.disableScroll()
          }

          inst.object_selected = options.selected[0]
        })

        this.canvas.on('selection:updated', function(options) {
          // disable scroll only for unlocked objects, to be able to modify
          if(!options.selected[0].frozen) {
            inst.disableScroll()
          }

          inst.object_selected = options.selected[0]
        })

        this.canvas.on('selection:cleared', function(options) {
          inst.enableScroll()
          inst.object_selected = null
        })
      },
      disableScroll() {
        this.canvas.allowTouchScrolling = false;
      },
      enableScroll() {
        this.canvas.allowTouchScrolling = true;
      },
      toObject() {
        return CanvasHelper.getClone(this.canvas)
      },
      toSvg() {
        return this.canvas.toSVG({ width: 300, height: 150 })
      },
      toBlob() {
        return CanvasHelper.getBlob(this.canvas)
      },
      toDataUrl() {
        return CanvasHelper.getDataUrl(this.canvas)
      },
      clear() {
        this.canvas.clear()
      },
      set(type) {
        switch (type) {
          case 'text':
            this.params.fill = this.color
            this.addText(this.params)
            break

          case 'textbox':
            this.params.fill = this.color
            this.addTextbox(this.params)
            break

          case 'circle':
            this.params.fill = 'transparent'
            this.addShape('circle')
            break

          case 'circle_solid':
            this.params.fill = this.color
            this.addShape('circle')
            break

          case 'rect':
            this.params.fill = 'transparent'
            this.params.stroke = this.color
            this.addShape('rect')
            break

          case 'rect_solid':
            this.params.fill = this.color
            this.params.stroke = this.color
            this.addShape('rect')
            break

          case 'line':
            this.params.stroke = this.color
            this.addShape('line')
            break

          case 'selectMode':
            // this.currentActiveMethod = this.drag
            this.canvas.isDrawingMode = false
            this.canvas.forEachObject(object => {
              // object.selectable = true
              object.evented = true
            })
            // remove event listeners for object creation
            if (this.canvas.__eventListeners) {
              this.canvas.__eventListeners['mouse:down'] = null
              this.canvas.__eventListeners['mouse:move'] = null
              this.canvas.__eventListeners['mouse:up'] = null
            }
            break

          case 'freeDrawing':
            this.disableScroll()
            this.params.stroke = this.color
            this.params.drawingMode = true
            this.only_pen = true
            this.drawing(this.params)
            break

          case 'freeDrawingFinger':
            this.disableScroll()
            this.params.stroke = this.color
            this.params.drawingMode = true
            this.only_pen = false
            this.drawing(this.params)
            break

          case 'erase':
            this.disableScroll()
            // this.params.stroke = this.color
            this.params.drawingMode = true
            this.erase(this.params)
            break

          default:
        }
      },
      addImage(signed_id, url) {
        CanvasHelper.createImage(this.canvas, signed_id, url)
        this.form.files.push({signed_id: signed_id })
      },
      addShape(type) {
        let inst = this
        this.tool_selected = new Shape(this.canvas, type, this.params, () => { inst.setEditMode(false) })
      },
      addText(params) {
        let inst = this
        this.tool_selected = new Text(this.canvas, params, 'Text', () => { inst.setEditMode(false) })
      },
      addTextbox(params) {
        let inst = this
        this.tool_selected = new Text(this.canvas, params, 'Textbox', () => { inst.setEditMode(false) })
      },
      drawing(params) {
        if (this.canvas.__eventListeners) {
          this.canvas.__eventListeners['object:added'] = null
        }
        this.canvas.isDrawingMode = params.drawingMode

        if (this.only_pen) {
          this.canvas.freeDrawingBrush = new fabric.PrimaOnlyPencilBrush(this.canvas)
        } else {
          this.canvas.freeDrawingBrush = new fabric.PrimaPencilBrush(this.canvas)
        }

        this.canvas.freeDrawingBrush.color = params.stroke
        this.canvas.freeDrawingBrush.width = params.strokeWidth
      },
      erase(params) {
        this.canvas.isDrawingMode = params.drawingMode
        this.canvas.freeDrawingBrush = new fabric.EraserBrush(this.canvas);
        this.canvas.freeDrawingBrush.width = params.strokeWidth
      },
      getColor(tool) {
        if(tool === this.currentTool) {
          if(this.currentTool == 'erase')
            return '#e40000'

          return this.color
        } else {
          return '#111827'
        }
      },
      getColorForSelected() {
        if(this.editMode) {
          if(this.currentTool == 'erase')
            return '#e40000'

          return this.color
        } else {
          return '#111827'
        }
      },
      getColorStyle(val) {
        if(val) {
          return { 'background-color': this.color, 'border-color': this.color }
        } else {
          return { 'border-color': this.color }
        }
      },
      setSize(size) {
        if(size === 'sm') {
          this.params.strokeWidth = 2
          this.params.fontSize = 16
          this.setEditMode(true)
        }
        if(size === 'md') {
          this.params.strokeWidth = 4
          this.params.fontSize = 32
          this.setEditMode(true)
        }
        if(size === 'lg') {
          this.params.strokeWidth = 8
          this.params.fontSize = 64
          this.setEditMode(true)
        }
        if(size === 'xl') {
          this.params.strokeWidth = 12
          this.params.fontSize = 128
          this.setEditMode(true)
        }
      },
      toggleEditMode() {
        this.setEditMode(!this.editMode)
      },
      setEditMode(val) {
        this.editMode = val
        // exit edit mode if active object is IText
        let objects = this.canvas.getObjects()
        objects.forEach(object => {
          if(object.text) {
            object.exitEditing()
          }
        })

        if(this.editMode) {
          this.set(this.currentTool)
          this.disableScroll()
        } else {
          this.set('selectMode')
          this.enableScroll()
        }
      },
      setTool(name) {
        if(this.canvas.getActiveObject() && this.canvas.getActiveObject().text) {
          this.canvas.getActiveObject().exitEditing()
        }
        this.canvas.discardActiveObject()
        this.currentTool = name
        this.editMode = true
        this.disableScroll()
        this.canvas.isDrawingMode = false
        this.set(this.currentTool)
      },
      setCurrentTool() {
        this.setTool(this.currentTool)
      },
      scaleBackgroundImage() {
        if(this.form.meta && this.form.meta.bg_image_scale && this.canvas.backgroundImage) {
          this.canvas.backgroundImage.scale(this.form.meta.bg_image_scale)
          this.canvas.renderAll(this.canvas)
        }
      },
      toggleFrozen() {
        let obj = this.object_selected
        if(obj) {
          let val = obj.frozen

          CanvasUtils.freezeObject(obj, !val)
          this.canvas.renderAll(this.canvas)
        }
      },
      toggleLocked() {
        let obj = this.object_selected
        if(obj) {
          let val = obj.locked

          CanvasUtils.lockObject(obj, !val)
          this.canvas.renderAll(this.canvas)
        }
      },
      removeSelected() {
        let objects = []
        if (this.canvas.getActiveObject()) {
          this.canvas.getActiveObjects().forEach(obj => {
            objects.push(obj)
          })
        }
        this.remove(objects)
      },
      removeAll() {
        let objects = []
        this.canvas.getObjects().forEach(obj => {
          if(!obj.frozen) {
            objects.push(obj)
          }
        })
        this.remove(objects)
      },
      remove(objects) {
        const image_objects = objects.filter(item => item.signed_id)

        this.canvas.remove.apply(this.canvas, objects)

        if(this.form.files && this.form.files.length > 0 && image_objects.length > 0) {
          image_objects.forEach(item => {
            console.log('try to find', item.signed_id)
            this.form.files.forEach(file => {
              if(file.signed_id == item.signed_id) {
                console.log('mark to remove', item.signed_id)
                file._destroy = true
              }
            })
          })
        }
        this.canvas.discardActiveObject()
      },
      changeColor(colorProperty) {
        this.color = colorProperty
        this.params.stroke = colorProperty
        this.setCurrentTool()

        this.disableScroll()
      },
      toggleBg4Lines_1() {
        if(this.form.meta.bg_structure && this.form.meta.bg_structure === '4lines_1') {
          this.form.meta.bg_structure = ''
          CanvasUtils.removeBg(this.canvas)
        } else {
          this.form.meta.bg_structure = '4lines_1'
          CanvasUtils.setBg4Lines_1(this.canvas)
        }
      },
      toggleBg4Lines_2() {
        if(this.form.meta.bg_structure && this.form.meta.bg_structure === '4lines_2') {
          this.form.meta.bg_structure = ''
          CanvasUtils.removeBg(this.canvas)
        } else {
          this.form.meta.bg_structure = '4lines_2'
          CanvasUtils.setBg4Lines_2(this.canvas)
        }
      },
      toggleBg2Lines() {
        if(this.form.meta.bg_structure && this.form.meta.bg_structure === '2lines') {
          this.form.meta.bg_structure = ''
          CanvasUtils.removeBg(this.canvas)
        } else {
          this.form.meta.bg_structure = '2lines'
          CanvasUtils.setBg2Lines(this.canvas)
        }
      },
      toggleBgLines() {
        if(this.form.meta.bg_structure && this.form.meta.bg_structure === 'lines') {
          this.form.meta.bg_structure = ''
          CanvasUtils.removeBg(this.canvas)
        } else {
          this.form.meta.bg_structure = 'lines'
          CanvasUtils.setBgLines(this.canvas)
        }
      },
      toggleBgSquares() {
        if(this.form.meta.bg_structure && this.form.meta.bg_structure === 'squares') {
          this.form.meta.bg_structure = ''
          CanvasUtils.removeBg(this.canvas)
        } else {
          this.form.meta.bg_structure = 'squares'
          CanvasUtils.setBgSquares(this.canvas)
        }
      },
      bringToFront() {
        let obj = this.canvas.getActiveObject()
        if(obj) {
          this.canvas.bringToFront(obj)
          this.canvas.discardActiveObject()
          this.canvas.renderAll(this.canvas)
        }
      },
      sendToBack() {
        let obj = this.canvas.getActiveObject()
        if(obj) {
          this.canvas.sendBackwards(obj)
          this.canvas.discardActiveObject()
          this.canvas.renderAll(this.canvas)
        }
      },
      getHeightStyle() {
        if(!this.height) {
          // maybe adjust here what to subtract
          // if(this.$refs.wrapper)
          //   console.log(this.$refs.wrapper.clientWidth)
          return {
            height: 'calc(100vh - 130px)'
          }
        }
      },
      scalePlus() {
        let obj = this.canvas.getActiveObject()
        if(obj) {
          let x = round(obj.scaleX + .025, 2)
          let y = round(obj.scaleY + .025, 2)
          obj.scale(x, y)
          this.canvas.renderAll(this.canvas)
        }
      },
      scaleMinus() {
        let obj = this.canvas.getActiveObject()
        if(obj) {
          let x = round(obj.scaleX - .025, 2)
          let y = round(obj.scaleY - .025, 2)
          obj.scale(x, y)
          this.canvas.renderAll(this.canvas)
        }
      },
      undo() {
        this.canvas.undo(() => {
          CanvasUtils.updateFrozenObjects(this.canvas)
        })
      }
    }
  }
</script>
<style scoped>
.upper-canvas{
  z-index: 1;
}
.canvas-container {
  overflow-y: auto;
}
.scrollbar {
  scrollbar-width: auto;
}
/* width */
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #edf2f7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 100vh;
  border: 3px solid #edf2f7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}
</style>
