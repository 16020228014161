// Automatic Global Registration of Base Components
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

const componentFiles = import.meta.glob('../Shared/**/*.vue', { eager: true });

const sharedCompontents = (app) => {
  Object.entries(componentFiles).forEach(([path, m]) => {
    const componentName = upperFirst(
      camelCase(
        path
          .split('/')
          .pop()
          .replace(/\.\w+$/, ''),
      ),
    );

    // console.log(path)
    // Register component globally
    app.component(
      componentName,
      // Look for the component options on `.default`, which will
      // exist if the component was exported with `export default`,
      // otherwise fall back to module's root.
      m.default || m,
    );
  });
}

export default sharedCompontents
