const staticHost = document
  .getElementsByName('static-host')[0]
  .getAttribute('content')

const getStaticUrl = (route) => {
  if (staticHost) {
    return `${staticHost}/${route}`
  }
}

export default getStaticUrl
