<i18n>
{
  "en": {
    "title": "Registration",
    "account": "School-Account-ID",
    "account_placeholder": "you will receive your school account id from your secretary",
    "first_name": "First Name",
    "first_name_placeholder": "your first name",
    "last_name": "Last Name",
    "last_name_placeholder": "your last name",
    "email": "Email",
    "email_placeholder": "your email address",
    "password": "Password",
    "password_placeholder": "your password",
    "password_confirmation": "Password Confirmation",
    "password_confirmation_placeholder": "confirm your password",
    "cancel": "Cancel",
    "register": "Register",
    "register_information": "after you are registered, we'll send you a confirmation email including a link for activating your user account",
    "terms_accepted_1": "I accept the",
    "terms_accepted_2": "terms and conditions"
  },
  "de": {
    "title": "Registrierung",
    "account": "Schul-Account-ID",
    "account_placeholder": "Sie erhalten die Schul-Account-Id vom Sekretariat Ihrer Schule",
    "first_name": "Vorname",
    "first_name_placeholder": "Ihr Vorname",
    "last_name": "Nachname",
    "last_name_placeholder": "Ihr Nachname",
    "email": "E-Mail",
    "email_placeholder": "Ihre Email Adresse",
    "password": "Passwort",
    "password_placeholder": "Bitte geben Sie hier ein sicheres Passwort ein",
    "password_confirmation": "Passwortbestätigung",
    "password_confirmation_placeholder": "Nochmalige Eingabe Ihres Passworts",
    "cancel": "Abbrechen",
    "register": "Registrieren",
    "register_information": "Wir senden Ihnen nach der Registrierung eine Bestätigungsmail mit einem Link zur Aktivierung Ihres Benutzerkontos.",
    "terms_accepted_1": "Mit der Anmeldung akzeptiere ich die",
    "terms_accepted_2": "Nutzungsbedingungen"
  }
}
</i18n>

<template>
  <form
    class="overflow-hidden text-gray-900 bg-yellow-dark border rounded-lg max-w-xl mx-auto mb-12"
    @submit.prevent="submit"
  >
    <div class="px-4 md:px-10 py-6">
      <h1 class="text-3xl font-bold text-center">
        {{ $t('title') }}
      </h1>
      <img class="w-40 mx-auto mt-4" :src="imagePath('prima.schule_wr.svg')" />
      <div class="w-24 mx-auto my-4 border-white border-b-2" />

      <text-input
        v-model="form.account_id"
        :errors="$page.props.errors && $page.props.errors.account_id"
        class="mt-4 md:mt-10"
        :label="$t('account')"
        :placeholder="$t('account_placeholder')"
        autofocus
        autocomplete="off"
      />

      <text-input
        v-model="form.first_name"
        :errors="$page.props.errors && $page.props.errors.first_name"
        class="mt-4 md:mt-10"
        :label="$t('first_name')"
        :placeholder="$t('first_name_placeholder')"
      />

      <text-input
        v-model="form.last_name"
        :errors="$page.props.errors && $page.props.errors.name"
        class="mt-4 md:mt-10"
        :label="$t('last_name')"
        :placeholder="$t('last_name_placeholder')"
      />

      <text-input
        v-model="form.email"
        class="mt-4 md:mt-10"
        :label="$t('email')"
        :placeholder="$t('email_placeholder')"
        :errors="$page.props.errors && $page.props.errors.email"
        type="email"
        autocomplete="email"
      />

      <text-input
        v-model="form.password"
        name="password"
        class="mt-6"
        :label="$t('password')"
        :placeholder="$t('password_placeholder')"
        :errors="$page.props.errors && $page.props.errors.password"
        type="password"
        autocomplete="new-password"
      />
      <text-input
        v-model="form.password_confirmation"
        name="password_confirmation"
        class="mt-6"
        :label="$t('password_confirmation')"
        :placeholder="$t('password_confirmation_placeholder')"
        :errors="$page.props.errors && $page.props.errors.password_confirmation"
        type="password"
        autocomplete="new-password"
      />
      <label class="flex items-center mt-6 select-none" for="terms_accepted">
        <input
          id="terms_accepted"
          v-model="form.terms_accepted"
          class="form-checkbox"
          type="checkbox"
        />
        <span class="ml-2">
          {{ $t('terms_accepted_1') }}
          <a
            class="text-indigo-600 font-bold hover:underline focus:outline-none"
            :href="$getStaticUrl('conditions')"
            target="_blank"
            >{{ $t('terms_accepted_2') }}</a
          >
        </span>
      </label>
      <div class="mt-4">
        {{ $t('register_information') }}
      </div>
    </div>

    <div
      class="flex items-center justify-between px-10 py-4 bg-gray-100 border-t border-gray-200"
    >
      <Link
        class="hover:underline focus:outline-none"
        tabindex="-1"
        :href="$routes.new_user_session()"
      >
        {{ $t('cancel') }}
      </Link>
      <loading-button
        :loading="sending"
        class="btn-red"
        type="submit"
        :disabled="!isPresent"
      >
        {{ $t('register') }}
      </loading-button>
    </div>
  </form>
  <footer-actions :bg-visible="true">
    <a class="py-2 text-sm sm:text-base" :href="$getStaticUrl('')"
      >© prima.schule</a
    >
    <ul class="ml-auto flex">
      <li class="sm:mr-2 p-2 rounded hover:bg-yellow-dark hover:text-white">
        <a :href="$getStaticUrl('terms')">Impressum</a>
      </li>
      <li class="p-2 rounded hover:bg-yellow-dark hover:text-white">
        <a :href="$getStaticUrl('privacy')">Datenschutz</a>
      </li>
    </ul>
  </footer-actions>
</template>

<script>
export default {
  data() {
    return {
      sending: false,
      form: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        password_confirmation: null,
        account_id: null,
        terms_accepted: null,
      },
    }
  },
  computed: {
    isPresent() {
      return (
        this.form.account_id &&
        this.form.email &&
        this.form.password &&
        this.form.password_confirmation &&
        this.form.first_name &&
        this.form.last_name &&
        this.form.terms_accepted
      )
    },
  },
  methods: {
    submit() {
      this.$inertia.post(
        this.$routes.user_registration(),
        {
          user: this.form,
        },
        {
          onStart: () => (this.sending = true),
          onFinish: () => (this.sending = false),
        },
      )
    },
  },
}
</script>
