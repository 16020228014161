<i18n>
{
  "en": {
    "caption": "Caption",
    "url": "url",
    "token": "access token",
    "room": "Room",
    "room-description": "Caption and url for the room. The access code will be shown as tip text over the video-chat image."
  },
  "de": {
    "caption": "Bezeichnung",
    "url": "Internetadresse (url)",
    "token": "Zugangscode",
    "room": "Raum",
    "room-description": "Bezeichnung und Internetadresse des digitalen Klassenzimmers. Der Zugangscode wird als Tiptext über dem Video-Chat-Symbol angezeigt."
  }
}
</i18n>
<template>
  <form
    autocomplete="off"
    @submit.prevent="$emit('submit')"
  >
    <form-group
      :first="true"
      :last="true"
      class="mt-8"
      :name="$t('room')"
      :description="$t('room-description')"
    >
      <text-input
        v-model="form.name"
        :errors="$page.props.errors && $page.props.errors.name"
        class="pr-6 pb-8 w-full"
        :label="$t('caption')"
      />
      <text-input
        v-model="form.url"
        :errors="$page.props.errors && $page.props.errors.url"
        class="pr-6 pb-8 w-full"
        :label="$t('url')"
      />
      <text-input
        v-model="form.access_pin"
        :errors="$page.props.errors && $page.props.errors.access_pin"
        class="pr-6 pb-8 w-full"
        :label="$t('token')"
      />
    </form-group>
    <slot />
  </form>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
}
</script>
