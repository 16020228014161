<template>
  <div class="flex items-center">
    <div class="flex-shrink-0">
      <img
        v-if="user.avatar"
        class="w-10 h-10 rounded-full"
        :src="user.avatar"
        alt=""
      >
      <svg
        v-else
        viewBox="0 0 496 512"
        class="w-10 h-10"
      >
        <path
          fill="currentColor"
          d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"
        />
      </svg>
    </div>

    <div class="ml-3">
      <p class="text-base font-medium leading-normal text-gray-900">
        {{ user.name }}
      </p>

      <div class="flex text-base text-gray-500">
        <time
          v-if="date"
          :datetime="isoDate"
        >
          {{ $filters.toDate(date, "ddd DD.MM.YYYY HH:mm") }}
        </time>
      </div>

      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    date: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    isoDate() {
      return new Date(this.date).toISOString().split('T')[0]
    },
  },
}
</script>
