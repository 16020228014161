<template>
  <button
    :disabled="loading"
    class="flex items-center"
    @click="click()"
  >
    <div
      v-if="loading"
      class="btn-spinner mr-2"
    />
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    loading: Boolean,
  },
  emits: ['click'],
  methods: {
    click() {
      this.$emit('click')
    },
  },
}
</script>
