<template>
  <div :class="{'mt-2 md:mt-4': first, 'mt-2': !first, 'mb-2 md:mb-3': last}">
    <div class="md:grid lg:grid-cols-3 lg:gap-6">
      <div class="lg:col-span-1">
        <div class="">
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            {{ name }}
          </h3>
          <p class="my-1 lg:pb-4 text-base leading-5 text-gray-700">
            {{ description }}
          </p>
        </div>
      </div>
      <div class="lg:col-span-2">
        <div
          class="px-4 py-2 bg-white border-2 border-gray-400 sm:rounded"
          :class="{'flex items-start gap-2 md:gap-4': flexed}"
        >
          <slot />
        </div>
      </div>
    </div>
    <div
      v-if="!last"
      class="hidden sm:block"
    >
      <div class="md:mt-2 py-4">
        <div class="border-t border-gray-400" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    first: {
      type: Boolean,
      required: false,
      default: false,
    },
    last: {
      type: Boolean,
      required: false,
      default: false,
    },
    flexed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
