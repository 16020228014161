<i18n>
{
  "en": {
    "title": "Plan",
    "restore": "restore",
    "deleted": "deleted",
    "delete": "delete permanently",
    "media": "mediCat",
    "memo": "digiDoc"
  },
  "de": {
    "title": "Wochenplan",
    "restore": "wiederherstellen",
    "deleted": "gelöscht",
    "delete": "entgültig löschen",
    "media": "mediCat",
    "memo": "digiDok"
  }
}
</i18n>
<template>
  <layout
    class="bg-fixed bg-cover bg-no-repeat"
    :header-enabled="false"
    :style="planBgImageStyle"
  >
    <section class="w-full" :style="planColorStyle">
      <div
        class="w-full max-w-screen-xl mx-auto px-2 md:px-4 pt-2 md:pt-4 pb-16"
      >
        <header
          class="w-full border-b-2 mb-2"
          :style="`border-color: ${plan.fg_color}`"
        >
          <div class="w-full flex items-center">
            <button class="btn-back items-center" @click="navigateBack()">
              <icon class="w-10 h-10 md:w-12 md:h-12" name="arrow-left" />
            </button>
            <h1
              class="ml-2 md:ml-6 pt-1 text-lg sm:text-2xl flex-grow"
              :class="{ 'line-through': plan.discarded_at }"
            >
              <span>{{ getTitle() }}</span>
              <span v-if="plan.title"> ({{ plan.title }})</span>
            </h1>
            <div v-if="plan.discarded_at" class="mr-12 font-bold text-red-600">
              {{ $t('deleted') }}
            </div>
          </div>
          <div class="w-full flex items-center">
            <div class="inline-flex">
              <button
                v-for="day in days"
                :key="day.id"
                class="w-12 md:w-20 py-2"
                :class="{
                  'font-bold text-3xl md:text-4xl': day.id === current_day.id,
                }"
                @click="current_day = day"
              >
                {{ day.label }}
              </button>
            </div>
          </div>
        </header>
        <div class="inline-block w-full overflow-x-hidden">
          <div class="swiper-container">
            <div class="swiper-wrapper tasks">
              <div v-for="day in days" :key="day.id" class="swiper-slide">
                <div class="flex w-full flex-wrap">
                  <div
                    v-if="getIntroText(day)"
                    v-dompurify-html="getIntroText(day)"
                    class="ql-editor w-full px-2 pb-4"
                  />
                  <ul name="lessons" class="w-full">
                    <li
                      v-for="lesson in getLessons(day)"
                      :key="lesson.id"
                      class="w-full my-2 md:my-4 lg:my-6"
                    >
                      <lesson :plan="plan" :lesson="lesson" />
                    </li>
                  </ul>
                  <ul class="tasks w-full">
                    <li
                      v-for="task in getTasks(day)"
                      :key="task.id"
                      class="
                        task
                        w-full
                        flex flex-wrap
                        p-2
                        lg:p-4
                        my-2
                        md:my-4
                        border
                        rounded
                      "
                      :style="{
                        backgroundColor: task.bg_color,
                        color: task.fg_color,
                        borderColor: getBorderColor(task),
                      }"
                    >
                      <div
                        v-if="
                          $page.props.auth.user.owner && task.users.length > 0
                        "
                        class="w-full flex flex-wrap mb-2"
                      >
                        <div
                          v-for="user in task.users"
                          :key="user.name"
                          class="mr-2 p-2 border rounded"
                          :style="{ borderColor: task.fg_color }"
                        >
                          {{ user.name }}
                        </div>
                      </div>
                      <div class="w-1/2 md:w-1/6 mt-2 pb-2 md:pb-4">
                        <div class="w-full flex">
                          <div
                            v-if="task.subject"
                            class="px-4 py-2 border rounded"
                            :style="{
                              backgroundColor: task.subject.bg_color,
                              color: task.subject.fg_color,
                              borderColor: getBorderColor(task),
                            }"
                          >
                            {{ task.subject.short_name }}
                          </div>
                          <div
                            v-if="task.is_homework"
                            class="
                              ml-2
                              p-2
                              w-10
                              md:w-12
                              h-10
                              md:h-12
                              flex
                              items-center
                              justify-center
                              border
                              rounded-full
                            "
                            :style="{ borderColor: task.fg_color }"
                          >
                            HA
                          </div>
                        </div>
                      </div>
                      <div class="w-full md:w-5/6 md:pb-2">
                        <div class="flex">
                          <img
                            v-if="
                              task.tasks_clips_attributes.some(
                                (item) => item.is_task_thumb === true,
                              )
                            "
                            class="w-16 mr-2 mt-0 mb-auto"
                            :src="getThumbUrl(task)"
                          />
                          <div
                            v-if="task.content"
                            v-dompurify-html="task.content"
                            class="ql-editor w-full mt-2"
                          />
                          <button
                            v-if="$page.props.auth.user.owner"
                            class="
                              w-8
                              h-8
                              ml-auto
                              mt-2
                              p-2
                              flex
                              items-center
                              justify-center
                              border
                              rounded-full
                            "
                            :style="{ borderColor: task.fg_color }"
                            @click="$inertia.get($routes.task(task.id))"
                          >
                            <icon class="w-2 h-4" name="i" />
                          </button>
                        </div>
                      </div>
                      <div
                        v-if="task.tasks_clips_attributes.length > 0"
                        class="clips w-full py-2 flex flex-wrap"
                      >
                        <div class="w-full md:w-1/6">
                          <!-- <div class="my-2">
                            {{ $t('media') }}
                          </div> -->
                        </div>
                        <div
                          class="
                            w-full
                            md:w-5/6
                            flex flex-wrap
                            items-center
                            gap-2
                            md:gap-4
                          "
                        >
                          <div
                            v-for="tasks_clip in task.tasks_clips_attributes.filter(
                              (item) => item.is_task_thumb === false,
                            )"
                            :key="tasks_clip.id"
                            class="clip cursor-pointer"
                          >
                            <video
                              v-if="
                                tasks_clip.clip &&
                                tasks_clip.clip.clip_type === 'video'
                              "
                              :src="tasks_clip.clip.download_url"
                              controls
                              :type="tasks_clip.clip.clip_type"
                              :poster="tasks_clip.clip.clip_url"
                            />
                            <audio
                              v-else-if="
                                tasks_clip.clip &&
                                tasks_clip.clip.clip_type === 'audio'
                              "
                              :src="tasks_clip.clip.download_url"
                              controls
                              :type="tasks_clip.clip.clip_type"
                            />
                            <div
                              v-else-if="
                                (tasks_clip.clip &&
                                  tasks_clip.clip.clip_type === 'photo') ||
                                (tasks_clip.clip &&
                                  tasks_clip.clip.clip_type === 'document')
                              "
                              class="w-40 relative inline-block"
                            >
                              <img
                                :src="tasks_clip.clip.image_url"
                                @click="showClip(task, tasks_clip.clip)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="task.memos.length > 0 "
                        class="w-full flex flex-wrap py-2 md:py-4"
                      >
                        <div class="w-full md:w-1/6">
                          <img
                            class="w-12 py-2"
                            :src="imagePath('digiDog_r.svg')"
                            :alt="$t('memo')"
                          />
                        </div>
                        <div class="w-full md:w-5/6">
                          <div
                            name="task-memos"
                            class="flex flex-wrap items-center gap-2 md:gap-4"
                          >
                            <div
                              v-for="(
                                memo, index
                              ) in task.memos"
                              :key="index"
                              name="memo"
                              class="relative cursor-pointer w-40"
                            >
                              <img
                                :src="memo.image_url"
                                class="rounded"
                                :class="{'border-2 border-yellow-dark': memo.is_user_memo }"
                                @click="navigate_to(memo)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="w-full flex flex-wrap">
                        <div class="w-full md:w-1/6">
                          <button
                            v-if="task.feedback_required"
                            class="
                              mt-0
                              cursor-pointer
                              relative
                              w-16
                              h-16
                              md:w-24 md:h-20
                              clip
                            "
                            name="btnHomework"
                            @click="newComment(task)"
                          >
                            <img
                              :src="imagePath('basket_y.svg')"
                              class="clip__bg absolute top-0 w-24"
                            />
                            <img
                              :src="imagePath('basket.svg')"
                              class="clip__fg absolute top-0 w-24"
                            />
                          </button>
                        </div>
                        <div
                          class="comments w-full md:w-2/3 rounded text-gray-800"
                          :class="{
                            'bg-yellow-dark': task.comments.length > 0,
                          }"
                        >
                          <comment
                            v-for="comment in task.comments"
                            :key="comment.id"
                            :comment="comment"
                          />
                        </div>
                        <div
                          class="
                            w-full
                            md:w-1/6
                            pt-1
                            flex flex-wrap
                            items-end
                            justify-end
                            gap-4
                          "
                        >
                          <ul
                            name="task-reflections"
                            class="flex flex-wrap justify-end gap-2"
                          >
                            <li
                              v-for="reflection in task.reflections_attributes"
                              :key="reflection.id"
                              class="flex"
                            >
                              <button
                                class="w-8"
                                @click="openModalReflections([reflection])"
                              >
                                <img
                                  v-if="reflection.user_rate"
                                  name="task-reflection-vote"
                                  class="w-8"
                                  :src="getImagePath(reflection)"
                                />
                                <icon
                                  v-else
                                  :fill="task.subject.fg_color"
                                  name="smile"
                                  class="w-8"
                                />
                              </button>
                            </li>
                          </ul>
                          <button
                            v-if="
                              $page.props.settings.task_statuses &&
                              !$page.props.auth.user.owner &&
                              currentDayPresent
                            "
                            class="flex justify-center items-center"
                            :style="{ color: task.fg_color }"
                            name="btnTaskStatus"
                            @click="setTaskStatus(task)"
                          >
                            <icon
                              v-if="task.task_status.status_id"
                              class="w-8 h-8"
                              name="check-circle"
                            />
                            <icon v-else class="w-8 h-8" name="circle" />
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul
                    name="day-reflections"
                    class="w-full mt-4 flex justify-end px-2 lg:px-4"
                  >
                    <li
                      v-for="reflection in dayReflections"
                      :key="reflection.id"
                    >
                      <button
                        title="Selbstreflexion"
                        class="flex ml-4"
                        @click="openModalReflections([reflection])"
                      >
                        <img
                          v-if="reflection.user_rate"
                          name="day-reflection-vote"
                          class="w-8"
                          :src="getImagePath(reflection)"
                        />
                        <icon v-else fill="#e0e0e0" class="w-8" name="smile" />
                      </button>
                    </li>
                  </ul>
                  <div
                    v-dompurify-html="getOutroText(day)"
                    class="ql-editor w-full px-2 pt-4 leading-tight"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer-actions>
          <div v-if="!plan.discarded_at" class="flex ml-auto pr-2">
            <dropdown
            >
              <div class="btn-menu-primary">
                <img
                  class="w-8 h-8"
                  :style="`color: ${plan.fg_color}`"
                  :src="imagePath('download_pdf.svg')"
                />
              </div>
              <template #dropdown>
                <div class="mt-2 bg-white rounded">
                  <a
                    class="block px-4 py-2 hover:bg-yellow-dark hover:text-white"
                    :href="$routes.program(plan.id)"
                    target="_blank"
                    aria-label="week-plan"
                    rel="noopener noreferrer"
                    >Wochenplan</a
                  >
                  <a
                    v-if="material_week"
                    class="block px-4 py-2 hover:bg-yellow-dark hover:text-white"
                    :href="$routes.material(plan.id)"
                    target="_blank"
                    >Materialien</a
                  >
                  <a
                    v-if="current_day"
                    class="
                      block
                      border-t
                      px-4
                      py-2
                      hover:bg-yellow-dark hover:text-white
                    "
                    :href="$routes.program_day(plan.id, current_day.id)"
                    target="_blank"
                    aria-label="day-plan"
                    rel="noopener noreferrer"
                    >Tagesplan {{ current_day.label }}</a
                  >
                  <a
                    v-if="current_day && material_day"
                    class="block px-4 py-2 hover:bg-yellow-dark hover:text-white"
                    :href="$routes.material_day(plan.id, current_day.id)"
                    target="_blank"
                    >Materialien {{ current_day.label }}</a
                  >
                </div>
              </template>
            </dropdown>
            <Link
              v-if="$page.props.auth.user.owner"
              class="btn-menu-primary ml-4"
              name="edit_plan"
              :href="$routes.edit_plan(plan.id)"
              as="button"
              replace
            >
              <icon class="w-6 h-6" name="pencil-alt" />
            </Link>
          </div>
          <div v-else class="flex ml-auto">
            <button
              v-if="$page.props.auth.user.owner"
              class="btn-delete mr-2"
              @click="destroy()"
            >
              {{ $t('delete') }}
            </button>
            <Link
              v-if="$page.props.auth.user.owner"
              class="btn-green"
              :href="$routes.restore_plan(plan.id)"
              method="put"
              as="button"
            >
              {{ $t('restore') }}
            </Link>
          </div>
        </footer-actions>
        <modal
          v-if="current_task"
          title="Hausaufgabe senden"
          :colored-header="true"
          :open="showModalComment"
          @close="showModalComment = false"
        >
          <new-comment
            :value="comment_form"
            :errors="errors"
          >
            <loading-button
              :loading="sending"
              class="block btn md:w-auto mx-auto"
              @click="submit"
            >
              senden
            </loading-button>
          </new-comment>
        </modal>
        <modal
          :show-header="true"
          :open="showModalReflections"
          @close="showModalReflections = false"
        >
          <reflection
            :reflections="current_reflections"
            @finished="showModalReflections = false"
          />
        </modal>
      </div>
    </section>
  </layout>
</template>

<script>
import axios from 'axios'
import 'swiper/css/bundle'
import Comment from './_Comment.vue'
import FooterActions from '@/Shared/FooterActions.vue'
import Layout from '@/Layouts/Minimal.vue'
import Lesson from './_Lesson.vue'
import moment from 'moment'
import NewComment from './_CommentForm.vue'
import Swiper from 'swiper'

export default {
  components: {
    Comment,
    FooterActions,
    Layout,
    Lesson,
    NewComment,
  },
  layout: null,
  props: {
    plan: {
      type: Object,
      required: true,
    },
    subjects: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data: () => {
    return {
      sending: false,
      days: [
        { id: 1, label: 'Mo' },
        { id: 2, label: 'Di' },
        { id: 3, label: 'Mi' },
        { id: 4, label: 'Do' },
        { id: 5, label: 'Fr' },
      ],
      current_day: '',
      showModalComment: false,
      showModalReflections: false,
      current_task: '',
      current_reflections: [],
      comment_form: {
        content: '',
        file: null,
        selected_memo: null,
        use_case: '',
      },
      errors: { file: [] },
      swiper: null,
    }
  },
  computed: {
    dayTasks() {
      if (!this.current_day) {
        return []
      }
      return this.plan.tasks_attributes.filter(
        (item) => item.day === this.current_day.id,
      )
    },
    dayTasksDone() {
      if (!this.current_day || this.dayTasks.length < 1) {
        return false
      }
      return (
        this.plan.tasks_attributes.filter(
          (item) =>
            item.day === this.current_day.id &&
            item.task_status &&
            item.task_status.status_id === 1,
        ).length === this.dayTasks.length
      )
    },
    dayReflections() {
      if (!this.plan.reflections) {
        return []
      }
      return this.plan.reflections.filter(
        (item) => item.reflection_date === this.getCurrentDayDate,
      )
    },
    getCurrentDayDate() {
      if (!this.plan.week || !this.current_day) {
        return ''
      }
      return moment()
        .year(this.plan.year)
        .isoWeek(this.plan.week)
        .isoWeekday(Number.parseInt(this.current_day.id))
        .locale('de')
        .format('YYYY-MM-DD')
    },
    currentDayPresent() {
      return this.getCurrentDayDate <= moment().format('YYYY-MM-DD')
    },
    material_day() {
      const tasks_attributes = this.plan.tasks_attributes.filter(
        (item) => item.day === this.current_day.id,
      )
      return tasks_attributes.some((task) => {
        return task.tasks_clips_attributes.some((tasks_clip) => {
          return tasks_clip.clip.filename.toLowerCase().endsWith('pdf')
        })
      })
    },
    material_week() {
      const tasks_attributes = this.plan.tasks_attributes
      return tasks_attributes.some((task) => {
        return task.tasks_clips_attributes.some((tasks_clip) => {
          return tasks_clip.clip.filename.toLowerCase().endsWith('pdf')
        })
      })
    },
    planBgImageStyle() {
      if (this.plan.bg_texture) {
        return `background-image: url('${this.imagePath(
          this.plan.bg_texture,
        )}');`
      }
      return ''
    },
    planColorStyle() {
      if (this.plan.bg_texture) {
        return `min-height: 100vh; background-color: ${this.plan.bg_color}CC; color: ${this.plan.fg_color};`
      }
      return `min-height: 100vh; background-color: ${this.plan.bg_color}; color: ${this.plan.fg_color};`
    },
  },
  watch: {
    current_day: function (val) {
      if (val) {
        this.swiper.slideTo(this.days.indexOf(val))
        if (window.localStorage) {
          window.localStorage.setItem('plan__current_day_id', val.id)
        }
      }
    },
  },
  mounted() {
    // first check for specific day in url, e.g. /plans/xxxxxxxx?day=3
    if (
      window.location.search &&
      window.location.search.substring(1).startsWith('day')
    ) {
      let day_search = parseInt(
        window.location.search.substring(1).split('=')[1],
      )
      if (day_search < 1 || day_search > 5) {
        day_search = 1
      }
      this.current_day = this.days.find((day) => day.id === day_search)
    } else {
      // check localStorage
      if (window.localStorage) {
        const current_day_id = Number.parseInt(
          window.localStorage.getItem('plan__current_day_id'),
        )
        if (current_day_id && current_day_id >= 1 && current_day_id <= 5) {
          this.current_day = this.days.find(
            (item) => item.id === current_day_id,
          )
        } else {
          this.current_day = this.days[0]
        }
      } else {
        this.current_day = this.days[0]
      }
    }
    let that = this
    that.swiper = new Swiper('.swiper-container', { threshold: 20 })
    that.swiper.on('slideChange', function () {
      that.current_day = that.days[this.activeIndex]
    })
  },
  methods: {
    getTitle() {
      if (!this.plan.week) {
        return ''
      }
      let week = moment().year(this.plan.year).isoWeek(this.plan.week)
      return `${week.isoWeekday(1).locale('de').format('DD.MM.YYYY')} - ${week
        .day(5)
        .locale('de')
        .format('DD.MM.YYYY')}`
    },
    getIntroText(day) {
      switch (day.id) {
        case 1:
          if (this.plan.intro_1) return this.plan.intro_1
          break
        case 2:
          if (this.plan.intro_2) return this.plan.intro_2
          break
        case 3:
          if (this.plan.intro_3) return this.plan.intro_3
          break
        case 4:
          if (this.plan.intro_4) return this.plan.intro_4
          break
        case 5:
          if (this.plan.intro_5) return this.plan.intro_5
          break
        default:
          break
      }
    },
    getOutroText(day) {
      switch (day.id) {
        case 1:
          if (this.plan.outro_1) return this.plan.outro_1
          break
        case 2:
          if (this.plan.outro_2) return this.plan.outro_2
          break
        case 3:
          if (this.plan.outro_3) return this.plan.outro_3
          break
        case 4:
          if (this.plan.outro_4) return this.plan.outro_4
          break
        case 5:
          if (this.plan.outro_5) return this.plan.outro_5
          break
        default:
          break
      }
    },
    getTasks(day) {
      if (!day) {
        return []
      }
      return this.plan.tasks_attributes.filter((item) => item.day === day.id)
    },
    getLessons(day) {
      return this.plan.lessons.filter((item) => item.day === day.id)
    },
    getImagePath(reflection) {
      if (
        reflection &&
        reflection.user_rate &&
        reflection.user_rate > 0 &&
        reflection.user_rate < 5
      ) {
        return this.imagePath(`rate_${reflection.user_rate}.svg`)
      }
    },
    getBorderColor(task) {
      if (task.bg_color) {
        return 'transparent'
      }
      return '#fff'
    },
    getSubjectFgColor(id) {
      let subject = this.subjects.find((item) => item.id === id)
      if (subject) return subject.fg_color
    },
    getSubjectBgColor(id) {
      let subject = this.subjects.find((item) => item.id === id)
      if (subject) return subject.bg_color
    },
    newComment(task) {
      // if (this.$page.props.auth.user.owner) {
      //   this.$notify({
      //     title:
      //       'Hinweis: HA können in dieser Ansicht nur von Schülern hochgeladen werden.',
      //     type: 'warn',
      //   })
      //   return
      // }
      this.current_task = task
      this.showModalComment = true
    },
    openModalReflections(reflections) {
      this.current_reflections = reflections
      this.showModalReflections = true
    },
    submit() {
      this.errors.file = []
      let data = new FormData()
      data.append('plan_id', this.plan.id)
      data.append('task_id', this.current_task.id)
      data.append('comment[content]', this.comment_form.content || '')

      if (this.comment_form.selected_memo) {
        data.append('memo_id', this.comment_form.selected_memo.id)
      } else if (this.comment_form.file) {
        data.append('comment[file]', this.comment_form.file)
      } else {
        this.errors.file = ['noch keine HA']
        return
      }

      let that = this

      axios
        .post(this.$routes.comments(), data, {
          headers: { Accept: 'application/json' },
        })
        .then((response) => {
          this.sending = false
          console.log(response)
          if (response.status === 200) {
            this.comment_form = {}
            this.showModalComment = false
            if (response.data && response.data.clip) {
              this.current_task.comments.splice(0, 0, response.data.clip)
            }
            this.current_task = null
            that.$notify({
              title: response.data.message,
              type: 'success',
            })
          } else {
            that.$notify({
              title: response.data.message,
              type: 'error',
            })
          }
        })
        .catch((error) => {
          console.log(error)
          that.$notify({
            title: error.response.data.message,
            type: 'error',
          })
        })
    },
    destroy() {
      if (
        confirm('Soll der Wochenplan tatsächlich entgültig gelöscht werden?')
      ) {
        this.$inertia.delete(this.$routes.plan(this.plan.id))
      }
    },
    setTaskStatus(task) {
      if (this.$page.props.auth.user.owner) {
        this.$notify({
          title: 'Hinweis: Aufgaben können nur von Schülern abgehakt werden.',
          type: 'warn',
        })
        return
      }
      // if (task.feedback_required && task.comments.length === 0) {
      //   this.$notify({
      //     title:
      //       'lege erst deine Arbeit in den HA-Korb',
      //     type: 'warn',
      //   })
      //   return
      // }
      // if (task.reflections.length > 0 && task.reflections.find(item => !item.user_rate)) {
      //   this.$notify({
      //     title:
      //       'beantworte zuerst die Fragen zur Selbstreflexion',
      //     type: 'warn',
      //   })
      //   return
      // }
      let that = this
      if (task.task_status && task.task_status.id) {
        axios
          .patch(this.$routes.task_task_status(task.id, task.task_status.id), {
            task_status: {
              status_id: task.task_status.status_id === 0 ? 1 : 0,
            },
          },{
            headers: { Accept: 'application/json' },
          })
          .then((response) => {
            if (response.status === 200) {
              task.task_status = response.data
              if (task.task_status.status_id === 1) {
                let msg = that.dayTasksDone
                  ? 'prima, alle Aufgaben erledigt !'
                  : 'prima !'
                that.$notify({
                  title: msg,
                  type: 'success',
                })
              } else {
                that.$notify({
                  title: 'doch noch nicht ganz fertig ?',
                  type: 'warn',
                })
              }
            } else {
              window.location.reload()
            }
          })
          .catch(function (error) {
            that.$notify({
              title: error.response.data.message,
              type: 'error',
            })
            window.location.reload()
          })
      } else {
        axios
          .post(this.$routes.task_task_statuses(task), {
            task_status: { task_id: task.id, status_id: 1 },
          },{
            headers: { Accept: 'application/json' },
          })
          .then((response) => {
            if (response.status === 200) {
              task.task_status = response.data
              that.$notify({
                title: 'prima !',
                type: 'success',
              })
            } else {
              window.location.reload()
            }
          })
          .catch(function (error) {
            that.$notify({
              title: error.response.data.message,
              type: 'error',
            })
            window.location.reload()
          })
      }
    },
    downloadClip(url) {
      window.open(url, '_blank')
    },
    showClip(task, clip) {
      this.current_task = task
      window.open(clip.download_url)
    },
    navigate_to(task_memo) {
      if(task_memo.method === 'get')
        this.$inertia.get(task_memo.url)

      if(task_memo.method === 'post') {
        if (this.$page.props.auth.user.owner) {
          this.$notify({
            title: 'Hinweis: digiDok wird nur für Schüler dupliziert.',
            type: 'warn',
          })
          return
        }
        this.$inertia.post(task_memo.url, { task_id: task_memo.task_id })
      }
    },
    getThumbUrl(task) {
      let task_clip = task.tasks_clips_attributes.find(
        (item) => item.is_task_thumb === true,
      )
      if (task_clip && task_clip.clip) return task_clip.clip.image_url
    },
    navigateBack() {
      if (this.$page.props.auth.user.owner) {
        this.$inertia.get(this.$routes.plans())
      } else {
        this.$inertia.get(this.$routes.root())
      }
    },
  },
}
</script>
<style scoped>
.ql-editor {
  height: auto !important;
}
.ql-editor img {
  display: inline !important;
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.board-field {
  background-color: #eafffb;
  border-radius: 0.25rem;
}
header.fixed {
  width: 100%;
  max-width: 1280px;
}
img.clip__bg {
  opacity: 0;
}
img.clip__fg {
  opacity: 1;
}
button.clip:hover > img.clip__bg {
  opacity: 1;
}
button.clip:hover > img.clip__fg {
  opacity: 0;
}
</style>
