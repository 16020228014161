<template>
  <section
    class="w-full"
  >
    <div
      v-if="images.length > 0"
      class="w-full mt-2 grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-4"
    >
      <div
        v-for="tasks_clip in images"
        :key="tasks_clip.id"
        class="clip relative inline-block"
      >
        <img
          :src="tasks_clip.clip.image_url"
          class="cursor-pointer"
          @click="showClip(tasks_clip.clip.download_url)"
        >
        <button
          v-if="plan && task.feedback_required"
          class="absolute right-0 bottom-0 p-1 mr-1 mb-1 bg-gray-400 hover:bg-gray-600 rounded"
          @click="newMemo(tasks_clip)"
        >
          <icon
            class="w-6 h-6 text-white"
            name="pencil-alt"
          />
        </button>
      </div>
    </div>
    <video
      v-for="tasks_clip in videos"
      :key="tasks_clip.id"
      :src="tasks_clip.clip.download_url"
      controls
      :type="tasks_clip.clip.clip_type"
      class="my-4"
    />
    <audio
      v-for="tasks_clip in audios"
      :key="tasks_clip.id"
      :src="tasks_clip.clip.download_url"
      controls
      :type="tasks_clip.clip.clip_type"
      class="my-4"
    />
  </section>
</template>
<script>

export default {
  props: {
    plan: {
      type: Object,
      required: false,
      default: null,
    },
    task: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      index: null
    }
  },
  computed: {
    audios() {
      return this.task.tasks_clips_attributes.filter(
        (item) => item.is_task_thumb === false &&
        item.clip.clip_type === 'audio'
      )
    },
    videos() {
      return this.task.tasks_clips_attributes.filter(
        (item) => item.is_task_thumb === false &&
        item.clip.clip_type === 'video'
      )
    },
    images() {
      return this.task.tasks_clips_attributes.filter(
        (item) => item.is_task_thumb === false &&
        item.clip.clip_type === 'photo'
      )
    },
  },
  methods: {
    showClip(url) {
      window.open(url, '_blank')
    },
    newMemo(tasks_clip) {
      this.$inertia.get(
        this.$routes.new_plan_memo(this.plan.id),
        { task_id: this.task.id, clip_id: tasks_clip.clips.id }
      )
    }
  }
}
</script>
