<i18n>
{
  "en": {
    "title": "Edit Me",
    "password-change": "To change password please fill out both password fields",
    "delete": "Delete Account",
    "save": "save"
  },
  "de": {
    "title": "Mein Konto bearbeiten",
    "password-change": "Zum Ändern des Passwortes müssen beide Passwort-Felder ausgefüllt werden",
    "delete": "Mein Account löschen",
    "save": "Speichern"
  }
}
</i18n>
<template>
  <page-title :title="$t('title')" />
  <user-form
    class="mt-4 md:mt-8"
    v-model="form"
  />
  <footer-actions>
    <button
      class="btn-delete"
      tabindex="-1"
      type="button"
      @click="show_confirm_dialog=true"
    >
      {{ $t('delete') }}
    </button>
    <loading-button
      :loading="sending"
      class="btn ml-auto"
      type="submit"
      @click="submit"
    >
      {{ $t('save') }}
    </loading-button>
  </footer-actions>
  <confirm-delete
    v-show="show_confirm_dialog"
    :modalHeadline="$t('delete')"
    @deleteRecordEvent="destroy"
    @close="show_confirm_dialog = false"
  />
</template>

<script>
import Layout from '@/Layouts/Full.vue'
import UserForm from './Form.vue'

export default {
  components: {
    UserForm,
  },
  layout: Layout,
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  remember: 'form',
  data() {
    return {
      show_confirm_dialog: false,
      sending: false,
      form: this.user,
    }
  },
  watch: {
    user: function() {
      this.form = this.user
    }
  },
  methods: {
    submit() {
      let data = new FormData()
      data.append('user[first_name]', this.form.first_name || '')
      data.append('user[last_name]', this.form.last_name || '')
      data.append('user[email]', this.form.email || '')
      data.append('user[password]', this.form.password || '')
      data.append('user[password_confirmation]', this.form.password_confirmation || '')
      data.append('user[avatar]', this.form.avatar || '')
      data.append('_method', 'put')

      this.$inertia.post(this.$routes.me(), data, {
        onStart: () => this.sending = true,
        onFinish: () => {
          this.sending = false
          if (!this.$page.props.errors) {
            this.form.avatar = null
            this.form.password = null
            this.form.password_confirmation = null
          }
        },
      },
      {
        preserveState: false
      })
    },
    destroy() {
      this.$inertia.delete(this.$routes.me())
    },
  },
}
</script>
