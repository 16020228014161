<i18n>
{
  "en": {
    "title": "Edit Post",
    "attachment": "Attachment",
    "add-pdf": "add file",
    "email-sent": "Email notification last sent on",
    "cancel": "cancel",
    "delete": "delete",
    "send": "send",
    "view": "view",
    "save": "save"
  },
  "de": {
    "title": "Post bearbeiten",
    "attachment": "Anhang",
    "add-pdf": "Datei hinzufügen",
    "email-sent": "Email-Benachrichtigung zuletzt gesendet am",
    "cancel": "abbrechen",
    "delete": "löschen",
    "send": "senden",
    "view": "Ansicht",
    "save": "speichern"
  }
}
</i18n>
<template>
  <Layout class="bg-gray-100">
    <header class="mb-4 md:mb-8 flex items-center">
      <page-title :nav-back-url="$routes.posts()" :title="$t('title')" />
    </header>
    <div id="board" class="lg:pl-12">
      <div class="px-4 flex flex-wrap">
        <user-form v-if="form" :value="form" @submit="submit" />
        <section class="w-full mt-4">
          <div class="flex">
            <div class="my-auto">
              {{ $t('attachment') }}
            </div>
            <button ref="show" class="ml-auto btn" @click.prevent="browse">
              {{ $t('add-pdf') }}
            </button>
            <input
              ref="file"
              type="file"
              accept="application/pdf"
              class="hidden"
              @change="change"
            />
          </div>
          <ul class="flex gap-2 md:gap-4">
            <li v-for="file in post.files" :key="file.id" class="">
              <a
                :href="file.download_url"
                target="_blank"
                class="btn flex items-center"
              >
                <div class="mr-4">{{ file.filename }}</div>
                <button
                  class="rounded-full w-6 h-6 hover:bg-red-dark"
                  @click.prevent="destroyFile(file.id)"
                >
                  <icon name="cross" fill="white" class="w-2 h-2 mx-auto" />
                </button>
              </a>
            </li>
          </ul>
        </section>
        <section v-if="post.notified_at" class="mt-4 w-full text-right">
          <div class="">
            {{ $t('email-sent') }}
            {{ $filters.toDate(post.notified_at, 'DD.MM.YYYY HH:mm') }}
          </div>
        </section>
      </div>
    </div>
    <modal
      title="Email-Benachrichtigung senden"
      :open="showModal"
      @close="showModal = false"
    >
      <section class="bg-white p-4">
        <ul
          class="flex flex-wrap overflow-y-auto"
          style="max-height: calc(100vh - 175px)"
        >
          <template v-for="user in users">
            <template v-for="email in user.contact_emails" :key="email">
              <li class="p-2 mr-4 my-2 border rounded">
                {{ user.name }} ({{ email }})
              </li>
            </template>
          </template>
        </ul>
        <div class="w-full flex justify-center gap-x-2 md:gap-x-4 mt-4">
          <button class="btn-gray"  @click="showModal = false">
            {{ $t('cancel') }}
          </button>
          <button class="btn bg-green-dark" @click="notify">
            {{ $t('send') }}
          </button>
        </div>
      </section>
    </modal>
    <footer-actions>
      <button class="btn-delete" tabindex="-1" type="button" @click="destroy">
        {{ $t('delete') }}
      </button>
      <button
        ref="notify"
        name="btnNotify"
        class="btn py-2 ml-auto mr-2 md:mr-4"
        type="button"
        @click="checkStatus()"
      >
        <icon class="w-6 h-6" name="bell" />
      </button>
      <Link class="btn mr-2 md:mr-4" :href="$routes.post(post.slug)">
        {{ $t('view') }}
      </Link>
      <loading-button
        :loading="sending"
        class=""
        :class="[{ btn: !dirty }, { 'btn-green': dirty }]"
        type="submit"
        @click="submit()"
      >
        {{ $t('save') }}
      </loading-button>
    </footer-actions>
  </Layout>
</template>

<script>
import Layout from '@/Layouts/Full.vue'
import UserForm from './Form.vue'
import _ from 'lodash'
import moment from 'moment'
import hash from 'object-hash'

export default {
  layout: null,
  components: {
    Layout,
    UserForm,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      sending: false,
      form: null,
      theme: 2,
      showModal: false,
      dirty: false,
      form_hash: null,
    }
  },
  watch: {
    form: {
      handler: function (val, oldVal) {
        if (val && oldVal) {
          if (hash(val) != this.form_hash) {
            this.dirty = true
          } else {
            this.dirty = false
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.form = _.omit(this.post, 'id')
    this.form_hash = hash(this.form)
  },
  methods: {
    submit() {
      let data = new FormData()
      data.append('post[title]', this.form.title || '')
      data.append('post[status]', this.form.status || '')
      data.append('post[date_publication]', this.form.date_publication || '')
      data.append('post[content]', this.form.content || '')
      data.append('post[notify_by_email]', this.form.notify_by_email || '')
      this.$inertia.put(this.$routes.post(this.post.id), data, {
        replace: true,
        onStart: () => (this.sending = true),
        onSuccess: () => {
          this.form = _.omit(this.post, 'id')
          this.sending = false
          this.form_hash = hash(this.form)
          this.dirty = false
        },
      })
    },
    destroy() {
      if (confirm('Soll Post tatsächlich gelöscht werden?')) {
        this.$inertia.put(this.$routes.discard_post(this.post.id))
      }
    },
    destroyFile(id) {
      if (confirm('Soll Anhang tatsächlich gelöscht werden?')) {
        this.$inertia.put(
          this.$routes.discard_post(this.post.id) + `?attachment_id=${id}`,
        )
      }
    },
    checkStatus() {
      this.$refs.notify.blur()
      if (this.dirty) {
        this.$notify({ title: 'Änderungen speichern.', type: 'warn' })
        return
      }
      if (this.users.length === 0) {
        this.$notify({
          title: 'Noch keine Email-Adressen bei Schulkindern hinterlegt.',
          type: 'warn',
        })
        return
      }
      if (this.form.status != 'visible') {
        this.$notify({
          title:
            'Email-Benachrichtigung nur für öffentliche Elternpost möglich.',
          type: 'warn',
        })
        return
      }
      if (
        moment(new Date(this.form.date_publication)).isAfter(new Date(), 'day')
      ) {
        this.$notify({
          title:
            'Datum Veröffentlichung darf für Email-Benachrichtigung nicht in der Zukunft liegen.',
          type: 'warn',
        })
        return
      }
      this.showModal = true
    },
    notify() {
      this.$inertia.post(
        this.$routes.post_notify(this.post.id),
        {},
        {
          onFinish: () => {
            this.showModal = false
            this.$refs.notify.blur()
          },
        },
      )
    },
    browse() {
      this.$refs.file.click()
      this.$refs.show.blur()
    },
    change(e) {
      let data = new FormData()
      if (e.target.files.length > 0) {
        data.append('post[files]', e.target.files[0])
      }
      this.$inertia.post(
        this.$routes.post_create_file(this.post.id),
        data,
        { preserveState: true, preserveScroll: true },
        {
          onFinish: () => {
            this.$refs.show.blur()
          },
        },
      )
    },
  },
}
</script>
