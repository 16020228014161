<i18n>
{
  "en": {
    "placeholder": "insert Text"
  },
  "de": {
    "placeholder": "Text eingeben"
  }
}
</i18n>
<script>
import Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.bubble.css'
import 'quill-emoji/dist/quill-emoji.css'
import 'quill-emoji'

export default {
  props: {
    id: {
      type: String,
      default() {
        return `editor-${parseInt(Math.random() * 10000)}`
      },
    },
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      editor: null,
      // refId: `editor-wrapper-${ parseInt(Math.random() * 10000) }`,
    }
  },
  mounted() {
    let placeholder
    if(this.placeholder) {
      placeholder = this.placeholder
    } else {
      placeholder= this.$t('placeholder')
    }
    this.editor = new Quill(this.$refs[this.id], {
      modules: {
        toolbar: [
          [{ size: ['small', false, 'large', 'huge'] }],
          // [{ header: [1, 2, 3, 4, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ color: [] }, { background: [] }],
          ['link'],
        ],
        'emoji-toolbar': true,
        'emoji-textarea': true,
        'emoji-shortname': true,
      },
      theme: 'bubble',
      formats: [
        'bold',
        'italic',
        'underline',
        'strike',
        'color',
        'background',
        'size',
        'link',
        'emoji',
      ],
      placeholder: placeholder,
    })

    this.editor.root.innerHTML = this.modelValue
    this.editor.on('text-change', () => this.update())
  },
  // beforeDestroy() {
  //   this.editor.off('text-change', () => this.update())
  // },

  methods: {
    update() {
      this.$emit(
        'update:modelValue', this.editor.getText() ? this.editor.root.innerHTML : '',
      )
    }
  },
}
</script>

<template>
  <div
    :id="id"
    :ref="id"
  />
</template>
