<i18n>
{
  "en": {
    "avatar": "Avatar",
    "remove_avatar": "Are you sure to delete this image?",
    "user-name": "User Name",
    "user-name-description": "first name and given name",
    "user-groups": "User Groups",
    "user-groups-description": "manage user groups where user has access to",
    "first_name": "First Name",
    "last_name": "Last Name",
    "user-avatar-password": "Avatar and Password",
    "user-permissions": "Permissions",
    "user-permissions-description": "allowed permissions",
    "user-emails": "Email Addresses",
    "user-emails-description": "recipients of parent posts",
    "slug": "Slug",
    "password_token": "Password",
    "is_locked": "user is locked",
    "can_upload": "allowed to upload photos onto digiFactory",
    "placeholder_generation": "auto generated",
  },
  "de": {
    "avatar": "Avatar",
    "remove_avatar": "Soll Bild tatsächlich gelöscht werden?",
    "user-name": "Name",
    "user-description": "Vorname und Nachname",
    "user-groups": "Benutzergruppen",
    "user-groups-description": "Anwender hat Zugriff auf diese Benutzergruppen",
    "first_name": "Vorname",
    "last_name": "Nachname",
    "user-avatar-password": "Avatar und Passwort",
    "user-avatar-password-description": "",
    "user-permissions": "Berechtigungen",
    "user-permissions-description": "erlaubte Aktionen",
    "user-emails": "Email Addressen",
    "user-emails-description": "Empfänger der Elternpost",
    "slug": "Kennung",
    "password_token": "Passwort",
    "is_locked": "Anwender ist gesperrt",
    "can_upload": "darf Fotos in die digiWerkstatt hochladen",
    "placeholder_generation": "wird automatisch generiert",
  }
}
</i18n>
<template>
  <section class="bg-gray-50 p-2 md:px-4 rounded">
    <form-group
      :name="$t('user-name')"
      :description="$t('user-name-description')"
      :first="true"
      :flexed="true"
    >
      <text-input
        v-model="form.first_name"
        :errors="$page.props.errors && $page.props.errors.first_name"
        class="w-full"
        :label="$t('first_name')"
      />
      <text-input
        v-model="form.last_name"
        :errors="$page.props.errors && $page.props.errors.last_name"
        class="w-full"
        :label="$t('last_name')"
      />
    </form-group>
    <form-group
      :name="$t('user-groups')"
      :description="$t('user-groups-description')"
    >
      <slot name="actionAddPermission" />
    </form-group>
    <form-group
      :name="$t('user-avatar-password')"
      description=""
      :flexed="true"
    >
      <text-input
        v-model="form.password_token"
        :errors="$page.props.errors && $page.props.errors.password_token"
        class="w-full"
        :label="$t('password_token')"
        :placeholder="$t('placeholder_generation')"
      />
        <div class="w-full flex items-center relative">
          <div class="w-20 h-20 overflow-hidden rounded-lg flex items-center justify-center">
            <img
              v-if="form.avatar_url"
              class="w-20 h-20"
              :src="form.avatar_url"
              :alt="$t('avatar')"
            >
            <div
              v-else
              class="w-20 h-20 rounded-full bg-gray-200"
            />
          </div>
          <a
            v-if="form.avatar_url"
            class="absolute cursor-pointer top-0 w-6 h-6 border border-gray-300 bg-gray-100 rounded-full flex items-center justify-center hover:bg-red-300"
            style="margin-left: 3.75em;"
            @click.prevent="removeAvatar()"
          >
            <icon
              class="w-3 h-3"
              name="cross"
            />
          </a>
          <span class="ml-5">
            <file-input
              v-model="form.avatar"
              class="w-full ml-4"
              :errors="$page.props.errors && $page.props.errors.avatar"
              type="file"
              accept="image/png,image/jpeg,image/jpg,image/gif"
            />
          </span>
        </div>
      <!-- <select-box
        v-model="form.role_id"
        :options="roles"
        :optionKey="true"
        :label="$t('role')"
        name="roles"
        class="mt-2 md:mt-4"
      /> -->
    </form-group>
    <form-group
      :name="$t('user-permissions')"
      :description="$t('user-permissions-description')"
    >
      <check-box
        v-model="form.can_upload"
        class=""
        :label="$t('can_upload')"
      />
      <check-box
        v-model="form.is_locked"
        class="mt-2"
        :label="$t('is_locked')"
      />
    </form-group>
    <form-group
      :name="$t('user-emails')"
      :description="$t('user-emails-description')"
      :last="true"
    >
      <!-- <div
        class="mt-2 md:mt-4 w-full"
      >
        <div class="mt-4 mb-2">
          Email-Adressen:
        </div> -->
        <ul
          name="emails"
          class="w-full flex flex-wrap gap-y-2"
        >
          <li
            v-for="(email, index) in form.contact_emails"
            :key="index"
            name="email"
            class="email relative w-full"
          >
            <input
              v-model="form.contact_emails[index]"
              type="email"
              name="contact_email"
              class="w-full form-input"
              :errors="email.errors"
              placeholder="Email-Adresse"
            />
            <button
              name="removeEmail"
              class="absolute right-0 top-0 mt-3 mr-2"
              @click.prevent="removeEmail(email)"
            >
              <icon
                class="w-4 h-4"
                name="trash"
              />
            </button>
          </li>
        </ul>
        <button
          class="block ml-auto mt-2"
          @click.prevent="addEmail()"
          name="add_email_address"
        >
          <icon name="plus-circle" class="w-8 h-8" />
        </button>
      <!-- </div> -->
    </form-group>
    <slot />
  </section>
</template>

<script>

export default {
  components: {
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    user_accounts: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: () => {
    return {
      email_list: null
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
  mounted() {
  },
  methods: {
    validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if(re.test(email.address)) {
        email.errors = []
        email.valid = true
      } else {
        email.errors = ['Email ist ungültig']
        email.valid = false
      }
    },
    addEmail() {
      this.form.contact_emails.push('')
    },
    removeEmail(email) {
      this.form.contact_emails.splice(this.form.contact_emails.indexOf(email), 1)
    },
    removeAvatar() {
      if (confirm(this.$t('remove_avatar'))) {
        this.$inertia.delete(this.$routes.avatar_user(this.form.id))
      }
    },
    getAccountName(index) {
      const account = this.user_accounts.find(item => item.id == index)
      if(account){
        return account.name
      }
      return ''
    }
  }
}
</script>
