<template>
  <section
    class="w-full bg-secondary-yellow"
    style="min-height: 100vh"
  >
    <slot />
    <notifications
      position="bottom center"
      classes="flash mb-1"
    />
    <flash-messages />
  </section>
</template>

<script>

export default {
  mounted() {
    if(this.$page.props.auth && this.$page.props.auth.user)
      localStorage.setItem('token', this.$page.props.auth.user.token)
  }
}
</script>
