<template>
  <div>
    <label
      v-if="label"
      class="form-label"
      :for="id"
    >{{ label }}:</label>
    <input
      :id="id"
      ref="input"
      v-bind="$attrs"
      class="form-input"
      :class="{ error: errors.length }"
      :type="type"
      :value="value"
      autocomplete="off"
      :list="listId"
      :placeholder="placeholder"
      @input="$emit('update:modelValue', $event.target.value)"
    >
    <datalist :id="listId">
      <option
        v-for="item in items"
        :key="item"
      >
        {{ item }}
      </option>
    </datalist>
    <div
      v-if="errors.length"
      class="form-error"
    >
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `text-input-${parseInt(Math.random() * 10000)}`
      },
    },
    listId: {
      type: String,
      default() {
        return `list-${parseInt(Math.random() * 10000)}`
      },
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end)
    },
  },
}
</script>
