<i18n>
{
  "en": {
    "title": "New User Account",
    "save": "save"
  },
  "de": {
    "title": "Neue Benutzergruppe",
    "save": "speichern"
  }
}
</i18n>
<template>
  <page-title :navBackUrl="$routes.user_accounts()" :title="$t('title')" />
  <user-form :value="form" :can="can" @submit="submit" />

  <footer-actions>
    <loading-button
      :loading="sending"
      class="btn ml-auto"
      type="submit"
      @click="submit()"
    >
      {{ $t('save') }}
    </loading-button>
  </footer-actions>
</template>
<script>
import Layout from '@/Layouts/Full.vue'
import UserForm from './Form.vue'

export default {
  components: {
    UserForm,
  },
  layout: Layout,
  props: {
    user_account: {
      type: Object,
      required: true,
    },
    can: {
      type: Object,
      required: true,
    },
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: this.user_account,
    }
  },
  methods: {
    submit() {
      let data = new FormData()
      data.append('user_account[name]', this.form.name || '')
      data.append('user_account[avatar]', this.form.avatar || '')
      data.append('user_account[memos_enabled]', this.form.memos_enabled || '')

      this.$inertia.post(this.$routes.user_accounts(), data, {
        preserveState: false,
        onStart: () => (this.sending = true),
        onSuccess: () => {
          this.sending = false
        },
      })
    },
  },
}
</script>
