<i18n>
{
  "en": {
    "news": "News",
    "imprint" : "Imprint",
    "privacy" : "Privacy",
    "today": "today",
    "tasks": "Tasks",
    "memo": "digiFactory",
    "knowledge": "Knowledge Area",
    "posts": "Parent Posts"
  },
  "de": {
    "news": "Neuigkeiten",
    "imprint" : "Impressum",
    "privacy" : "Datenschutz",
    "today": "heute",
    "tasks": "Aufgaben",
    "memo": "digiWerkstatt",
    "knowledge": "Wissensecke",
    "posts": "Elternpost"
  }
}
</i18n>
<template>
  <div
    class="cards relative grid grid-cols-1 md:grid-cols-2 md:grid-rows-2 gap-8 lg:gap-x-16 pt-4"
    style="min-height: 100vh"
  >
    <div class="flex items-center justify-center">
      <div class="relative" style="width: 17rem">
        <button
          class="w-full h-full hover:font-bold"
          @click="getUrl($routes.plans())"
        >
          <img class="" :src="imagePath('taskCat.svg')" />
          <img
            class="absolute top-0"
            style="margin-top: 0.5rem; width: 16rem; height: 7rem"
            :src="imagePath('labelBox.svg')"
          />
          <div
            class="absolute top-0 text-4xl"
            style="
              margin-top: 2.75rem;
              width: 16rem;
              height: 7rem;
              color: #fdc12c;
            "
          >
            {{ $t('tasks') }}
          </div>
        </button>
        <div
          class="absolute top-0"
          style="margin-left: 8rem; margin-top: 16rem"
        >
          <button
            v-if="plan && plan.current_day"
            class="text-4xl text-white hover:font-bold"
            @click="getUrl($routes.plan(plan.id, { day: plan.current_day }))"
          >
            {{ $t('today') }}
          </button>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center">
      <button
        v-if="
          $page.props.menu &&
          $page.props.menu.main_menu &&
          $page.props.menu.main_menu.some((item) => item.caption === 'memos')
        "
        class="relative hover:font-bold"
        style="width: 16rem"
        @click="getUrl($routes.memos())"
      >
        <img class="" :src="imagePath('digiDog.svg')" style="width: 15rem" />
        <div
          class="absolute top-0 left-0"
          style="margin-top: 14.5rem; margin-left: 3rem"
        >
          <img
            class="w-full"
            style="height: 7rem"
            :src="imagePath('labelBox.svg')"
          />
          <div
            class="absolute w-full top-0 text-center text-4xl"
            style="margin-top: 2.25rem; color: #fdc12c"
          >
            {{ $t('memo') }}
          </div>
        </div>
      </button>
      <div v-else class="relative" style="width: 16rem">
        <img class="" :src="imagePath('digiDog.svg')" style="width: 15rem" />
        <div
          class="absolute top-0 left-0"
          style="margin-top: 14.5rem; margin-left: 3rem"
        >
          <img
            class="w-full"
            style="height: 7rem"
            :src="imagePath('labelBox.svg')"
          />
          <div
            class="absolute w-full top-0 text-center text-4xl"
            style="margin-top: 2rem; color: #fdc12c"
          >
            {{ $t('memo') }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-start justify-center pb-8">
      <button
        class="relative"
        style="width: 18rem"
        @click="getUrl($routes.knowledge())"
      >
        <img :src="imagePath('mediCat_ce.svg')" style="width: 13rem" />
        <div
          class="absolute top-0 left-0"
          style="margin-top: 13.5rem; margin-left: 3rem"
        >
          <img
            style="width: 18rem; height: 7rem"
            :src="imagePath('labelBox.svg')"
          />
          <div
            class="absolute top-0 text-4xl hover:font-bold"
            style="margin-top: 2.25rem; width: 14rem; color: #fdc12c"
          >
            {{ $t('knowledge') }}
          </div>
        </div>
      </button>
    </div>
    <div class="flex items-center justify-center pt-8">
      <button
        class="relative"
        style="width: 12rem"
        @click="getUrl($routes.posts())"
      >
        <img :src="imagePath('postDog.svg')" />
        <div
          class="absolute top-0 left-0"
          style="margin-top: 8rem; margin-left: 3.5rem; margin-right: -4rem"
        >
          <img style="width: 19rem" :src="imagePath('labelBox.svg')" />
          <div
            class="absolute top-0 text-4xl hover:font-bold"
            style="margin-top: 0.5rem; width: 12rem; color: #fdc12c"
          >
            {{ $t('posts') }}
          </div>
          <div
            v-if="$page.props.auth.user.unread_posts > 0"
            class="absolute right-0 top-0 mt-12 w-8 h-8"
          >
            <img
              class="unread_image absolute right-0 h-8 -mt-4 mr-2"
              :src="imagePath('notification.svg')"
            />
            <div
              class="unread_counter absolute right-0 mr-3 -mt-3 w-6 text-center text-primary-red"
            >
              {{ $page.props.auth.user.unread_posts }}
            </div>
          </div>
        </div>
      </button>
    </div>
    <div class="pupil text-center">
      <Link as="button" name="Presentation" :href="$routes.presentation()">
        <img
          class="w-40 h-40 cursor-pointer"
          :src="imagePath('avatar_bg.svg')"
        />
        <div
          v-if="
            $page.props.auth &&
            $page.props.auth.user &&
            !$page.props.auth.user.owner
          "
          class="relative top-0 w-full text-primary-red text-xl font-bold"
          style="margin-top: -1.75rem"
        >
          {{ $page.props.auth.user.first_name }}
        </div>
      </Link>
    </div>
  </div>
</template>

<script>
import layout from '../../Layouts/FullViewer.vue'

export default {
  layout: layout,
  props: {
    plan: {
      required: false,
      type: Object,
      default: null,
    },
  },
  methods: {
    getUrl(url) {
      this.$inertia.get(url)
    },
  },
}
</script>
