import { fabric } from 'fabric'
import 'fabric-history'

(function () {
  /**
   * Returns current state of the string of the canvas
   */
  fabric.Canvas.prototype._historyNext = function () {
    // overwritten, to save also object status
    return JSON.stringify(this.toObject(this.extraProps))
  }
})()