<i18n>
{
  "en": {
    "title": "Memo",
    "delete": "delete"
  },
  "de": {
    "title": "Notiz",
    "delete": "löschen"
  }
}
</i18n>
<template>
  <section class="max-w-screen-xl mx-auto">
    <page-title :nav-back-url="$routes.memos()" :title="$t('title')" />
    <img
      :src="memo.image_url"
      class="w-full mt-4 md:mt-8"
    >
    <button
      class="mt-4 btn-delete"
      tabindex="-1"
      type="button"
      @click="destroy"
    >
      {{ $t('delete') }}
    </button>
  </section>
</template>

<script>
import Layout from '@/Layouts/Full.vue'

export default {
  Layout: Layout,
  props: {
    memo: {
      type: Object,
      required: true,
    },
    task: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      sending: false,
      message: ''
    }
  },
  computed: {
   parsedContent() {
      if(this.memo.content) {
        return JSON.parse(this.memo.content)
      }
      return null
    },
    title() {
      if(this.memo.attachable_type === 'Comment') {
        return 'Korrigierte Aufgabe'
      }
      if(this.memo.attachable_type === 'Clip') {
        return 'Bearbeitete Aufgabe'
      }
      return 'Notiz'
    }
  },
  methods: {
    destroy() {
      if (confirm('Soll tatsächlich gelöscht werden?')) {
        this.$inertia.delete(this.$routes.memo(this.memo.id))
      }
    }
  }
}
</script>
