import '../styles/application.scss'
import axios from 'axios'
import i18n from '@/utils/i18n'
import { useI18n } from 'vue-i18n'
import sharedComponents from '@/utils/shared_components'
import 'quill/dist/quill.core.css'
import { createApp, h } from 'vue'
import { createInertiaApp, Link } from '@inertiajs/inertia-vue3'
import * as Routes from '@/utils/routes.js'
import getCookieValue from '@/utils/cookies'
import HoneybadgerVue from '@honeybadger-io/vue'
import Full from '@/Layouts/Full.vue'
import moment from 'moment'
import Notifications from '@kyvg/vue3-notification'
import getStaticUrl from '@/utils/static_url.js'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'

// Tell Axios to send the CSRF token (taken from the cookie)
// in the header named as "X-CSRF-Token", as this is the name
// expected by Rails
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'

const ax = axios.create({
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
	},
})
// ax.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
ax.defaults.baseURL = window.location.origin

const imagePath = (name) => { return new URL(`../images/${name}`, import.meta.url).href }
const honeybadgerApiKeyTag = document.querySelector('meta[name="honeybadger-api-key"]')
const version = document.querySelector('meta[name="version"]').content
const appHost = document.querySelector('meta[name="app-host"]').content
const pages = import.meta.glob('../Pages/**/*.vue', { eager: true })

createInertiaApp({
  resolve: async (name) => {
    const component = await pages[`../Pages/${name}.vue`];
    if (!component)
      throw new Error(
        `Unknown page ${name}. Is it located under Pages with a .vue extension?`,
      );

    // Get XSRF token from cookie and replace the CSRF meta tag (used by DirectUpload)
    var token = decodeURIComponent(getCookieValue('XSRF-TOKEN'));
    if (token) {
      const metaTag = document.querySelector('meta[name="csrf-token"]');
      if (metaTag) metaTag.setAttribute('content', token);
    }

    component.default.layout =
      component.default.layout === undefined ? Full : component.default.layout;
    return component;
  },

  setup({ el, App, props, plugin }) {
    const appName = document
      .querySelector('meta[name="application-name"]')
      .getAttribute('content')

    const app = createApp({
      metaInfo: {
        titleTemplate: (title) => (title ? `${title} :: ${appName}` : appName),
      },
      // https://github.com/intlify/vue-i18n-next/issues/1186
      beforeCreate() {
        this.customT = function(input) {
          const { t } = useI18n()
          return t(input)
        }
        app.config.globalProperties.$t = this.customT
      },
      render: () => h(App, props),
    })

    app.directive('autofocus', {
      // Enable:
      //   v-autofocus="true"
      //   OR
      //   v-autofocus
      //
      // Disable:
      //   v-autofocus="false"
      inserted(el, binding) {
        if (binding.value || binding.value == undefined)
          el.focus()
      }
    })

    app.config.globalProperties.$axios = ax
    app.config.globalProperties.$routes = Routes
    app.config.globalProperties.$getStaticUrl = getStaticUrl
    app.config.globalProperties.imagePath = imagePath
    app.config.globalProperties.$filters = {
      toDate(value, format) {
        if (!value) {
          return ''
        }
        if (format === undefined) format = 'DD.MM.YYYY HH:mm'

        return moment(value).locale('de').format(format)
      },
    }
    app.use(plugin)
    app.use(i18n)
    app.component("Link", Link)

    app.use(VueDOMPurifyHTML)
    app.use(Notifications)

    if (honeybadgerApiKeyTag.content && version) {
      app.use(HoneybadgerVue, {
        apiKey: honeybadgerApiKeyTag.content,
        environment: appHost,
        revision: version,
      })
    }

    app.use(VueTippy, {
      directive: 'tippy',
      arrow: true,
      onShow(instance) {
        setTimeout(() => {
          instance.hide();
        }, 3000);
      }
    })

    sharedComponents(app)

    app.mount(el)
  },
})
