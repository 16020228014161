import { fabric } from 'fabric'
import _ from 'lodash'

const GROUP_LINES = 'G_BG_LINES'
const GROUP_2_LINES = 'G_BG_2_LINES'
const GROUP_4_LINES_1 = 'G_BG_4_LINES_1'
const GROUP_4_LINES_2 = 'G_BG_4_LINES_2'
const GROUP_SQUARES = 'G_BG_SQUARES'

function __remove(canvas) {
  if (!canvas)
    return

  let objects = canvas.getObjects()
  let other_objects = objects.find(item => item.id && typeof (item.id) === 'string' && item.id.startsWith('G_BG'))
  if (other_objects) {
    canvas.remove(other_objects)
  }
}

// row hight hr, space hight hs, proportions p1, p2, p3
function __getBg4Lines(canvas, hr, hs, p1, p2, p3) {
  if (!canvas)
    return

  let height = canvas.height
  let width = canvas.width
  let color = '#9CA3AF' // '#D1D5DB'
  // let dh = 160
  const offset_x = 10
  let h = hr + hs + 40 // add additonal offset top
  const dh1 = _.round(p1 / (p1 + p2 + p3) * hr, 2)
  const dh2 = _.round(p2 / (p1 + p2 + p3) * hr, 2)
  const dh3 = _.round(p3 / (p1 + p2 + p3) * hr, 2)

  var objects = []

  do {
    var background = new fabric.Rect({
      top: h - hr,
      left: offset_x,
      width: width - 2 * offset_x,
      height: dh1 + dh2 + dh3,
      fill: '#fff',
      hasBorders: false,
      objectCaching: false,
      selectable: false
    })
    objects.push(background)

    let h1 = h - dh1 - dh2 - dh3
    let h2 = h - dh2 - dh3
    let h3 = h - dh3

    var points1 = [offset_x, h1, width - offset_x, h1]
    objects.push(new fabric.Line(points1, { stroke: color, objectCaching: false, selectable: false }))

    var points2 = [offset_x, h2, width - offset_x, h2]
    objects.push(new fabric.Line(points2, { stroke: color, objectCaching: false, selectable: false }))

    var points3 = [offset_x, h3, width - offset_x, h3]
    objects.push(new fabric.Line(points3, { stroke: color, objectCaching: false, selectable: false }))

    var points4 = [offset_x, h, width - offset_x, h]
    objects.push(new fabric.Line(points4, { stroke: color, objectCaching: false, selectable: false }))

    var pointsL = [offset_x, h, offset_x, h1]
    objects.push(new fabric.Line(pointsL, { stroke: color, objectCaching: false, selectable: false }))

    var pointsR = [width - offset_x, h + 1, width - offset_x, h1]
    objects.push(new fabric.Line(pointsR, { stroke: color, objectCaching: false, selectable: false }))

    h += (hr + hs)
  } while (h < height)


  var group = new fabric.Group(objects, {
    erasable: false,
    selectable: false,
    objectCaching: false,
    hoverCursor: 'default',
  })

  return group
}

function __getBg2Lines(canvas, hr, hs, p1, p2, p3) {
  if (!canvas)
    return

  let height = canvas.height
  let width = canvas.width
  let color = '#9CA3AF' // '#D1D5DB'
  // let dh = 160
  const offset_x = 10
  let h = hr + hs + 40 // add additonal offset top
  const dh2 = _.round(p2 / (p1 + p2 + p3) * hr, 2)
  const dh3 = _.round(p3 / (p1 + p2 + p3) * hr, 2)

  var objects = []

  do {
    let h2 = h - dh2 - dh3
    let h3 = h - dh3

    // var points1 = [offset_x, h1, width - offset_x, h1]
    // objects.push(new fabric.Line(points1, { stroke: color, objectCaching: false, selectable: false }))

    var points2 = [offset_x, h2, width - offset_x, h2]
    objects.push(new fabric.Line(points2, { stroke: color, objectCaching: false, selectable: false }))

    var points3 = [offset_x, h3, width - offset_x, h3]
    objects.push(new fabric.Line(points3, { stroke: color, objectCaching: false, selectable: false }))

    // var points4 = [offset_x, h, width - offset_x, h]
    // objects.push(new fabric.Line(points4, { stroke: color, objectCaching: false, selectable: false }))

    var pointsL = [offset_x, h2, offset_x, h3]
    objects.push(new fabric.Line(pointsL, { stroke: color, objectCaching: false, selectable: false }))

    var pointsR = [width - offset_x, h2 + 1, width - offset_x, h3]
    objects.push(new fabric.Line(pointsR, { stroke: color, objectCaching: false, selectable: false }))

    h += (hr + hs)
  } while (h < height)


  var group = new fabric.Group(objects, {
    erasable: false,
    selectable: false,
    objectCaching: false,
    hoverCursor: 'default',
  })
  group.id = GROUP_2_LINES

  return group
}

function __getBgLines(canvas, hr) {
  if (!canvas)
    return

  let height = canvas.height
  let width = canvas.width
  let color = '#D1D5DB'
  // let dh = 60
  var h = hr
  var lines = []

  do {
    var points = [0, h, 2 * width, h]
    lines.push(new fabric.Line(points, { stroke: color, objectCaching: false, selectable: false }))
    h += hr
  } while (h < 2 * height)

  var group = new fabric.Group(lines, {
    erasable: false,
    selectable: false,
    objectCaching: false,
    hoverCursor: 'default',
  })
  group.id = GROUP_LINES

  return group
}

function __getBgSquares(canvas) {
  if (!canvas)
    return

  let height = canvas.height
  let width = canvas.width
  let color = '#D1D5DB'
  let dh = 40
  var h = dh
  var b = dh
  var points
  var lines = []
  do {
    points = [0, h, 2 * width, h]
    lines.push(new fabric.Line(points, { stroke: color, objectCaching: false, selectable: false }))
    h += dh
  } while (h < 2 * height)

  do {
    points = [b, 0, b, 2 * height]
    lines.push(new fabric.Line(points, { stroke: color, objectCaching: false, selectable: false }))
    b += dh
  } while (b < 2 * width)

  var group = new fabric.Group(lines, {
    erasable: false,
    selectable: false,
    objectCaching: false,
    hoverCursor: 'default',
  })
  group.id = GROUP_SQUARES


  return group
}

class CanvasUtils {
  static setBg2Lines(canvas) {
    if (!canvas)
      return

    __remove(canvas)

    const group = __getBg2Lines(canvas, 50, 0, 1, 1, 1)
    canvas.add(group)
    canvas.sendToBack(group)
    canvas.setBackgroundColor('#fff')

    // canvas.requestRenderAll()
  }

  static setBg4Lines_1(canvas) {
    if (!canvas)
      return

    __remove(canvas)

    const group = __getBg4Lines(canvas, 120, 40, 1, 1, 1)
    group.id = GROUP_4_LINES_1
    canvas.add(group)
    canvas.sendToBack(group)
    canvas.setBackgroundColor('#d0ddbf')

    // canvas.requestRenderAll()
  }

  static setBg4Lines_2(canvas) {
    if (!canvas)
      return

    __remove(canvas)

    const group = __getBg4Lines(canvas, 60, 25, 1, 1, 1)
    group.id = GROUP_4_LINES_2
    canvas.add(group)
    canvas.sendToBack(group)
    canvas.setBackgroundColor('#d0ddbf')

    // canvas.requestRenderAll()
  }

  static setBgLines(canvas) {
    if (!canvas)
      return

    __remove(canvas)

    const group = __getBgLines(canvas, 40)
    canvas.add(group)
    canvas.sendToBack(group)
    canvas.setBackgroundColor('#fff')

    // canvas.requestRenderAll()
  }

  static setBgSquares(canvas) {
    if (!canvas)
      return

    __remove(canvas)

    const group = __getBgSquares(canvas)
    canvas.add(group)
    canvas.sendToBack(group)
    canvas.setBackgroundColor('#fff')

    // canvas.requestRenderAll()
  }

  static removeBg(canvas) {
    if (!canvas)
      return

    let objects = canvas.getObjects()
    let objLines = objects.find(item => item.id && typeof item.id === 'string' && item.id.startsWith('G_BG'))
    if (objLines) {
      canvas.remove(objLines)
    }

    canvas.setBackgroundColor('#fff')
    // canvas.requestRenderAll()
  }

  static updateFrozenObjects(canvas) {
    if (!canvas || !canvas._objects)
      return

    canvas._objects.forEach(object => {
      if(object.frozen) {
        this.freezeObject(object, true)
      }
    })
    canvas.renderAll()
  }

  static freezeObject(obj, val) {
    if (!obj)
      return

    // ensure that text fields are not in edit mode
    if(obj.type === 'textbox' || obj.type === 'i-text') {
      obj.exitEditing()
    }

    obj.lockMovementX = val
    obj.lockMovementY = val
    obj.lockRotation = val
    obj.lockScalingX = val
    obj.lockScalingY = val
    obj.lockScalingFlip = val

    obj.hasControls = !val
    obj.frozen = val
  }

  static updateLockedObjects(canvas) {
    if (!canvas || !canvas._objects)
      return

    canvas._objects.forEach(object => {
      if(object.locked) {
        this.lockObject(object, true)
      }
    })
    canvas.renderAll()
  }

  static lockObject(obj, val) {
    if (!obj)
      return

    obj.locked = val
  }

}

export default CanvasUtils
