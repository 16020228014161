<template>
  <div
    :tabindex="tabindex"
    v-bind="$attrs"
    class="relative"
    @blur="open = false"
  >
    <label
      v-if="label"
      class="form-label"
    >{{ label }}</label>
    <div :name="name" class="form-input flex cursor-pointer">
      <div class="flex-grow" @click="open = !open">
        {{ selectedValue }}
      </div>
      <svg class="w-6 h-6" @click="open = !open">
        <path d="M7 10 l5 5 5-5z" />
      </svg>
      <button
        v-if="showRemove && selected != null"
        class="absolute h-full right-0 top-0 flex items-center mr-8 focus:outline-none"
        @click.prevent="handleSelect(null)"
      >
        <icon
          class="w-4 h-4 text-gray-300"
          name="times-circle"
        />
      </button>
    </div>
    <ul
      :name="name"
      class="absolute w-full bg-white border border-gray-300 z-20 mt-1"
      :class="{ hidden: !open, block: open }"
    >
      <li
        v-for="option of options"
        :key="option.id"
        class="p-1 cursor-pointer hover:bg-gray-300"
        @click="handleSelect(option)"
      >
        {{ getValue(option) }}
      </li>
    </ul>
    <div v-if="errors.length" class="form-error">
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon.vue'

export default {
  inheritAttrs: false,
  components: {
    Icon,
  },
  props: {
    modelValue: {
      type: [String, Boolean, Number],
      required: false,
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    optionKey: {
      type: Boolean,
      required: false,
      default: false,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    label: {
      type: String,
      default: null,
    },
    showRemove: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: this.modelValue,
      open: false,
    }
  },
  emits: ['update:modelValue', 'changed'],
  computed: {
    selectedValue() {
      if (this.selected === null) return null

      if (this.optionKey) {
        // console.log(this.options.find(item => item.id === this.selected.name))
        const item = this.options.find((item) => item.id === this.selected)
        if(item)
          return item.name
      } else {
        return this.selected
      }
    },
  },
  methods: {
    getValue(option) {
      if (typeof option === 'string') {
        return option
      } else {
        return option.name
      }
    },
    handleSelect(option) {
      this.open = false
      if (option === null) {
        this.selected = null
      } else if (typeof option === 'string') {
        this.selected = option
      } else {
        this.selected = option.id
      }
      this.$emit('update:modelValue', this.selected)
      this.$emit('changed', this.selected)
    },
  },
}
</script>
