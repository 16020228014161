<template>
  <section v-if="nextUrl">
    <div ref="trigger" class="mt-32 flex justify-center">Loading</div>
    <div class="w-full">
      <icon name="loading" class="w-12 h-12 mx-auto" />
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    propName: {
      type: String,
      required: true,
    },
    items: {
      type: Object,
      required: false,
      default: null,
    },
    options: {
      type: Object,
      default: () => ({
        root: null,
        threshold: '0',
      }),
    },
  },
  data() {
    return {
      observer: null,
    }
  },
  mounted() {
    if (this.$refs.trigger) {
      this.observer = new IntersectionObserver((entries) => {
        this.handleIntersect(entries[0])
      }, this.options)

      this.observer.observe(this.$refs.trigger)
    }
  },
  destroyed() {
    if (this.observer) {
      this.observer.disconnect()
    }
  },
  computed: {
    records() {
      if (this.items) return this.items
      else return this.$page.props[this.propName]
    },
    nextUrl() {
      if (this.items) {
        return this.url(this.items.meta.next)
      } else {
        return this.url(this.records.meta.next)
      }
    },
  },
  methods: {
    handleIntersect(entry) {
      if (entry.isIntersecting) {
        this.load(this.nextUrl)
      }
    },
    url(pageNumber) {
      return pageNumber
        ? this.records.meta.scaffold_url.replace(/__pagy_page__/, pageNumber)
        : null
    },
    load(url) {
      if (this.loading) return
      this.loading = true
      axios
        .get(url, {
          headers: { Accept: 'application/json' },
        })
        .then((response) => {
          this.records.meta = response.data.meta
          this.records.data.push(...response.data.data)
          this.loading = false
        })
        .catch(() => {
          document.location.reload()
        })
    },
  },
}
</script>
