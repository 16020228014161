<template>
  <section
    class="w-full grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-4"
  >
    <div
      v-for="tasks_clip in task.tasks_clips.filter(
        (item) => item.is_task_thumb === false
      )"
      :key="tasks_clip.id"
      class=""
    >
      <video
        v-if="tasks_clip.clips && tasks_clip.clips.clip_type === 'video'"
        :src="tasks_clip.clips.download_url"
        controls
        :type="tasks_clip.clips.clip_type"
      />
      <audio
        v-if="tasks_clip.clips && tasks_clip.clips.clip_type === 'audio'"
        :src="tasks_clip.clips.download_url"
        controls
        :type="tasks_clip.clips.clip_type"
      />
      <div
        v-if="tasks_clip.clips && tasks_clip.clips.clip_type === 'photo'"
        class="relative inline-block mb-2"
      >
        <img
          :src="tasks_clip.clips.download_url"
          class="cursor-pointer"
          @click="showClip(tasks_clip.clips.download_url)"
        >
      </div>
    </div>
  </section>
</template>
<script>

export default {
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  methods: {
    showClip(url) {
      window.open(url, '_blank')
    },
  }
}
</script>
