import { fabric } from 'fabric'
import './canvasHistory'
import './brushes/prima_base_brush'
import './brushes/prima_only_pencil_brush'
import './brushes/prima_pencil_brush'

class FabricUtils {
  static init(width, height) {
    fabric.Object.prototype.borderColor = 'blue'
    fabric.Object.prototype.cornerColor = 'blue'
    fabric.Object.prototype.transparentCorners = false

    // allow touch scrolling (https://github.com/fabricjs/fabric.js/issues/5903)
    var defaultOnTouchStartHandler = fabric.Canvas.prototype._onTouchStart
    fabric.util.object.extend(fabric.Canvas.prototype, {
      _onTouchStart: function (e) {
        // if allowTouchScrolling is enabled, no object was at the
        // the touch position and we're not in drawing mode, then
        // let the event skip the fabricjs canvas and do default
        // behavior
        if (this.allowTouchScrolling && !this.isDrawingMode) {
          // returning here should allow the event to propagate and be handled
          // normally by the browser
          return
        }

        // otherwise call the default behavior
        defaultOnTouchStartHandler.call(this, e)
      }
    })

    let canvas = new fabric.Canvas('canvas', {
      backgroundColor: '#fff',
      preserveObjectStacking: true,
      selection: false,
      allowTouchScrolling: true,
      width: width,
      height: height,
    })

    canvas.on('object:moving', function (e) {
      var obj = e.target
      // if object is too big ignore
      if (obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width) {
        return
      }
      obj.setCoords()
      // top-left  corner
      if (obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0) {
        obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top)
        obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left)
      }
      // bot-right corner
      if (obj.getBoundingRect().top + obj.getBoundingRect().height > obj.canvas.height || obj.getBoundingRect().left + obj.getBoundingRect().width > obj.canvas.width) {
        obj.top = Math.min(obj.top, obj.canvas.height - obj.getBoundingRect().height + obj.top - obj.getBoundingRect().top)
        obj.left = Math.min(obj.left, obj.canvas.width - obj.getBoundingRect().width + obj.left - obj.getBoundingRect().left)
      }
    })

    canvas.freeDrawingBrush = new fabric.PrimaOnlyPencilBrush(canvas)

    return canvas
  }
}

export default FabricUtils
