<i18n>
{
 "en": {
    "display-format": "DD.MM.YYYY",
    "next-month-caption": "Nächster Monat",
    "prev-month-caption": "Vorhergehender Monat",
    "weekdays": "Mo Di Mi Do Fr Sa So",
    "months": "Januar Februar März April Mai Juni Juli August September Oktober November Dezember"
  },
 "de": {
    "display-format": "DD.MM.YYYY",
    "next-month-caption": "Nächster Monat",
    "prev-month-caption": "Vorhergehender Monat",
    "weekdays": "Mo Di Mi Do Fr Sa So",
    "months": "Januar Februar März April Mai Juni Juli August September Oktober November Dezember"
  }
}
</i18n>
<template>
  <div>
    <label
      v-if="label"
      class="form-label"
      :for="id"
    >{{ label }}</label>

    <date-picker
      :id="id"
      ref="input"
      v-bind="$attrs"
      :input-attributes="{ class: 'form-input'}"
      :class="{ error: errors.length }"
      class="w-full"
      :type="type"
      :value="value"
      :display-format="$t('display-format')"
      :next-month-caption="$t('next-month-caption')"
      :prev-month-caption="$t('prev-month-caption')"
      :weekdays="$t('weekdays').split(' ')"
      :months="$t('months').split(' ')"
      clearable
      @input="$emit('update:modelValue', $event)"
    />
    <div
      v-if="errors.length"
      class="form-error"
    >
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
import DatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: {
    DatePicker,
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `date-input-${parseInt(Math.random() * 10000)}`
      },
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end)
    },
  },
}
</script>
<style lang="scss">
.vdpComponent {
  font-size: inherit;
}

.vdpOuterWrap {
  font-size: 10px;
}

.vdpClearInput {
  margin-top: 2px;
  width: 2em;

  &:focus {
    outline: 0;
  }
}
</style>>
