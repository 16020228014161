<i18n>
{
  "en": {
    "title": "Self-reflection",
    "Plan": "Day",
    "Task": "Task",
    "date": "date",
    "type": "type",
    "content": "content",
    "no": "no"
  },
  "de": {
    "title": "Selbstreflexion",
    "Plan": "Tag",
    "Task": "Afg.",
    "date": "Datum",
    "type": "Typ",
    "content": "Inhalt",
    "no": "Anz."
  }
}
</i18n>
<template>
  <page-title :title="$t('title')">
    <template #action>
      <div class="relative">
        <button
          v-if="form.search.length > 0"
          class="absolute right-0 mt-2 mr-2 md:mr-4"
          @click="form.search = ''"
        >
          x
        </button>
        <input
          v-model="form.search"
          class="rounded-full border w-16 md:w-32 px-4 py-2"
          placeholder="Suche "
        />
      </div>
    </template>
  </page-title>
  <div class="w-full flex justify-end mt-2">
    <button
      title="in Prozent (gerundet)"
      class="py-2 px-4 text-xl border-2 rounded-xl mr-2"
      :class="{
        'border-white': !values_per_cent,
        'border-yellow-dark': values_per_cent,
      }"
      @click="values_per_cent = true"
    >
      %
    </button>
    <button
      title="in Summe"
      class="py-2 px-4 text-xl border-2 rounded-xl"
      :class="{
        'border-white': values_per_cent,
        'border-yellow-dark': !values_per_cent,
      }"
      @click="values_per_cent = false"
    >
      ∑
    </button>
  </div>
  <div class="w-full flex flex-wrap border-yellow-dark items-center border-b-2">
    <div class="w-1/2 md:w-32 py-4 font-bold">
      {{ $t('date') }}
    </div>
    <div class="w-1/2 md:w-20 py-4 font-bold">
      {{ $t('type') }}
    </div>
    <div class="w-full md:w-56 lg:flex-grow py-4 font-bold">
      {{ $t('content') }}
    </div>
    <div class="w-16 py-4 flex justify-center">
      <icon fill="black" class="w-6 h-6" name="rate_1" />
    </div>
    <div class="w-16 py-4 flex justify-center">
      <icon fill="black" class="w-6 h-6" name="rate_2" />
    </div>
    <div class="w-16 py-4 flex justify-center">
      <icon fill="black" class="w-6 h-6" name="rate_3" />
    </div>
    <div class="w-16 py-4 flex justify-center">
      <icon fill="black" class="w-6 h-6" name="rate_4" />
    </div>
    <div class="w-8 h-6 lg:w-16 pl-2 font-bold">
      {{ $t('no') }}
    </div>
  </div>
  <ul class="">
    <li
      v-for="item in reflections.data"
      :key="item.id"
      class="w-full border-yellow-dark border-b hover:bg-yellow-light"
    >
      <Link
        class="w-full flex items-center flex-wrap"
        :href="$routes.reflection(item.id)"
      >
        <div class="w-1/2 md:w-32 py-4">
          {{ $filters.toDate(item.reflection_date, 'ddd DD.MM.YYYY') }}
        </div>
        <div class="w-1/2 md:w-20 py-4">
          {{ $t(item.reflectable_type) }}
        </div>
        <div class="w-full md:w-56 lg:flex-grow py-4">
          {{ item.content }}
        </div>
        <div class="w-16 py-4 text-center">
          <span v-if="!values_per_cent">
            {{ item.count_rate_1 }}
          </span>
          <span v-if="values_per_cent">
            {{ item.per_cent_rate_1 }}
          </span>
        </div>
        <div class="w-16 px-2 py-4 text-center">
          <span v-if="!values_per_cent">
            {{ item.count_rate_2 }}
          </span>
          <span v-if="values_per_cent">
            {{ item.per_cent_rate_2 }}
          </span>
        </div>
        <div class="w-16 px-2 py-4 text-center">
          <span v-if="!values_per_cent">
            {{ item.count_rate_3 }}
          </span>
          <span v-if="values_per_cent">
            {{ item.per_cent_rate_3 }}
          </span>
        </div>
        <div class="w-16 px-2 py-4 text-center">
          <span v-if="!values_per_cent">
            {{ item.count_rate_4 }}
          </span>
          <span v-if="values_per_cent">
            {{ item.per_cent_rate_4 }}
          </span>
        </div>
        <div class="w-8 lg:w-16 px-4 py-4">
          {{ item.count }}
        </div>
      </Link>
    </li>
  </ul>
  <pagination prop-name="reflections" />
</template>

<script>
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'

export default {
  props: {
    reflections: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      sending: false,
      items: [],
      form: { search: '' },
      theme: 2,
      values_per_cent: true,
    }
  },
  watch: {
    form: {
      handler: throttle(function () {
        let query = pickBy(this.form)
        this.$inertia.get(
          this.$routes.reflections(query),
          {},
          { preserveState: true, preserveScroll: true },
        )
      }, 150),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
  },
}
</script>
<style scoped>
.content {
  width: 200px;
}
@media (min-width: 1024px) {
  .content {
    width: 600px;
  }
}
</style>
