<i18n>
{
  "en": {
    "title": "Edit User Account",
    "delete": "Delete User Account",
    "save": "save"
  },
  "de": {
    "title": "Benutzergruppe bearbeiten",
    "delete": "Benutzergruppe löschen",
    "save": "speichern"
  }
}
</i18n>
<template>
  <page-title :navBackUrl="$routes.user_accounts()" :title="$t('title')" />
  <user-form :value="form" :can="can" @submit="submit">
    <template #actionAddPermission>
      <div class="flex items-end gap-x-4">
        <ul class="flex-grow flex flex-wrap gap-2 md:gap-4">
          <li class="" v-for="user_permission in form.user_permissions" :key="user_permission.id">
            <div class="btn-green">
              <span>{{ user_permission.name }}</span>
              <button v-tippy content="entfernen" @click="removeUserPermission(user_permission)">
                <icon
                  class="ml-3 w-3 h-3"
                  name="cross"
                />
              </button>
            </div>
          </li>
        </ul>
        <button class="" @click="show_users_modal = true">
          <icon name="plus-circle" class="w-10 h-10" />
        </button>
      </div>
    </template>
  </user-form>
  <footer-actions>
    <button
      class="btn-delete"
      tabindex="-1"
      type="button"
      @click="show_confirm_dialog=true"
    >
      löschen
    </button>
    <loading-button
      :loading="sending"
      class="btn ml-auto"
      type="submit"
      @click="submit()"
    >
      {{ $t('save') }}
    </loading-button>
  </footer-actions>
  <confirm-delete
    v-show="show_confirm_dialog"
    :modalHeadline="$t('delete')"
    @deleteRecordEvent="destroy"
    @close="show_confirm_dialog = false"
  />
  <modal
    :open="show_users_modal"
    :colored-header="true"
    title="Teilen"
    @close="show_users_modal = false"
  >
    <ul class="bg-white p-4 flex flex-wrap gap-2 md:gap-4">
      <li v-for="user in usersSelection" :key="user.id">
        <button :class="{'btn-green': user.selected, 'btn-gray': !user.selected}" @click="toggleUserPermission(user)">{{ user.name }}</button>
      </li>
    </ul>
  </modal>
</template>
<script>
import Layout from '@/Layouts/Full.vue'
import UserForm from './Form.vue'

export default {
  components: {
    UserForm,
  },
  layout: Layout,
  props: {
    user_account: {
      type: Object,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    can: {
      type: Object,
      required: true,
    },
  },
  remember: 'form',
  data() {
    return {
      show_confirm_dialog: false,
      show_users_modal: false,
      sending: false,
      form: this.user_account,
      usersSelection: [],
    }
  },
  mounted() {
    this.users.forEach(user => {
      let selected = false
      this.user_account.user_permissions.forEach(user_permission => {
        if(user_permission.user_id == user.id) {
          selected = true
          return
        }
      })
      this.usersSelection.push({
        id: user.id,
        selected: selected,
        name: user.name
      })
    })
  },
  methods: {
    submit() {
      let data = new FormData()
      data.append('user_account[name]', this.form.name || '')
      data.append('user_account[avatar]', this.form.avatar || '')
      data.append('user_account[memos_enabled]', this.form.memos_enabled || '')
      data.append('_method', 'put')

      this.$inertia.post(this.$routes.user_account(this.user_account.id), data, {
        preserveState: false,
        onStart: () => (this.sending = true),
        onSuccess: () => {
          this.sending = false
        },
      })
    },
    destroy() {
      this.$inertia.delete(this.$routes.user_account(this.user_account.id), {
        preserveState: false,
        onStart: () => (this.sending = true),
        onSuccess: () => {
          this.sending = false
        },
      })
    },
    toggleUserPermission(user) {
      if(user.selected) {
        // remove
        let user_permission = this.user_account.user_permissions.find(item => item.user_id == user.id)
        // if(user_permission)
        this.$inertia.delete(this.$routes.user_permission(user_permission.id), {
          onSuccess: (event) => {
            this.form.user_permissions = event.props.user_account.user_permissions
            user.selected = false
          }
        })
      } else {
        // add
        let data = new FormData()
        data.append('user_permission[viewable_id]', this.user_account.id || '')
        data.append('user_permission[user_id]', user.id || '')

        this.$inertia.post(this.$routes.user_permissions(), data, {
          onSuccess: (event) => {
            this.form.user_permissions = event.props.user_account.user_permissions
            user.selected = true
          }
        })
      }
    },
    removeUserPermission(user_permission) {
      this.$inertia.delete(this.$routes.user_permission(user_permission.id), {
        onSuccess: (event) => {
          this.form.user_permissions = event.props.user_account.user_permissions
        }
      })
    }
  },
}
</script>
