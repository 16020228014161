<i18n>
{
  "en": {
    "title": "New Plan",
    "save": "save"
  },
  "de": {
    "title": "Neuer Wochenplan",
    "save": "speichern"
  }
}
</i18n>
<template>
  <header class="mb-4 md:mb-8">
    <page-title :nav-back-url="$routes.plans()" :title="$t('title')" />
  </header>
  <user-form
    v-if="form"
    v-model="form"
    :clips="clips"
    :memos="memos"
    :subjects="subjects"
    :rooms="rooms"
    :users="users"
    class="border border-yellow-dark"
    @submit="submit"
  />
  <footer-actions>
    <loading-button
      :loading="sending"
      class="btn ml-auto"
      type="submit"
      @click="submit()"
    >
      {{ $t('save') }}
    </loading-button>
  </footer-actions>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import UserForm from './Form.vue'

export default {
  components: {
    UserForm,
  },
  props: {
    plan: {
      type: Object,
      required: true,
    },
    clips: {
      type: Array,
      required: true,
    },
    memos: {
      type: Array,
      required: true,
    },
    subjects: {
      type: Array,
      required: true,
    },
    rooms: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      sending: false,
      form: null,
    }
  },
  mounted() {
    this.form = _.omit(this.plan, 'id', 'deleted_at')
    if (!this.form.bg_color) {
      this.form.bg_color = '#457067'
    }
    if (!this.form.fg_color) {
      this.form.fg_color = '#ffffff'
    }
    if (!this.form.year) {
      this.form.year = moment().year()
    }
    if (!this.form.week) {
      this.form.week = moment().isoWeek()
    }
  },
  methods: {
    submit() {
      this.$inertia.post(this.$routes.plans(), { plan: this.form })
    },
  },
}
</script>
<style scoped></style>
