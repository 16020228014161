<i18n>
{
  "en": {
    "account": "Account",
    "news": "News",
    "imprint" : "Imprint",
    "privacy" : "Privacy"
  },
  "de": {
    "account": "Konto",
    "news": "Neuigkeiten",
    "imprint" : "Impressum",
    "privacy" : "Datenschutz"
  }
}
</i18n>
<template>
  <nav
    class="w-full flex items-center pl-2 pr-1 sm:px-4 md:pl-6 py-1 max-w-screen-xl mx-auto"
  >
    <div class="md:flex-shrink-0 mr-auto">
      <Link
        class="flex items-center text-white"
        :href="$routes.root()"
        aria-label="Home"
        role="navigation"
      >
        <img
          class="h-8 my-2"
          :src="imagePath('prima.schule_wy.svg')"
        >
      </Link>
    </div>

    <div
      v-if="$page && $page.props.auth"
      name="main-menu"
      class="flex ml-auto items-center"
    >
      <template v-if="$page.props.auth.user">
        <dropdown name="user-menu">
          <div
            class="flex text-sm rounded-full focus:outline-none focus:shadow-solid"
            :class="{'bg-white': $page.props.auth.user.avatar_url}"
          >
            <img
              v-if="$page.props.auth.user.avatar_url"
              class="w-14 h-14 rounded-full"
              :src="$page.props.auth.user.avatar_url"
              alt=""
            >
            <icon
              v-else
              name="user-circle-black"
              class="w-14 h-14 text-white rounded-full"
            />
          </div>
          <template
            #dropdown
          >
            <div class="mt-1 bg-white rounded-b">
              <Link
                v-if="$page.props.auth.user.owner"
                class="block px-4 pt-3 pb-2 hover:bg-primary-red hover:text-white"
                role="navigation"
                :href="$routes.announcements()"
              >
                {{ $t('news') }}
              </Link>
              <a
                class="block px-4 py-2 hover:bg-primary-red hover:text-white"
                :href="$getStaticUrl('terms')"
              >
                {{ $t('imprint') }}
              </a>
              <a
                class="block px-4 py-2 hover:bg-primary-red hover:text-white"
                :href="$getStaticUrl('privacy')"
              >
                {{ $t('privacy') }}
              </a>
              <Link
                v-if="$page.props.auth.user.owner"
                class="block px-4 pt-3 pb-2 hover:bg-primary-red hover:text-white border-t"
                role="navigation"
                :href="$routes.me()"
              >
                {{ $t('account') }}
              </Link>
              <Link
                class="w-full px-4 pt-3 pb-2 text-left rounded-b hover:bg-primary-red hover:text-white border-t"
                :href="$routes.destroy_user_session()"
                method="delete"
                as="button"
              >
                Logout
              </Link>
            </div>
          </template>
        </dropdown>
      </template>
    </div>
  </nav>
</template>
<script>

export default {
  methods: {
    navigate() {
      console.log("123")
    }
  }
}
</script>
