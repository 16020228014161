<template>
  <section
    class="w-full border border-yellow-dark rounded-t-lg"
  >
    <header
      class="flex items-center w-full p-2 md:py-4 cursor-pointer select-none bg-white rounded-t-lg"
      @click="$emit('setCurrentId', id)"
    >
      <div
        class="flex-grow font-bold"
        :class="colorClass"
      >
        {{ label }}
      </div>
      <div
        v-if="labelDateTime"
        class="mr-2"
      >
        {{ labelDateTime }}
      </div>
      <div
        class="flex p-1 text-yellow-dark"
      >
        <svg
          aria-hidden="true"
          fill="none"
          height="24"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewbox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polyline
            v-if="!showContent"
            points="6 9 12 15 18 9"
          />
          <polyline
            v-if="showContent"
            points="18 15 12 9 6 15"
          />
        </svg>
      </div>
    </header>
    <div
      class="flex w-full flex-wrap border-t px-2 pt-2 border-yellow-dark bg-white"
      :class="id === currentId ? 'block' : 'hidden'"
    >
      <slot />
    </div>
  </section>
</template>
<script>

export default {
	props: {
    id: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    labelDateTime: {
      type: String,
      default: null,
    },
    labelType: {
      type: String,
      default: null,
    },
    currentId: {
      type: Number,
      default: null,
    }
	},
	data() {
    return {
      showContent: true,
    }
	},
  computed: {
    colorClass() {
      if(this.labelType === 'error') {
        return 'text-red-dark'
      }
      if(this.labelType === 'fix') {
        return 'text-yellow-dark'
      }
      return ''
    }
  }
}
</script>
