<template>
  <svg
    v-if="name === 'cheveron-down'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
    />
  </svg>
  <svg
    v-else-if="name === 'arrow-left'"
    viewBox="0 0 43.98 44.42"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g>
        <path
          fill="currentColor"
          d="M44,21a24.61,24.61,0,0,1-1.26,8.52,24,24,0,0,1-4.38,7.67,20.32,20.32,0,0,1-16.18,7.22,21.42,21.42,0,0,1-8.78-2.21,24.74,24.74,0,0,1-7.1-5.31A23,23,0,0,1,0,21,21.86,21.86,0,0,1,6.63,5.49,19.22,19.22,0,0,1,13.88,1a27.89,27.89,0,0,1,8.27-1,24.88,24.88,0,0,1,8.13,1.38A19.72,19.72,0,0,1,42.23,12.69,23.93,23.93,0,0,1,44,21Zm-2.63,0a19.31,19.31,0,0,0-5.5-13.68,20.82,20.82,0,0,0-6.13-4.57A17.29,17.29,0,0,0,22.15,1a15.76,15.76,0,0,0-7.42,2.09A23,23,0,0,0,8.67,7.52,19.26,19.26,0,0,0,3.29,21,18,18,0,0,0,9,34.12a16.69,16.69,0,0,0,6.2,3.58,20.45,20.45,0,0,0,6.92.91A22.14,22.14,0,0,0,35,33.82,17.58,17.58,0,0,0,41.34,21Z"
        />
        <path
          fill="currentColor"
          d="m 28.49,11.44 c -2.54,2 -2.36,1.44 -4.66,3.72 -1.542333,1.521954 -2.988461,3.138411 -4.33,4.84 0,0 -3.07,1 -3.1,0.74 -0.433331,-1.371224 -0.412793,-0.716282 0.26,0.14 0.47,0.56 1.62,0.35 2.16,0.92 1.1,1.13 7.55,6.89 8.72,8 2.35,2.23 -1.12,0.55 1.35,2.68 l -0.18,0.26 c -2.953333,-1.38 -5.896667,-2.8 -8.83,-4.26 -1.47,-0.74 -2.93,-1.49 -4.4,-2.31 -0.74,-0.41 -1.47,-0.84 -2.22,-1.33 -0.407634,-0.259347 -0.8015,-0.539726 -1.18,-0.84 -0.258052,-0.210391 -0.498823,-0.44113 -0.72,-0.69 -0.0821,-0.09223 -0.158887,-0.189056 -0.23,-0.29 -0.102517,-0.142125 -0.192839,-0.292661 -0.27,-0.45 0,-0.07 -0.11,-0.24 -0.17,-0.39 -0.06988,-0.191072 -0.120135,-0.388756 -0.15,-0.59 -0.0575,-0.330853 -0.0575,-0.669147 0,-1 0.03192,-0.215908 0.08897,-0.427343 0.17,-0.63 v -0.13 0 0 c 0.25,-0.54 0.74,-1.32 0.78,-1.41 0.152298,-0.219574 0.350121,-0.403753 0.58,-0.54 0.38,-0.24 0.77,-0.5 1.15,-0.7 0.75,-0.42 1.54,-0.77 2.29,-1.14 1.546382,-0.718314 3.046014,-1.533259 4.49,-2.44 2.82,-1.64 5.6,-3.31 8.25,-5.16 z"
        />
      </g>
    </g>
  </svg>
  <svg
    v-else-if="name === 'dashboard'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path
      d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm-5.6-4.29a9.95 9.95 0 0 1 11.2 0 8 8 0 1 0-11.2 0zm6.12-7.64l3.02-3.02 1.41 1.41-3.02 3.02a2 2 0 1 1-1.41-1.41z"
    />
  </svg>
  <svg
    v-else-if="name === 'location'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path
      d="M10 20S3 10.87 3 7a7 7 0 1 1 14 0c0 3.87-7 13-7 13zm0-11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
    />
  </svg>
  <svg
    v-else-if="name === 'trash'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z"
    />
  </svg>
  <svg
    v-else-if="name === 'plus-circle'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z"
    />
  </svg>
  <svg
    v-else-if="name === 'plus'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    fill="currentColor"
  >
    <path
      d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
    />
  </svg>
  <svg
    v-else-if="name === 'minus-circle'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path
      d="M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4M16,10V17A1,1 0 0,1 15,18H9A1,1 0 0,1 8,17V10H16M13.5,6L14.5,7H17V9H7V7H9.5L10.5,6H13.5Z"
    />
  </svg>
  <svg
    v-else-if="name === 'save'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      fill="currentColor"
      d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM224 416c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64s64 28.654 64 64c0 35.346-28.654 64-64 64zm96-304.52V212c0 6.627-5.373 12-12 12H76c-6.627 0-12-5.373-12-12V108c0-6.627 5.373-12 12-12h228.52c3.183 0 6.235 1.264 8.485 3.515l3.48 3.48A11.996 11.996 0 0 1 320 111.48z"
    />
  </svg>
  <svg
    v-else-if="name === 'ellipsis-v'"
    xmlns="http://www.w3.org/2000/svg"
    height="20"
    width="20"
    viewBox="0 0 192 512"
  >
    <path
      fill="currentColor"
      d="M96 184c39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72 32.2-72 72-72zM24 80c0 39.8 32.2 72 72 72s72-32.2 72-72S135.8 8 96 8 24 40.2 24 80zm0 352c0 39.8 32.2 72 72 72s72-32.2 72-72-32.2-72-72-72-72 32.2-72 72z"
    />
  </svg>
  <svg
    v-else-if="name === 'cross'"
    xmlns="http://www.w3.org/2000/svg"
    height="492"
    width="492"
    viewBox="0 0 492 492"
  >
    <path
      fill="currentColor"
      d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872
          c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872
          c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052
          L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116
          c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952
          c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116
          c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"
    />
  </svg>
  <svg
    v-else-if="name === 'file-image'"
    xmlns="http://www.w3.org/2000/svg"
    height="35"
    width="20"
    viewBox="0 0 384 512"
    fill="currentColor"
  >
    <path
      d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm32-48h224V288l-23.5-23.5c-4.7-4.7-12.3-4.7-17 0L176 352l-39.5-39.5c-4.7-4.7-12.3-4.7-17 0L80 352v64zm48-240c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48z"
    />
  </svg>
  <svg
    v-else-if="name === 'basket'"
    width="464.66669"
    height="464.66672"
    viewBox="0 0 464.66669 464.66669"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style="fill-opacity: 1; stroke-width: 0.87390023"
      d="m 153.73809,121.33983 c -23.74471,18.29095 -43.89473,54.50551 -46.62772,72.97014 -2.50517,16.92561 -10.983633,17.27058 -21.504387,15.66151 -15.236026,-2.33024 -32.391694,13.27628 -21.518838,27.41611 7.156335,9.3066 25.666547,7.17853 26.434866,24.65449 2.299159,25.96093 13.708759,52.19097 25.381919,75.74112 9.39061,14.52844 22.22423,32.38821 43.33207,32.307 23.86176,0.67241 53.33218,0.91011 71.64519,0.91011 18.31299,0 68.04453,2.62222 91.26408,-3.70236 14.53527,-6.88954 20.85443,-21.57921 29.42564,-33.37165 13.09399,-22.41447 22.92572,-46.77791 26.78521,-71.88422 -1.30053,-15.60293 13.74945,-14.89003 23.04856,-25.21102 9.23587,-10.25077 -12.0056,-27.12425 -25.92014,-26.85958 -15.56744,1.27106 -25.1136,0.0689 -25.79552,-12.55309 C 345.88138,178.51337 329.8339,141.81493 306.83375,122.90001 286.55699,106.22476 257.9801,94.938877 230.41884,94.682189 202.99218,94.426748 174.5085,105.34002 153.73809,121.33983 Z m 74.85667,3.56879 c 18.39465,-0.39245 38.7544,4.74172 52.243,15.91934 21.68007,17.96564 40.9235,57.49799 34.84915,72.87464 -6.01229,15.2195 -64.08585,14.19986 -84.80572,14.73399 -0.21397,0.007 -0.54725,0.008 -0.75311,0.0145 -28.41165,-0.16634 -87.22594,-1.77192 -90.24369,-14.7485 -3.01772,-12.97661 17.24075,-53.28085 38.95787,-71.22285 13.09173,-10.81597 31.924,-17.19078 49.7525,-17.57116 z"
      sodipodi:nodetypes="acssccczcccsccaaaaassscaa"
      inkscape:connector-curvature="0"
      fill="currentColor"
    />
    <circle
      style="fill: none; stroke-width: 36"
      cx="231.81766"
      cy="237.13747"
      r="216.77136"
      stroke="currentColor"
    />
  </svg>
  <svg
    v-else-if="name === 'search'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
    />
  </svg>
  <svg
    v-else-if="name === 'video'"
    width="210.01mm"
    height="140.01mm"
    viewBox="0 2333 21001 14001"
    preserveAspectRatio="xMidYMid"
    fill-rule="evenodd"
    stroke-width="28.222"
    stroke-linejoin="round"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:ooo="http://xml.openoffice.org/svg/export"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:presentation="http://sun.com/xmlns/staroffice/presentation"
    xmlns:smil="http://www.w3.org/2001/SMIL20/"
    xmlns:anim="urn:oasis:names:tc:opendocument:xmlns:animation:1.0"
    xml:space="preserve"
  >
    <defs class="ClipPathGroup">
      <clipPath id="presentation_clip_path" clipPathUnits="userSpaceOnUse">
        <rect x="0" y="2333" width="21001" height="14001" />
      </clipPath>
    </defs>
    <defs class="TextShapeIndex">
      <g ooo:slide="id1" ooo:id-list="id3" />
    </defs>
    <defs class="EmbeddedBulletChars">
      <g
        id="bullet-char-template(57356)"
        transform="scale(0.00048828125,-0.00048828125)"
      >
        <path d="M 580,1141 L 1163,571 580,0 -4,571 580,1141 Z" />
      </g>
      <g
        id="bullet-char-template(57354)"
        transform="scale(0.00048828125,-0.00048828125)"
      >
        <path d="M 8,1128 L 1137,1128 1137,0 8,0 8,1128 Z" />
      </g>
      <g
        id="bullet-char-template(10146)"
        transform="scale(0.00048828125,-0.00048828125)"
      >
        <path
          d="M 174,0 L 602,739 174,1481 1456,739 174,0 Z M 1358,739 L 309,1346 659,739 1358,739 Z"
        />
      </g>
      <g
        id="bullet-char-template(10132)"
        transform="scale(0.00048828125,-0.00048828125)"
      >
        <path
          d="M 2015,739 L 1276,0 717,0 1260,543 174,543 174,936 1260,936 717,1481 1274,1481 2015,739 Z"
        />
      </g>
      <g
        id="bullet-char-template(10007)"
        transform="scale(0.00048828125,-0.00048828125)"
      >
        <path
          d="M 0,-2 C -7,14 -16,27 -25,37 L 356,567 C 262,823 215,952 215,954 215,979 228,992 255,992 264,992 276,990 289,987 310,991 331,999 354,1012 L 381,999 492,748 772,1049 836,1024 860,1049 C 881,1039 901,1025 922,1006 886,937 835,863 770,784 769,783 710,716 594,584 L 774,223 C 774,196 753,168 711,139 L 727,119 C 717,90 699,76 672,76 641,76 570,178 457,381 L 164,-76 C 142,-110 111,-127 72,-127 30,-127 9,-110 8,-76 1,-67 -2,-52 -2,-32 -2,-23 -1,-13 0,-2 Z"
        />
      </g>
      <g
        id="bullet-char-template(10004)"
        transform="scale(0.00048828125,-0.00048828125)"
      >
        <path
          d="M 285,-33 C 182,-33 111,30 74,156 52,228 41,333 41,471 41,549 55,616 82,672 116,743 169,778 240,778 293,778 328,747 346,684 L 369,508 C 377,444 397,411 428,410 L 1163,1116 C 1174,1127 1196,1133 1229,1133 1271,1133 1292,1118 1292,1087 L 1292,965 C 1292,929 1282,901 1262,881 L 442,47 C 390,-6 338,-33 285,-33 Z"
        />
      </g>
      <g
        id="bullet-char-template(9679)"
        transform="scale(0.00048828125,-0.00048828125)"
      >
        <path
          d="M 813,0 C 632,0 489,54 383,161 276,268 223,411 223,592 223,773 276,916 383,1023 489,1130 632,1184 813,1184 992,1184 1136,1130 1245,1023 1353,916 1407,772 1407,592 1407,412 1353,268 1245,161 1136,54 992,0 813,0 Z"
        />
      </g>
      <g
        id="bullet-char-template(8226)"
        transform="scale(0.00048828125,-0.00048828125)"
      >
        <path
          d="M 346,457 C 273,457 209,483 155,535 101,586 74,649 74,723 74,796 101,859 155,911 209,963 273,989 346,989 419,989 480,963 531,910 582,859 608,796 608,723 608,648 583,586 532,535 482,483 420,457 346,457 Z"
        />
      </g>
      <g
        id="bullet-char-template(8211)"
        transform="scale(0.00048828125,-0.00048828125)"
      >
        <path d="M -4,459 L 1135,459 1135,606 -4,606 -4,459 Z" />
      </g>
    </defs>
    <defs class="TextEmbeddedBitmaps" />
    <g class="SlideGroup">
      <g>
        <g id="id1" class="Slide" clip-path="url(#presentation_clip_path)">
          <g class="Page">
            <g class="com.sun.star.drawing.ClosedBezierShape">
              <g id="id3">
                <rect
                  class="BoundingBox"
                  stroke="none"
                  fill="none"
                  x="0"
                  y="2333"
                  width="21002"
                  height="14002"
                />
                <path
                  fill="currentColor"
                  stroke="none"
                  d="M 12258,2333 L 1743,2333 C 780,2333 0,3113 0,4076 L 0,14591 C 0,15554 780,16334 1743,16334 L 12258,16334 C 13220,16334 14001,15554 14001,14591 L 14001,4076 C 14001,3113 13220,2333 12258,2333 Z M 19163,3708 L 15167,6464 15167,12203 19163,14956 C 19936,15488 21001,14945 21001,14015 L 21001,4648 C 21001,3722 19940,3175 19163,3708 Z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <svg
    v-else-if="name === 'tasks'"
    aria-hidden="true"
    focusable="false"
    data-icon="tasks"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M139.61 35.5a12 12 0 0 0-17 0L58.93 98.81l-22.7-22.12a12 12 0 0 0-17 0L3.53 92.41a12 12 0 0 0 0 17l47.59 47.4a12.78 12.78 0 0 0 17.61 0l15.59-15.62L156.52 69a12.09 12.09 0 0 0 .09-17zm0 159.19a12 12 0 0 0-17 0l-63.68 63.72-22.7-22.1a12 12 0 0 0-17 0L3.53 252a12 12 0 0 0 0 17L51 316.5a12.77 12.77 0 0 0 17.6 0l15.7-15.69 72.2-72.22a12 12 0 0 0 .09-16.9zM64 368c-26.49 0-48.59 21.5-48.59 48S37.53 464 64 464a48 48 0 0 0 0-96zm432 16H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"
    />
  </svg>
  <svg
    v-else-if="name === 'check'"
    x="0px"
    y="0px"
    width="94.191185"
    height="94.191185"
    viewBox="0 0 94.191183 94.191181"
  >
    <g>
      <path
        fill="currentColor"
        d="m 61.899816,12.826039 c -9.318,17.668 -16.835,36.839 -21.697,56.419 -3.867,-5.199 -8.034,-10.182 -12.875,-14.148 -3.048,-2.496 -14.662,0.99 -10.214,4.635 8.101,6.639 14.265,16.844 20.284,25.334 1.922,2.713 11.237,2.025 11.899,-1.672 4.384,-24.451 14.22,-50.023 25.776,-71.935 1.682,-2.825 -11.166,-2.44 -13.173,1.367 z"
      />
    </g>
  </svg>
  <svg
    v-else-if="name === 'text'"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    enable-background="new 0 0 1000 1000"
    xml:space="preserve"
  >
    <g>
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path
          fill="currentColor"
          d="M571.7,5006.6c-7.7-5.7-13.4-373.3-13.4-813.7c0-716,3.8-802.2,30.6-813.7c23-7.7,187.6,145.5,589.7,547.6l557.1,557.1h1326.8h1324.9V402.2l-1.9-4083.7l-547.6-488.2c-302.5-268-557.1-501.6-568.6-520.8c-13.4-21.1-11.5-40.2,5.7-61.3c23-28.7,189.5-30.6,1694.4-26.8l1667.6,5.7l5.7,49.8c5.7,42.1-67,113-549.5,545.6l-557.1,495.9V402.2v4081.8h1326.8h1324.8l536.1-538c294.9-294.8,557.1-545.6,582-555.2c32.5-13.4,57.4-9.6,90,11.5l46,28.7v765.8v763.9l-42.1,28.7c-38.3,26.8-490.1,30.6-4430.2,30.6C2557.1,5020,577.5,5014.3,571.7,5006.6z"
        />
      </g>
    </g>
  </svg>
  <svg
    v-else-if="name === 'bell'"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      fill="currentColor"
      d="M439.39 362.29c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71zM67.53 368c21.22-27.97 44.42-74.33 44.53-159.42 0-.2-.06-.38-.06-.58 0-61.86 50.14-112 112-112s112 50.14 112 112c0 .2-.06.38-.06.58.11 85.1 23.31 131.46 44.53 159.42H67.53zM224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64z"
    />
  </svg>
  <svg
    v-else-if="name === 'smile'"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 496 512"
  >
    <path
      fill="currentColor"
      d="M 248,8 C 111,8 0,119 0,256 0,393 111,504 248,504 385,504 496,393 496,256 496,119 385,8 248,8 Z m 0,462 C 137.7,470 34,366.3 34,256 34,145.7 137.7,42 248,42 358.3,42 462,145.7 462,256 462,366.3 358.3,470 248,470 Z M 168,240 c 17.7,0 32,-14.3 32,-32 0,-17.7 -14.3,-32 -32,-32 -17.7,0 -32,14.3 -32,32 0,17.7 14.3,32 32,32 z m 160,0 c 17.7,0 32,-14.3 32,-32 0,-17.7 -14.3,-32 -32,-32 -17.7,0 -32,14.3 -32,32 0,17.7 14.3,32 32,32 z m 4,72.6 c -20.8,25 -51.5,39.4 -84,39.4 -32.5,0 -63.2,-14.3 -84,-39.4 -8.5,-10.2 -23.7,-11.5 -33.8,-3.1 -10.2,8.5 -11.5,23.6 -3.1,33.8 30,36 74.1,56.6 120.9,56.6 46.8,0 90.9,-20.6 120.9,-56.6 8.5,-10.2 7.1,-25.3 -3.1,-33.8 -10.1,-8.4 -25.3,-7.1 -33.8,3.1 z"
    />
  </svg>
  <svg
    v-else-if="name === 'smile1'"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 496 512"
  >
    <path
      fill="currentColor"
      d="M 248,8 C 111,8 0,119 0,256 0,393 111,504 248,504 385,504 496,393 496,256 496,119 385,8 248,8 Z m 0,462 C 137.7,470 34,366.3 34,256 34,145.7 137.7,42 248,42 358.3,42 462,145.7 462,256 462,366.3 358.3,470 248,470 Z M 168,240 c 17.7,0 32,-14.3 32,-32 0,-17.7 -14.3,-32 -32,-32 -17.7,0 -32,14.3 -32,32 0,17.7 14.3,32 32,32 z m 160,0 c 17.7,0 32,-14.3 32,-32 0,-17.7 -14.3,-32 -32,-32 -17.7,0 -32,14.3 -32,32 0,17.7 14.3,32 32,32 z m 4,72.6 c -20.8,25 -51.5,39.4 -84,39.4 -32.5,0 -63.2,-14.3 -84,-39.4 -8.5,-10.2 -23.7,-11.5 -33.8,-3.1 -10.2,8.5 -11.5,23.6 -3.1,33.8 30,36 74.1,56.6 120.9,56.6 46.8,0 90.9,-20.6 120.9,-56.6 8.5,-10.2 7.1,-25.3 -3.1,-33.8 -10.1,-8.4 -25.3,-7.1 -33.8,3.1 z"
    />
  </svg>
  <svg
    v-else-if="name === 'rate_1'"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 496 512"
  >
    <path
      fill="currentColor"
      d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm84-143.4c-20.8 25-51.5 39.4-84 39.4s-63.2-14.3-84-39.4c-8.5-10.2-23.6-11.5-33.8-3.1-10.2 8.5-11.5 23.6-3.1 33.8 30 36 74.1 56.6 120.9 56.6s90.9-20.6 120.9-56.6c8.5-10.2 7.1-25.3-3.1-33.8-10.2-8.4-25.3-7.1-33.8 3.1zM136.5 211c7.7-13.7 19.2-21.6 31.5-21.6s23.8 7.9 31.5 21.6l9.5 17c2.1 3.7 6.2 4.7 9.3 3.7 3.6-1.1 6-4.5 5.7-8.3-3.3-42.1-32.2-71.4-56-71.4s-52.7 29.3-56 71.4c-.3 3.7 2.1 7.2 5.7 8.3 3.4 1.1 7.4-.5 9.3-3.7l9.5-17zM328 152c-23.8 0-52.7 29.3-56 71.4-.3 3.7 2.1 7.2 5.7 8.3 3.5 1.1 7.4-.5 9.3-3.7l9.5-17c7.7-13.7 19.2-21.6 31.5-21.6s23.8 7.9 31.5 21.6l9.5 17c2.1 3.7 6.2 4.7 9.3 3.7 3.6-1.1 6-4.5 5.7-8.3-3.3-42.1-32.2-71.4-56-71.4z"
    />
  </svg>
  <svg
    v-else-if="name === 'rate_2'"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 496 512"
  >
    <path
      fill="currentColor"
      d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm4 72.6c-20.8 25-51.5 39.4-84 39.4s-63.2-14.3-84-39.4c-8.5-10.2-23.7-11.5-33.8-3.1-10.2 8.5-11.5 23.6-3.1 33.8 30 36 74.1 56.6 120.9 56.6s90.9-20.6 120.9-56.6c8.5-10.2 7.1-25.3-3.1-33.8-10.1-8.4-25.3-7.1-33.8 3.1z"
    />
  </svg>
  <svg
    v-else-if="name === 'rate_3'"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 496 512"
  >
    <path
      fill="currentColor"
      d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160-64c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm8 144H160c-13.2 0-24 10.8-24 24s10.8 24 24 24h176c13.2 0 24-10.8 24-24s-10.8-24-24-24z"
    />
  </svg>
  <svg
    v-else-if="name === 'rate_4'"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 496 512"
  >
    <path
      fill="currentColor"
      d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160-64c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm-80 128c-40.2 0-78 17.7-103.8 48.6-8.5 10.2-7.1 25.3 3.1 33.8 10.2 8.4 25.3 7.1 33.8-3.1 16.6-19.9 41-31.4 66.9-31.4s50.3 11.4 66.9 31.4c8.1 9.7 23.1 11.9 33.8 3.1 10.2-8.5 11.5-23.6 3.1-33.8C326 321.7 288.2 304 248 304z"
    />
  </svg>
  <svg
    v-else-if="name === 'individual'"
    xmlns="http://www.w3.org/2000/svg"
    height="535"
    width="535"
    version="1.1"
    viewBox="0 0 535 535"
    role="img"
  >
    <path
      fill="currentColor"
      d="m 269.3688,92.124279 c 25.94581,0 46.97934,21.033531 46.97934,46.979331 0,25.94581 -21.03353,46.97935 -46.97934,46.97935 -25.94583,0 -46.97936,-21.03354 -46.97936,-46.97935 0,-25.9458 21.03353,-46.979331 46.97936,-46.979331 m 35.2345,94.909091 h -8.33883 c -16.67107,7.6657 -36.40166,7.99676 -53.79135,0 h -8.33883 c -19.45974,0 -35.23452,15.7748 -35.23452,35.2345 v 99.8311 c 0,9.72988 7.8874,17.61726 17.61725,17.61726 h 11.74483 v 99.83109 c 0,9.72988 7.8874,17.61728 17.61727,17.61728 h 46.97934 c 9.72987,0 17.61726,-7.8874 17.61726,-17.61728 v -99.83109 h 11.74484 c 9.72985,0 17.61726,-7.88738 17.61726,-17.61726 v -99.8311 c 0,-19.4597 -15.7748,-35.2345 -35.23452,-35.2345 z"
      style="
        fill-opacity: 1;
        stroke: #ffffff;
        stroke-width: 7.10490131;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-opacity: 1;
      "
      inkscape:connector-curvature="0"
      sodipodi:nodetypes="cssscsccsssscsssscssss"
    />
    <circle
      stroke="currentColor"
      style="fill: none; stroke-width: 36"
      cx="267.31671"
      cy="267.68329"
      r="249.3167"
    />
    <path
      d="m 404.60439,138.30257 c 19.22907,0 34.81751,15.58845 34.81751,34.81751 0,19.22906 -15.58844,34.81751 -34.81751,34.81751 -19.22906,0 -34.81752,-15.58845 -34.81752,-34.81751 0,-19.22906 15.58846,-34.81751 34.81752,-34.81751 m 26.11313,70.3394 h -6.1801 c -12.35532,5.68123 -26.97813,5.9266 -39.86606,0 h -6.1801 c -14.42208,0 -26.11314,11.69107 -26.11314,26.11312 v 73.98723 c 0,7.21103 5.84553,13.05656 13.05657,13.05656 h 8.70437 v 73.98721 c 0,7.21104 5.84554,13.05657 13.05657,13.05657 h 34.81751 c 7.21105,0 13.05658,-5.84553 13.05658,-13.05657 v -73.98721 h 8.70438 c 7.21102,0 13.05656,-5.84553 13.05656,-13.05656 v -73.98723 c 0,-14.42205 -11.69107,-26.11312 -26.11314,-26.11312 z"
      style="
        fill: #b3b3b3;
        fill-opacity: 1;
        stroke: #ffffff;
        stroke-width: 7.10490131;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-opacity: 1;
      "
      inkscape:connector-curvature="0"
      sodipodi:nodetypes="cssscsccsssscsssscssss"
    />
    <path
      d="m 135.28712,139.56783 c 19.22907,0 34.81751,15.58845 34.81751,34.81751 0,19.22905 -15.58844,34.81751 -34.81751,34.81751 -19.22907,0 -34.81752,-15.58846 -34.81752,-34.81751 0,-19.22906 15.58845,-34.81751 34.81752,-34.81751 m 26.11313,70.3394 h -6.1801 c -12.35532,5.68123 -26.97813,5.92659 -39.86606,0 h -6.1801 c -14.42208,0 -26.11314,11.69107 -26.11314,26.11312 v 73.98723 c 0,7.21103 5.84553,13.05656 13.05656,13.05656 h 8.70437 v 73.98721 c 0,7.21104 5.84555,13.05657 13.05658,13.05657 h 34.81751 c 7.21105,0 13.05658,-5.84553 13.05658,-13.05657 v -73.98721 h 8.70438 c 7.21102,0 13.05656,-5.84553 13.05656,-13.05656 v -73.98723 c 0,-14.42205 -11.69107,-26.11312 -26.11314,-26.11312 z"
      style="
        fill: #b3b3b3;
        fill-opacity: 1;
        stroke: #ffffff;
        stroke-width: 7.10490131;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-opacity: 1;
      "
      inkscape:connector-curvature="0"
      sodipodi:nodetypes="cssscsccsssscsssscssss"
    />
  </svg>
  <svg
    v-else-if="name === 'ha'"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 469.54 469.54"
  >
    <circle
      style="
        fill: none;
        fill-opacity: 1;
        stroke-width: 36;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
      "
      cx="234.77136"
      cy="234.77136"
      r="216.77136"
      stroke="currentColor"
    />
    <g
      aria-label="HA"
      transform="translate(2.1584802,11.568982)"
      fill="currentColor"
    >
      <path
        d="m 209.76181,292.71315 q -0.53163,5.3163 -4.51887,8.7719 -3.72141,3.4556 -8.7719,3.4556 -5.31632,0 -9.30355,-3.18978 -3.98723,-3.45561 -3.72142,-8.77191 0.53164,-11.43007 1.32907,-22.06271 0.53165,-9.30353 1.06327,-19.1387 0.53163,-10.10099 1.06326,-17.27802 h -68.58041 l -3.45559,58.21362 q -0.26582,5.3163 -4.51887,8.7719 -3.98723,3.4556 -9.03772,3.4556 -5.050499,0 -9.03773,-3.18978 -3.721418,-3.45561 -3.455605,-8.77191 l 7.974462,-140.0848 q 0.531639,-5.31631 4.784683,-8.24027 4.25304,-3.18979 9.03773,-3.18979 4.78468,0 8.7719,3.18979 3.98724,2.92396 3.72142,7.97446 -0.53162,10.89844 -1.32907,21.53105 -0.53163,9.03773 -1.06327,18.87291 -0.53162,9.83517 -1.06326,16.74639 h 68.58041 l 3.45561,-56.88454 q 0.26581,-5.31631 4.51885,-8.24027 4.51887,-3.18979 9.30356,-3.18979 4.78467,0 8.50608,2.92398 3.72142,2.92396 3.45561,8.24027 z"
      />
      <path
        d="m 376.42774,291.91569 q 0,5.58212 -3.4556,9.56936 -3.45559,3.98723 -9.30354,3.98723 -4.25306,0 -8.77192,-2.65815 -4.25304,-2.92398 -5.58213,-7.17702 l -14.08822,-42.53049 h -51.83402 l -19.13872,43.06211 q -1.5949,3.98724 -5.58214,6.91122 -3.7214,2.65815 -8.24027,2.65815 -5.58213,0 -9.56935,-3.98723 -3.72142,-4.25306 -3.72142,-10.10099 0,-0.53164 0,-1.59491 0,-1.06324 0.26581,-1.59489 l 61.40339,-135.56592 q 2.12652,-5.05049 6.37957,-8.50608 4.51886,-3.72142 10.36681,-3.72142 6.11375,0 10.10099,3.98723 4.25305,3.98723 6.11374,9.30353 1.06327,3.45561 4.78469,14.61987 3.72142,11.16424 8.77191,26.04991 5.0505,14.61985 10.36682,31.10042 5.58211,16.48056 10.10099,30.8346 4.51885,14.08822 7.44282,23.9234 3.18979,9.83518 3.18979,11.43007 z m -63.26409,-108.18692 -20.4678,47.31515 h 35.61929 z"
      />
    </g>
  </svg>
  <svg v-else-if="name === 'hash'" viewBox="0 0 469.54 469.54">
    <circle
      style="
        fill: none;
        fill-opacity: 1;
        stroke-width: 36;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
      "
      cx="234.77136"
      cy="234.77136"
      r="216.77136"
      stroke="currentColor"
    />
    <g
      aria-label="HA"
      transform="translate(2.1584802,11.568982)"
      fill="currentColor"
    >
      <path
        d="m 147.30781,364.83466 13.6165,-72.74079 h -32.96627 v -21.14141 h 36.90789 l 13.6165,-70.23249 h -39.05786 v -21.14141 h 42.99948 l 12.89985,-70.59082 h 21.85807 l -13.25818,70.59082 h 49.80773 l 13.25818,-70.59082 h 21.49974 l -13.25817,70.59082 h 35.11624 v 21.14141 h -39.05786 l -13.25818,70.23249 h 40.84951 v 21.14141 h -45.14946 l -13.6165,72.74079 h -21.49974 l 13.6165,-72.74079 h -49.80773 l -13.6165,72.74079 z m 39.41619,-93.8822 h 49.80773 l 13.25818,-70.23249 h -49.80774 z"
      />
    </g>
  </svg>
  <svg
    v-else-if="name === 'user-circle-black'"
    fill="currentColor"
    viewBox="0 0 20 20"
  >
    <path
      fill-rule="evenodd"
      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
      clip-rule="evenodd"
    />
  </svg>
  <svg
    v-else-if="name === 'pencil-alt'"
    fill="currentColor"
    viewBox="0 0 20 20"
  >
    <path
      d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
    />
    <path
      fill-rule="evenodd"
      d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
      clip-rule="evenodd"
    />
  </svg>
  <svg
    v-else-if="name === 'document'"
    fill="currentColor"
    viewBox="0 0 84.596 84.596"
  >
    <path
      d="M55.285,0H19.767C13.49,0,8.384,5.107,8.384,11.384v61.828c0,6.277,5.107,11.384,11.384,11.384h45.062   c6.276,0,11.383-5.106,11.383-11.384v-49.82L55.285,0z M56.942,10.853l9.561,10.686h-9.561V10.853z M64.829,78.596H19.767   c-2.969,0-5.384-2.415-5.384-5.384V11.384C14.384,8.415,16.799,6,19.767,6h31.175v21.538h19.27v45.674   C70.213,76.181,67.798,78.596,64.829,78.596z"
    />
  </svg>
  <svg
    v-else-if="name === 'info'"
    fill="currentColor"
    viewBox="0 0 23.625 23.625"
  >
    <path
      d="m 14.271,18.307 c -0.608,0.24 -1.092,0.422 -1.455,0.548 -0.362,0.126 -0.783,0.189 -1.262,0.189 -0.736,0 -1.309,-0.18 -1.717,-0.539 -0.408,-0.359 -0.611,-0.814 -0.611,-1.367 0,-0.215 0.015,-0.435 0.045,-0.659 0.031,-0.224 0.08,-0.476 0.147,-0.759 l 0.761,-2.688 c 0.067,-0.258 0.125,-0.503 0.171,-0.731 0.046,-0.23 0.068,-0.441 0.068,-0.633 0,-0.342 -0.071,-0.582 -0.212,-0.717 -0.143,-0.135 -0.412,-0.201 -0.813,-0.201 -0.196,0 -0.398,0.029 -0.605,0.09 -0.205,0.063 -0.383,0.12 -0.529,0.176 L 8.46,10.188 C 8.958,9.985 9.435,9.811 9.89,9.667 c 0.455,-0.146 0.885,-0.218 1.29,-0.218 0.731,0 1.295,0.178 1.692,0.53 0.395,0.353 0.594,0.812 0.594,1.376 0,0.117 -0.014,0.323 -0.041,0.617 -0.027,0.295 -0.078,0.564 -0.152,0.811 l -0.757,2.68 c -0.062,0.215 -0.117,0.461 -0.167,0.736 -0.049,0.275 -0.073,0.485 -0.073,0.626 0,0.356 0.079,0.599 0.239,0.728 0.158,0.129 0.435,0.194 0.827,0.194 0.185,0 0.392,-0.033 0.626,-0.097 0.232,-0.064 0.4,-0.121 0.506,-0.17 z M 14.137,7.429 c -0.353,0.328 -0.778,0.492 -1.275,0.492 -0.496,0 -0.924,-0.164 -1.28,-0.492 -0.354,-0.328 -0.533,-0.727 -0.533,-1.193 0,-0.465 0.18,-0.865 0.533,-1.196 0.356,-0.332 0.784,-0.497 1.28,-0.497 0.497,0 0.923,0.165 1.275,0.497 0.353,0.331 0.53,0.731 0.53,1.196 0,0.467 -0.177,0.865 -0.53,1.193 z"
    />
  </svg>
  <svg
    v-else-if="name === 'clipboard'"
    viewBox="0 0 240.00001 240"
    width="240"
    height="240"
  >
    <path
      fill="currentColor"
      d="M 187.29437,29.90861 H 149.90861 C 149.90861,13.41214 136.49648,0 120.00001,0 103.50355,0 90.091395,13.41214 90.091395,29.90861 h -37.38575 c -12.38403,0 -22.43147,10.04742 -22.43147,22.43145 V 216.8374 c 0,12.38404 10.04744,22.43146 22.43147,22.43146 H 187.29436 c 12.38404,0 22.43146,-10.04742 22.43146,-22.43146 V 52.34006 c 0,-12.38403 -10.04742,-22.43145 -22.43145,-22.43145 z M 120.00001,18.69288 c 6.21538,0 11.21573,5.00035 11.21573,11.21573 0,6.21539 -5.00035,11.21573 -11.21573,11.21573 -6.21538,0 -11.21573,-5.00034 -11.21573,-11.21573 0,-6.21538 5.00035,-11.21573 11.21573,-11.21573 z m 67.29436,195.34059 c 0,1.54217 -1.26176,2.80393 -2.80393,2.80393 H 55.509575 c -1.54216,0 -2.80393,-1.26176 -2.80393,-2.80393 V 55.14399 c 0,-1.54216 1.26177,-2.80393 2.80393,-2.80393 h 19.62752 v 16.82359 c 0,3.08433 2.52354,5.60787 5.60786,5.60787 h 78.510105 c 3.08432,0 5.60786,-2.52354 5.60786,-5.60787 V 52.34006 h 19.62752 c 1.54217,0 2.80393,1.26177 2.80393,2.80393 z"
    />
  </svg>
  <svg
    v-else-if="name === 'collaboration'"
    viewBox="0 0 192.01 184.6"
    fill="currentColor"
  >
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_8" data-name="Ebene 8">
        <path
          d="M128.59,184.54s.35-32.9-43.86-29c-19,1.67-19,28.76-19,28.76Z"
        />
        <path
          d="M191.38,116.45s.36-32.9-43.86-29c-19,1.66-19,28.76-19,28.76Z"
        />
        <path d="M64.74,118.57s.35-32.9-43.86-29c-19,1.66-19,28.76-19,28.76Z" />
        <path d="M127.53,62.35s.35-32.9-43.86-29c-19,1.67-19,28.76-19,28.76Z" />
        <circle cx="97.47" cy="134.23" r="13.95" />
        <path
          d="M102.46,135.21l0,0a1,1,0,0,0,.27-.31.72.72,0,0,1-.29.31c0,.05.35.28.38.48a.37.37,0,0,0-.09-.14c0,.07.1.15.14.23h0l.2.41h0v0s-.06-.12,0-.13a.65.65,0,0,1,.16.56c0-.23.12-.4.15-.63V136a.51.51,0,0,1,.14-.25.69.69,0,0,1,0,.13l0-.07h0a.28.28,0,0,1,.07-.13.87.87,0,0,1,.29-.41c0-.08-.34-.3-.32-.45,0,.11.1.62-.07.56a2.8,2.8,0,0,0-.09.59c-.06.05-.09.18-.18.27,0,.25-.09.31.07.48v-.05c1.28,1.06,3-1.38,1.63-2.33-.08-.51-.73-.42-1.06-.7,0,0,0,0,0,.07,0-.21-.57.33-.58.22l0,0c-.18-.09-.43-.43-.61-.37a1.54,1.54,0,0,0,0,.3c-.05-.07,0,.09-.05.07-.18-.34-.2-.18-.11-.07s-.14.07-.18.09,0,.23-.16,0c-.05.09-.07,0-.18,0s-.14.36-.18.36v0c-1.25.3-.71,2,.22,2.36.07-.12-.09,0,0-.18.16.11.23.34.39.38s-.05.24.09.14l0,.13c.07-.09.05.16.1.12s0,0,0,0,.05,0,0,.11h0s0,0,0,0h0c.09-.14.18-.3.27-.43v0s0-.09,0-.14l0,0h0a.21.21,0,0,1,0,.13s.05-.07.05,0a1.56,1.56,0,0,1-.09-.34s0,0,.07,0a.58.58,0,0,0,.09.23s0-.16,0-.09,0,0,0,.06,0,0,0,0,0,.09,0,.11,0,0,.07,0,0,.06,0,.13,0-.17,0-.27c0,.07,0,0,0,0v-.12s0-.07,0,0,0,0,0,0c-.18.46,0,.48,0,0,0,0,0-.13,0-.16l0,.09a.65.65,0,0,1,0-.2l-.07.41s0-.27.05-.11c0-.05,0-.1,0-.12h0s0,0,0-.11,0-.7,0-.75c0,.16,0,.16,0,.18a.32.32,0,0,0,0-.16s0,0,0,.09a.3.3,0,0,1,0-.13c0,.09,0-.07,0,0a.12.12,0,0,1,0,.09c-.1-.07,0-.25,0-.37,0,0,0,.19,0,.16s.1-.14,0-.25v0c-.06.08,0-.14,0-.16l0,0s0,.11,0,0-.12.25-.12.2,0-.09,0,0,.14-.2.11-.18-.18.29-.15.18,0,0,0,.07a1.71,1.71,0,0,0,0-.25c-.05.09-.05-.05-.1.07,0-.07.07-.19.1-.25v0s.1-.19,0-.07c0,0,0-.06,0-.09h0c0-.18-.07.12,0,0a.08.08,0,0,0,0,.07.41.41,0,0,1,.05-.18c0,.07-.07.11-.1.18a1.92,1.92,0,0,1,.14-.29,1.36,1.36,0,0,1-.11.2h0C102.63,135.05,102.51,135.11,102.46,135.21Z"
        />
        <path
          class="cls-6"
          d="M89.14,137.47l0,0a.94.94,0,0,0,.27-.32.7.7,0,0,1-.29.32s.35.28.38.48a.37.37,0,0,0-.09-.14,1.76,1.76,0,0,1,.14.23h0l.2.41h0v0s-.06-.12,0-.13a.65.65,0,0,1,.16.56c0-.23.12-.4.15-.63v0a.53.53,0,0,1,.14-.24s0,.09,0,.13l0-.07h0a.33.33,0,0,1,.07-.13.87.87,0,0,1,.29-.41c0-.08-.34-.3-.32-.45,0,.11.1.62-.06.56a2.28,2.28,0,0,0-.1.59c-.06.05-.09.18-.18.27,0,.25-.09.31.07.48v0c1.28,1.06,3-1.38,1.63-2.33-.08-.51-.72-.42-1.06-.7,0,0,0,0,0,.07,0-.21-.58.33-.59.22l0,0c-.18-.09-.43-.43-.61-.37a1.54,1.54,0,0,0,0,.3c0-.07,0,.09,0,.07-.18-.34-.2-.19-.11-.07s-.14.07-.18.09,0,.23-.16,0c0,.09-.07,0-.18,0s-.14.36-.18.36v0c-1.25.3-.71,2,.22,2.36.07-.12-.09,0,0-.18.16.11.23.34.39.38s0,.24.09.14l0,.13c.06-.09,0,.16.09.12s0-.05,0-.05,0,0,0,.11h0s0,0,0,0h0c.09-.14.18-.3.27-.43v0s0-.09,0-.14l0,0h0a.21.21,0,0,1,0,.13s0-.07,0,0a1.56,1.56,0,0,1-.09-.34c0-.05,0,0,.07,0a.58.58,0,0,0,.09.23s0-.16,0-.09,0,0,0,.06,0,0,0,0,0,.09,0,.11,0,0,.06,0,0,.06,0,.13,0-.17,0-.27c0,.07,0,.05,0,.05V139s0-.07.05,0,0,0,0,0c-.18.46,0,.48,0,0,0,0,0-.13,0-.16l0,.09a.65.65,0,0,1,0-.2l-.07.41s0-.27,0-.11c0-.05,0-.1,0-.12h0s0,0,0-.11,0-.7,0-.75c0,.16,0,.16,0,.18a.47.47,0,0,0,0-.16s0,.05,0,.09a.3.3,0,0,1,0-.13c0,.09,0-.07,0,0a.16.16,0,0,1,0,.09c-.11-.07,0-.25,0-.37,0,0,0,.19,0,.16s.1-.14,0-.25v0c-.07.08-.06-.14,0-.16l0,0c0,.05,0,.12,0,0s-.12.25-.12.2,0-.09,0,0,.14-.21.11-.18-.18.29-.15.18,0,0,0,.07a1.71,1.71,0,0,0,0-.25c0,.09,0,0-.09.07a1.84,1.84,0,0,1,.09-.25v0s.1-.19,0-.07c0,0,0-.06,0-.09h0c0-.18-.07.12,0,0a.08.08,0,0,0,0,.07.41.41,0,0,1,0-.18,1.51,1.51,0,0,0-.09.18,1.85,1.85,0,0,1,.13-.29,1.36,1.36,0,0,1-.11.2h0C89.31,137.31,89.19,137.37,89.14,137.47Z"
        />
        <circle class="cls-5" cx="33.62" cy="68.25" r="13.95" />
        <path
          class="cls-6"
          d="M38.61,69.23l0,0a.87.87,0,0,0,.28-.32.67.67,0,0,1-.3.32s.36.28.39.47a1,1,0,0,0-.09-.13,2.53,2.53,0,0,1,.13.22h0l.21.41h0v0c-.05,0-.07-.13,0-.14a.66.66,0,0,1,.16.57c.05-.23.12-.4.16-.63v0a.52.52,0,0,1,.14-.25.78.78,0,0,0-.05.14l0-.07h0a.61.61,0,0,1,.07-.14.85.85,0,0,1,.3-.4c0-.08-.34-.3-.32-.46,0,.12.1.62-.07.57a2.7,2.7,0,0,0-.09.59c-.07,0-.09.18-.18.27,0,.25-.1.31.07.48v0c1.28,1.06,3-1.38,1.63-2.33-.09-.51-.73-.43-1.07-.71,0,0,0,0,0,.07,0-.21-.57.34-.59.23l0,0c-.18-.09-.43-.43-.61-.36a1.47,1.47,0,0,0-.05.29c0-.06,0,.09,0,.07-.18-.34-.21-.18-.12-.07s-.13.07-.18.09,0,.23-.16,0c0,.09-.06,0-.18,0s-.13.36-.18.36v0c-1.24.3-.7,2,.23,2.35.07-.11-.09,0,0-.18.16.11.22.34.38.39s0,.24.09.13l0,.14c.07-.09.05.16.09.11s0,0,0,0,0,0,0,.11h0s0,0,0,0h0c.09-.13.18-.29.27-.43v0s0-.09,0-.13l0,0h0a.34.34,0,0,1,0,.14S39,71,39,71a1.91,1.91,0,0,1-.1-.34s0,0,.07,0a1,1,0,0,0,.09.23s0-.16.05-.09,0,0,0,.07,0,0,0,0,0,.09,0,.11.05,0,.07,0,0,.07,0,.14,0-.18,0-.27,0,0,0,0V70.8s0-.07,0,0,0,0,0,0c-.18.46,0,.47,0,0,0,0,0-.14,0-.16l0,.09a.71.71,0,0,1,0-.2l-.07.4s0-.26,0-.11c0,0,0-.09,0-.11h0s0,0,0-.12,0-.7,0-.74c0,.16,0,.16,0,.18a.41.41,0,0,0,0-.16s0,0,0,.09a.34.34,0,0,1,0-.14c0,.09,0-.06,0,0a.15.15,0,0,1,0,.09c-.1-.06,0-.25,0-.36s0,.18,0,.16.1-.14,0-.25v0c-.06.09,0-.14,0-.16l0,0s0,.12,0,0-.11.25-.11.21,0-.09,0,0,.13-.2.11-.18-.18.3-.16.18a.11.11,0,0,0,0,.07,1.81,1.81,0,0,0,0-.25c0,.09,0,0-.09.07,0-.07.07-.18.09-.25v0s.1-.2,0-.07c0,0,0-.06,0-.09h0c0-.18-.06.11,0,0s0,0,0,.07a.53.53,0,0,1,0-.18c0,.06-.07.11-.09.18a1.64,1.64,0,0,1,.14-.3,1.57,1.57,0,0,1-.12.21h0C38.78,69.08,38.66,69.13,38.61,69.23Z"
        />
        <path
          class="cls-6"
          d="M25.29,71.49l0,0a.94.94,0,0,0,.27-.32.67.67,0,0,1-.3.32s.36.28.39.47a.51.51,0,0,0-.09-.13,2.53,2.53,0,0,1,.13.22h0l.21.41h0v0c-.05,0-.07-.13,0-.14a.66.66,0,0,1,.16.57c0-.23.12-.4.16-.64v0a.52.52,0,0,1,.14-.25.78.78,0,0,0,0,.14l0-.07h0a.61.61,0,0,1,.07-.14.85.85,0,0,1,.3-.4c0-.08-.34-.3-.32-.46,0,.12.1.62-.07.57a2.7,2.7,0,0,0-.09.59c-.07,0-.09.18-.18.27,0,.25-.1.31.07.47v0c1.28,1.06,3.05-1.38,1.63-2.33-.08-.51-.73-.43-1.07-.71,0,0,0,0,0,.07,0-.21-.57.34-.59.23l0,0c-.18-.09-.43-.43-.61-.36a1.47,1.47,0,0,0-.05.29c0-.06,0,.09,0,.07-.18-.34-.21-.18-.12-.07s-.13.07-.18.09,0,.23-.16,0c0,.09-.06,0-.18,0s-.13.36-.18.36v0c-1.24.29-.7,2,.23,2.35.07-.11-.09,0,0-.18.16.11.23.34.38.39s0,.24.09.13l0,.14c.07-.09.05.16.09.11s0,0,.05,0,0,0,0,.11h0s0,0,0,0h0c.09-.13.18-.29.28-.43v0s0-.09,0-.13l0,0h0a.34.34,0,0,1,0,.14s.05-.07.05,0a1.43,1.43,0,0,1-.09-.34s0,0,.06,0a1.23,1.23,0,0,0,.09.23s0-.16,0-.09,0,0,0,.07,0,0,0,0,0,.09,0,.11.05,0,.07,0,0,.07,0,.14,0-.18,0-.27c0,.06,0,0,0,0v-.11s0-.07,0,0,0,0,0,0c-.18.46,0,.47,0,0,0,0,0-.14,0-.16L26,73a.71.71,0,0,1,0-.2l-.07.4s0-.26,0-.11c0,0,0-.09,0-.11h0s0,0,0-.12.05-.7,0-.74c0,.15,0,.15,0,.18a.41.41,0,0,0,0-.16s0,0,0,.09a.34.34,0,0,1,0-.14c0,.09,0-.06,0,0a.15.15,0,0,1,0,.09c-.1-.06,0-.25,0-.36s0,.18,0,.16.1-.14,0-.25v0c-.06.09,0-.14,0-.16l0,0s0,.12,0,0-.11.25-.11.21,0-.09,0,0,.13-.2.11-.18-.18.3-.16.18a.11.11,0,0,0,0,.07,1.81,1.81,0,0,0,0-.25c0,.09,0,0-.09.07,0-.07.07-.18.09-.25v.05s.11-.2,0-.07a.32.32,0,0,1,0-.09h0c0-.18-.07.11-.05,0s0,0,0,.07a.65.65,0,0,1,0-.19c0,.07-.07.12-.09.19a1.64,1.64,0,0,1,.14-.3.92.92,0,0,1-.12.21h0C25.46,71.34,25.34,71.39,25.29,71.49Z"
        />
        <circle class="cls-5" cx="94.92" cy="13.95" r="13.95" />
        <path
          class="cls-6"
          d="M99.92,14.93l0,0a.76.76,0,0,0,.27-.32.6.6,0,0,1-.29.32c0,.05.36.28.38.48a.37.37,0,0,0-.09-.14,1.09,1.09,0,0,1,.14.23h0l.2.4h0v0s-.06-.13,0-.13a.63.63,0,0,1,.16.56c0-.23.12-.4.16-.63v0a.6.6,0,0,1,.13-.25s0,.09,0,.14l0-.07h0a.33.33,0,0,1,.07-.13.94.94,0,0,1,.29-.41c0-.08-.33-.3-.31-.45,0,.11.1.61-.07.56a2.27,2.27,0,0,0-.09.59c-.07,0-.09.18-.18.27,0,.25-.1.31.07.48v0c1.27,1.06,3-1.38,1.63-2.33-.09-.51-.73-.43-1.07-.7,0,0,0,0,0,.07,0-.21-.57.33-.59.22l0,0c-.18-.09-.43-.43-.61-.36a1,1,0,0,0-.05.3c0-.07,0,.09,0,.06-.19-.34-.21-.18-.12-.06s-.13.06-.18.09,0,.23-.16,0c0,.09-.07,0-.18,0s-.14.36-.18.36v0c-1.24.3-.71,2,.23,2.36.06-.12-.09,0,0-.19.15.12.22.34.38.39s0,.24.09.14l0,.13c.07-.09,0,.16.09.11s0,0,0,0,0,0,0,.11h0s0,0,0,0h0l.27-.44v0s0-.09,0-.14l0,0h0a.34.34,0,0,1,0,.14s0-.07,0,0a1.89,1.89,0,0,1-.09-.34s0,0,.07,0a.63.63,0,0,0,.09.22s0-.15,0-.09,0,0,0,.07,0,0,0,0,0,.09,0,.12,0,0,.07,0,0,.07,0,.14,0-.18,0-.27c0,.07,0,0,0,0v-.11s0-.07,0,0,0,0,0,0c-.19.46,0,.48,0,0,0,0,0-.14,0-.16l0,.09a.46.46,0,0,1,0-.2l-.06.41s0-.27,0-.12c0,0,0-.09,0-.11h0s0,0,0-.11,0-.71,0-.75c0,.16,0,.16,0,.18a.47.47,0,0,0,0-.16s0,0,0,.09a.21.21,0,0,1,0-.13c0,.09,0-.07,0,0a.13.13,0,0,1,0,.09c-.1-.06,0-.25,0-.36s0,.18,0,.16.09-.14,0-.25v0c-.06.09-.06-.13,0-.15l0,0s0,.12,0,0-.11.24-.11.2,0-.09,0,0,.14-.21.12-.18-.18.29-.16.18a.07.07,0,0,0,0,.06,1.79,1.79,0,0,0,0-.24c0,.09,0-.05-.09.06,0-.06.07-.18.09-.25v0s.1-.19,0-.07,0-.06,0-.09h0c0-.18-.07.11,0,0s0,0,0,.07a.61.61,0,0,1,0-.18c0,.07-.07.11-.09.18,0-.08.11-.22.13-.3a.71.71,0,0,1-.11.21h0C100.09,14.77,100,14.83,99.92,14.93Z"
        />
        <path
          class="cls-6"
          d="M86.6,17.19l0,0a.76.76,0,0,0,.27-.32.6.6,0,0,1-.29.32c0,.05.36.28.39.48a.61.61,0,0,0-.1-.14,1.09,1.09,0,0,1,.14.23h0l.2.4h0v0s-.06-.13,0-.13a.63.63,0,0,1,.16.56c0-.23.12-.4.16-.63v0a.52.52,0,0,1,.14-.25.78.78,0,0,0-.05.14l0-.07h0a.54.54,0,0,1,.07-.13.94.94,0,0,1,.29-.41c0-.08-.33-.3-.31-.46,0,.12.1.62-.07.57a2.27,2.27,0,0,0-.09.59c-.07.05-.09.18-.18.27,0,.25-.1.31.07.48v-.05c1.28,1.06,3-1.38,1.63-2.33-.09-.51-.73-.43-1.07-.7,0,0,0,0,0,.06,0-.2-.57.34-.59.23l0,0c-.18-.09-.43-.43-.61-.36a1,1,0,0,0,0,.3c0-.07,0,.09,0,.06-.19-.34-.21-.18-.12-.06s-.13.06-.18.09,0,.23-.16,0c0,.09-.07,0-.18,0s-.14.36-.18.36v0c-1.24.3-.71,2,.23,2.36.06-.12-.09,0,0-.19.16.12.22.34.38.39s0,.24.09.13l0,.14c.07-.09.05.16.09.11s0,0,0,0,0,0,0,.11h0s0,0,0,0h0c.09-.13.18-.29.27-.43v0s0-.09,0-.14l0,0h0A.34.34,0,0,1,87,19c0-.05,0-.07,0-.05a3.37,3.37,0,0,1-.09-.34s0,0,.07,0a.63.63,0,0,0,.09.22s0-.15.05-.09,0,0,0,.07,0,0,0,0,0,.09,0,.12,0,0,.07,0c0,.12,0,.07,0,.14s0-.18,0-.27c0,.07,0,0,0,0v-.11s0-.07,0,0,0,0,0,0c-.19.46,0,.48,0,0,0,0,0-.14,0-.16l0,.09a.68.68,0,0,1,0-.2l-.07.41s0-.27,0-.12c0,0,0-.09,0-.11h0c0-.05,0,0,0-.11s0-.71,0-.75c0,.16,0,.16,0,.18a.44.44,0,0,0,0-.16s0,.05,0,.09a.21.21,0,0,1,0-.13c0,.09,0-.07,0,0a.13.13,0,0,1,0,.09c-.1-.06,0-.25,0-.36s0,.18,0,.16.09-.14,0-.25v0c-.06.09-.06-.14,0-.15l0,0s0,.12,0,0-.11.24-.11.2,0-.09,0,0a2.06,2.06,0,0,0,.11-.18c0-.07-.18.29-.16.18a.07.07,0,0,0,0,.06,1.79,1.79,0,0,0,0-.24c0,.09,0-.05-.09.06,0-.06.07-.18.09-.25v.05s.1-.19,0-.07c0,0,0-.06,0-.09h0c0-.18-.07.11,0,0s0,0,0,.07a.61.61,0,0,1,0-.18c0,.07-.07.11-.09.18a1.64,1.64,0,0,1,.14-.3,1.13,1.13,0,0,1-.12.21h0C86.77,17,86.65,17.09,86.6,17.19Z"
        />
        <circle cx="158.99" cy="68.68" r="13.95" />
        <path
          d="M164,69.66l0,0a.94.94,0,0,0,.27-.32.7.7,0,0,1-.29.32s.35.28.38.48a.37.37,0,0,0-.09-.14c0,.07.1.15.14.23h0l.2.41h0v0s-.06-.12,0-.13a.65.65,0,0,1,.16.56c0-.23.12-.4.15-.63v0a.53.53,0,0,1,.14-.24.69.69,0,0,1-.05.13l0-.07h0a.24.24,0,0,1,.07-.13.87.87,0,0,1,.29-.41c0-.08-.34-.3-.32-.45,0,.11.1.62-.07.56a2.8,2.8,0,0,0-.09.59c-.06,0-.09.18-.18.27,0,.25-.09.31.07.48v0c1.28,1.06,3.05-1.38,1.63-2.33-.08-.51-.73-.42-1.06-.7,0,0,0,0,0,.07,0-.21-.57.33-.58.22l0,0c-.18-.09-.43-.43-.61-.37a1.54,1.54,0,0,0,0,.3c-.05-.07,0,.09-.05.07-.18-.34-.2-.19-.11-.07s-.14.07-.18.09,0,.23-.16,0c-.05.09-.07,0-.18,0s-.14.36-.18.36v0c-1.25.3-.71,2,.22,2.36.07-.12-.09,0,0-.18.16.11.23.34.39.38s-.05.24.09.14l0,.13c.07-.09,0,.16.1.12s0,0,0,0,.05,0,0,.11h0s0,0,0,0h0c.09-.14.18-.3.27-.43v0s0-.09,0-.14l0,0h0a.21.21,0,0,1,0,.13s0-.07,0,0a1.56,1.56,0,0,1-.09-.34s0,0,.07,0a.58.58,0,0,0,.09.23s0-.16,0-.1,0,0,0,.07,0,0,0,0,0,.09,0,.11,0,0,.07,0,0,.06,0,.13,0-.17,0-.27c0,.07,0,0,0,0v-.12s0-.07.05,0,0,0,0,0c-.18.46,0,.48,0,0,0,0,0-.13,0-.16l0,.09a.65.65,0,0,1,0-.2l-.07.41s0-.27,0-.12c0,0,0-.09,0-.11h0s0,0,0-.11,0-.7,0-.75c0,.16,0,.16,0,.18a.32.32,0,0,0,0-.16s0,0,0,.09a.3.3,0,0,1,0-.13c0,.09,0-.07,0,0a.12.12,0,0,1,0,.09c-.1-.07,0-.25,0-.37s0,.18,0,.16.1-.14,0-.25v0c-.06.08-.05-.14,0-.16l0,0s0,.12,0,0-.12.25-.12.2,0-.09,0,0,.14-.21.11-.18-.18.29-.15.18,0,0,0,.07a1.71,1.71,0,0,0,0-.25c-.05.09-.05,0-.1.07,0-.07.07-.19.1-.25v0s.1-.19,0-.07,0-.06,0-.09h0c0-.18-.07.12-.05,0a.08.08,0,0,0,0,.07.41.41,0,0,1,.05-.18c0,.07-.07.11-.1.18a1.92,1.92,0,0,1,.14-.29,1.36,1.36,0,0,1-.11.2h0C164.15,69.5,164,69.56,164,69.66Z"
        />
        <path
          class="cls-6"
          d="M150.66,71.92l0,0a.94.94,0,0,0,.27-.32.7.7,0,0,1-.29.32s.35.28.38.48a.37.37,0,0,0-.09-.14,1.76,1.76,0,0,1,.14.23h0l.2.41h0v0c-.05,0-.06-.13,0-.13a.64.64,0,0,1,.16.56c0-.23.12-.4.15-.63v0a.53.53,0,0,1,.14-.24s0,.09,0,.13l0-.07h0a.33.33,0,0,1,.07-.13A.87.87,0,0,1,152,72c0-.08-.34-.3-.32-.45,0,.11.1.62-.06.56a2.28,2.28,0,0,0-.1.59c-.06.05-.09.18-.18.27,0,.25-.09.31.07.48v0c1.28,1.06,3-1.38,1.63-2.33-.08-.51-.73-.42-1.06-.7,0,0,0,0,0,.07,0-.21-.58.33-.59.22l0,0c-.18-.09-.43-.43-.61-.37a1.54,1.54,0,0,0,0,.3c-.05-.07,0,.09-.05.07-.18-.34-.2-.19-.11-.07s-.14.07-.18.09,0,.23-.16,0c-.05.09-.07,0-.18,0s-.14.36-.18.36v0c-1.25.3-.71,2,.22,2.36.07-.12-.09,0,0-.18.16.11.23.34.39.38s-.05.24.09.14l0,.13c.06-.09,0,.16.09.12s0,0,0,0,.05,0,0,.11h0s0,0,0,0h0c.09-.14.18-.3.27-.43v0s0-.09,0-.14l0,0h0a.21.21,0,0,1,0,.13s0-.07,0,0a1.56,1.56,0,0,1-.09-.34s0,0,.07,0a.58.58,0,0,0,.09.23s0-.16,0-.1,0,0,0,.07,0,0,0,0,0,.09,0,.11.05,0,.07,0,0,.06,0,.13,0-.17,0-.27c0,.07,0,0,0,0v-.12s0-.07,0,0,0,0,0,0c-.18.46,0,.48,0,0,0,0,0-.13,0-.16l0,.09a.65.65,0,0,1,0-.2l-.07.41s0-.27,0-.12c0,0,0-.09,0-.11h0s0,0,0-.11,0-.7,0-.75c0,.16,0,.16,0,.18a.47.47,0,0,0,0-.16s0,0,0,.09a.3.3,0,0,1,0-.13c0,.09,0-.07,0,0a.12.12,0,0,1,0,.09c-.1-.07,0-.26,0-.37s0,.18,0,.16.1-.14,0-.25v0c-.06.08,0-.14,0-.16l0,0s0,.12,0,0-.12.25-.12.2,0-.09,0,0,.14-.21.11-.18-.18.29-.15.18,0,0,0,.07a1.9,1.9,0,0,0,0-.25c-.05.09-.05,0-.09.06a2.34,2.34,0,0,1,.09-.24v0s.1-.19,0-.07c0,0,0-.06,0-.09h0c0-.18-.07.12,0,0s0,0,0,.07a.41.41,0,0,1,.05-.18,1.51,1.51,0,0,0-.09.18,1.51,1.51,0,0,1,.13-.29,1.36,1.36,0,0,1-.11.2h0C150.83,71.76,150.71,71.82,150.66,71.92Z"
        />
      </g>
      <g id="Ebene_7" data-name="Ebene 7">
        <path
          class="cls-7"
          d="M127.09,63.62c-21.7,0-44.26.31-66,.31A30.06,30.06,0,0,1,62.8,51.41c3.59-10,7.5-16.12,17.48-19.69.75-.27,6.83-4.11,24.16-.67S127.3,62.12,127.09,63.62Zm-5.52-3.91a.82.82,0,0,0,.83-.78h0a3.76,3.76,0,0,0,0-.58c-1.72-10.72-10.93-20.45-17.57-22.46S88,34.58,84,36,68.4,41.08,65.5,48.53c-1,2.51-2.33,9.65-1.24,13.79Z"
        />
        <path
          class="cls-7"
          d="M66.06,118.78c-21.7,0-44.26.31-66,.31a30,30,0,0,1,1.69-12.53c3.58-10,6.89-14.3,13.15-17.78S35.61,88.41,41,86.3a6.43,6.43,0,0,1,2.4-.09C60.47,86.93,66.27,117.28,66.06,118.78Zm-5.52-3.92a.82.82,0,0,0,.83-.77h0a2.82,2.82,0,0,0,0-.58C59.63,102.78,53.73,95.31,43.78,91c-.57-.24,3.32,1.47-2,0s-13.7-3-18.83-.72S7.37,96.24,4.47,103.69c-1,2.5-2.32,9.65-1.23,13.79Z"
        />
        <path
          class="cls-7"
          d="M94.87.25A14.38,14.38,0,0,1,95.07,29,14.56,14.56,0,0,1,80.51,14.59,14.37,14.37,0,0,1,94.87.25ZM82,13c-.52,8.35,7.8,12.16,13.23,12.08s9.61-5.15,9.59-10.52S102.51.45,92.87,1.47C87.49,2.05,81.94,7.6,82,13Z"
        />
        <path
          class="cls-7"
          d="M52.06,15.35c2.36,3.12,2.84,4.47-1.19,6C48,18.31,48.16,17.27,52.06,15.35Z"
        />
        <path
          class="cls-7"
          d="M43.37,147.66l3.29,2.7c-.91,1.2-1.74,2.31-2.72,3.62l-3.58-3Z"
        />
        <path
          class="cls-7"
          d="M46.48,24.56c-2.45,3.78-3.5,3.61-6.1.16C42.63,21.51,44.24,21.67,46.48,24.56Z"
        />
        <path class="cls-7" d="M59.84,158.75l3.9,1.77L62,164.44l-4.21-1.78Z" />
        <path
          class="cls-7"
          d="M33.1,136.57,29.84,139c-3.33-2.53-3.17-3.72.88-5.94.38.54.78,1.09,1.16,1.65S32.65,135.89,33.1,136.57Z"
        />
        <path class="cls-7" d="M21.44,127l3.85-2.09,2.25,3.54-3.94,2.39Z" />
        <path
          class="cls-7"
          d="M61.34,10.84c2,3.31,1.82,4.44-2.05,5.69C56.77,13.16,57.26,12.16,61.34,10.84Z"
        />
        <path
          class="cls-7"
          d="M21.42,49.24c1.43-4,2.71-4.18,6.1-1.75C25.94,51,24.78,51.32,21.42,49.24Z"
        />
        <path
          class="cls-7"
          d="M30.21,42.68c-3.74-1.83-3.74-3-.94-6C32.74,38.69,33.16,39.85,30.21,42.68Z"
        />
        <path
          class="cls-7"
          d="M72.45,11.47c-3.61,1.95-4.48,1.15-5.4-2.73C70.71,7,71.77,7.32,72.45,11.47Z"
        />
        <path class="cls-7" d="M52.68,159.66l-3.93-2.41,2.39-3.55,3.56,2.39Z" />
        <path
          class="cls-7"
          d="M39.05,32.08c-1.84,3.14-3.06,3-5.62.09C35.73,29,36.83,28.56,39.05,32.08Z"
        />
        <path class="cls-7" d="M32.91,144l3.26-2.94,2.53,3.13-2.55,2.73Z" />
        <ellipse class="cls-7" cx="98.1" cy="9.95" rx="2.08" ry="2.21" />
        <ellipse class="cls-7" cx="89.78" cy="11.51" rx="2.08" ry="2.21" />
        <path
          class="cls-7"
          d="M88.62,19s4.93,4.42,9,2.08,3.5-5.84,3.5-5.84-1.69,3.64-4,3.89A76.84,76.84,0,0,1,88.62,19Z"
        />
        <path
          class="cls-7"
          d="M47.1,68a14.37,14.37,0,0,1-28.58,3.1,14.57,14.57,0,0,1,12.87-16A14.37,14.37,0,0,1,47.1,68Zm-14-11.57c-8.36.33-12.68,8.5-12,13.89s7.17,10.57,12.5,10c4.94-.52,14.18-4.55,12.16-14.19C44.57,60.79,38.49,55.83,33.08,56.39Z"
        />
        <ellipse class="cls-7" cx="36.53" cy="66.49" rx="2.08" ry="2.21" />
        <ellipse class="cls-7" cx="28.76" cy="66.7" rx="2.08" ry="2.21" />
        <path
          class="cls-7"
          d="M27.46,73.14s4.93,4.42,9,2.08,3.5-5.84,3.5-5.84-1.69,3.63-4,3.89A76.84,76.84,0,0,1,27.46,73.14Z"
        />
        <path
          class="cls-7"
          d="M129.66,184.29c-21.7,0-44.26.31-66,.31-1-14.68,10-29.65,18.57-31.82s14.43-2,22.35-1C122.65,154.2,130.21,182.65,129.66,184.29Zm-5.52-3.92c2.06-9.7-8.72-21.5-18.76-23.8s-42.53-.4-38.55,26.41Z"
        />
        <path
          class="cls-7"
          d="M109.9,133.34a14.37,14.37,0,0,1-28.58,3.1,14.57,14.57,0,0,1,12.87-16A14.37,14.37,0,0,1,109.9,133.34Zm-14-11.57c-8.37.33-12.68,8.5-12.06,13.89s7.18,10.57,12.51,10c4.94-.52,14.18-4.55,12.16-14.19C107.37,126.17,101.29,121.21,95.88,121.77Z"
        />
        <ellipse class="cls-7" cx="99.33" cy="131.87" rx="2.08" ry="2.21" />
        <ellipse class="cls-7" cx="91.55" cy="132.08" rx="2.08" ry="2.21" />
        <path
          class="cls-7"
          d="M90.26,138.52s4.93,4.42,9,2.08,3.5-5.84,3.5-5.84-1.69,3.63-4,3.89A76.84,76.84,0,0,1,90.26,138.52Z"
        />
        <path
          class="cls-7"
          d="M192,117.24c-21.7,0-44.26.31-66,.31-1-14.68,7.68-31,22.17-32.85,6.49-.82,12.64.38,20.41,1.69C186.53,89.41,192.52,115.6,192,117.24Zm-4.8-3.79c2.59-36.14-65.44-32.56-58,2.48Z"
        />
        <path
          class="cls-7"
          d="M145.93,59.87A14.38,14.38,0,0,1,170,75.58,14.57,14.57,0,0,1,150,79.76,14.36,14.36,0,0,1,145.93,59.87Zm3.52,17.84c6.68,5,15.19,1.45,18.13-3.12s1.14-12.72-3.34-15.66c-4.16-2.73-13.86-5.46-18.4,3.28C143.34,67,144.91,74.71,149.45,77.71Z"
        />
        <ellipse class="cls-7" cx="161.64" cy="64.82" rx="2.08" ry="2.21" />
        <ellipse class="cls-7" cx="153.87" cy="65.03" rx="2.08" ry="2.21" />
        <path
          class="cls-7"
          d="M151.23,71.58s4.05,5.24,8.44,3.67,4.51-5.1,4.51-5.1-2.33,3.26-4.67,3.09A77.1,77.1,0,0,1,151.23,71.58Z"
        />
        <path
          class="cls-7"
          d="M169.7,35.92c-1.35.93-2.36,1-3.45-.32a2.67,2.67,0,0,1,.3-3.72c1.39-1.22,2.38-1.22,3.57.05A2.68,2.68,0,0,1,169.7,35.92Z"
        />
        <path
          class="cls-7"
          d="M159.26,27.93c-1.55-1.15-1.66-2.15-.52-3.62a2.6,2.6,0,0,1,4-.17,2.38,2.38,0,0,1,.33,2.94A2.59,2.59,0,0,1,159.26,27.93Z"
        />
        <path
          class="cls-7"
          d="M175.21,46.82a2.12,2.12,0,0,1-2.9-.88,2.69,2.69,0,0,1,.79-3.87c1.38-.83,2.3-.57,3.23.82A2.66,2.66,0,0,1,175.21,46.82Z"
        />
        <path
          class="cls-7"
          d="M131.63,7a2.08,2.08,0,0,1,.91,2.18A2.6,2.6,0,0,1,128,10.68a2.29,2.29,0,0,1-.48-1.85A2.59,2.59,0,0,1,131.63,7Z"
        />
        <path
          class="cls-7"
          d="M140.15,12.91A2.11,2.11,0,0,1,143.3,12a2.66,2.66,0,0,1,1,3.89,2.13,2.13,0,0,1-3.11.82A2.69,2.69,0,0,1,140.15,12.91Z"
        />
        <path
          class="cls-7"
          d="M175.37,57.19a2.17,2.17,0,0,1,1.17-3,2.58,2.58,0,0,1,3.71,1.39,2.11,2.11,0,0,1-1.22,3A2.62,2.62,0,0,1,175.37,57.19Z"
        />
        <path
          class="cls-7"
          d="M149.9,20.39a2,2,0,0,1-.44-2.76,2.57,2.57,0,0,1,4-.66c1,1,1.11,1.88,0,3.11A2.59,2.59,0,0,1,149.9,20.39Z"
        />
        <path
          class="cls-7"
          d="M143.11,157.33c-2.16-3.07-2.6-4.4,1.1-6C146.88,154.42,146.7,155.44,143.11,157.33Z"
        />
        <path
          class="cls-7"
          d="M148.24,148.28c2.24-3.72,3.21-3.56,5.6-.16C151.77,151.27,150.3,151.12,148.24,148.28Z"
        />
        <path
          class="cls-7"
          d="M134.6,161.76c-1.86-3.25-1.67-4.36,1.88-5.59C138.8,159.48,138.34,160.47,134.6,161.76Z"
        />
        <path
          class="cls-7"
          d="M171.23,124c-1.31,3.91-2.48,4.11-5.59,1.73C167.09,122.26,168.15,122,171.23,124Z"
        />
        <path
          class="cls-7"
          d="M163.17,130.45c3.43,1.81,3.43,3,.86,5.89C160.85,134.38,160.46,133.24,163.17,130.45Z"
        />
        <path
          class="cls-7"
          d="M124.41,161.14c3.31-1.91,4.11-1.13,5,2.68C126,165.56,125,165.22,124.41,161.14Z"
        />
        <path
          d="M155.06,140.88c1.68-3.09,2.8-2.92,5.15-.09C158.11,143.92,157.09,144.34,155.06,140.88Z"
        />
      </g>
    </g>
  </svg>
  <svg v-else-if="name === 'x'" fill="currentColor" viewBox="0 0 20 20">
    <path
      fill-rule="evenodd"
      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
      clip-rule="evenodd"
    />
  </svg>
  <svg v-else-if="name === 'x-circle'" fill="currentColor" viewBox="0 0 20 20">
    <path
      fill-rule="evenodd"
      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
      clip-rule="evenodd"
    />
  </svg>

  <svg
    v-else-if="name === 'circle'"
    width="67.502502"
    height="67.502502"
    viewBox="0 0 67.502501 67.502495"
  >
    <circle
      style="
        fill: none;
        stroke-width: 5;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-opacity: 1;
      "
      stroke="currentColor"
      cx="33.751251"
      cy="33.751251"
      r="31.251249"
    />
  </svg>
  <svg
    v-else-if="name === 'check-circle'"
    viewBox="0 0 67.502501 67.502488"
    height="67.502495"
    width="67.502502"
  >
    <path
      fill="currentColor"
      d="m 40.375609,18.554522 c -4.435798,8.410783 -8.014237,17.537062 -10.328772,26.858045 -1.84087,-2.474964 -3.824555,-4.847101 -6.129093,-6.7351 -1.450989,-1.188212 -6.979789,0.471285 -4.862335,2.206474 3.856449,3.16047 6.790797,8.018519 9.656119,12.060151 0.91496,1.291512 5.349329,0.963993 5.664472,-0.795949 2.086986,-11.639803 6.769375,-23.813254 12.270564,-34.244376 0.800709,-1.34483 -5.315531,-1.161552 -6.270955,0.650755 z"
      style="fill-opacity: 1; stroke-width: 0.47604612"
      inkscape:connector-curvature="0"
    />
    <circle
      stroke="currentColor"
      r="31.251249"
      cy="33.751251"
      cx="33.751251"
      style="
        fill: none;
        stroke-width: 5;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-opacity: 1;
      "
    />
  </svg>
  <svg v-else-if="name === 'share'" viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M352 320c-22.608 0-43.387 7.819-59.79 20.895l-102.486-64.054a96.551 96.551 0 0 0 0-41.683l102.486-64.054C308.613 184.181 329.392 192 352 192c53.019 0 96-42.981 96-96S405.019 0 352 0s-96 42.981-96 96c0 7.158.79 14.13 2.276 20.841L155.79 180.895C139.387 167.819 118.608 160 96 160c-53.019 0-96 42.981-96 96s42.981 96 96 96c22.608 0 43.387-7.819 59.79-20.895l102.486 64.054A96.301 96.301 0 0 0 256 416c0 53.019 42.981 96 96 96s96-42.981 96-96-42.981-96-96-96z"
    />
  </svg>
  <svg v-else-if="name === 'upload'" fill="none" viewBox="0 0 24 24" stroke="currentColor" id="upload" class="w-8 h-8 text-cool-gray-800 group-hover:text-purple-600 group-focus:text-purple-600"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"></path></svg>
  <svg
    v-else-if="name === 'subject'"
    viewBox="0 0 156.87 204.96"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <g>
      <g>
        <path
          fill="#fff"
          d="M9.19,197.36l-1.38-.12-2.63-.31-1.32-.22a5.09,5.09,0,0,1-1.42-.44.66.66,0,0,1-.32-.36.59.59,0,0,1,0-.47.82.82,0,0,1,.12-.2l.06-.11.15-.56a10.82,10.82,0,0,0,.2-1.27c.12-.85.18-1.72.23-2.58.44-7-.19-14-.09-21Q3,152,3.68,134.38C2.12,171,.66,200.86,0,201c-1.65.24,79.28,1.7,79.28,1.7v-.07L9.09,200.24Z"
        />
        <path
          fill="#fff"
          d="M9.22,86.07Q9,107,8.57,128C8.32,142,7.81,156,6.7,170c-.59,7-1.84,13.93-2.66,20.9l-.39,2.61c-.08.43-.15.86-.24,1.32a5,5,0,0,1-.19.69h0a3.93,3.93,0,0,0,.82.2c.42.08.85.13,1.28.18.86.11,1.72.19,2.58.26l1.33.09L15.68,3.31,95.13,6,82.62,5.19,14.77,1l0-.64L8.89.21s0,.68-.07,2h.76Q9.7,44.13,9.22,86.07Z"
        />
        <path
          fill="currentColor"
          d="M60.17,200.48l-21-1.16c-7-.35-14-.77-20.95-1.23-3-.18-6.06-.42-9.08-.73l-.1,2.88,70.22,2.35.15-1.1Z"
        />
        <path
          fill="#423e3f"
          d="M8.57,2.16,5.49,86,3.93,127.82q-.14,3.27-.25,6.56c2.25-52.93,4.7-120,5.14-132.22Z"
        />
        <path
          class="cls-3"
          d="M106,6.33l41.33,1.38.06-1.83,1.74,1.89,4.54.15.06-1.86.08-3.23-3.16-.09c-11.32-.36-22.65-.56-34-.84l-34-.65L14.81,0l0,.35,91.76,2.21Z"
        />
        <polygon
          fill="#423e3f"
          points="147.43 5.88 147.37 7.71 149.17 7.77 147.43 5.88"
        />
        <path
          fill="#423e3f"
          d="M2.9,190.72c0,.86-.11,1.73-.23,2.58a10.82,10.82,0,0,1-.2,1.27l-.15.56-.06.11a.82.82,0,0,0-.12.2.59.59,0,0,0,0,.47.66.66,0,0,0,.32.36,5.09,5.09,0,0,0,1.42.44l1.32.22,2.63.31,1.38.12,0-1.13-1.33-.09c-.86-.07-1.72-.15-2.58-.26-.43,0-.86-.1-1.28-.18a3.93,3.93,0,0,1-.82-.2A1.33,1.33,0,0,1,3,196a.46.46,0,0,0,.1-.39c-.07-.17-.09-.14-.09-.15l.26.08h0a5,5,0,0,0,.19-.69c.09-.46.16-.89.24-1.32L4,190.87c.82-7,2.07-13.92,2.66-20.9C7.81,156,8.32,142,8.57,128s.47-28,.65-42q.48-41.94.36-83.91H8.82C8.38,14.33,5.93,81.45,3.68,134.38Q3,152.05,2.81,169.71C2.71,176.73,3.34,183.76,2.9,190.72Z"
        />
        <polygon
          fill="#423e3f"
          points="82.62 5.19 95.13 5.96 106.04 6.33 106.55 2.56 14.79 0.35 14.77 0.99 82.62 5.19"
        />
        <path
          fill="#423e3f"
          d="M3.05,195.57A.46.46,0,0,1,3,196a1.33,1.33,0,0,0,.27-.46L3,195.42S3,195.4,3.05,195.57Z"
        />
        <path
          fill="#423e3f"
          d="M150.48,9.19,147.33,9,141,197.32c1.07,0,2.13.05,3.2.1l-3.31,3.16.11-3.26c-5.92-.17-11.83-.13-17.76-.1l-21,.08L80,197.39l-.56,4.1c7.54.39,15.09.76,22.64,1.07l21,.86c7,.29,13.94.56,21,.47l3.18.09.1-3.2L153.71,7.92l-4.54-.15Z"
        />
        <path fill="#423e3f" d="M150.48,9.19l-1.31-1.42-1.8-.06,0,1.32Z" />
        <path
          fill="#423e3f"
          d="M116.54,7.29c10.26.58,20.52,1.22,30.79,1.74l0-1.32L106,6.33l0,.31Z"
        />
        <path
          fill="#423e3f"
          d="M144.2,197.42c-1.07,0-2.13-.07-3.2-.1l-.11,3.26Z"
        />
        <path
          fill="#423e3f"
          d="M60.27,197.43l-21-.15c-7-.11-14-.26-20.95-.54-3-.14-6.08-.29-9.12-.51l0,1.13c3,.31,6,.55,9.08.73,7,.46,14,.88,21,1.23l21,1.16,19.29,1,.56-4.1Q70.14,197.44,60.27,197.43Z"
        />
        <polygon
          fill="#423e3f"
          points="106.04 6.33 95.13 5.96 106 6.64 106.04 6.33"
        />
        <path
          fill="currentColor"
          d="M153.71,7.92l-6.37,192.86-.1,3.2-3.18-.09c-7,.09-14-.18-21-.47l-21-.86c-7.55-.31-15.1-.68-22.64-1.07l-.15,1.1,71,2.37L156.87,8Z"
        />
        <path
          d="M102.24,197.3l21-.08c5.93,0,11.84-.07,17.76.1L147.33,9c-10.27-.52-20.53-1.16-30.79-1.74L106,6.64,95.13,6,15.68,3.31,9.23,196.23c3,.22,6.08.37,9.12.51,7,.28,14,.43,20.95.54l21,.15q9.85,0,19.75,0Z"
          fill="currentColor"
        />
        <polygon
          fill="#fff"
          points="122.27 94.05 38.05 89.37 39.9 47.41 122.27 49.71 122.27 94.05"
        />
        <path
          fill="#423e3f"
          d="M121.6,91.15c-1.39,0-5.73-2.38-7.12-2.3-2.53.08-5.08.18-7.63.3,0,0-30.38,1.67-35.47,1.69,0,0-12.71-1.64-15.24-2-5-.66-15-1.1-15-1.1.06-.74.11-1.48.12-2.22,0-2.53,0-5.09,0-7.66-.13-10.17-.66-20.29-1.42-30.41l-1.17.07c-.55,10.11-1,20.23-1.61,30.28-.13,2.51-.33,5-.56,7.47-.09,1-1.07,6.92,0,6.75.19.16,14.28,1.57,19.4,1.28,2.55-.2,5.12-1.13,7.68-1.26a30.32,30.32,0,0,1,3.81.12l3.81.26c5.07.38,3.14-.1,8.22.5l27.16,2.2c2.52.31,5.07.54,7.61.78a46.27,46.27,0,0,0,7.62.37l3.8-.1-.09-3.37-1.18-43-1.2-1.28c-6.31-.4-12.6-.6-18.94-.82l-30.8-1-12,.41-18.94-.47,0,1.09,18.94,1,11.93.06,30.84,1.45c5.87.28,11.75.55,17.63.69h0"
        />
      </g>
    </g>
  </svg>
  <svg
    v-else-if="name === 'camera'"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M512 144v288c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48h88l12.3-32.9c7-18.7 24.9-31.1 44.9-31.1h125.5c20 0 37.9 12.4 44.9 31.1L376 96h88c26.5 0 48 21.5 48 48zM376 288c0-66.2-53.8-120-120-120s-120 53.8-120 120 53.8 120 120 120 120-53.8 120-120zm-32 0c0 48.5-39.5 88-88 88s-88-39.5-88-88 39.5-88 88-88 88 39.5 88 88z"
    />
  </svg>
  <svg
    v-else-if="name === 'i'"
    viewBox="0 0 192 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M20 424.229h20V279.771H20c-11.046 0-20-8.954-20-20V212c0-11.046 8.954-20 20-20h112c11.046 0 20 8.954 20 20v212.229h20c11.046 0 20 8.954 20 20V492c0 11.046-8.954 20-20 20H20c-11.046 0-20-8.954-20-20v-47.771c0-11.046 8.954-20 20-20zM96 0C56.235 0 24 32.235 24 72s32.235 72 72 72 72-32.235 72-72S135.764 0 96 0z"
    ></path>
  </svg>
  <svg
    v-else-if="name === 'eye'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
  >
    <path
      fill="currentColor"
      d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"
    ></path>
  </svg>
  <svg
    v-else-if="name === 'loading'"
    class="animate-spin"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      class="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      stroke-width="4"
    />
    <path
      class="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
  <svg
    v-else-if="name === 'times-circle'"
    aria-hidden="true"
    focusable="false"
    data-prefix="far"
    data-icon="times-circle"
    class="svg-inline--fa fa-times-circle fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
    />
  </svg>
  <svg
    v-else-if="name === 'qr-code'"
    fill="none" viewBox="0 0 24 24" stroke="currentColor" id="qrcode" class="w-8 h-8 text-cool-gray-800"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"></path></svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
  },
}
</script>
