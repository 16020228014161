<i18n>
{
  "en": {
    "title": "Memos",
    "take": "take this"
  },
  "de": {
    "title": "digiDoks",
    "take": "übernehmen"
  }
}
</i18n>
<template>
  <section
    class="z-10 overflow-y-auto"
    style="max-height: 90vh"
  >
    <header class="flex pb-2 items-center">
      <h1 class="w-full font-bold text-3xl">
        {{ $t('title') }}
      </h1>
    </header>
    <ul class="w-full grid grid-cols-1 md:grid-cols-4 gap-2 md:gap-4 mt-2">
      <li
        v-for="memo in memos.data"
        :key="memo.id"
        class="w-full"
      >
        <Link
          class="relative block w-full h-full p-2 rounded bg-white border-2 border-yellow-dark group"
          :href="$routes.edit_memo(memo.id)"
        >
          <img
            v-if="memo.image_url"
            :src="memo.image_url"
          >
          <div class="ml-auto text-sm">
            {{ memo.updated_at }}
          </div>
          <div class="absolute top-0 left-0 w-full h-full flex items-center justify-center">
            <button
              name="addMemo"
              class="text-white bg-yellow-dark cursor-pointer rounded py-2 px-4 m-2 opacity-0 group-hover:opacity-100 group-active:opacity-100 group-focus:opacity-100 transition-opacity duration-200"
              target="_blank"
              @click.prevent="addMemo(memo)"
            >
              {{ $t('take') }}
            </button>
          </div>
        </Link>
      </li>
    </ul>
  </section>
</template>
<script>
import axios from 'axios'

export default {
  data() {
    return {
      memos: []
    }
  },
  mounted() {
      axios
        .get(this.$routes.memos(), {
          headers: { Accept: 'application/json' },
        })
        .then(response => {
          this.memos = response.data
        })
  },
  methods: {
    addMemo(memo) {
      this.$emit('memoToAdd', memo)
    }
  }

}
</script>