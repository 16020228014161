<i18n>
{
  "en": {
    "title": "Overview"
  },
  "de": {
    "title": "Übersicht"
  }
}
</i18n>
<template>
  <main-menu />
</template>
