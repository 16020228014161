<i18n>
{
  "en": {
    "title": "Edit Subject",
    "name": "Subject",
    "description": "short name appears on subject book cover",
    "caption": "Caption",
    "short-caption": "Short Caption",
    "background-color": "Background Color",
    "font-color": "Font Color",
    "visible": "visible as exercise book for pupils"
  },
  "de": {
    "title": "Schulfach bearbeiten",
    "name": "Schulfach",
    "description": "Kurzbezeichnung erscheint auf Schulheft",
    "caption": "Bezeichnung",
    "short-caption": "Kurzbezeichnung",
    "background-color": "Hintergrundfarbe",
    "font-color": "Schriftfarbe",
    "visible": "für Schulkind als Schulheft sichtbar"
  }
}
</i18n>
<template>
  <div class="bg-gray-50 p-2 md:px-4 rounded">
    <form-group
      class="w-full"
      :name="$t('name')"
      :description="$t('description')"
      :first="true"
      :last="true"
    >
      <text-input
        v-model="form.name"
        :errors="$page.props.errors && $page.props.errors.name"
        class="pr-6 pb-8 w-full lg:w-1/2"
        :label="$t('caption')"
      />
      <text-input
        v-model="form.short_name"
        :errors="$page.props.errors && $page.props.errors.short_name"
        class="pr-6 pb-8 w-full lg:w-1/2"
        :label="$t('short-caption')"
      />
      <label class="w-full flex items-center my-2">
        <div class="w-40">{{ $t('background-color') }}</div>
        <color-picker
          v-model="form.bg_color"
          class="w-8 h-8"
        />
      </label>
      <label class="w-full flex items-center my-2">
        <div class="w-40">{{ $t('font-color') }}</div>
        <color-picker
          v-model="form.fg_color"
          class="w-8 h-8"
        />
      </label>
      <check-box
        v-model="form.visible"
        :label="$t('visible')"
        class="w-full mt-8"
      />
    </form-group>
    <slot />
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
}
</script>
