<!-- Source: https://github.com/adamwathan/vue-tailwind-examples -->

<template>
  <teleport to="body">
    <div
      v-if="showModal"
      class="fixed inset-0 z-50"
    >
      <transition
        enter-active-class="transition-all transition-fast ease-out-quad"
        leave-active-class="transition-all transition-medium ease-in-quad"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
        appear
        @before-leave="backdropLeaving = true"
        @after-leave="backdropLeaving = false"
      >
        <div v-if="showBackdrop">
          <div class="fixed inset-0 bg-black opacity-50" />
        </div>
      </transition>

      <transition
        enter-active-class="transition-all transition-fast ease-out-quad"
        leave-active-class="transition-all transition-medium ease-in-quad"
        enter-class="opacity-0 scale-70"
        enter-to-class="opacity-100 scale-100"
        leave-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-70"
        appear
        @before-leave="cardLeaving = true"
        @after-leave="cardLeaving = false"
      >
        <div
          v-if="showContent"
          class="relative flex items-center h-full text-center mx-2 lg:mx-4"
          @click="close"
        >
          <!-- <div class="absolute inline-block align-middle w-0 h-screen" /> -->

          <div
            class="align-middle text-left mx-auto rounded w-full max-w-screen-lg"
            @click.stop
          >
            <div
              v-if="showHeader"
              class="px-4 py-2 md:py-4 border-b border-gray-200 flex justify-between items-center"
              :class="{'bg-yellow-dark text-white': coloredHeader, 'bg-gray-100 text-gray-800': !coloredHeader}"
            >
              <h1 class="font-bold text-xl">
                {{ title }}
              </h1>

              <button
                type="button"
                class="p-2 rounded-full border-2 border-transparent hover:border-white"
                :class="{'hover:border-white': coloredHeader, 'hover:border-gray-600': !coloredHeader}"
                name="closeModal"
                @click="close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-4 h-4"
                  :class="{'text-white': coloredHeader, 'text-gray-600': !coloredHeader}"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="currentColor"
                    d="M10 8.59L2.93 1.51 1.51 2.93 8.59 10l-7.08 7.07 1.42 1.42L10 11.41l7.07 7.08 1.42-1.42L11.41 10l7.08-7.07-1.42-1.42L10 8.59z"
                  />
                </svg>
              </button>
            </div>
            <div
              class="overflow-y-auto"
              style="max-height: calc(100vh - 80px)"
            >
              <slot />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    coloredHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      showBackdrop: false,
      showContent: false,
      backdropLeaving: false,
      cardLeaving: false,
    }
  },
  emits: ['close'],
  computed: {
    leaving() {
      return this.backdropLeaving || this.cardLeaving
    },
  },
  watch: {
    open: {
      handler: function(newValue) {
        if (newValue) {
          this.show()
        } else {
          this.close()
        }
      },
      immediate: true,
    },
    leaving(newValue) {
      if (newValue === false) {
        this.showModal = false
        this.$emit('close')
      }
    },
  },
  created() {
    const onEscape = e => {
      if (this.open && e.keyCode === 27) {
        this.close()
      }
    }
    // document.addEventListener('keydown', onEscape)
    // this.$once('hook:destroyed', () => {
    //   document.removeEventListener('keydown', onEscape)
    // })
  },
  methods: {
    show() {
      this.showModal = true
      this.showBackdrop = true
      this.showContent = true
      document.body.style.setProperty('overflow', 'hidden')
    },
    close() {
      this.showBackdrop = false
      this.showContent = false
      document.body.style.removeProperty('overflow')
    },
  },
}
</script>