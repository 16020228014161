<i18n>
{
  "en": {
    "title": "User"
  },
  "de": {
    "title": "Anwender"
  }
}
</i18n>
<template>
  <page-title :nav-back-url="$routes.users()" :title="$t('title')" />
  <section class="w-full flex flex-wrap justify-center">
    <div class="w-full mb-4 md:mb-8 text-xl md:text-2xl text-center">
      <img
        v-if="user.avatar_url"
        class="mx-auto"
        :src="user.avatar_url"
        alt="Avatar"
      >
      {{ user.first_name }} {{ user.last_name }}
    </div>
    <div
      v-dompurify-html="user.qr_code"
      class="w-64 h-64"
    />
  </section>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    }
  }
}
</script>
