<template>
  <article class="w-full p-2 flex flex-wrap sm:flex-nowrap items-center">
    <div
      class="w-3/4 sm:w-32 text-sm"
    >
      <div>{{ comment.user }}</div>
      <div>{{ $filters.toDate(comment.created_at, "DD.MM.YY HH:mm") }}</div>
    </div>
    <div class="w-1/4 sm:w-12 flex justify-end pr-2">
      <button
        v-if="comment.download_url"
        @click="openUrl()"
      >
        <icon
          name="file-image"
          class="w-8 h-8"
        />
      </button>
    </div>
    <div class="w-full sm:px-2">
      <blockquote
        v-if="comment.content"
        v-dompurify-html="contentWithBreaks"
        class="full rounded px-3 py-1 leading-normal whitespace-pre-wrap bg-gray-100 text-gray-700"
      />
    </div>
  </article>
</template>

<script>

export default {
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },

  computed: {
    contentWithBreaks() {
      return this.comment.content.split('\r\n').join('<br>')
    },
  },

  methods: {
    openUrl() {
      if(this.comment.download_url) {
        window.open(this.comment.download_url, '_blank')
      }
    }
  }
}
</script>
