<i18n>
{
  "en": {
    "title": "New Room",
    "save": "save"
  },
  "de": {
    "title": "Neuer Raum",
    "save": "speichern"
  }
}
</i18n>
<template>
  <page-title :nav-back-url="$routes.rooms()" :title="$t('title')" />
  <user-form :value="form" @submit="submit" />
  <footer-actions>
    <loading-button
      :loading="sending"
      class="btn ml-auto"
      type="submit"
      @click="submit()"
    >
      {{ $t('save') }}
    </loading-button>
  </footer-actions>
</template>

<script>
import UserForm from './Form.vue'

export default {
  components: {
    UserForm,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: this.user,
      theme: 2,
    }
  },
  methods: {
    submit() {
      this.$inertia.post(this.$routes.rooms(), this.form, {
        onStart: () => (this.sending = true),
        onFinish: () => (this.sending = false),
      })
    },
  },
}
</script>
