<i18n>
{
  "en": {
    "collaborative": "group work",
    "collaborative-description": "enables digital group work",
    "settings": "digiDoc",
    "title": "Edit digiDoc",
    "media": "Media",
    "media-case": "mediCat",
    "subject": "subject",
    "delete": "delete digiDoc"
  },
  "de": {
    "collaborative": "Gruppenarbeit",
    "collaborative-description": "ermöglicht digitale Gruppenarbeit",
    "settings": "digiDok",
    "title": "digiDok bearbeiten",
    "media": "Medien",
    "media-case": "mediCat",
    "subject": "Fach",
    "delete": "digiDok löschen"
  }
}
</i18n>
<template>
  <Layout :bg-color="true">
    <section class="w-full h-full max-w-screen-xl mx-auto">
      <section class="w-full flex flex-wrap items-center px-1 pt-1 md:pt-2 pb-2 gap-x-2 md:gap-x-4">
        <button
          class="btn-back items-center"
          @click="navigateBack()"
        >
          <img
            class="w-8 h-8 md:w-10 md:h-10"
            :src="imagePath('arrow_left.svg')"
          >
        </button>
        <div class="w-12 sm:flex-grow text-sm sm:text-lg md:text-2xl font-sans">
          {{ $t('title') }}
        </div>
        <!-- <button
          class="w-8 md:w-10 h-8 md:h-10 border border-gray-900 rounded-full flex items-center justify-center"
          @click="addPage()"
        >
          <icon class="w-6 h-6" name="plus" />
        </button> -->
        <button
          class="border border-gray-500 rounded-full hover:bg-blue-dark hover:text-white"
          name="btnAddClip"
          @click="showModalClips = !showModalClips"
        >
          <img
            class="w-8 h-8 md:w-10 md:h-10"
            :src="imagePath('mediCat_ce1.svg')"
          >
        </button>
        <button
          v-if="$page.props.auth.user.owner || $page.props.auth.user.can_upload"
          class="p-1 md:p-2 border border-gray-500 rounded-full text-gray-500 hover:bg-blue-dark hover:text-white"
          name="btnTakePhoto"
          @click="takePhoto()"
        >
          <!-- <icon
            class="w-6 h-6"
            name="camera"
          /> -->
          <img
            class="w-6 h-6"
            :src="imagePath('camera_1.svg')"
          >
        </button>
        <button
          class="p-1 md:p-2 border border-gray-500 rounded-full hover:bg-blue-dark hover:text-white"
          @click="saveImage"
        >
          <img
            class="w-6 h-6"
            :src="imagePath('download_img.svg')"
          >
        </button>
        <button
          class="border border-gray-500 rounded-full"
          name="more_actions"
          @click="showModalSettings = !showModalSettings"
        >
          <img
            class="w-8 h-8 md:w-10 md:h-10"
            :src="imagePath('digiDog_sm.svg')"
          >
        </button>
        <button
          name="btnSave"
          class="w-10 h-8 md:h-10 border border-gray-500 rounded-full flex items-center justify-center hover:bg-green-dark hover:text-white"
          :class="[{ 'text-gray-500': !dirty }, { 'bg-green-dark text-white': dirty }]"
          @click="save"
        >
          <icon
            class="w-6 h-6"
            name="check"
          />
        </button>
      </section>
      <editor
        v-if="form"
        ref="graphicEditor"
        :value="form"
        :clip-url="memo.attachable_file_url"
        @dirtyChanged="dirtyChanged"
      />
      <div
        class="absolute top-0 left-0 w-full"
        style="margin-top: calc(100vh - 28px);"
      >
        <div class="max-w-screen-xl mx-auto flex flex-wrap">
          <div
            v-for="(user, index) in users"
            :key="index"
            class="flex items-center mx-1 pt-1"
          >
            <div class="bg-green-dark w-3 h-3 rounded-full" />
            <div class="ml-1">
              {{ user.user }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <modal
      :open="showModalSettings"
      :title="$t('settings')"
      :colored-header="true"
      @close="showModalSettings = false"
    >
      <div
        class="p-2 md:p-4 bg-white"
      >
        <!-- <check-box
          v-model="form.collaborative"
          :label="$t('collaborative')"
        /> -->
        <select-box
          v-model="form.subject_id"
          :options="subjects"
          :optionKey="true"
          :label="$t('subject')"
        />
        <div class="flex mt-16 gap-x-2">
          <button
            class="btn-delete"
            tabindex="-1"
            type="button"
            @click="destroy"
          >
            {{ $t('delete') }}
          </button>
          <div class="flex-grow text-right">
            <div>{{ memo.user_name }}</div>
            <div class="text-sm">
              <span>{{ $filters.toDate(memo.created_at, 'DD.MM.YY HH:MM') }}</span>
              <span>{{ $filters.toDate(memo.updated_at, 'DD.MM.YY HH:MM') }}</span>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      :open="showModalClips"
      :title="$t('media-case')"
      :colored-header="true"
      @close="showModalClips = false"
    >
      <clips
        class="p-2 md:p-4 bg-white"
        @clipToAdd="clipToAdd"
      />
    </modal>
    <input
      ref="file"
      type="file"
      accept="image/jpeg,image/gif,image/png,application/pdf"
      multiple="false"
      class="hidden"
      @change="file_input_change"
    >
    <modal-spinner :open="showModalSpinner"></modal-spinner>
  </Layout>
</template>

<script>
import axios from 'axios'
import Layout from '@/Layouts/Minimal.vue'
import Editor from '@/Shared/graphic_editor/Editor.vue'
import Clips from './_Clips.vue'
import moment from 'moment'

export default {
  components: {
    Clips,
    Editor,
    Layout,
  },
  layout: null,
  props: {
    memo: {
      type: Object,
      required: true,
    },
    subjects: {
      type: Array,
      required: true,
    },
    meta: {
      type: Object,
      required: false,
      default: null
    },
  },
  data() {
    return {
      sending: false,
      dirty: false,
      message: '',
      memoChannel: null,
      memoOnlineChannel: null,
      receivedPayload: null,
      users: [],
      form: {},
      showModalClips: false,
      showModalSettings: false,
      showModalSpinner: false,
      timeout_save: null,
    }
  },
  computed: {
    title() {
      if(this.memo.attachable_type === 'Comment') {
        return 'Aufgabe korrigieren'
      }
      if(this.memo.attachable_type === 'Clip') {
        return 'Aufgabe bearbeiten'
      }
      return 'digiDok bearbeiten'
    }
  },
  mounted() {
    this.form = this.memo
  },
  beforeDestroy() {
    clearTimeout(this.timeout_save)
  },
  methods: {
    navigateBack() {
      if(this.memo.collaborative && this.memoOnlineChannel) {
        this.memoOnlineChannel.unsubscribe_user({ user: this.$page.props.auth.user.name })
      }

      if(this.meta && this.meta.back_url) {
        this.$inertia.get(this.meta.back_url, { tab: 1 }, { replace:true })
      } else {
        this.$inertia.get(this.$routes.memos(), {}, { replace:true })
      }
    },
    saveImage() {
      this.saveImageAsFile(this.$refs.graphicEditor.toDataUrl())
    },
    saveImageAsFile(base64) {
      let link = document.createElement('a')
      link.setAttribute('href', base64)
      link.setAttribute('download', 'prima.schule-digiWerkstatt-' + moment().format('YYYYMMDDhmmss') + '.png')
      link.click()
    },
    save(silent) {
      let show_message = true
      if(silent && typeof(silent)=== 'boolean')
        show_message = !silent

      let content, blob

      if(this.$refs.graphicEditor) {
        content = this.$refs.graphicEditor.toObject()
        blob = this.$refs.graphicEditor.toBlob()

        let data = new FormData()
        data.append('memo[file]', blob)
        data.append('memo[collaborative]', this.form.collaborative)
        data.append('memo[subject_id]', this.form.subject_id || '')
        data.append('memo[content]', JSON.stringify(content))
        data.append('memo[meta]', JSON.stringify(this.form.meta))
        this.form.files.forEach(item => {
          if(item.signed_id) {
            // blob already exists
            if(item._destroy === false) {
              data.append('memo[files][]', item.signed_id)
            }
          } else {
            // new form field object
            data.append('memo[files][]', item)
          }
        })

        if(show_message) {
          this.$inertia
            .put(this.$routes.memo(this.memo.id), data, {
              preserveState: true,
              onStart: () => this.sending = true,
              onFinish: () => {
                this.sending = false
                this.dirty = false
              },
            })
        } else {
          axios
            .put(this.$routes.memo(this.memo.id), data, {
              headers: { Accept: 'application/json' },
            })
            .then(() => {
              this.dirty = false
            })
        }
      }
    },
    destroy() {
      if (confirm('Soll Notiz tatsächlich gelöscht werden?')) {
        this.showModalSettings = false
        this.$inertia.delete(this.$routes.memo(this.memo.id))
      }
    },
    clipToAdd(clip) {
      let data = new FormData()
      data.append('memo[clip_id]', clip.id)

      this.$inertia.post(this.$routes.memo_attach_file(this.memo.id),
        data,
        {
          preserveState: false,
          onStart: () => this.sending = true,
          onFinish: () => {
            this.sending = false
            this.showModalClips = false
          }
        }
      )
    },
    takePhoto() {
      this.$refs.file.click()
    },

    //--- private ---

    file_input_change(e) {
      let file = e.target.files[0]

      let data = new FormData()
      data.append('memo[file_to_attach]', file)

      this.$inertia.post(this.$routes.memo_attach_file(this.memo.id),
        data,
        {
          preserveState: false,
          onStart: () => {
            this.sending = true
            this.showModalSpinner = true
          },
          onFinish: () => {
            this.sending = false
            this.showModalSpinner = false
          }
        }
      )
    },

    dirtyChanged(dirty) {
      // set autosave for pupils
      if(!this.dirty && !this.$page.props.auth.user.owner) {
        if(this.$page.props.settings.environment === 'test') {
          // short time for test env
          this.setSubmitTimer(1000)
        } else {
          this.setSubmitTimer(15000)
        }
      }
      this.dirty = dirty
    },

    setSubmitTimer(delaytime) {
      this.timeout_save = setTimeout(() => {
        this.save(true)
      }, delaytime)
    }
  }
}
</script>
