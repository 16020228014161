<template>
  <ul class="tasks w-full">
    <li
      v-for="task in tasks"
      :key="task.id"
      class="w-full flex flex-wrap bg-white text-gray-900 p-2 lg:p-4 my-2 md:my-4 lg:mb-12 border border-gray-900 rounded"
      :style="`background-color: ${ task.bg_color }; color: ${ task.fg_color }; border-color: ${ getBorderColor(task) };`"
    >
      <div
        class="w-1/2 md:w-1/6 mt-2 pt-1 pb-2 md:pb-4 lg:flex flex-wrap items-start"
      >
        <div class="py-1 pr-4">
          {{ $filters.toDate(task.date, 'DD.MM.YYYY') }}
        </div>
        <ul class="w-full flex flex-wrap items-start">
          <li
            v-for="(tag, index) in task.tags"
            :key="index"
            class="mr-2"
          >
            <Link
              :href="$routes.subject_tasks(task.subject.id, { 'tags': tag})"
            >
              #{{ tag }}
            </Link>
          </li>
        </ul>
      </div>
      <div class="w-full md:w-2/3 pb-2">
        <div class="flex">
          <img
            v-if="
              task.tasks_clips.some(
                (item) => item.is_task_thumb === true
              )
            "
            class="w-16 mr-2 mt-0 mb-auto"
            :src="getThumbUrl(task)"
          >
          <div
            v-if="task.content"
            v-dompurify-html="task.content"
            class="ql-editor w-full mt-2"
          />
        </div>
        <div
          v-if="task.shares.length > 0"
          class="w-full flex flex-wrap py-2"
        >
          <div
            v-for="share in task.shares"
            :key="share.name"
            class="mr-1 p-1 border border-white rounded"
          >
            {{ share.name }}
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/6 py-2 flex items-start justify-end">
        <ul
          name="task-clips"
          class="flex flex-wrap items-center"
        >
          <template v-for="tasks_clip in task.tasks_clips">
            <li
              v-if="tasks_clip.clips && tasks_clip.clips.clip_type === 'document'"
              :key="tasks_clip.id"
              class="mx-1"
            >
              <button
                class="w-auto cursor-pointer"
                @click="navigateTo(tasks_clip.clips.download_url)"
              >
                <icon
                  name="file-image"
                />
              </button>
            </li>
          </template>
        </ul>
      </div>
      <template
        v-if="task.tasks_clips.length > 0"
      >
        <div
          class="w-full flex md:w-1/6">
          <img
            class="w-8 md:w-12"
            :src="imagePath('mediCat_ce1.svg')"
          >
        </div>
        <div
          class="w-full md:w-5/6"
        >
          <clips-viewer
            v-if="task.tasks_clips.filter((item) => item.is_task_thumb === false).length > 0"
            :task="task"
          />
        </div>
      </template>
      <template
        v-if="task.user_memos.length > 0"
      >
        <div
          class="w-full flex md:w-1/6">
          <img
            class="w-8 md:w-12"
            :src="imagePath('digiDog_r.svg')"
          >
        </div>
        <div
          class="w-full md:w-5/6"
        >
          <section
            class="user-memos w-full mt-2 lg:mt-4 grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-4"
          >
            <div
              v-for="memo in task.user_memos"
              :key="memo.id"
              class="memo w-full relative mb-2"
            >
              <img
                :src="memo.image_url"
                class="bg-white border border-gray-600 rounded cursor-pointer"
                @click="editMemo(memo)"
              >
              <div
                v-if="memo.collaborative"
                class="absolute left-0 bottom-0 p-1 ml-1 mb-2"
              >
                <icon
                  class="w-6 h-6"
                  name="collaboration"
                />
              </div>
            </div>
          </section>
        </div>
      </template>
      <div class="w-full flex items-end gap-x-2 md:w-1/6">
        <!-- <div
          v-if="task.is_homework"
          class="w-8 h-8 flex items-center justify-center border rounded-full"
        >
          <span>HA</span>
        </div> -->
        <icon
          v-if="task.feedback_required"
          class="w-8 h-8"
          name="basket"
        />
      </div>
      <div
        class="w-full md:w-2/3 rounded text-gray-900"
        :class="{'bg-orange-200': task.comments.length > 0}"
      >
        <comment
          v-for="comment in task.comments"
          :key="comment.id"
          :comment="comment"
        />
      </div>
      <div class="w-full md:w-1/6 pt-1 flex flex-wrap items-end justify-end">
        <ul
          name="task-reflections"
          class="flex flex-wrap"
        >
          <li
            v-for="reflection in task.reflections"
            :key="reflection.id"
            class="flex"
          >
            <div
              class="w-8 ml-4"
            >
              <img
                v-if="reflection.user_rate"
                name="task-reflection-vote"
                class="w-8"
                :src="getImagePath(reflection)"
              >
              <icon
                v-else
                :fill="task.subject.fg_color"
                name="smile"
                class="w-8"
              />
            </div>
          </li>
        </ul>
        <div
          class="flex justify-center items-center ml-4"
          name="btnTaskStatus"
        >
          <icon
            v-if="task.task_status.status_id"
            class="w-8 h-8"
            :fill="task.subject.fg_color"
            name="check_circle"
          />
          <icon
            v-else
            class="w-8 h-8"
            :fill="task.subject.fg_color"
            name="circle"
          />
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
import ClipsViewer from './_ClipsViewer.vue'
import Comment from '@/Pages/Plans/_Comment.vue'

export default {
  components: {
    ClipsViewer,
    Comment,
  },
  props: {
    tasks: {
      type: Array,
      required: true,
      default: () => [],
    },
    subject: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getBorderColor(task) {
      if(task.bg_color) {
        return 'transparent'
      }
      return '#6B7280'
    },
    getThumbUrl(task) {
      let tasks_clips = task.tasks_clips.find(
        (item) => item.is_task_thumb === true,
      )
      if (
        tasks_clips &&
        tasks_clips.clips &&
        tasks_clips.clips.download_url
      ) {
        return tasks_clips.clips.download_url
      }
    },
    getImagePath(reflection){
      if(reflection && reflection.user_rate && reflection.user_rate > 0 && reflection.user_rate < 5) {
        return this.imagePath(`rate_${ reflection.user_rate }.svg`)
      }
    },
    navigateTo(url) {
      window.open(url, '_blank')
    },
    editMemo(memo) {
      this.$inertia.get(this.$routes.edit_memo(memo.id), { subject_id: this.subject.id })
    }
  }
}
</script>