<template>
  <label
    v-bind="$attrs"
    class="flex items-center"
  >
    <div class="bg-white border-2 rounded border-gray-300 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2">
      <input
        :id="id"
        v-model="checked"
        type="checkbox"
        class="opacity-0 absolute"
        @change="$emit('update:modelValue', checked)"
      >
      <svg
        class="fill-current hidden w-4 h-4 pointer-events-none"
        viewBox="0 0 20 20"
      >
        <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
      </svg>
    </div>
    <div class="select-none">{{ label }}</div>
    <div
      v-if="errors.length"
      class="form-error"
    >
      {{ errors[0] }}
    </div>
  </label>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `check-box-input-${parseInt(Math.random() * 10000)}`
      },
    },
    modelValue: {
      type: [Boolean],
      require: true,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checked: this.modelValue
    }
  }
}
</script>

<style scoped>
  input:checked + svg {
    display: block;
  }
</style>