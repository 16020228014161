<i18n>
{
  "en": {
    "upload": "Upload files",
    "drag-drop": "- click or drag and drop here",
    "error": "Error occured"
  },
  "de": {
    "upload": "Dateien hochladen",
    "drag-drop": "- hier klicken oder Drag & Drop",
    "error": "Fehler aufgetreten"
  }
}
</i18n>

<template>
  <div
    class="relative flex flex-wrap p-5 border-2 border-dashed rounded border-gray-300"
  >
    <div
      v-for="attributes in clips"
      :id="`clip-${attributes.id || attributes.tempId}`"
      :key="attributes.id || attributes.tempId"
      class="m-2 overflow-hidden rounded z-10"
    >
      <lazy-image
        v-if="attributes.image_url"
        :src="attributes.image_url"
        :blurhash="attributes.blurhash"
        :width="128"
        :height="128"
        alt=""
        class="object-cover w-32 h-32 bg-gray-300"
      />
      <div
        class="flex justify-between p-2 overflow-hidden bg-gray-200 rounded-b"
      >
        <div
          v-if="attributes.progress >= 0 && attributes.progress < 100 "
          class="w-full"
        >
          <div
            class="h-4 bg-gray-500 rounded"
            :style="`width: ${attributes.progress}%`"
          />
        </div>

        <div
          v-if="attributes.error"
          class="inline"
        >
          <span
            class="text-xl font-black text-red-600 cursor-default"
            :title="attributes.error"
          >! {{ attributes.error }}</span>
        </div>
      </div>
    </div>

    <div class="text-center w-full cursor-pointer z-0">
      <icon
        name="upload"
        class="mt-5 mx-auto h-12 w-12 text-gray-400"
      />

      <p class="mt-1 text-sm text-gray-600">
        <span class="font-medium text-blue-600">
          {{ $t('upload') }}
        </span>
        {{ $t('drag-drop') }}
      </p>

      <p class="mt-1 text-xs text-gray-500">
        PDF, JPG, PNG, GIF, MP4, MP3
      </p>

      <input
        type="file"
        multiple
        accept="image/jpeg,image/png,image/gif,video/mp4,video/quicktime,audio/mpeg,application/pdf"
        class="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
        @change="uploadFiles"
      >
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import loadImage from 'blueimp-load-image'
import { DirectUpload } from '@rails/activestorage'

export default {

  data() {
    return {
      clips: []
    }
  },

  methods: {
    addClip(attributes) {
      this.clips.push({
        image_url: attributes.image_url,
        filename: attributes.filename,
        progress: attributes.progress,
        tempId:   attributes.tempId,
        file: null,
      })
    },

    uploadFiles(event) {
      const files = event.target.files || event.dataTransfer.files
      Array.from(files).forEach((file) => this.processFile(file, Math.floor(Math.random()*100000)))
    },

    ///////////////// private /////////////////

    processFile(file, tempId) {
      if (
        !(
          file.type == 'image/jpeg' ||
          file.type == 'image/png' ||
          file.type == 'image/gif' ||
          file.type == 'video/mp4' ||
          file.type == 'video/quicktime' ||
          file.type == 'audio/mpeg' ||
          file.type == 'application/pdf'
        )
      )
        return

      // Step 1: Add empty Clip
      this.addClip({
        filename: file.name,
        progress: 0,
        image_url: null,
        tempId: tempId,
      })

      // Step 2: Generate thumbnail
      if (file.type.startsWith('image/')) this.imageThumbnail(file, tempId)
      else if (file.type.startsWith('video/')) this.videoThumbnail(file, tempId)

      // Step 3: Upload the file
      new Uploader(file, tempId, this.$routes.direct_uploads(), this)
    },

    imageThumbnail(file, tempId) {
      loadImage(
        file,
        (image) => {
          if (image.type === 'error') {
            this.updateClip(tempId, { error: 'Thumbnailing failed!' })
          } else {
            this.updateClip(tempId, { image_url: image.toDataURL() })
          }
        },
        {
          maxWidth: 500,
          maxHeight: 500,
          meta: true,
          crop: true,
          contain: false,
          orientation: true,
        },
      )
    },

    videoThumbnail(file, tempId) {
      var video = document.createElement('video')
      var canvas = document.createElement('canvas')

      video.addEventListener(
        'loadedmetadata',
        () => {
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight
        },
        { once: true },
      )

      video.addEventListener(
        'timeupdate',
        () => {
          canvas
            .getContext('2d')
            .drawImage(video, 0, 0, video.videoWidth, video.videoHeight)

          this.updateClip(tempId, { image_url: canvas.toDataURL() })
        },
        { once: true },
      )

      video.setAttribute('src', URL.createObjectURL(file))
      video.currentTime = 2 // 2 seconds after beginning
    },

    updateClip(tempId, attributes) {
      const clip = this.clips.find(item => item.tempId === tempId)

      if (attributes.blobId) {
        clip.file = attributes.blobId
        // upload is finished; create clip
        this.persistClip(clip)
      } else Object.assign(clip, attributes)
    },

    persistClip(clip) {
      let data = new FormData()
      data.append('clip[file]', clip.file)

      let that = this
      axios.post(this.$routes.clips(), data, {
        headers: { Accept: 'application/json' },
      })
        .then(response => {
          if(response.status === 200) {
            this.$emit('createdClip', response.data)
            this.clips.splice(this.clips.indexOf(clip), 1)
          } else {
            clip.error = this.$t('error')
          }
        })
        .catch(() => clip.error = that.$t('error'))
    }
  },
}

class Uploader {
  constructor(file, tempId, url, delegate) {
    this.delegate = delegate
    this.file = file
    this.tempId = tempId

    const upload = new DirectUpload(file, url, this)

    upload.create((error, blob) => {
      if (error) {
        delegate.updateClip(tempId, { error: error })

        throw new Error(`Error while uploading file: ${ error }`)
      } else {
        delegate.updateClip(tempId, { blobId: blob.signed_id })
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', (event) =>
      this.directUploadDidProgress(event),
    )
  }

  directUploadDidProgress(event) {
    this.delegate.updateClip(this.tempId, {
      progress: (100 * event.loaded) / event.total,
    })
  }
}
</script>
