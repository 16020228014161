<i18n>
{
  "en": {
    "title": "Edit Subject",
    "delete": "delete",
    "save": "save"
  },
  "de": {
    "title": "Fach bearbeiten",
    "delete": "löschen",
    "save": "speichern"
  }
}
</i18n>
<template>
  <page-title :nav-back-url="$routes.subjects()" :title="$t('title')" />
  <user-form class="mt-4 md:mt-8" :value="form" @submit="submit" />
  <footer-actions>
    <button class="btn-delete" tabindex="-1" type="button" @click="destroy">
      {{ $t('delete') }}
    </button>
    <loading-button
      :loading="sending"
      class="btn ml-auto"
      type="submit"
      @click="submit()"
    >
      {{ $t('save') }}
    </loading-button>
  </footer-actions>
</template>
<script>
import Layout from '@/Layouts/Full.vue'
import UserForm from './Form.vue'

export default {
  components: {
    UserForm,
  },
  layout: Layout,
  props: {
    subject: {
      type: Object,
      required: true,
    },
    can: {
      type: Object,
      required: true,
    },
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: {
        ...this.subject,
      },
      theme: 2,
    }
  },
  methods: {
    submit() {
      this.$inertia.put(this.$routes.subject(this.subject.id), this.form, {
        onStart: () => (this.sending = true),
        onSuccess: () => (this.sending = false),
      })
    },
    destroy() {
      if (confirm('Soll tatsächlich gelöscht werden?')) {
        this.$inertia.delete(this.$routes.subject(this.subject.id))
      }
    },
  },
}
</script>
