import { fabric } from 'fabric'
import CanvasUtils from './canvasUtils'

export default (function() {

  function Text(canvas, params, text_type, callback) {
    this.canvas = canvas
    this.params = params
    this.text_type = text_type
    this.callback = callback

    // reset further mouse down handler
    this.canvas.off('mouse:down')
    this.canvas.off('mouse:up')
    this.bindEvents()
  }

  Text.prototype.bindEvents = function() {
    let inst = this
    inst.canvas.on('mouse:down', function(o) {
      inst.onMouseDown(o)
    })
    inst.canvas.on('mouse:up', function() {
      inst.onMouseUp()
    })
  }

  Text.prototype.onMouseUp = function() {
    // remove mouse:down and mouse:up event to create once
    let inst = this
    inst.canvas.off('mouse:down')
    inst.canvas.off('mouse:up')

    this.callback()

    // set generated text object to active and enter editing
    if (inst.text) {
      inst.canvas.setActiveObject(inst.text)
      inst.text.selectionStart = 0
      inst.text.selectionEnd = inst.text.text.length
      inst.text.enterEditing()
    }
  }

  Text.prototype.onMouseDown = function(o) {
    let inst = this

    let pointer = inst.canvas.getPointer(o.e)
    this.origX = pointer.x
    this.origY = pointer.y
    let text
    if(this.text_type && this.text_type === 'Textbox') {
      text = new fabric.Textbox(this.params.placeholder, {
        fill: this.params.fill,
        left: this.origX,
        top: this.origY,
        fontFamily: this.params.fontFamily,
        fontSize: this.params.fontSize,
        hasBorders: true,
        selectable: true,
        editable: true,
        width: inst.canvas.width - this.origX - 10,
        id: parseInt(Math.random()*1000000),
      })
    } else {
      text = new fabric.IText(this.params.placeholder, {
        fill: this.params.fill,
        left: this.origX,
        top: this.origY,
        fontFamily: this.params.fontFamily,
        fontSize: this.params.fontSize,
        hasBorders: true,
        selectable: true,
        editable: true,
        id: parseInt(Math.random()*1000000),
      })
    }

    // initial behavior: object is editable but locked to move/scale/rotate
    CanvasUtils.freezeObject(text, true)

    inst.canvas.add(text)

    this.text = text

    inst.canvas.requestRenderAll()
  }

  return Text
})()
