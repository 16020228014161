/**
 * File generated by js-routes 2.2.4
 * Based on Rails 7.0.4 routes of Prima::Application
 */
const __jsr = (() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /admin/accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_account = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/admin/accounts/:id/send_notification(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_account_send_notification = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_notification"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_accounts = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/announcements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_announcement = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"announcements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/announcements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_announcements = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"announcements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/clips/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_clip = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"clips"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/clips(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_clips = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"clips"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/comments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_comment = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/comments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_comments = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"comments"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lesson = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lessons = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/memos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_memo = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"memos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/memos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_memos = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"memos"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/plans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_plan = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_plans = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"plans"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/reflections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_reflection = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reflections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reflections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_reflections = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reflections"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/roles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_role = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/roles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_roles = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"roles"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/rooms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_room = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"rooms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/rooms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_rooms = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"rooms"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_root = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/shares/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_share = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"shares"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/shares(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_shares = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"shares"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/subjects/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subject = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subjects"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subjects(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subjects = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subjects"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/tasks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_task = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tasks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_tasks = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tasks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/tasks_clips/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_tasks_clip = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tasks_clips"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tasks_clips(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_tasks_clips = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tasks_clips"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/tasks_memos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_tasks_memo = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tasks_memos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tasks_memos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_tasks_memos = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tasks_memos"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/user_accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_account = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"user_accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/user_accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_accounts = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"user_accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/user_reflections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_reflection = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"user_reflections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/user_reflections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_reflections = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"user_reflections"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_users = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /announcements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const announcements = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"announcements"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/:id/avatar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const avatar_user = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"avatar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /masquerade/back(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const back_user_masquerade_index = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"masquerade"],[2,[7,"/"],[2,[6,"back"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /account/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_user_registration = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"account"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /clips/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clip = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clips"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /clips(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clips = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"clips"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /comments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const comments = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"comments"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /me/avatar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_me_avatar = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"me"],[2,[7,"/"],[2,[6,"avatar"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_accounts/:id/avatar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_account_avatar = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user_accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"avatar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /logout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"logout"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const direct_uploads = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /plans/:id/discard(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const discard_plan = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"discard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /posts/:id/discard(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const discard_post = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"discard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_account = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/announcements/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_announcement = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"announcements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/clips/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_clip = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"clips"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/comments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_comment = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_lesson = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/memos/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_memo = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"memos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/plans/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_plan = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reflections/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_reflection = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reflections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/roles/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_role = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/rooms/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_room = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"rooms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/shares/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_share = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"shares"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subjects/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_subject = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subjects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tasks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_task = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tasks_clips/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_tasks_clip = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tasks_clips"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tasks_memos/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_tasks_memo = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tasks_memos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_user = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/user_accounts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_user_account = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"user_accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/user_reflections/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_user_reflection = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"user_reflections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clips/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_clip = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clips"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /memos/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_memo = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /plans/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_plan = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /plans/:plan_id/memos/:id/edit(.:format)
 * @param {any} plan_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_plan_memo = __jsr.r({"plan_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"memos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /posts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_post = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /presentation/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_presentation = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"presentation"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rooms/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_room = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rooms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /subjects/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_subject = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"subjects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tasks/:task_id/comments/:id/edit(.:format)
 * @param {any} task_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_task_comment = __jsr.r({"task_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user_accounts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_account = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user_accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user_accounts/users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_account_user = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user_accounts"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /account/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_registration = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"account"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /medicat(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const knowledge = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"medicat"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /plans/:id/material(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const material = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"material"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /plans/:id/material/:day(.:format)
 * @param {any} id
 * @param {any} day
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const material_day = __jsr.r({"id":{"r":true},"day":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"material"],[2,[7,"/"],[2,[3,"day"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /me(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const me = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"me"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /memos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const memo = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /memos/:id/attach(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const memo_attach_file = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"attach"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /memos/:id/clone(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const memo_clone = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"clone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /memos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const memos = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"memos"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_account = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/announcements/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_announcement = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"announcements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/clips/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_clip = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"clips"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/comments/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_comment = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_lesson = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/memos/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_memo = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"memos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/plans/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_plan = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reflections/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_reflection = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reflections"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/roles/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_role = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/rooms/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_room = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"rooms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/shares/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_share = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"shares"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subjects/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_subject = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subjects"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tasks/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_task = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tasks_clips/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_tasks_clip = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tasks_clips"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tasks_memos/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_tasks_memo = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tasks_memos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_user = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/user_accounts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_user_account = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"user_accounts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/user_reflections/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_user_reflection = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"user_reflections"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clips/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_clip = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"clips"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /memos/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_memo = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"memos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /plans/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_plan = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /plans/:plan_id/memos/new(.:format)
 * @param {any} plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_plan_memo = __jsr.r({"plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"memos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /posts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_post = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rooms/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_room = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rooms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /subjects/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_subject = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subjects"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_accounts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_account = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_accounts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /confirmation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_confirmation = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /account/register(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_registration = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"account"],[2,[7,"/"],[2,[6,"register"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const page = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /plans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /plans/:plan_id/memos/:id(.:format)
 * @param {any} plan_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_memo = __jsr.r({"plan_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"memos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plans = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"plans"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /posts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const post = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /posts/:id/create_file(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const post_create_file = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /posts/:id/notify(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const post_notify = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"notify"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /posts/:id/pdf(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const post_pdf = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /posts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const posts = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"posts"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /presentation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const presentation = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"presentation"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /plans/:id/program(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const program = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"program"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /plans/:id/program/:day(.:format)
 * @param {any} id
 * @param {any} day
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const program_day = __jsr.r({"id":{"r":true},"day":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"program"],[2,[7,"/"],[2,[3,"day"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /reflections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reflection = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"reflections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_rate/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reflection_user_rate = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user_rate"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /reflections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reflections = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reflections"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /plans/:id/restore(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const restore_plan = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"restore"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /posts/:id/restore(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const restore_post = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"restore"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rooms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const room = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rooms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rooms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rooms = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rooms"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /shares/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const share = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shares"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /shares(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shares = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shares"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_web = __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]]);

/**
 * Generates rails route to
 * /subjects/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const subject = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"subjects"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /subjects/:subject_id/tasks(.:format)
 * @param {any} subject_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const subject_tasks = __jsr.r({"subject_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"subjects"],[2,[7,"/"],[2,[3,"subject_id"],[2,[7,"/"],[2,[6,"tasks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /subjects(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const subjects = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subjects"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /tags(/:query)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tags = __jsr.r({"query":{},"format":{}}, [2,[7,"/"],[2,[6,"tags"],[2,[1,[2,[7,"/"],[3,"query"]]],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /tasks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const task = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tasks/:task_id/comments/:id(.:format)
 * @param {any} task_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const task_comment = __jsr.r({"task_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tasks/:task_id/comments(.:format)
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const task_comments = __jsr.r({"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"comments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tasks/:task_id/task_statuses/:id(.:format)
 * @param {any} task_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const task_task_status = __jsr.r({"task_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"task_statuses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tasks/:task_id/task_statuses(.:format)
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const task_task_statuses = __jsr.r({"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"task_statuses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tasks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tasks = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tasks"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_account = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user_accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_accounts = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_accounts"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_confirmation = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /masquerade(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_masquerade_index = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"masquerade"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /user_permissions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_permission = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user_permissions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_permissions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_permissions = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_permissions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_registration = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"account"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /welcome(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const welcome = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"welcome"],[1,[2,[8,"."],[3,"format"]]]]]);

