<i18n>
{
  "en": {
    "my-comment": "Mein Kommentar",
    "recipient": "Recipient",
    "save": "senden"
  },
  "de": {
    "my-comment": "Mein Kommentar",
    "recipient": "Empfänger",
    "save": "senden"
  }
}
</i18n>
<template>
  <section class="bg-white px-4 pb-2">
    <div class="w-full flex flex-wrap py-3">
      <select-box
        v-model="form.user_ref_id"
        :options="users"
        :optionKey="true"
        class="select-box w-full my-3"
        :label="$t('recipient')"
        name="recipient"
      />
      <html-editor
        v-model="form.content"
        class="w-full border rounded my-2"
        :placeholder="$t('my-comment')"
        name="content"
      />
      <file-input
        v-model="form.file"
        :errors="$page.props.errors && $page.props.errors.file"
        class="w-full mt-3"
        type="file"
        accept="image/*,audio/*,video/*"
        label="meine Arbeit"
        :inline="true"
      />
    </div>
    <slot />
  </section>
</template>

<script>

export default {
  components: {
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
}
</script>
