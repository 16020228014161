<i18n>
{
  "en": {
    "show-content": "show content",
    "create-memo": "create correction",
    "delete": "Löschen",
    "delete-confirm": "Diesen Kommentar wirklich löschen?"
  },
  "de": {
    "show-content": "Datei anzeigen",
    "create-memo": "Korrektur erstellen",
    "delete": "Löschen",
    "delete-confirm": "Diesen Kommentar wirklich löschen?"
  }
}
</i18n>
<template>
  <article
    class="
      w-full
      mb-5
      text-lg
      p-2
      md:p-0
      border
      md:border-none
      rounded
      md:flex
      items-center
    "
  >
    <div class="w-full md:w-1/4 flex items-center text-sm">
      <div class="flex-grow">{{ comment.user_ref }}</div>
      <div class="relative group">
        <button v-if="comment.download_url" name="btnCommentMenu">
          <icon name="file-image" class="w-8 h-8" />
        </button>
        <div
          class="
            absolute
            left-8
            bottom-0
            opacity-0
            group-hover:opacity-100
            group-active:opacity-100
            group-focus:opacity-100
            bg-yellow-dark
            text-white
            rounded-md
            transition-opacity
            duration-500
            px-1
            pt-1
          "
        >
          <button
            class="hover:text-yellow-light"
            name="btnShowFile"
            v-tippy="{ placement: 'right' }"
            :content="$t('show-content')"
            @click="openUrl()"
          >
            <icon name="eye" class="w-8 h-8" />
          </button>
          <button
            class="hover:text-yellow-light"
            name="btnCreateMemo"
            v-tippy="{ placement: 'right' }"
            :content="$t('create-memo')"
            @click="createMemo()"
          >
            <icon name="pencil-alt" class="w-8 h-8" />
          </button>
        </div>
      </div>
    </div>
    <div
      class="
        my-2
        w-full
        md:w-1/2
        rounded-lg
        px-3
        py-2
        leading-normal
        whitespace-pre-wrap
        bg-gray-200
        text-gray-700
      "
    >
      <blockquote v-dompurify-html="contentWithBreaks" />
    </div>
    <UserCard
      class="w-full md:w-1/4 pl-2 mt-2 sm:mt-0 text-sm"
      :user="comment.user"
      :date="comment.created_at"
    >
      <div v-if="comment.can.destroy_comment" class="">
        <button class="underline focus:outline-none" @click="destroyComment">
          <small>{{ $t('delete') }}</small>
        </button>
      </div>
    </UserCard>
  </article>
</template>

<script>
import UserCard from '../Users/_Card.vue'

export default {
  components: { UserCard },

  props: {
    comment: {
      type: Object,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
  },
  computed: {
    contentWithBreaks() {
      if (this.comment.content) {
        return this.comment.content.split('\r\n').join('<br>')
      } else {
        return ' '
      }
    },
  },
  methods: {
    destroyComment() {
      if (confirm(this.$t('delete-confirm'))) {
        this.$inertia.delete(
          this.$routes.task_comment(this.task, this.comment),
          {
            preserveScroll: true,
            only: ['comments', 'flash', 'errors'],
          },
        )
      }
    },
    createMemo() {
      this.$inertia.post(this.$routes.memos(), {
        task_id: this.task.id,
        comment_id: this.comment.id,
      })
    },
    openUrl() {
      if (this.comment.download_url) {
        window.open(this.comment.download_url, '_blank', 'prima.schule')
      }
    },
  },
}
</script>
