<i18n>
{
  "en": {
    "title": "Parents Posts",
    "deleted": "deleted",
    "new_post": "create post",
    "draft": "draft",
    "public": "public",
    "visible_from": "visible from"
  },
  "de": {
    "title": "Elternpost",
    "deleted": "gelöscht",
    "new_post": "neuer Post",
    "draft": "Entwurf",
    "public": "Öffentlich",
    "visible_from": "sichtbar ab"
  }
}
</i18n>
<template>
  <page-title :title="$t('title')" />
  <ul class="items mt-4 md:mt-8">
    <li
      v-for="item in posts.data"
      :key="item.slug"
      class="border-b md:border-b-2 border-yellow-dark"
    >
      <Link :href="$routes.post(item.slug)">
        <div class="pl-2 py-2 md:py-4 flex items-center flex-wrap">
          <div
            class="flex-grow py-2"
            :class="{ 'line-through': item.discarded_at }"
          >
            {{ item.title }}
          </div>
          <div
            v-if="
              $page.props.auth.user &&
              !$page.props.auth.user.owner &&
              item.unread
            "
            class="w-6 h-6 mr-4 text-black font-bold"
          >
            <img class="h-6" :src="imagePath('notification_t.svg')" />
            <div class="unread_counter w-6 text-center relative -mt-5 md:-mt-6">
              !
            </div>
          </div>
          <div class="py-2">
            {{ $filters.toDate(item.date_publication, 'DD.MM.YYYY') }}
          </div>
          <div v-if="$page.props.auth.user.owner" class="w-20 p-2">
            {{ getStatus(item.status) }}
          </div>
          <div v-if="item.discarded_at" class="font-bold text-red-dark p-2">
            {{ $t('deleted') }}
          </div>
        </div>
      </Link>
    </li>
  </ul>
  <pagination prop-name="posts" />
  <footer-actions v-if="posts.meta.last > 1 || $page.props.auth.user.owner">
    <div class="w-full flex items-center justify-end">
      <Link
        v-if="$page.props.auth.user.owner"
        class="btn-menu-red"
        name="new_post"
        :href="$routes.new_post()"
      >
        <icon class="w-6 h-6" name="plus" />
      </Link>
    </div>
  </footer-actions>
</template>

<script>
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'

export default {
  props: {
    posts: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      sending: false,
      items: [],
      form: { search: '' },
    }
  },
  watch: {
    form: {
      handler: throttle(function () {
        let query = pickBy(this.form)
        this.$inertia.get(
          this.$routes.posts(
            Object.keys(query).length ? query : { remember: 'forget' },
          ),
          {
            preserveState: true,
            preserveScroll: true,
            only: ['posts'],
          },
        )
      }, 150),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
    getStatus(status) {
      if (status === 'draft') {
        return 'Entwurf'
      } else {
        return 'Öffentlich'
      }
    },
  },
}
</script>
<style scoped>
.dotted:before {
  content: ' · ';
}
</style>
