<template>
  <svg v-if="name === 'pen'" viewBox="0 0 512 512" fill="currentColor"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" /></svg>
  <svg v-else-if="name === 'circle'" viewBox="0 0 512 512" fill="currentColor"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" /></svg>
  <svg v-else-if="name === 'circle-full'" viewBox="0 0 512 512" fill="currentColor"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" /></svg>
  <svg v-else-if="name === 'rubber'" fill="currentColor" viewBox="0 0 21 21"><path d='M12.728 12.728L8.485 8.485l-5.657 5.657 2.122 2.121a3 3 0 0 0 4.242 0l3.536-3.535zM11.284 17H14a1 1 0 0 1 0 2H3a1 1 0 0 1-.133-1.991l-1.453-1.453a2 2 0 0 1 0-2.828L12.728 1.414a2 2 0 0 1 2.828 0L19.8 5.657a2 2 0 0 1 0 2.828L11.284 17z'/></svg>
  <svg v-else-if="name === 'font'" fill="currentColor" viewBox="0 0 448 512"><path d="M254 52.8C249.3 40.3 237.3 32 224 32s-25.3 8.3-30 20.8L57.8 416H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32h-1.8l18-48H303.8l18 48H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H390.2L254 52.8zM279.8 304H168.2L224 155.1 279.8 304z"/></svg>
  <svg v-else-if="name === 'line'" fill="currentColor" viewBox="0 0 448 512"><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>
  <svg v-else-if="name === 'square'" fill="currentColor" viewBox="0 0 512 512"><path d="M464 48V464H48V48H464zM48 0H0V48 464v48H48 464h48V464 48 0H464 48z"/></svg>
  <svg v-else-if="name === 'square-full'" fill="currentColor" viewBox="0 0 512 512"><path d="M0 0H512V512H0V0z"/></svg>
  <svg v-else-if="name === 'finger-draw'"
    viewBox="2 2 22 22"
    fill="none"
  >
    <path d="M 14.52,11.3333 V 6.50001 C 14.52,5.67158 13.8485,5 13.02,5 V 5 C 12.1916,5 11.52003,5.67158 11.52003,6.50001 V 15.9412 L 9.60296,14.7007 c -0.6122,-0.3961 -1.42581,-0.257 -1.87166,0.32 v 0 c -0.41249,0.5338 -0.38649,1.2856 0.06187,1.7897 L 11.52003,21 H 19.52 c 0.1588,-1.4291 0.4433,-3.84 0.6684,-5.7299 0.186,-1.562 -0.8642,-3.0026 -2.4083,-3.3029 z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" id="path1" />
    <path style=" fill: none; fill-rule: evenodd; stroke: currentColor; stroke-width: 0.6; stroke-dasharray: none; " id="path3" d="M 1.7861313,8.1418785 C 2.2437888,8.0016551 2.695383,8.3455001 2.8008609,8.7864279 2.9451971,9.3897945 2.4931029,9.9649621 1.9081912,10.082583 1.1598881,10.233062 0.45928968,9.6707624 0.33060983,8.9417934 0.17298643,8.0488598 0.84644159,7.2219296 1.7195201,7.0827861 2.689113,6.9282611 3.6040603,7.6083366 3.8267668,8.5466125" transform="matrix(2.0355197,0,0,-1.9320088,3.3365383,22.922436)" />
  </svg>
  <svg v-else-if="name === 'scissors'" fill="currentColor" viewBox="0 0 512 512"><path d="M256 192l-39.5-39.5c4.9-12.6 7.5-26.2 7.5-40.5C224 50.1 173.9 0 112 0S0 50.1 0 112s50.1 112 112 112c14.3 0 27.9-2.7 40.5-7.5L192 256l-39.5 39.5c-12.6-4.9-26.2-7.5-40.5-7.5C50.1 288 0 338.1 0 400s50.1 112 112 112s112-50.1 112-112c0-14.3-2.7-27.9-7.5-40.5L499.2 76.8c7.1-7.1 7.1-18.5 0-25.6c-28.3-28.3-74.1-28.3-102.4 0L256 192zm22.6 150.6L396.8 460.8c28.3 28.3 74.1 28.3 102.4 0c7.1-7.1 7.1-18.5 0-25.6L342.6 278.6l-64 64zM64 112a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm48 240a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg>
  <svg v-else-if="name === 'clone'" fill="currentColor" viewBox="0 0 512 512"><path d="M288 448H64V224h64V160H64c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H288c35.3 0 64-28.7 64-64V384H288v64zm-64-96H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H224c-35.3 0-64 28.7-64 64V288c0 35.3 28.7 64 64 64z"/></svg>
  <svg v-else-if="name === 'vector-square'" fill="currentColor" viewBox="0 0 448 512"><path d="M368 80h32v32H368V80zM352 32c-17.7 0-32 14.3-32 32H128c0-17.7-14.3-32-32-32H32C14.3 32 0 46.3 0 64v64c0 17.7 14.3 32 32 32V352c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32H320c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32V384c0-17.7-14.3-32-32-32V160c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H352zM96 160c17.7 0 32-14.3 32-32H320c0 17.7 14.3 32 32 32V352c-17.7 0-32 14.3-32 32H128c0-17.7-14.3-32-32-32V160zM48 400H80v32H48V400zm320 32V400h32v32H368zM48 112V80H80v32H48z"/></svg>
  <svg v-else-if="name === 'lock'" fill="currentColor" viewBox="0 0 448 512"><path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/></svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
}
</script>
