<i18n>
{
  "en": {
    "title": "Rooms",
    "caption": "Caption",
    "url": "Internet Address"
  },
  "de": {
    "title": "Räume",
    "caption": "Bezeichnung",
    "url": "Internetaddresse"
  }
}
</i18n>
<template>
  <page-title :title="$t('title')" />
  <div class="mt-4 md:mt-8">
    <div class="md:flex font-bold border-b-2 border-yellow-dark p-4">
      <div class="w-full md:w-1/3">
        {{ $t('caption') }}
      </div>
      <div class="w-full md:w-1/3">
        {{ $t('url') }}
      </div>
    </div>
    <ul class="w-full">
      <li
        v-for="item in items_sorted"
        :key="item.id"
        class="w-full border-b border-yellow-dark md:flex hover:bg-yellow-light"
      >
        <Link :href="$routes.edit_room(item.id)" class="w-full md:w-1/3 p-4">
          {{ item.name }}
        </Link>
        <div class="w-full md:w-1/3 py-4">
          <a
            :href="item.url"
            target="_blank"
            class="hover:text-blue hover:underline"
            >{{ item.url }}</a
          >
        </div>
      </li>
    </ul>
  </div>
  <footer-actions>
    <Link
      v-if="$page.props.auth.user.owner"
      class="ml-auto btn-menu-red"
      name="new_room"
      :href="$routes.new_room()"
    >
      <icon class="w-6 h-6" name="plus" />
    </Link>
  </footer-actions>
</template>

<script>
import sortBy from 'lodash/sortBy'

export default {
  props: {
    rooms: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      sending: false,
      items: [],
      newItem: '',
      theme: 2,
    }
  },
  computed: {
    items_sorted() {
      return sortBy(this.items, ['name'])
    },
  },
  mounted() {
    this.items = this.rooms
  },
  methods: {
    removeItem: function (item) {
      this.item.splice(this.items.indexOf(item), 1)
    },
  },
}
</script>
<style scoped></style>
