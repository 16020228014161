<template>
  <form
    class="w-full"
    @submit.prevent="$emit('submit')"
  >
    <div class="flex gap-2">
      <text-input
        v-model="form.title"
        class="flex-grow"
        label="Titel"
        :errors="$page.props.errors && $page.props.errors.title"
      />
      <select-box
        v-model="form.status"
        :options="statuses"
        :optionKey="true"
        class="status w-full md:w-40"
        label="Status"
        name="Status"
        :errors="$page.props.errors && $page.props.errors.status"
        @changed="status_changed"
      />
      <date-input
        v-model="form.date_publication"
        :errors="$page.props.errors && $page.props.errors.date_publication"
        class="date w-full md:w-54"
        label="Datum Veröffentlichung"
      />
    </div>
    <section class="w-full mt-4">
      <label
        for="post_content"
        class="pb-2"
      >
        <div class="mb-2">Inhalt:</div>
      </label>
      <html-editor
        id="post_content"
        v-model="form.content"
        class="editor bg-white border rounded"
      />
    </section>
    <slot />
  </form>
</template>

<script>
import moment from 'moment'

export default {
  components: {
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      statuses: [
        { id: 'draft', name: 'Entwurf'},
        { id: 'visible', name: 'veröffentlicht'}
      ]
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
  methods: {
    status_changed(value) {
      if (!this.form.date_publication && value === 'visible') {
        // eslint-disable-next-line vue/no-mutating-props
        this.form.date_publication = moment(new Date()).format('YYYY-MM-DD')
      }
    },
  },
}
</script>

<style scoped>
.tasks img { display: inline; }
.editor { min-height: 300px; }
</style>