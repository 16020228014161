<i18n>
{
  "en": {
    "title": "Edit Room",
    "delete": "delete",
    "save": "save"
  },
  "de": {
    "title": "Raum bearbeiten",
    "delete": "löschen",
    "save": "speichern"
  }
}
</i18n>
<template>
  <page-title :nav-back-url="$routes.rooms()" :title="$t('title')" />
  <user-form :value="form" @submit="submit" />
  <footer-actions>
    <button class="btn-delete" tabindex="-1" type="button" @click="destroy">
      {{ $t('delete') }}
    </button>
    <loading-button
      :loading="sending"
      class="btn ml-auto"
      type="submit"
      @click="submit()"
    >
      {{ $t('save') }}
    </loading-button>
  </footer-actions>
</template>
<script>
import UserForm from './Form.vue'

export default {
  components: {
    UserForm,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
    lessons: {
      type: Object,
      required: true,
    },
    can: {
      type: Object,
      required: true,
    },
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: {
        ...this.room,
      },
      theme: 2,
    }
  },
  methods: {
    submit() {
      this.$inertia.put(this.$routes.room(this.room.id), this.form).then(() => {
        this.sending = false
      })
    },
    destroy() {
      let msg = 'Soll tatsächlich gelöscht werden?'
      if (this.lessons.count && this.lessons.count > 0) {
        msg =
          `Raum in Video-Unterricht verlinkt (${this.lessons.count}x). ` + msg
      }
      if (confirm(msg)) {
        this.$inertia.delete(this.$routes.room(this.room.id))
      }
    },
  },
}
</script>
