<template>
  <div
    v-bind="$attrs"
  >
    <label
      v-if="label"
      class="form-label"
      :for="id"
    >{{ label }}:</label>
    <textarea
      :id="id"
      ref="input"
      class="form-textarea"
      :class="{ error: errors.length }"
      :value="value"
      :rows="rows"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <div
      v-if="errors.length"
      class="form-error"
    >
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `textarea-input-${parseInt(Math.random() * 10000)}`
      },
    },
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    rows: {
      type: String,
      default: '2'
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
  },
}
</script>
