import { fabric } from 'fabric'

export default (function() {
  let shape
  let strokeDashArray

  function Shape(canvas, type, params, callback) {
    this.canvas = canvas
    this.params = params
    this.className = 'Shape'

    this.origX = 0
    this.origY = 0

    shape = type
    this.callback = callback

    // reset further mouse down handler
    this.canvas.off('mouse:down')
    this.canvas.off('mouse:up')
    this.bindEvents()
  }

  Shape.prototype.bindEvents = function() {
    let inst = this
    inst.selectable = true
    inst.canvas.off('mouse:down')

    inst.canvas.on('mouse:down', function(o) {
      inst.onMouseDown(o)
    })
    inst.canvas.on('mouse:up', function() {
      inst.onMouseUp()
    })
  }
  Shape.prototype.onMouseUp = function() {
    let inst = this
    inst.canvas.off('mouse:down')
    inst.canvas.off('mouse:up')

    inst.canvas.renderAll()

    this.callback()
  }

  Shape.prototype.onMouseDown = function(o) {
    let inst = this

    let pointer = inst.canvas.getPointer(o.e)
    this.origX = pointer.x
    this.origY = pointer.y
    if (shape === 'rect') {
      let rect = new fabric.Rect({
        left: this.origX,
        top: this.origY,
        originX: 'left',
        originY: 'top',
        width: 100,
        height: 100,
        fill: this.params.fill,
        transparentCorners: true,
        stroke: this.params.stroke,
        strokeWidth: this.params.strokeWidth,
        strokeDashArray: strokeDashArray,
        noScaleCache: false,
        strokeUniform: true,
        id: parseInt(Math.random() * 1000000),
      })
      inst.canvas.add(rect) //.setActiveObject(rect)
    }
    if (shape === 'circle') {
      let circle = new fabric.Ellipse({
        top: this.origY,
        left: this.origX,
        rx: 50,
        ry: 50,
        transparentCorners: true,
        fill: this.params.fill,
        stroke: this.params.stroke,
        strokeWidth: this.params.strokeWidth,
        strokeDashArray: strokeDashArray,
        noScaleCache: false,
        strokeUniform: true,
        id: parseInt(Math.random() * 1000000),
      })
      inst.canvas.add(circle) //.setActiveObject(circle)
    }
    if (shape === 'line') {
      var points = [pointer.x, pointer.y, pointer.x + 200, pointer.y]
      let line = new fabric.Line(points, {
        strokeDashArray: strokeDashArray,
        stroke: this.params.stroke,
        strokeWidth: this.params.strokeWidth,
        lockScaleY: true,
        originX: 'center',
        originY: 'center',
        transparentCorners: true,
        hasBorders: true,
        hasControls: true,
        id: parseInt(Math.random() * 1000000),
      })
      line.setControlsVisibility({ bl: false, br: false, mb: false, mt: false, tl: false, tr: false })
      inst.canvas.add(line) //.setActiveObject(line)
    }
  }

  return Shape
})()
