<i18n>
{
  "en": {
    "cancel": "cancel",
    "paste": "paste",
    "task-paste": "paste task from clipboard",
    "task-delete": "delete task",
    "task-cut": "move task",
    "task-copy": "copy task",
    "task-edit": "edit task",
    "task-copied": "task copied to clipboard",
    "assume-changes": "assume changes",
    "close": "close",
    "delete": "delete plan",
    "draft": "draft",
    "digidoc-added": "digiDoc added",
    "empty": "No",
    "from": "from",
    "lessons": "Video-Lessons",
    "media-added": "Media added",
    "memo": "Memo",
    "no_rooms_yet": "No rooms defined yet.",
    "no_subjects_yet": "No subjects defined yet.",
    "pupils": "Pupils",
    "reflections": "Self-Reflections",
    "relation-task-media": "Relation Task - Media",
    "save": "save",
    "tags": "Tags",
    "tasks": "Tasks",
    "text": "Text",
    "title": "Edit Plan",
    "toggle_select": "select all / remove all",
    "user-specific": "user specific task",
    "velvet": "Velvet",
    "view": "view",
    "visible": "visible",
    "week": "Cal.-Week",
    "year": "Year"
  },
  "de": {
    "cancel": "abbrechen",
    "paste": "einfügen",
    "task-paste": "Aufgabe von Zwischenablage einfügen",
    "task-delete": "Aufgabe löschen",
    "task-cut": "Aufgabe verschieben",
    "task-copy": "Aufgabe kopieren",
    "task-edit": "Aufgabe editieren",
    "task-copied": "Aufgabe in Zwischenablage kopiert",
    "assume-changes": "Änderungen übernehmen",
    "close": "schließen",
    "date_publication": "Veröffentlichungsdatum",
    "delete": "Plan löschen",
    "digidoc-added": "digiDok hinzugefügt",
    "draft": "Entwurf",
    "empty": "Keine",
    "from": "ab",
    "lessons": "Video-Unterricht",
    "media-added": "Medium hinzugefügt",
    "memo": "Verknüpfung Aufgabe - digiDok",
    "no_rooms_yet": "Es sind noch keine Räume angelegt.",
    "no_subjects_yet": "Es sind noch keine Fächer angelegt.",
    "pupils": "Schulkinder",
    "reflections": "Selbstreflexion",
    "relation-task-media": "Verknüpfung Aufgabe - Medien",
    "save": "Speichern",
    "tags": "Schlagworte",
    "tasks": "Aufgaben",
    "text": "Text",
    "title": "Wochenplan bearbeiten",
    "toggle_select": "alle auswählen / entfernen",
    "user-specific": "Aufgabe individualisieren",
    "view": "Ansicht",
    "visible": "Öffentlich",
    "week": "KW",
    "year": "Jahr"
  }
}
</i18n>
<template>
  <Layout class="bg-gray-100">
    <header
      v-if="form"
      class="sm:mb-4 md:mb-8"
    >
      <div class="flex items-center flex-wrap md:flex-no-wrap gap-x-2">
        <div class="flex flex-grow items-center">
          <button
            class="btn-back items-center"
            @click="gotoIndex()"
          >
            <img
              class="w-12 h-12"
              :src="imagePath('arrow_left.svg')"
            >
          </button>
          <div class="flex-grow lg:flex items-center gap-x-2 md:gap-x-4 mt-1 ml-1 md:ml-4">
            <div class="sm:font-bold  sm:text-xl md:text-3xl">
              {{ getDateIntervall }}
            </div>
            <div class="flex-grow">
              {{ form.title }}
            </div>
          </div>
        </div>
        <button
          v-tippy
          :content="$t('title')"
          name="showModalPlan"
          class="p-1 md:p-2 ml-auto md:mr-1 rounded-full bg-gray-500 hover:bg-yellow-dark text-white"
          @click="showModalPlan=true"
        >
          <icon
            class="w-6 h-6"
            name="pencil-alt"
          />
        </button>
        <button
          name="share-plan"
          class="text-white rounded-full p-2"
          :class="{ 'bg-blue-dark': shared, 'bg-gray-500': !shared }"
          @click="show_share_modal=true"
        >
          <icon name="share" class="w-6 h-6" />
        </button>
      </div>
    </header>
    <div class="flex mt-2 md:mt-4 border-black border-b">
      <button
        v-for="day in days"
        :key="day.id"
        class="w-9 sm:w-16 md:w-20 px-2 py-2"
        :class="{ 'border-b-4 border-indigo-600': day.id === current_day.id }"
        @click.prevent="setCurrentDay(day)"
      >
        {{ day.label }}
      </button>
      <div class="text-right flex-grow md:mr-2">
        <button
          id="btnTextMode"
          class="py-1 md:py-2 pr-1 sm:pr-2"
          @click.prevent="view_mode = 'text'"
        >
          <icon
            v-tippy
            class="w-4 sm:w-6 h-7 sm:h-8"
            :class="view_mode === 'text' ? 'text-indigo-600' : 'text-gray-600'"
            name="text"
            :content="'Texte (' + current_day.label + ')'"
          />
        </button>
        <button
          id="btnTaskMode"
          class="py-1 md:py-2 pr-1 sm:pr-2"
          :class="view_mode === 'tasks' ? 'text-indigo-600' : 'text-gray-600'"
          @click.prevent="view_mode = 'tasks'"
        >
          <icon
            v-tippy
            class="w-6 sm:w-8 h-6 sm:h-8"
            :content="'Aufgaben (' + current_day.label + ')'"
            name="tasks"
          />
        </button>
        <button
          v-if="$page.props.settings.reflections"
          id="btnReflectionMode"
          v-tippy
          class="py-1 md:py-2 pr-1 sm:pr-2"
          :content="'Selbstreflexionen (' + current_day.label + ')'"
          @click.prevent="view_mode = 'reflections'"
        >
          <icon
            class="w-6 sm:w-8 h-6 sm:h-8"
            :class="view_mode === 'reflections' ? 'text-indigo-600' : 'text-gray-600'"
            name="smile1"
          />
        </button>
        <button
          id="btnLessonMode"
          v-tippy
          class="py-1 md:py-2"
          :content="'Video-Unterricht (' + current_day.label + ')'"
          @click.prevent="view_mode = 'lessons'"
        >
          <icon
            class="w-6 sm:w-8 h-6 sm:h-8"
            :class="view_mode === 'lessons' ? 'text-indigo-600' : 'text-gray-600'"
            name="video"
          />
        </button>
      </div>
    </div>
    <div class="flex items-center h-12 md:py-4 lg:py-8">
      <div class="flex-grow pl-2 font-bold">
        {{ getCurrentDay }}
      </div>
      <div class="mr-4">
        {{ getModeLabel }}
      </div>
      <button
        v-if="view_mode == 'tasks' && clipboard.item"
        name="btnPasteTask"
        class="mr-4 pl-4 pr-1 py-2 h-8 flex items-center bg-gray-500 hover:bg-yellow-dark text-white rounded-full transition-all duration-1000"
        :class="{'w-32': clipboard.item, 'w-0': !clipboard.item }"
        @click="pasteTask()"
      >
        <div
          v-tippy
          :content="$t('task-paste')"
          class="flex-grow flex items-center"
        >
          <img :src="imagePath('paste.svg')" class="w-5 h-5" />
          <div class="ml-2 text-sm">{{ $t('paste') }}</div>
        </div>
        <button
          v-tippy
          :content="$t('cancel')"
          @click.prevent="clipboard.item = null"
        >
          <icon
            class="w-6 h-6 m-1 p-1 border rounded-full hover:bg-gray-500"
            name="cross"
          />
        </button>
      </button>
      <button
        v-if="view_mode != 'text'"
        ref="btnAddItem"
        name="add_item"
        class="btn md:mr-2 p-2 lg:p-3 flex items-center justify-center rounded-full"
        @click="addItem()"
      >
        <icon
          class="w-6 h-6"
          name="plus"
        />
      </button>
    </div>
    <div
      v-if="current_day"
      class="mb-8"
    >
      <div
        v-if="view_mode === 'text'"
        class="tasks flex flex-wrap"
      >
        <label
          class="mb-1"
          for="introText"
        >Einleitender Text für {{ current_day.label }}:</label>
        <html-editor
          id="introText"
          v-model="introText"
          class="w-full my-2 lg:my-4 border rounded bg-white"
          style="min-height: 100px"
        />
        <label
          class="mt-4 mb-1"
          for="introText"
        >Abschließender Text für {{ current_day.label }}:</label>
        <html-editor
          id="outroText"
          v-model="outroText"
          class="w-full my-2 lg:my-4 border rounded bg-white"
          style="min-height: 100px"
        />
      </div>
      <div v-if="view_mode === 'tasks'">
        <draggable
          v-if="dayTasks.length > 0"
          v-model="dayTasks"
          class="tasks flex flex-wrap"
          name="dayTasks"
          delay-on-touch-only="true"
          delay="100"
          filter=".ignore-draggable"
          @end="reorderDayTasks()"
          item-key="id"
        >
          <template
            #item="{element}"
          >
          <div
            class="task w-full my-1 sm:my-2"
          >
            <task-edit
              v-if="element === current_task && showEditTask"
              :value="element"
              :clips="local_clips"
              :memos="memos"
              :subjects="subjects"
              :users="users"
              class=".ignore-draggable"
            >
              <template #actions>
                <button
                  v-tippy
                  name="btnShowMediaGallery"
                  class="mr-2"
                  content="Medien"
                  :class="element.tasks_clips_attributes.length > 0 ? 'text-indigo-600' : 'text-gray-600'"
                  @click.prevent="showClipGallery(element)"
                >
                  <icon
                    class="w-6 md:w-8 h-6 md:h-8"
                    name="file-image"
                  />
                </button>
                <button
                  v-tippy
                  name="btnShowMemoGallery"
                  content="digiDok"
                  :class="element.tasks_memos_attributes.length > 0 ? 'text-indigo-600' : 'text-gray-600'"
                  @click.prevent="showMemoGallery(element)"
                >
                  <icon
                    class="w-6 md:w-8 h-6 md:h-8"
                    name="clipboard"
                  />
                </button>
              </template>
              <div class="flex mt-4">
                <button
                  class="ml-auto p-1 rounded-full bg-gray-500 hover:bg-yellow-dark text-white"
                  name="btnCloseEditForm"
                  @click="current_task = null; showEditTask = false"
                >
                  <icon
                    class="w-4 h-4 m-1 p-1"
                    name="cross"
                  />
                </button>
              </div>
            </task-edit>
            <task
              v-else
              :plan="plan"
              :task="element"
              :clips="clips"
              :memos="memos"
              :subjects="subjects"
              class="group"
              :class="{'hidden': element._destroy}"
            >
              <div class=".ignore-draggable w-full flex opacity-0 group-hover:opacity-100 group-active:opacity-100 group-focus:opacity-100 transition-opacity duration-500">
                <button
                  v-tippy
                  class="p-2 rounded-full bg-gray-500 hover:bg-red-dark text-white"
                  name="btnDeleteTask"
                  :content="$t('task-delete')"
                  @click="element._destroy = !element._destroy"
                >
                  <icon
                    class="w-4 h-4"
                    name="trash"
                  />
                </button>
                <button
                  v-tippy
                  class="ml-auto p-2 w-8 h-8 flex items-center justify-center bg-gray-500 hover:bg-yellow-dark text-white rounded-full"
                  name="btnCutTask"
                  :content="$t('task-cut')"
                  @click="cutTask(element)"
                >
                  <tool-icon class="w-4 h-4" name="scissors" />
                </button>
                <button
                  v-tippy
                  class="ml-2 p-2 w-8 h-8 flex items-center justify-center bg-gray-500 hover:bg-yellow-dark text-white rounded-full"
                  name="btnCopyTask"
                  :content="$t('task-copy')"
                  @click="copyTask(element)"
                >
                  <tool-icon class="w-4 h-4" name="clone" />
                </button>
                <button
                  v-tippy
                  class="ml-2 p-2 w-8 h-8 flex items-center justify-center bg-gray-500 hover:bg-yellow-dark text-white rounded-full"
                  name="btnEditTask"
                  :content="$t('task-edit')"
                  @click="editTask(element)"
                >
                  <tool-icon class="w-4 h-4" name="pen" />
                </button>
              </div>
            </task>
          </div>
          </template>
        </draggable>
      </div>
      <div v-if="view_mode === 'lessons'">
        <section
          v-for="lesson in dayLessons"
          :key="lesson.id"
          class="w-full border-t border-white flex flex-wrap md:flex-no-wrap py-4"
        >
          <div class="w-full md:w-64">
            <!-- <html-editor v-model="lesson.title"></html-editor> -->
            <input
              v-model="lesson.title"
              name="lesson_title"
              placeholder="Uhrzeit / Info"
            />
          </div>
          <div class="w-full md:w-64">
            <select-box
              v-model="lesson.room_id"
              :options="rooms"
              :optionKey="true"
            />
          </div>
          <div class="w-full">
            <multiselect
              v-model="lesson.user_ids"
              :options="users.map((user) => user.id)"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Bitte auswählen"
              :custom-label="getUserName"
              :preselect-first="false"
            >
              <template #beforeList>
                <button
                  class="w-full p-2"
                  @click="toggleSelectAll(lesson)"
                >
                  {{ $t('toggle_select') }}
                </button>
              </template>
            </multiselect>
          </div>
          <button
            class="p-2"
            @click.prevent="lesson._destroy = !lesson._destroy"
          >
            <icon
              class="w-4"
              :class="lesson._destroy ? 'text-red-light' : ''"
              name="trash"
            />
          </button>
        </section>
      </div>
      <div v-if="view_mode === 'reflections'">
        <ul class="w-full">
          <li
            v-for="reflection in dayReflections"
            :key="reflection.id"
            class="w-full border-t border-white flex flex-wrap md:flex-no-wrap py-4"
          >
            <text-input
              v-model="reflection.position"
              class="w-10"
            />
            <text-input
              v-model="reflection.content"
              name="self-reflection"
              class="flex-grow"
              placeholder="Neue Selbstreflexion"
            />
            <button
              class="p-2"
              @click.prevent="reflection._destroy = !reflection._destroy"
            >
              <icon
                class="w-4"
                :class="reflection._destroy ? 'text-red-light' : ''"
                name="trash"
              />
            </button>
          </li>
        </ul>
      </div>
    </div>

    <footer-actions>
      <button
        class="btn mb-auto ml-auto mr-4"
        @click="showPlan()"
      >
        {{ $t('view') }}
      </button>
      <loading-button
        :loading="sending"
        :class="[{ 'btn' : !dirty }, { 'btn-green' : dirty }]"
        type="submit"
        @click="submit('')"
      >
        {{ $t('save') }}
      </loading-button>
    </footer-actions>
    <modal
      title="Setting Plan"
      :open="showModalPlan"
      :colored-header="true"
      @close="showModalPlan = false"
    >
      <user-form v-model="form" />
      <div class="flex p-4 bg-gray-100">
        <button
          class="btn-delete"
          tabindex="-1"
          type="button"
          @click="destroy"
        >
          {{ $t('delete') }}
        </button>
        <button
          class="ml-auto btn"
          type="button"
          @click="showModalPlan = false"
        >
          {{ $t('close') }}
        </button>
      </div>
    </modal>
    <modal
      :open="showModalMemo"
      :title="$t('memo')"
      :colored-header="true"
      @close="showModalMemo = false"
    >
      <memos
        class="p-4 bg-white"
        @memoToAdd="memoToAdd"
      />
    </modal>
    <modal
      :open="showModalMedia"
      :title="$t('relation-task-media')"
      :colored-header="true"
      @close="showModalMedia = false"
    >
      <clips
        class="p-2 md:p-4 bg-white"
        @clipToAdd="clipToAdd"
      />
    </modal>
    <modal
      :open="show_share_modal"
      :colored-header="true"
      title="Teilen"
      @close="show_share_modal = false"
    >
      <div class="bg-white p-4">
        <shares
          :shareable="{ id: plan.id, type: 'Plan' }"
          :viewables="viewables"
          @sharesCountChanged="sharesCountChanged"
        />
      </div>
    </modal>
  </Layout>
</template>

<script>
import _ from 'lodash'
import Clips from './_Clips.vue'
import Memos from './_Memos.vue'
import draggable from 'vuedraggable'
import hash from 'object-hash'
import Layout from '@/Layouts/Full.vue'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import Task from './_Task.vue'
import TaskEdit from './_TaskEdit.vue'
import UserForm from './Form.vue'

export default {
  components: {
    Clips,
    draggable,
    Layout,
    Memos,
    Multiselect,
    Task,
    TaskEdit,
    UserForm,
  },
  layout: null,
  props: {
    plan: {
      type: Object,
      required: true,
    },
    clips: {
      type: Array,
      required: true,
    },
    memos: {
      type: Array,
      required: true,
    },
    subjects: {
      type: Array,
      required: true,
    },
    rooms: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    viewables: {
      type: Array,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    }
  },
  data: () => {
    return {
      sending: false,
      form: null,
      form_hash: null,
      dirty: false,
      days: [
        { id: 1, label: 'Mo' },
        { id: 2, label: 'Di' },
        { id: 3, label: 'Mi' },
        { id: 4, label: 'Do' },
        { id: 5, label: 'Fr' },
      ],
      current_day: {},
      showModalPlan: false,
      showModalMedia: false,
      showModalMemo: false,
      showModalReflections: false,
      showModalTags: false,
      show_share_modal: false,
      shared: null,
      current_type: '',
      current_task: null,
      current_content: '',
      current_shareable: null,
      current_tags: '',
      view_mode: '',
      showIntro: false,
      options: [],
      dayTasks: [],
      showEditTask: false,
      local_clips: [],
      local_memos: [],
      clipboard: {
        item: null,
        move: false,
        copy: false
      },
    }
  },
  computed: {
    getModeLabel() {
      if (this.view_mode === 'text') {
        return this.$t('text')
      }
      if (this.view_mode === 'tasks') {
        return this.$t('tasks')
      }
      if (this.view_mode === 'lessons') {
        return this.$t('lessons')
      }
      if (this.view_mode === 'reflections') {
        return this.$t('reflections')
      }

      return ''
    },
    getDateIntervall() {
      if (!this.form.week) {
        return ''
      }
      let week = moment().year(this.form.year).isoWeek(this.form.week)
      return `${week
        .isoWeekday(1)
        .locale('de')
        .format('ddd DD.MM.YYYY')} - ${week
        .isoWeekday(5)
        .locale('de')
        .format('ddd DD.MM.YYYY')}`
    },
    getCurrentDay() {
      if (!this.form || !this.form.week || !this.current_day) {
        return ''
      }
      return this.$filters.toDate(
        moment()
          .year(this.form.year)
          .isoWeek(this.form.week)
          .isoWeekday(Number.parseInt(this.current_day.id)),
        'ddd DD.MM.YYYY',
      )
    },
    getCurrentDayDate() {
      if (!this.form.week || !this.current_day) {
        return ''
      }
      return this.$filters.toDate(
        moment()
          .year(this.form.year)
          .isoWeek(this.form.week)
          .isoWeekday(Number.parseInt(this.current_day.id)),
        'YYYY-MM-DD',
      )
    },
    dayLessons() {
      return this.form.lessons_attributes.filter(
        (item) => item.day === this.current_day.id,
      )
    },
    dayReflections() {
      return this.form.reflections_attributes.filter(
        (item) => item.reflection_date === this.getCurrentDayDate,
      )
    },
    introText: {
      get: function () {
        switch (this.current_day.id) {
          case 1:
            return this.form.intro_1
          case 2:
            return this.form.intro_2
          case 3:
            return this.form.intro_3
          case 4:
            return this.form.intro_4
          case 5:
            return this.form.intro_5
          default:
            return ''
        }
      },
      set: function (value) {
        switch (this.current_day.id) {
          case 1:
            this.form.intro_1 = value
            break
          case 2:
            this.form.intro_2 = value
            break
          case 3:
            this.form.intro_3 = value
            break
          case 4:
            this.form.intro_4 = value
            break
          case 5:
            this.form.intro_5 = value
            break
          default:
            break
        }
      },
    },
    outroText: {
      get: function () {
        switch (this.current_day.id) {
          case 1:
            return this.form.outro_1
          case 2:
            return this.form.outro_2
          case 3:
            return this.form.outro_3
          case 4:
            return this.form.outro_4
          case 5:
            return this.form.outro_5
          default:
            return ''
        }
      },
      set: function (value) {
        switch (this.current_day.id) {
          case 1:
            this.form.outro_1 = value
            break
          case 2:
            this.form.outro_2 = value
            break
          case 3:
            this.form.outro_3 = value
            break
          case 4:
            this.form.outro_4 = value
            break
          case 5:
            this.form.outro_5 = value
            break
          default:
            break
        }
      },
    },
  },
  watch: {
    form: {
      handler: function(val, oldVal) {
        if (val && oldVal) {
          if (hash(val) != this.form_hash) {
            this.dirty = true
          } else {
            this.dirty = false
          }
        }
      },
      deep: true,
    },
    current_day: function (val) {
      if (val && window.localStorage) {
        window.localStorage.setItem('plan__current_day_id', val.id)
        this.setDayTasks()
      }
    },
    view_mode: function (val) {
      if (val && window.localStorage) {
        window.localStorage.setItem('plan_edit__view_mode', val)
      }
    },
  },
  mounted() {
    this.shared = this.meta.shared
    this.local_clips = this.clips
    this.local_memos = this.memos
    this.form = _.omit(this.plan, 'id', 'deleted_at')
    this.form_hash = hash(this.form)

    if (window.localStorage) {
      const current_day_id = Number.parseInt(
        window.localStorage.getItem('plan__current_day_id'),
      )
      if (current_day_id && current_day_id >= 1 && current_day_id <= 5) {
        this.current_day = this.days.find((item) => item.id === current_day_id)
      } else {
        this.current_day = this.days[0]
      }
      const view_mode = window.localStorage.getItem('plan_edit__view_mode')
      if (view_mode) {
        this.view_mode = view_mode
      } else {
        this.view_mode = 'tasks'
      }
    } else {
      this.current_day = this.days[0]
      this.view_mode = 'tasks'
    }
  },
  methods: {
    submit() {
      this.sending = true
      this.$inertia
        .put(
          this.$routes.plan(this.plan.id),
          { plan: this.form },
          { preserveState: true, preserveScroll: true,
            onSuccess: () => {
              this.form = _.omit(this.plan, 'id', 'deleted_at')
              this.form_hash = hash(this.form)
              this.sending = false
              this.dirty = false
            },
        })
    },
    showPlan() {
      if(this.dirty) {
        if (confirm('Änderungen speichern?\n\nHinweis: Bei Abbruch gehen diese verloren!')) {
          this.submit(this.$routes.plan(this.plan.id))
        } else {
          this.$inertia.get(this.$routes.plan(this.plan.id))
        }
      } else {
        this.$inertia.get(this.$routes.plan(this.plan.id))
      }
    },
    destroy() {
      if (confirm('Soll der Wochenplan tatsächlich gelöscht werden?')) {
        this.showModalPlan = false
        this.$inertia.put(this.$routes.discard_plan(this.plan.id))
      }
    },
    gotoIndex() {
      if(this.dirty) {
        if (confirm('Änderungen speichern?\n\nHinweis: Bei Abbruch gehen diese verloren!')) {
          this.submit(this.$routes.plans())
        } else {
          this.$inertia.get(this.$routes.plans())
        }
      } else {
        this.$inertia.get(this.$routes.plans())
      }
    },
    addItem() {
      switch (this.view_mode) {
        case 'tasks': {
          if (this.subjects.length > 0) {
            const newTask = {
              // id: -this.tasks.length - 1,
              owner_id: this.$page.props.auth.user.user_id,
              day: this.current_day.id,
              subject_id: this.subjects[0].id,
              position: this.dayTasks.length + 1,
              is_homework: false,
              feedback_required: false,
              user_specific: false,
              _destroy: false,
              content: '',
              tags: [],
              tasks_memos_attributes: [],
              tasks_clips_attributes: [],
              reflections_attributes: [],
            }
            this.form.tasks_attributes.push(newTask)
            this.dayTasks.push(newTask)
            this.current_task = newTask
            this.showEditTask = true
            this.$refs.btnAddItem.blur()
          } else {
            this.$notify({
              title: 'Noch keine Fächer angelegt.',
              type: 'warn',
            })
          }

          break
        }
        case 'reflections': {
          this.form.reflections_attributes.push({
            owner_id: this.$page.props.auth.user.user_id,
            reflection_date: this.getCurrentDayDate,
            position: this.form.reflections_attributes.length + 1,
            content: '',
          })
          this.$refs.btnAddItem.blur()
          break
        }
        case 'lessons': {
          if (this.rooms.length > 0) {
            const newLesson = {
              owner_id: this.$page.props.auth.user.user_id,
              day: this.current_day.id,
              title: '',
              room_id: this.rooms[0].id,
              user_ids: [],
            }
            this.form.lessons_attributes.push(newLesson)
          } else {
            this.$notify({
              title: 'Noch keine Räume angelegt.',
              type: 'warn',
            })
          }
          this.$refs.btnAddItem.blur()
          break
        }

        default:
          break
      }
    },
    showClipGallery(task) {
      this.current_task = task
      this.showModalMedia = true
    },
    showMemoGallery(task) {
      this.current_task = task
      this.showModalMemo = true
    },
    getUserName(index) {
      const user = this.users.find(item => item.id == index)
      if(user){
        return user.name
      }
      return ''
    },
    toggleSelectAll(lesson) {
      if(lesson.user_ids.length === 0) {
        lesson.user_ids = this.users.map((user) => user.id)
      } else {
        lesson.user_ids = []
      }
    },
    setCurrentDay(day) {
      this.current_day = day
      let introText =document.querySelector('#introText')
      if(introText) {
        introText.children[0].innerHTML=this.introText
      }
      let outroText =document.querySelector('#outroText')
      if(outroText) {
        outroText.children[0].innerHTML=this.outroText
      }
    },
    setDayTasks() {
      this.dayTasks = _.sortBy(this.form.tasks_attributes.filter( (item) => item.day === this.current_day.id), ['position'])
    },
    cutTask(task) {
      this.clipboard.item = task
      this.clipboard.copy = false
      this.clipboard.move = true
    },
    copyTask(task) {
      this.clipboard.item = task
      this.clipboard.copy = true
      this.clipboard.move = false
    },
    pasteTask() {
      // check if object available
      if(this.clipboard.item) {
        // check what to do
        if(this.clipboard.copy) {
          // copy object
          let task_copy = JSON.parse(JSON.stringify(this.clipboard.item))
          task_copy.id = null
          task_copy.slug = null
          task_copy.tasks_clips_attributes.forEach(item => { item.id = null })
          task_copy.tasks_memos_attributes.forEach(item => { item.id = null })
          task_copy.reflections_attributes.forEach(item => { item.id = null })
          task_copy.day = this.current_day.id
          task_copy.position = this.dayTasks.length + 1
          task_copy.owner_id = this.$page.props.auth.user.user_id

          this.form.tasks_attributes.push(task_copy)
        }
        if(this.clipboard.move) {
          // move object
          let task_copy = this.clipboard.item
          task_copy.day = this.current_day.id
          task_copy.position = this.dayTasks.length + 1
          // console.log(this.form.tasks_attributes.indexOf(this.clipboard.item))
        }

        this.clipboard.item = null
        this.setDayTasks()
      }
    },
    editTask(task) {
      this.current_task = task
      this.showEditTask = true
    },
    reorderDayTasks() {
      this.dayTasks.forEach((item, index) => {
        item.position = index + 1
      })
    },
    updateTasksClips(tasks_clips) {
      if(tasks_clips) {
        this.current_task.tasks_clips = tasks_clips
      }
    },
    clipToAdd(clip) {
      if(this.current_task) {
        this.current_task.tasks_clips_attributes.push({
          clip_id: clip.id,
          _destroy: false,
          position: this.current_task.tasks_clips_attributes.length + 1,
        })
        let newClip = { id: clip.id, image_url: clip.image_url }
        this.local_clips.push(newClip)
        this.showModalMedia = false
      }
    },
    memoToAdd(memo) {
      if(this.current_task) {
        this.current_task.tasks_memos_attributes.push({
          memo_id: memo.id,
          collaborative: false,
          _destroy: false
        })
        this.local_memos.push(memo)
        this.showModalMemo = false
      }
    },
    sharesCountChanged(sharesCount) {
      if (sharesCount > 0) {
        this.shared = true
      } else {
        this.shared = false
      }
    }
  },
}
</script>
