<i18n>
{
  "en": {
    "title": "Login",
    "email": "Email",
    "password": "Password",
    "remember-me": "Remember me",
    "forgot-password": "Forgot password?",
    "login": "Login",
    "sign-up": "New user?"
  },
  "de": {
    "title": "Anmelden",
    "email": "E-Mail",
    "password": "Passwort",
    "remember-me": "Zukünftig automatisch anmelden",
    "forgot-password": "Passwort vergessen?",
    "login": "Anmelden",
    "sign-up": "Neu hier?"
  }
}
</i18n>

<template>
  <div class="w-full max-w-md mx-auto my-auto">
    <form
      class="text-gray-900 bg-yellow-dark border rounded overflow-hidden mb-12"
      @submit.prevent="submit"
    >
      <div class="px-4 md:px-10 pt-6 pb-12">
        <h1 class="text-center font-bold text-2xl">Anmeldung</h1>
        <img
          class="w-40 mx-auto mt-4"
          src:="@/images/prima.schule_wr.svg"
        />
        <div class="w-24 mx-auto my-4 border-white border-b-2" />
        <text-input
          v-model="form.id"
          class="mt-10"
          label="Schüler-Kennung / Email-Adresse"
          type="text"
          autofocus
          autocapitalize="off"
          autocomplete="name"
          :errors="$page.props.errors && $page.props.errors.email"
        />
        <text-input
          v-model="form.password"
          class="mt-6"
          label="Passwort"
          type="password"
          autocomplete="new-password"
          :errors="$page.props.errors && $page.props.errors.password"
        />
      </div>
      <div
        class="px-4 md:px-10 py-4 bg-gray-100 border-t border-gray-200 flex items-center"
      >
        <div v-if="can.signup" class="pr-4 text-center">
          <Link
            class="hover:underline focus:outline-none"
            tabindex="-1"
            :href="$routes.new_user_registration()"
          >
            {{ $t('sign-up') }}
          </Link>
        </div>
        <Link
          class="hover:underline focus:outline-none"
          tabindex="-1"
          :href="$routes.new_user_password()"
        >
          {{ $t('forgot-password') }}
        </Link>
        <loading-button
          :loading="sending"
          class="ml-auto btn-red"
          type="submit"
        >
          {{ $t('login') }}
        </loading-button>
      </div>
    </form>
  </div>
  <footer-actions>
    <a class="py-2 text-sm sm:text-base" :href="$getStaticUrl('')"
      >© prima.schule</a
    >
    <ul class="ml-auto flex">
      <li class="sm:mr-2 p-2 rounded hover:bg-yellow-dark hover:text-white">
        <a :href="$getStaticUrl('terms')">Impressum</a>
      </li>
      <li class="p-2 rounded hover:bg-yellow-dark hover:text-white">
        <a :href="$getStaticUrl('privacy')">Datenschutz</a>
      </li>
    </ul>
  </footer-actions>
</template>

<script>
export default {
  props: {
    can: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sending: false,
      form: {
        id: '',
        password: '',
      },
    }
  },
  methods: {
    submit() {
      this.$inertia.post(
        this.$routes.user_session(),
        {
          user: this.form,
        },
        {
          preserveState: false,
          onStart: () => (this.sending = true),
          onFinish: () => {
            this.sending = false
          },
          onSuccess: (event) => {
            if (event.props && event.props.auth) {
              // reload to get current color meta tags
              document.location.reload()
            }
          },
        },
      )
    },
  },
}
</script>
