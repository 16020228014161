<i18n>
{
  "en": {
    "title": "Task",
    "memos": "Memos",
    "received": "received from pupil",
    "no-feedback": "no feedback",
    "sent": "sent to pupil",
    "status": "Status",
    "done": "Done",
    "comments": "Comments",
    "summary": "Summary"
  },
  "de": {
    "title": "Aufgabe",
    "memos": "digiDoks",
    "received": "von Schulkind erhalten",
    "no-feedback": "keine Rückmeldungen",
    "sent": "an Schulkind gesendet",
    "status": "Bearbeitungsstatus",
    "done": "Erledigt",
    "comments": "HA-Korb",
    "summary": "Überblick"
  }
}
</i18n>
<template>
  <page-title :nav-back-url="$routes.tasks()" :title="$t('title')">
    <div v-if="task.content" class="h-8 truncate text-xl">
      <div v-dompurify-html="task.content.replace(/(<img([^>]+)>)/gi, '')" />
    </div>
    <template #action>
      <div class="text-right">
        <div class="">{{ $filters.toDate(task.date, 'ddd DD.MM.YYYY') }}</div>
        <div v-if="task.subject">
          {{ task.subject }}
        </div>
      </div>
    </template>
  </page-title>
  <TabGroup :selectedIndex="current_tab" @change="changeTab">
    <TabList class="flex gap-x-2 md:gap-x-4 border-b border-yellow-dark mt-2 md:mt-4">
      <Tab
        name="btnTabComments"
        v-slot="{ selected }"
        as="template"
      >
        <button
            :class="[selected ? 'bg-yellow-dark text-white' : 'text-gray-900']"
            class="p-2 rounded-t"
        >
          {{ $t('comments') }}
        </button>
      </Tab>
      <Tab
        v-if="task.user_memos.length > 0"
        name="btnTabUserMemos"
        v-slot="{ selected }"
        as="template"
      >
        <button
            :class="[selected ? 'bg-yellow-dark text-white' : 'text-gray-900']"
            class="p-2 rounded-t"
        >
          {{ $t('memos') }}
        </button>
      </Tab>
      <Tab
        v-if="$page.props.settings.task_statuses"
        v-slot="{ selected }"
        as="template"
      >
        <button
            :class="[selected ? 'bg-yellow-dark text-white' : 'text-gray-900']"
            class="p-2 rounded-t"
        >
          {{ $t('status') }}
        </button>
      </Tab>
    </TabList>
    <TabPanels>
      <TabPanel class="pt-4">
        <!-- <div class="flex items-center">
        </div> -->
        <section class="">
          <comment
            v-for="comment in comments"
            :key="comment.id"
            :comment="comment"
            :task="task"
          />
        </section>
      </TabPanel>
      <TabPanel  class="flex flex-wrap pt-4 gap-2 md:gap-4">
        <div
          v-for="(memo, index) in user_memos_sorted"
          :key="index"
          class="w-24 sm:w-32 md:w-56"
        >
          <button
            @click="$inertia.get($routes.edit_memo(memo.id), { task_id: task.id })"
          >
            <img class="border border-yellow-dark rounded" :src="memo.image_url" />
          </button>
          <div class="bloick md:flex items-center -mt-1">
            <div class="flex-grow">{{ memo.user_name }}</div>
            <div class="text-sm">{{ memo.updated_at }}</div>
          </div>
        </div>
      </TabPanel>
      <TabPanel class="pt-4">
        <div v-if="task.task_statuses.length > 0" class="font-bold">
          {{ $t('done') }}
        </div>
        <ul class="w-full flex flex-wrap gap-2 md:gap-4">
          <li
            v-for="(task_status, index) in task.task_statuses"
            :key="index"
            class="p-2 border border-gray-800 rounded"
          >
            {{ task_status.user_name }}
          </li>
        </ul>
      </TabPanel>
    </TabPanels>
  </TabGroup>
  <modal
    :title="$t('summary')"
    :open="showModalSummary"
    :coloredHeader="true"
    @close="showModalSummmary = false"
  >
    <div class="flex flex-wrap px-2 py-4 bg-white">
      <div class="w-full mb-2 text-xl">{{ $t('received') }}:</div>
      <ul class="w-full flex flex-wrap">
        <template v-for="(user, index) in user_sum">
          <li
            v-if="user.user_count > 0"
            :key="index + '_' + user.slug"
            class="px-1 py-2 border border-gray-500 rounded-md my-2 mr-2"
          >
            {{ user.name }} ({{ user.user_count }})
          </li>
        </template>
      </ul>
      <div class="w-full mt-4 mb-2 text-xl">{{ $t('no-feedback') }}:</div>
      <ul class="w-full flex flex-wrap">
        <template v-for="(user, index) in user_sum">
          <li
            v-if="user.user_count === 0"
            :key="index + '_' + user.slug"
            class="px-1 py-2 border border-gray-500 rounded-md my-2 mr-2"
          >
            {{ user.name }}
          </li>
        </template>
      </ul>
      <div class="w-full mt-4 mb-2 text-xl">{{ $t('sent') }}:</div>
      <ul class="w-full flex flex-wrap">
        <template v-for="(user, index) in user_sum">
          <li
            v-if="user.other_count > 0"
            :key="index + '_' + user.slug"
            class="px-1 py-2 border border-gray-500 rounded-md my-2 mr-2"
          >
            {{ user.name }} ({{ user.other_count }})
          </li>
        </template>
      </ul>
    </div>
  </modal>
  <modal
    title="Nachricht erstellen"
    :open="showModalMessage"
    @close="showModalMessage = false"
  >
    <comment-form v-model="form" :users="users" @submit="submit">
      <loading-button
        :loading="sending"
        class="btn ml-auto w-auto"
        @click="submit"
      >
        senden
      </loading-button>
    </comment-form>
  </modal>
  <footer-actions>
    <template
      v-if="current_tab === 0"
    >
      <button
        v-if="task.feedback_required"
        class="btn"
        @click="showModalSummary = true"
      >
        {{ $t('summary') }}
      </button>
      <button
        name="btnNewComment"
        class="ml-auto btn-menu-red"
        @click="showModalMessage = true"
      >
        <icon class="w-6 h-6" name="plus" />
      </button>
    </template>
  </footer-actions>
</template>
<script>
import sortBy from 'lodash/sortBy'
import throttle from 'lodash/throttle'
import Comment from './_Comment.vue'
import CommentForm from './_CommentForm.vue'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'

export default {
  components: {
    Comment,
    CommentForm,
    TabGroup, TabList, Tab, TabPanels, TabPanel
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    comments: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      sending: false,
      form: {
        content: '',
        file: null,
      },
      search: '',
      showModalMessage: false,
      showModalSummary: false,
      theme: 2,
      tabs: [
        { id: 1, label: 'comments' },
        { id: 2, label: 'summary' },
      ],
      current_tab: 0,
    }
  },
  computed: {
    user_sum() {
      let users = []
      this.users.forEach((user) => {
        var user_count = this.comments.filter(
          (comment) =>
            comment.user_ref_slug === user.slug &&
            comment.user.slug === user.slug,
        ).length
        var other_count = this.comments.filter(
          (comment) =>
            comment.user_ref_slug === user.slug &&
            comment.user.slug != user.slug,
        ).length
        users.push({
          slug: user.slug,
          name: user.name,
          user_count: user_count,
          other_count: other_count,
        })
      })
      return users
    },
    user_memos_sorted() {
      return sortBy(this.task.user_memos, (item) => {
        return item.user_name
      })
    },
  },
  watch: {
    search: {
      handler: throttle(function () {
        // let query = this.search
        // this.$inertia.get(
        //   this.$routes.task(this.task.id,
        //     query.length ? { search: query } : { remember: 'forget' },
        //   ),
        //   {
        //     preserveState: true,
        //     preserveScroll: true,
        //   },
        // )
      }, 150),
      deep: true,
    },
  },
  mounted() {
    const a = this.$page.url.split('?')
    if (a.length > 1) {
      const tab = a[1].split('=')
      if (tab.length > 0 && tab[0] === 'tab')
        this.current_tab = parseInt(tab[1])
    }
  },
  methods: {
    back() {
      this.$inertia.visit(this.$routes.tasks())
    },
    submit() {
      let data = new FormData()
      data.append('comment[content]', this.form.content || '')
      if (this.form.file) {
        data.append('comment[file]', this.form.file || '')
      }
      data.append('comment[user_ref_id]', this.form.user_ref_id || '')

      this.$inertia.post(this.$routes.task_comments(this.task), data, {
        preserveState: true,
        onStart: () => (this.sending = true),
        onSuccess: () => {
          this.sending = false
          this.form = {}
          this.showModalMessage = false
        },
      })
    },
    reset() {
      this.search = ''
    },
    changeTab(index) {
      this.current_tab = index
    }
  },
}
</script>
