<i18n>
{
  "en": {
    "reflections": "Self-Reflections",
    "subject": "Subject",
    "tags": "Tags",
    "task": "Task",
    "users": "Users",
    "media": "Media",
    "memo": "digi.Docs",
    "collaborative": "Collaboration",
    "color": "Color",
    "bg": "Background",
    "fg": "Font"
  },
  "de": {
    "reflections": "Selbstreflexion",
    "subject": "Fach",
    "tags": "Schlagworte",
    "task": "Aufgabe",
    "users": "Anwender",
    "media": "Medien",
    "memo": "digi.Doks",
    "collaborative": "Gruppenarbeit",
    "color": "Farbe",
    "bg": "Hintergrund",
    "fg": "Schrift"
  }
}
</i18n>
<template>
  <div
    class="w-full p-2 md:px-4 pt-4 border-2 border-indigo-700 rounded"
  >
    <div class="flex flex-wrap">
      <div class="w-full mb-2">
        <div class="flex items-center">
          <color-picker
            v-model="form.bg_color"
            v-tippy
            content="Hintergrundfarbe"
            class="mx-1"
            size="6"
          />
          <div class="hidden md:block ml-1 mr-2">{{ $t('bg') }}</div>
          <color-picker
            v-model="form.fg_color"
            v-tippy
            content="Schriftfarbe"
            class="mx-1"
            size="6"
          />
          <div class="hidden md:block mx-1">{{ $t('fg') }}</div>
          <button
            v-tippy
            class="ml-auto mr-2 text-gray-400"
            :class="form.is_homework ? 'text-indigo-600' : 'text-gray-600'"
            name="btnHomework"
            content="Hausaufgabe"
            @click="form.is_homework = !form.is_homework"
          >
            <icon
              name="ha"
              class="w-6 md:w-8 h-6 md:h-8"
            />
          </button>
          <button
            v-if="$page.props.settings.tasks"
            v-tippy
            class="mr-2"
            :class="form.feedback_required ? 'text-indigo-600' : 'text-gray-600'"
            name="btnFeedbackRequired"
            content="Hausaufgabenkorb"
            @click="form.feedback_required = !form.feedback_required"
          >
            <icon
              name="basket"
              class="w-6 md:w-8 h-6 md:h-8"
            />
          </button>
          <button
            v-tippy
            name="btnTags"
            class="mr-2"
            :class="form.tags.length > 0 ? 'text-indigo-600' : 'text-gray-600'"
            content="Schlagwörter"
            @click="show_tags = !show_tags"
          >
            <icon
              name="hash"
              class="w-6 md:w-8 h-6 md:h-8"
            />
          </button>
          <button
            v-if="$page.props.settings.reflections"
            v-tippy
            class="mr-2"
            :class="form.reflections_attributes.length > 0 ? 'text-indigo-600' : 'text-gray-600'"
            name="btnReflection"
            content="Selbstreflexionen (Aufgabe)"
            @click="toggleReflections"
          >
            <icon
              name="smile1"
              class="w-6 md:w-8 h-6 md:h-8"
            />
          </button>
          <button
            v-tippy
            class="mr-2"
            :class="form.user_specific ? 'text-indigo-600' : 'text-gray-600'"
            name="btnIndividual"
            content="Individualisierung"
            @click="form.user_specific = !form.user_specific"
          >
            <icon
              class="w-6 md:w-8 h-6 md:h-8"
              name="individual"
            />
          </button>
          <slot name="actions" />
        </div>
      </div>
      <div class="flex-grow">
        <div class="w-full flex flex-wrap">
          <div class="w-full md:w-56">
            <select-box
              v-model="form.subject_id"
              :options="subjects"
              :optionKey="true"
              :label="$t('subject')"
            />
          </div>
          <div class="lg:pl-2 flex-grow">
            <label class="block mt-2 md:mt-0">
              <div class="mb-1">{{ $t('task') }}</div>
              <html-editor
                v-model="form.content"
                name="task_content"
                class="border rounded"
              />
            </label>
          </div>
        </div>
        <div
          v-if="form.user_specific"
          class="w-full mt-4"
        >
          <div>
            {{ $t('users') }}
          </div>
          <multiselect
            v-model="form.user_ids"
            :options="users.map(user => user.id)"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Bitte auswählen"
            :custom-label="getUserName"
            :preselect-first="false"
          />
        </div>
        <tag-select
          v-if="show_tags"
          :value="form.tags"
          class="w-full mt-4"
          :label="$t('tags')"
        />
        <div
          v-if="show_reflections"
          class="w-full mt-4"
        >
          <div>
            {{ $t('reflections') }}
          </div>
          <!-- <draggable
            v-model="form.reflections_attributes"
            delay-on-touch-only="true"
            delay="100"
            class="w-full flex flex-wrap mt-1"
            item-key="id"
          > -->
            <!-- <template
              #item="{element}"
            > -->
            <div
              v-for="(reflection, index) in form.reflections_attributes"
              :key="reflection.id"
              class="w-full flex my-1"
            >
              <text-input
                v-model="reflection.content"
                class="flex-grow"
                placeholder="Neue Selbstreflexion"
                name="reflection"
              />
              <button
                class="ml-4 p-2"
                @click.prevent="reflection._destroy = +!reflection._destroy"
              >
                <icon
                  class="w-4"
                  :class="reflection._destroy ? 'text-red-light' : 'text-gray-600'"
                  name="trash"
                />
              </button>
              <div
                class="w-12 flex"
              >
                <button
                  v-if="index === form.reflections_attributes.length - 1"
                  class="ml-auto text-gray-600"
                  @click="addTaskReflection()"
                >
                  <icon
                    class="w-8 h-8"
                    name="plus-circle"
                  />
                </button>
              </div>
            </div>
            <!-- </template>
          </draggable> -->
        </div>
      </div>
      <div
        v-if="form.tasks_clips_attributes.length > 0"
        class="w-full mt-4"
      >
        <div class="mb-2">
          {{ $t('media') }}
        </div>
        <!-- <draggable
          v-model="form.tasks_clips_attributes"
          class="flex flex-wrap gap-2"
          delay-on-touch-only="true"
          delay="100"
          @end="reorderTasks()"
        > -->
          <div
            v-for="(tasks_clip, index) in form.tasks_clips_attributes"
            :key="index"
            class="image relative w-96 bg-white sm:flex border rounded"
          >
            <img
              :src="getClipUrl(tasks_clip.clip_id)"
              class="w-40"
            >
            <div class="p-2 block w-full">
              <label>
                <div class="text-sm">sichtbar ab Datum (opt.)</div>
                <date-input
                  v-model="tasks_clip.date_publication"
                  class="w-full"
                />
              </label>
              <label
                v-if="taskThumbEnabled(tasks_clip.clip_id)"
                class="mt-1 flex items-center"
              >
                <input
                  v-model="tasks_clip.is_task_thumb"
                  class="mr-1"
                  type="checkbox"
                >
                <span class="text-sm">Inline Bild</span>
              </label>
            </div>
            <button
              class="absolute bottom-0 right-0 m-2 border-2 border-white text-white rounded-full p-2"
              :class="{'hidden bg-gray-500': !tasks_clip._destroy, 'bg-red-light': tasks_clip._destroy }"
              @click="tasks_clip._destroy = !tasks_clip._destroy"
            >
              <icon
                name="trash"
                class="w-4 h-4"
              />
            </button>
          </div>
        <!-- </draggable> -->
      </div>
      <div
        v-if="form.tasks_memos_attributes.length > 0"
        class="w-full mt-4"
      >
        <div class="mb-2">
          {{ $t('memo') }}
        </div>
        <div
          class="flex flex-wrap gap-2"
        >
          <div
            v-for="(tasks_memo, index) in form.tasks_memos_attributes"
            :key="index"
            class="image relative sm:flex border rounded"
          >
            <img
              :src="getMemoUrl(tasks_memo.memo_id)"
              class="w-56"
            >
            <button
              class="absolute bottom-0 right-0 m-2 border-2 border-white bg-red-light text-white rounded-full p-2"
              :class="{'hidden': !tasks_memo._destroy }"
              @click="tasks_memo._destroy = !tasks_memo._destroy"
            >
              <icon
                name="trash"
                class="w-4 h-4"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    draggable,
    Multiselect
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    clips: {
      type: Array,
      required: true,
    },
    memos: {
      type: Array,
      required: true,
    },
    subjects: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      show_tags: false,
      show_shares : false,
      show_reflections: false,
      // show_clips: false,
      show_users: false,
      shares: []
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
  mounted() {
    if(this.form.tags.length > 0)
      this.show_tags = true
    if(this.form.reflections_attributes.length > 0)
      this.show_reflections = true
    if(this.form.user_specific)
      this.show_users = true
  },
  methods: {
    addTaskReflection() {
      this.form.reflections_attributes.push({
        owner_id: this.$page.props.auth.user.user_id,
        position: this.form.reflections_attributes.length + 1,
        content: '',
      })
    },
    toggleReflections() {
      this.show_reflections = !this.show_reflections
      if(this.show_reflections && this.form.reflections_attributes.length === 0)
        this.addTaskReflection()
    },
    reorderTasks() {
      let position = 0
      this.form.tasks_clips_attributes.forEach(item => {
        if(!item._destroy) {
          position++
          item.position = position
        }
      })
    },
    getClipUrl(clip_id) {
      let clip = this.clips.find(item => item.id === clip_id)
      if(clip)
        return clip.image_url
    },
    getUserName(index) {
      const user = this.users.find(item => item.id == index)
      if(user){
        return user.name
      }
      return ''
    },
    taskThumbEnabled(clip_id) {
      if (!clip_id) {
        return false
      }
      let result = this.clips.find(item => item.id === clip_id)
      if (result) {
        return result.clip_type === 'photo'
      }
    },
    destroyTasksClip(tasks_clip) {
      tasks_clip._destroy = 1
    },
    getMemoUrl(memo_id) {
      let memo = this.memos.find(item => item.id === memo_id)
      if(memo)
        return memo.image_url
    },

  }
}
</script>
<style scoped>
.image:hover button { display: block !important; }
</style>
