<i18n>
{
  "en": {
    "save": "save",
    "title": "New User"
  },
  "de": {
    "save": "Speichern",
    "title": "Neuer Anwender"
  }
}
</i18n>
<template>
  <page-title :nav-back-url="$routes.users()" :title="$t('title')" />
  <user-form
    class="mt-4 md:mt-8"
    v-model="form"
    :contactemails="contact_emails"
    :can="can"
    @submit="submit"
  >
    <template #actionAddPermission>
    <div class="text-center text-sm">Hinweis: um Gruppen hinzuzufügen, bitte zuerst speichern</div>
    </template>
  </user-form>
  <footer-actions>
    <loading-button
      :loading="sending"
      class="btn ml-auto"
      type="submit"
      @click="submit()"
    >
      {{ $t('save') }}
    </loading-button>
  </footer-actions>
</template>
<script>
import Layout from '@/Layouts/Full.vue'
import UserForm from './Form.vue'

export default {
  components: {
    UserForm,
  },
  layout: Layout,
  props: {
    user: {
      type: Object,
      required: true,
    },
    can: {
      type: Object,
      required: true,
    },
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      contact_emails: [],
      form: this.user,
    }
  },
  methods: {
    submit() {
      let data = new FormData()
      data.append('user[first_name]', this.form.first_name || '')
      data.append('user[last_name]', this.form.last_name || '')
      data.append('user[email]', this.form.email || '')
      data.append('user[password]', this.form.password || '')
      data.append('user[avatar]', this.form.avatar || '')
      data.append('user[can_upload]', this.form.can_upload || '')
      let emails = this.contact_emails.filter(item => item.valid).map(item => item.address)
      emails.forEach(item => {
        data.append('user[contact_emails][]', item)
      })

      this.$inertia
        .post(this.$routes.users(), data, {
          onStart: () => this.sending = true,
          onFinish: () => this.sending = false,
        },
        {
          preserveState: false
        })
    },
  },
}
</script>
