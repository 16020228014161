<i18n>
{
  "en": {
    "title": "digiFactory",
    "create": "create a new digiDoc",
    "no-preview": "No Preview"
  },
  "de": {
    "title": "digiWerkstatt",
    "create": "erstelle ein neues digiDok",
    "no-preview": "Keine Vorschau"
  }
}
</i18n>
<template>
  <page-title :title="$t('title')" />
  <ul class="mt-4 md:mt-8 memos w-full grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 lg:gap-12">
    <li
      v-for="memo in memos.data"
      :key="memo.id"
      class="memo w-full"
    >
      <Link
        class="block p-2 rounded bg-white border-2 border-yellow-dark"
        :href="$routes.edit_memo(memo.slug)"
        replace
      >
        <img
          v-if="memo.image_url"
          :src="memo.image_url"
        >
        <div class="flex text-sm mt-2">
          <icon
            v-if="memo.collaborative"
            name="collaboration"
            class="w-6 h-6 mr-1 text-green-dark"
          />
          <div class="">
            {{ memo.subject_name }}
          </div>
          <div class="ml-auto">
            {{ memo.updated_at }}
          </div>
        </div>
      </Link>
    </li>
  </ul>
  <pagination prop-name="memos" />
  <footer-actions>
    <div class="w-full flex items-end">
      <img
        class="w-20"
        :src="imagePath('digiDog_sm_r.svg')"
      >
      <!-- <div class="flex-grow text-right mr-4 mb-2 md:mr-8">
        {{ $t('create') }}
      </div> -->
      <Link
        class="ml-auto btn-menu-red"
        name="new_memo"
        :href="$routes.new_memo()"
      >
        <icon
          class="w-6 h-6"
          name="plus"
        />
      </Link>
    </div>
  </footer-actions>
</template>

<script>

export default {
  props: {
    memos: {
      type: Object,
      required: true,
    },
  }
}
</script>
