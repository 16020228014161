<i18n>
{
  "en": {
    "access-token": "to room with access token",
    "no-access-token": "to the room"
  },
  "de": {
    "access-token": "zum Raum mit Zugangscode",
    "no-access-token": "zum Raum"
  }
}
</i18n>
<template>
  <div
    class="w-full p-2 lg:py-4 sm:flex items-center border rounded"
    :style="`border-color: ${ plan.fg_color }`"
  >
    <div class="md:w-1/6 mr-2">
      <button
        v-if="lesson.room && lesson.room.url"
        v-tippy
        target="_blank"
        class="cursor-pointer relative w-16 md:w-20 p-2 rounded hover:bg-primary"
        :content="getContent(lesson)"
        @click="navigateTo(lesson.room.url)"
      >
        <img
          :src="imagePath('video_chat_sm.svg')"
        >
      </button>
    </div>
    <div class="w-full md:w-2/3">
      <div
        v-if="lesson.title"
        v-dompurify-html="lesson.title"
        class=""
      />
      <div
        v-if="lesson.users.length > 0"
        class="flex flex-wrap pt-2"
      >
        <ul
          v-for="user in lesson.users"
          :key="user.name"
        >
          <li
            class="border rounded mr-1 p-1"
            :style="`border-color: ${ plan.fg_color }`"
          >
            {{ user.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    plan: {
      type: Object,
      required: true,
    },
    lesson: {
      type: Object,
      required: true,
    },
  },
  methods: {
    navigateTo(url) {
      window.open(url, '_blank')
    },
    getContent(lesson) {
      if(lesson && lesson.room && lesson.room.access_pin) {
        return `${this.$t('access-token')}: ${lesson.room.access_pin}`
      } else {
        return this.$t('no-access-token')
      }
    }
  }
}
</script>