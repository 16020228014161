<i18n>
{
  "en": {
    "tags": "tags"
  },
  "de": {
    "tags": "Schlagworte"
  }
}
</i18n>
<template>
  <header>
    <page-title :nav-back-url="$routes.subjects()" :title="subject.name">
      <template #action >
        <icon
          class="w-6 lg:w-8 h-12"
          name="subject"
          :style="{ color: subject.bg_color }"
        />
      </template>
    </page-title>
  </header>
  <section class="w-full my-4">
    <button
      class="ml-auto flex p-2 border border-gray-700 rounded-md"
      :class="{ 'bg-gray-700 text-white': tag_mode }"
      name="toggleTagMode"
      @click="toggleTagMode"
    >
      {{ $t('tags') }}
    </button>
    <ul v-if="tag_mode" class="tags flex justify-end mt-2">
      <li
        v-for="(tag, index) in tags"
        :key="index"
        class="p-2"
        :class="{ 'font-bold border-b border-gray-700': tag === current_tag }"
      >
        <Link :href="$routes.subject_tasks(subject.id, { tags: tag })">
          {{ tag }}
        </Link>
      </li>
    </ul>
  </section>
  <div v-if="current_tag" class="text-2xl">Thema {{ current_tag }}</div>
  <task-list :tasks="tasks.data" :subject="subject" />
  <pagination prop-name="tasks" :items="tasks" />
</template>
<script>
import TaskList from './TaskList.vue'

export default {
  components: {
    TaskList,
  },
  props: {
    subject: {
      type: Object,
      required: true,
    },
    tasks: {
      type: Object,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      tag_mode: false,
      current_tag: '',
    }
  },
  mounted() {
    if (
      window.location.search &&
      window.location.search.substring(1).startsWith('tags')
    ) {
      let tag = decodeURI(
        window.location.search.substring(1).split('=')[1].split('&')[0],
      )
      this.current_tag = tag.charAt(0).toUpperCase() + tag.slice(1)
      this.tag_mode = true
    }
  },
  methods: {
    toggleTagMode() {
      this.tag_mode = !this.tag_mode
      if (!this.tag_mode) {
        this.$inertia.get(this.$routes.subject_tasks(this.subject.id), {
          preserveState: true,
        })
      }
    },
  },
}
</script>
