<i18n>
{
  "en": {
    "title": "Self-reflection",
    "no-information": "no information"
  },
  "de": {
    "title": "Selbstreflexion",
    "no-information": "keine Angaben"
  }
}
</i18n>
<template>
  <header>
    <page-title :nav-back-url="$routes.reflections()" :title="$t('title')" />
  </header>
  <section class="lg:ml-12 lg:pl-2">
    <div class="mt-4 font-bold">
      {{ $filters.toDate(reflection.reflection_date, 'ddd DD.MM.YYYY') }}
    </div>
    <div v-if="reflection.task" class="mt-2 flex">
      <span>Aufgabe:</span
      ><span v-dompurify-html="reflection.task.content" class="ml-1" />
    </div>
    <div
      v-dompurify-html="reflection.content"
      class="mt-4 py-4 font-bold border-b border-white"
    />
    <div class="flex justify-center items-start">
      <div class="w-1/4 py-4">
        <icon fill="black" class="w-6 mx-auto mb-2" name="rate_1" />
        <div
          v-dompurify-html="reflection.per_cent_rate_1"
          class="py-1 mt-2 text-center"
        />
        <div
          v-dompurify-html="reflection.count_rate_1"
          class="py-1 mb-2 text-center"
        />
        <div
          v-for="(item, index) in reflection.user_reflections.rate_1"
          :key="index"
          class="w-full py-2 text-center"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="w-1/4 py-4">
        <icon fill="black" class="w-6 mx-auto mb-2" name="rate_2" />
        <div
          v-dompurify-html="reflection.per_cent_rate_2"
          class="py-1 mt-2 text-center"
        />
        <div
          v-dompurify-html="reflection.count_rate_2"
          class="py-1 mb-2 text-center"
        />
        <div
          v-for="(item, index) in reflection.user_reflections.rate_2"
          :key="index"
          class="w-full py-2 text-center"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="w-1/4 py-4">
        <icon fill="black" class="w-6 mx-auto mb-2" name="rate_3" />
        <div
          v-dompurify-html="reflection.per_cent_rate_3"
          class="py-1 mt-2 text-center"
        />
        <div
          v-dompurify-html="reflection.count_rate_3"
          class="py-1 mb-2 text-center"
        />
        <div
          v-for="(item, index) in reflection.user_reflections.rate_3"
          :key="index"
          class="w-full py-2 text-center"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="w-1/4 py-4">
        <icon fill="black" class="w-6 mx-auto mb-2" name="rate_4" />
        <div
          v-dompurify-html="reflection.per_cent_rate_4"
          class="py-1 mt-2 text-center"
        />
        <div
          v-dompurify-html="reflection.count_rate_4"
          class="py-1 mb-2 text-center"
        />
        <div
          v-for="(item, index) in reflection.user_reflections.rate_4"
          :key="index"
          class="w-full py-2 text-center"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div
      v-if="
        reflection.user_reflections.no_rate &&
        reflection.user_reflections.no_rate.length > 0
      "
      class="mt-4 flex flex-wrap"
    >
      <div class="w-full py-4">
        {{ $t('no-information') }}
      </div>
      <div
        v-for="(item, index) in reflection.user_reflections.no_rate"
        :key="index"
        class="p-2 border border-white rounded-md my-2 mr-2"
      >
        {{ item.name }}
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    reflection: {
      type: Object,
      required: true,
    },
  },
}
</script>
