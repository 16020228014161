<i18n>
{
  "en": {
    "viewer": "Class"
  },
  "de": {
    "viewer": "Klasse"
  }
}
</i18n>
<template>
  <section class="w-full p-4 bg-white">
    <ul class="viewables flex flex-wrap">
      <li v-for="viewable in viewables" :key="viewable.viewable_id" class="">
        <button
          class="m-2 p-4 rounded-lg"
          :class="{
            'bg-green-dark text-white': hasShare(viewable.viewable_id, viewable.viewable_type),
            'bg-gray-200 text-gray-800': !hasShare(viewable.viewable_id, viewable.viewable_type)
          }"
          @click="toggleShare(viewable.viewable_id, viewable.viewable_type)"
        >
          {{ viewable.caption }}
        </button>
      </li>
    </ul>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    shareable: {
      type: Object,
      required: true,
    },
    roles: {
      type: Array,
      required: false,
      default: () => [],
    },
    users: {
      type: Array,
      required: false,
      default: () => [],
    },
    viewables: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      shares: [],
    }
  },
  mounted() {
    axios
      .get(
        this.$routes.shares({
          shareable_id: this.shareable.id,
          shareable_type: this.shareable.type,
        }), {
          headers: { Accept: 'application/json' },
        }
      )
      .then((response) => {
        this.shares = response.data
      })
  },
  methods: {
    hasShare(id, viewable_type) {
      return this.shares.some(
        (item) =>
          item.viewable_id === id && item.viewable_type === viewable_type,
      )
    },
    toggleShare(id, viewable_type) {
      let share = this.shares.find(
        (item) =>
          item.viewable_id === id && item.viewable_type === viewable_type,
      )
      if (share) {
        // remove share
        axios.delete(this.$routes.share(share.id), {
          headers: { Accept: 'application/json' },
        }).then(() => {
          this.shares.splice(this.shares.indexOf(share), 1)
          this.$emit('sharesCountChanged', this.shares.length)
        })
      } else {
        // create share
        let data = new FormData()
        data.append('share[shareable_id]', this.shareable.id)
        data.append('share[shareable_type]', this.shareable.type)
        data.append('share[viewable_id]', id)
        data.append('share[viewable_type]', viewable_type)
        axios.post(this.$routes.shares(), data, {
          headers: { Accept: 'application/json' },
        }).then((response) => {
          this.shares.push(response.data)
          this.$emit('sharesCountChanged', this.shares.length)
        })
      }
    },
  },
}
</script>
