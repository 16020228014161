import { fabric } from 'fabric'
import CanvasUtils from './canvasUtils'

class CanvasHelper {
  static extraProps = ['id', 'editable', 'erasable', 'frozen', 'locked', 'signed_id']

  static getClone(canvas) {
    if (!canvas)
      return

    let clone = canvas.toObject(this.extraProps)

    // first quick version to filter background lines / squares group
    return clone.objects.filter(item => item.type != 'group')
  }

  static getDataUrl(canvas) {
    if (!canvas)
      return

    let copy

    canvas.discardActiveObject()
    canvas.setViewportTransform([1, 0, 0, 1, 0, 0])

    if (canvas.backgroundImage) {
      let aCoords = canvas.backgroundImage.aCoords
      copy = canvas.toCanvasElement(1, {
        left: aCoords.tl.x,
        top: aCoords.tl.y,
        width: aCoords.tr.x,
        height: aCoords.br.y
      })
    } else {
      var sel = new fabric.ActiveSelection(
        canvas.getObjects().filter(item => item.type != 'group'),
        {
          canvas: canvas,
        },
      )
      canvas.setActiveObject(sel)
      canvas.requestRenderAll()
      // this.canvas.backgroundColor = null

      copy = canvas.toCanvasElement(1, {
        left: sel.left - 5,
        top: sel.top - 5,
        width: sel.width + 10,
        height: sel.height + 10,
      })
    }
    // copy.backgroundColor = null
    canvas.discardActiveObject()

    return copy.toDataURL('image/png')
  }

  static getBlob(canvas, crop) {
    // crop: true -> select all objects, false: canvas as is
    if (crop === undefined)
      crop = false

    let data_url

    if (crop) {
      data_url = this.getDataUrl()
    } else {
      data_url = canvas.toDataURL('image/png')
    }

    var blobBin = atob(data_url.split(',')[1])
    var array = []
    for (var i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i))
    }

    return new Blob([new Uint8Array(array)], { type: 'image/png' })
  }

  static drawContent(canvas, content, file_urls, meta, owner) {
    if (!canvas)
      return

    // disable fabric-history
    canvas.offHistory()
    // set extra props to serialize for fabric-history
    canvas.extraProps = this.extraProps

    let currently_used_file_urls = []

    if(content && typeof(content) === 'string')
      content = JSON.parse(content)

    if (content) {
      if (file_urls) {
        // update all img src attributes in content field with current urls before we draw them in canvas
        if (content && content.length > 0) {
          content.forEach(item => {
            if (item.type === 'image') {
              let image = file_urls.find(item1 => item1.signed_id == item.signed_id)
              if (image) {
                item.src = image.image_url
                currently_used_file_urls.push(item.signed_id)
              } else {
                item.src = ''
                let error = 'blob not found in memo content'
                console.log(error, item)
                // if (Vue.$honeybadger) Vue.$honeybadger.notify(error, 'Warning')
              }
            }
          })
        }
      }
    }

    if(file_urls) {
      // create all images for blob urls we haven't found in content field
      let init_position = -1
      file_urls.forEach(file => {
        if (currently_used_file_urls.indexOf(file.signed_id) < 0) {
          init_position += 1
          this.createImage(canvas, file.signed_id, file.image_url, init_position * 100)
        }
      })
    }

    if (content) {
      content.forEach(item => {
        // temp patch to make all serialized objects selectable
        item.selectable = true

        // temp patch to move value locked to new attribute frozen
        if (typeof(item.frozen) !='boolean') {
          item.frozen = item.locked
          item.locked = false
        }

        //  for pupils - make locked objects not selectable
        if (!owner && item.locked)
          item.selectable = false
      })
    }

    canvas.loadFromJSON({
      background: '#fff',
      objects: content,
      version: '5.3.0'
    }, () => {
      // check if background is set
      if (meta && meta.bg_structure) {
        if (meta.bg_structure === '4lines_1') {
          CanvasUtils.setBg4Lines_1(canvas)
        }
        if (meta.bg_structure === '4lines_2') {
          CanvasUtils.setBg4Lines_2(canvas)
        }
        if (meta.bg_structure === '2lines') {
          CanvasUtils.setBg2Lines(canvas)
        }
        if (meta.bg_structure === 'lines') {
          CanvasUtils.setBgLines(canvas)
        }
        if (meta.bg_structure === 'squares') {
          CanvasUtils.setBgSquares(canvas)
        }
      }

      CanvasUtils.updateFrozenObjects(canvas)

      canvas.renderAll()

      canvas.clearHistory()
      canvas.onHistory()
    })
  }

  static createImage(canvas, signed_id, url, position) {
    if (!canvas)
      return

    position = (typeof position !== 'undefined') ? position : 0

    fabric.Image.fromURL(url, function (oImg) {
      oImg.id = parseInt(Math.random() * 1000000)
      oImg.signed_id = signed_id
      oImg.scaleToWidth(300).set({
        left: position,
        top: position,
      })
      canvas.add(oImg)
      canvas.renderAll()
    }, { crossOrigin: 'anonymous', signed_id: signed_id })
  }

  static createBackgroundImage(canvas, image_url, meta) {
    if (!canvas)
      return

    if (image_url) {

      let domImg = new Image()
      domImg.crossOrigin = 'anonymous'

      let scale = 1
      if (meta.bg_image_scale) {
        scale = meta.bg_image_scale
      }

      domImg.onload = () => {
        let oImg = new fabric.Image(domImg, {}).scale(scale)

        canvas.setBackgroundImage(oImg, () => canvas.renderAll(canvas), {
          originX: 'left',
          originY: 'top',
        })
      }
      domImg.src = image_url
    }
  }
}

export default CanvasHelper
