<i18n>
{
  "en": {
    "title": "Media",
    "tags": "Tags",
    "take": "take",
    "media": "Media",
    "cancel": "cancel",
    "save": "save"
  },
  "de": {
    "title": "Medien",
    "tags": "Schlagworte",
    "take": "übernehmen",
    "media": "Medien",
    "cancel": "abbrechen",
    "save": "speichern"
  }
}
</i18n>
<template>
  <section class="z-10 ">
    <header class="flex pb-2 items-center justify-end">
      <button
        v-if="tags.length > 0"
        class="mx-2 flex p-2 border bg-white rounded-full"
        :class="{'bg-gray-700 text-white': tag_mode}"
        name="toggleTagMode"
        @click="toggleTagMode"
      >
        {{ $t('tags') }}
      </button>
      <div class="relative">
        <input
          id="search"
          v-model="form.search"
          class="rounded-full border w-20 lg:w-40 px-4 py-2"
          placeholder="Suche "
        >
        <button
          v-if="form.search.length >0"
          class="absolute right-0 mt-2 mr-4"
          @click="form.search=''"
        >
          x
        </button>
      </div>
    </header>
    <ul
      v-if="tag_mode"
      class="tags w-full flex justify-end mt-2"
    >
      <li
        v-for="(tag, index) in tags"
        :key="index"
        class="p-2"
        :class="{'font-bold border-b border-gray-700': tag === current_tag}"
      >
        <Link
          :href="$routes.clips({ 'tags': tag })"
          preserve-state
        >
          #{{ tag }}
        </Link>
      </li>
    </ul>
    <section
      v-if="items"
      id="clips-area"
      class="clips mt-2 md:mt-4 lg:mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 md:gap-4 lg:gap-8"
    >
      <div
        v-for="(clip, index) in items.data"
        :id="clip.id"
        :key="index"
        class="image relative group"
      >
        <img
          :src="clip.image_url"
          :alt="clip.filetype"
          :class="{'w-24 mx-auto': clip.filetype==='Audio'}"
        >
        <div
          v-if="clip.caption"
          class="w-full text-right pt-1"
        >
          {{ clip.caption }}
        </div>
        <ul class="mt-1 w-full flex flex-wrap md:text-sm">
          <li
            v-for="(tag, ti) in clip.tags"
            :key="ti"
            class="mr-1"
          >
            #{{ tag }}
          </li>
          <li class="ml-auto md:text-sm">
            {{ $filters.toDate(clip.updated_at, "DD.MM.YYYY HH:mm") }}
          </li>
        </ul>
        <div class="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <button
            name="addMemo"
            class="text-white bg-yellow-dark cursor-pointer rounded py-2 px-4 m-2 opacity-0 group-hover:opacity-100 group-active:opacity-100 group-focus:opacity-100 transition-opacity duration-500"
            @click="addClip(clip)"
          >
            &lt;&lt; {{ $t('take') }}
          </button>
        </div>
      </div>
      <pagination
        v-if="items"
        prop-name="clips"
        :items="items"
        class="pagination w-full "
      />
    </section>
  </section>
</template>

<script>
import axios from 'axios'
import throttle from 'lodash/throttle'
import pickBy from 'lodash/pickBy'

export default {
  data: () => {
    return {
      items: null,
      tags: [],
      form: { page: 1, search: '', file_type: 'printable' },
      tag_mode: false,
    }
  },
  watch: {
    form: {
      handler: throttle(function() {
        let query = pickBy(this.form)
        axios.get(this.$routes.clips(query), {
          headers: { Accept: 'application/json' },
        })
          .then(response => {
            this.items = response.data
          })
      }, 150),
      deep: true,
    }
  },
  mounted() {
    let query = pickBy(this.form)
    axios.get(this.$routes.clips(query), {
      headers: { Accept: 'application/json' },
    })
      .then(response => {
      this.items = response.data
      })
  },
  methods: {
    addClip(clip) {
      this.$emit('clipToAdd', clip)
    }
  }
}
</script>
<style scoped>
</style>
