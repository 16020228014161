<template>
  <section class="bg-white px-4 pb-2">
    <input
      ref="file"
      type="file"
      accept="image/*,application/pdf"
      multiple="false"
      class="hidden"
      @change="change"
    >
    <div
      v-if="step === 1"
      class="sm:flex w-full py-2"
    >
      <div class="w-full py-2 sm:pr-2">
        <button
          class="w-full flex py-4 border rounded bg-blue-dark text-white"
          name="btnHomeworkFile"
          @click.prevent="browse"
        >
          <img
            :src="imagePath('camera_2.svg')"
            class="w-16 h-12 ml-auto"
          >
          <img
            :src="imagePath('images.svg')"
            class="w-16 h-12 mr-auto"
          >
        </button>
      </div>
      <div
        v-if="memos.length > 0"
        class="w-full py-2 sm:pl-2"
      >
        <button
          class="w-full py-2 border rounded bg-green-dark"
          name="btnHomeworkMemo"
          @click.prevent="select"
        >
          <img
            class="w-16 h-16 mx-auto"
            :src="imagePath('digiDog_sm_r.svg')"
          >
        </button>
      </div>
    </div>
    <div
      v-show="step === 2"
      class="flex flex-wrap w-full py-3"
    >
      <button
        class="mt-4"
        @click.prevent="back(1)"
      >
        zurück
      </button>
      <div
        v-if="form.use_case === 'b'"
        class="w-full mt-4 flex flex-wrap"
        name="memosList"
      >
        <div
          v-for="memo in memos"
          :key="memo.id"
          class="w-full sm:w-1/2 p-2"
        >
          <button @click.prevent="addToSelection(memo)">
            <img
              :src="memo.image_url"
              class="bg-white border-2 border-gray-600 rounded"
              :class="{'border-green-dark border-4': memo == form.selected_memo}"
            >
          </button>
        </div>
      </div>
    </div>
    <div
      v-show="step === 3"
      class="w-full py-3"
    >
      <div
        v-if="form.use_case === 'a'"
      >
        <button
          class="my-4"
          @click.prevent="back(1)"
        >
          zurück
        </button>
        <div
          v-if="form.file && form.file.name"
          class="border-green-dark border-4 rounded p-4 mb-4"
        >
          {{ form.file.name }}
        </div>
      </div>
      <div
        v-if="form.use_case === 'b'"
      >
        <button
          class="my-4"
          @click.prevent="back(2)"
        >
          zurück
        </button>
        <img
          v-if="form.selected_memo"
          :src="form.selected_memo.image_url"
          class="bg-white mb-4 border-4 border-green-dark rounded"
        >
      </div>
      <html-editor
        v-model="form.content"
        class="w-full border rounded"
        placeholder="Nachricht"
      />
    </div>
    <slot v-if="step > 2" />
  </section>
</template>

<script>

export default {
  components: {
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    memos: {
      type: Array,
      required: false,
      default: () => []
    },
  },
  data() {
    return {
      step: 1,
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
  methods: {
    browse() {
      this.form.use_case = 'a'
      this.step = 2
      this.$refs.file.click()
    },
    select() {
      this.form.use_case = 'b'
      this.step = 2
    },
    back(pos) {
      this.step = pos
      if(pos === 1)
        this.form.selected_memo = null
    },
    addToSelection(memo) {
      this.form.selected_memo = memo
      this.step = 3
    },
    change(e) {
      this.form.file = e.target.files[0]
      if(this.form.file) {
        this.step = 3
      }
    },
  }
}
</script>
