<i18n>
{
  "en": {
    title: "User Accounts"
  },
  "de": {
    title: "Benutzergruppen"
  }
}
</i18n>
<template>
  <page-title :title="$t('title')" />
  <ul class="mt-4">
    <li
      v-for="item in user_accounts.data"
      :key="item.id"
      class="w-full border-yellow-dark border rounded mb-2 md:mb-4"
    >
      <Link
        class="w-full flex items-center p-2 md:p-4 gap-x-2 md:gap-x-4 rounded hover:bg-primary-yellow hover:text-white cursor-pointer"
        :href="$routes.edit_user_account(item.id)"
      >
        <img
          v-if="item.avatar_url"
          class="w-12 h-12 rounded-full"
          :src="item.avatar_url"
          alt="Avatar"
        />
        <div
          v-else
          class="w-12 h-12 rounded-full bg-gray-200"
        />
        <div class="font-bold">{{ item.name }}</div>
      </Link>
      <ul class="w-full flex flex-wrap gap-2 m-2 md:m-4">
        <li
          v-for="(user_permission, index) in item.user_permissions"
          :key="index"
          class="btn-gray"
          @click="$inertia.get($routes.edit_user_account_user(user_permission.user.id))"
        >
        {{ user_permission.user.name }}</li>
      </ul>
    </li>
  </ul>
  <footer-actions>
    <Link
      class="ml-auto btn-menu-red"
      name="new_account"
      :href="$routes.new_user_account()"
    >
      <icon class="w-6 h-6" name="plus" />
    </Link>
  </footer-actions>
</template>
<script>
export default {
  props: {
    user_accounts: {
      type: Object,
      required: true,
    },
  },
}
</script>
