<i18n>
{
  "en": {
    "title": "Knowledge Area",
    "media": "mediCat",
    "subjects": "Subjects"
  },
  "de": {
    "title": "Wissensecke",
    "media": "mediCat",
    "subjects": "Schulhefte"
  }
}
</i18n>
<template>
  <page-title :title="$t('title')" />
  <div
    class="mt-4 md:mt-8 grid grid-cols-1 sm:grid-cols-2 gap-8 md:gap-x-16"
  >
    <div
      class="flex items-center justify-center px-8"
    >
      <button
        class="w-64 hover:font-bold"
        style=""
        @click="getUrl($routes.subjects())"
      >
        <img
          :src="imagePath('mediCat_ce2.svg')"
        >
        <div class="relative">
          <img
            :src="imagePath('labelBox.svg')"
          >
          <div
            class="absolute w-full top-0 mt-5 text-center text-4xl text-primary-yellow"
          >
            {{ $t('subjects') }}
          </div>
        </div>
      </button>
    </div>
    <div
      class="flex mx-auto"
    >
      <button
        class="w-64 hover:font-bold"
        @click="getUrl($routes.clips())"
      >
        <img
          :src="imagePath('mediCat_ce1a.svg')"
        >
        <div class="relative">
          <img
            :src="imagePath('labelBox.svg')"
          >
          <div
            class="absolute w-full top-0 mt-4 text-center text-4xl text-primary-yellow"
          >
            {{ $t('media') }}
          </div>
        </div>
      </button>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
  },
  methods: {
    getUrl(url) {
      this.$inertia.get(url)
    }
  }
}
</script>
