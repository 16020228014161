<i18n>
{
  "en": {
    "save": "save",
    "collaborative": "group work",
    "collaborative-description": "enables digital group work",
    "settings": "Settings",
    "title": "New digiDoc",
    "correct-task": "correct Task",
    "media": "Media",
    "media-case": "mediCat"
  },
  "de": {
    "save": "speichern",
    "collaborative": "Gruppenarbeit",
    "collaborative-description": "ermöglicht digitale Gruppenarbeit",
    "settings": "Einstellungen",
    "title": "Neues digiDok",
    "correct-task": "Aufgabe korrigieren",
    "media": "Medien",
    "media-case": "mediCat"
  }
}
</i18n>
<template>
  <Layout :bg-color="true">
    <section class="w-full max-w-screen-xl h-full mx-auto">
      <section class="w-full flex flex-wrap items-center p-1 md:p-2 gap-x-1 sm:gap-x-2 md:gap-x-4">
        <button
          class="btn-back items-center"
          @click="navBack()"
        >
          <img
            class="w-8 h-8 md:w-10 md:h-10"
            :src="imagePath('arrow_left.svg')"
          >
        </button>
        <div class="w-12 flex-grow mx-2 text-sm sm:text-lg md:text-2xl font-sans">
          {{ title }} {{ memo.comment_user }}
        </div>
        <button
          name="btnSave"
          class="w-10 h-8 md:h-10 border border-gray-500 rounded-full flex items-center justify-center hover:bg-green-dark hover:text-white"
          :class="[{ 'text-gray-500': !dirty }, { 'bg-green-dark text-white': dirty }]"
          @click="save"
        >
          <icon
            class="w-6 h-6"
            name="check"
          />
        </button>
      </section>
      <editor
        ref="graphicEditor"
        :value="form"
        :clip-url="memo.attachable_file_url"
        @save="save"
        @dirtyChanged="dirtyChanged"
      />
    </section>
  </Layout>
</template>

<script>
import Layout from '@/Layouts/Minimal.vue'
import Editor from '@/Shared/graphic_editor/Editor.vue'

export default {
  components: {
    Editor,
    Layout,
  },
  layout: null,
  props: {
    memo: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      dirty: false,
      form: {},
      timeout_save: null,
    }
  },
  computed: {
    title() {
      if(this.memo.attachable_type === 'Comment')
        return this.$t('correct-task')

      return this.$t('title')
    }
  },
  mounted() {
    this.form = this.memo
  },
  beforeDestroy() {
    clearTimeout(this.timeout_save)
  },
  methods: {
    navBack() {
      let res = window.location.pathname.match(/^\/plans\/(\w+)/)
      if (res && res.length == 2) {
        this.$inertia.get(this.$routes.plan(res[1]))
      } else if (this.memo.task_id) {
        this.$inertia.get(this.$routes.task(this.memo.task_id))
      } else {
        this.$inertia.get(this.$routes.memos(), {}, { replace:true })
      }
    },
    saveImage() {
      this.saveImageAsFile(this.$refs.graphicEditor.canvas.toDataURL('image/jpeg', 1))
    },
    saveImageAsFile(base64) {
      let link = document.createElement('a')
      link.setAttribute('href', base64)
      link.setAttribute('download', 'image-markup')
      link.click()
    },
    clipToAdd(clip) {
      this.$refs.graphicEditor.addImage(clip.id, clip.download_url)
    },
    save() {
      if(this.$refs.graphicEditor) {
        let content = this.$refs.graphicEditor.toObject()
        let blob = this.$refs.graphicEditor.toBlob()

        let data = {
          memo: {
            file: blob,
            collaborative: this.form.collaborative,
            attachable_id: this.memo.attachable_id,
            attachable_type: this.memo.attachable_type,
            user_ref_id: this.memo.user_ref_id,
            task_id: this.memo.task_id,
            content: JSON.stringify(content),
            meta: JSON.stringify(this.form.meta)
          }
        }

        this.$inertia
          .post(this.$routes.memos(), data, {
            onStart: () => this.sending = true,
            onFinish: () => {
              this.sending = false
            },
          })
      }
    },

    dirtyChanged(dirty) {
      // set autosave for pupils
      if(!this.dirty && !this.$page.props.auth.user.owner) {
        if(this.$page.props.settings.environment === 'test') {
          // short time for test env
          this.setSubmitTimer(1000)
        } else {
          this.setSubmitTimer(15000)
        }
      }
      this.dirty = dirty
    },

    setSubmitTimer(delaytime) {
      this.timeout_save = setTimeout(() => {
        this.save()
      }, delaytime)
    }
  }
}
</script>
