<i18n>
{
  "en": {
    "title": "Subjects",
    "deleted": "deleted"
  },
  "de": {
    "title": "Schulhefte",
    "deleted": "gelöscht"
  }
}
</i18n>
<template>
  <page-title :viewer-nav-back-url="$routes.knowledge()" :title="$t('title')" />
  <div
    class="subjects mt-4 md:mt-8 flex flex-wrap justify-center gap-4 md:gap-8"
  >
    <div
      v-for="subject in subjects"
      :key="subject.id"
      class="subject relative w-56"
    >
      <Link :href="$routes.subject_tasks(subject.id)">
        <div class="relative" :style="{ color: subject.bg_color }">
          <icon class="" name="subject" />
          <div class="absolute w-full text-center top-0 mt-20 text-lg">
            {{ subject.short_name }}
          </div>
        </div>
      </Link>
      <Link
        v-if="$page.props.auth.user && $page.props.auth.user.owner"
        class="absolute bottom-0 right-0 mb-4 lg:mb-6 mr-4 lg:mr-8 p-2 rounded-full border border-white text-white"
        :href="$routes.edit_subject(subject.id)"
      >
        <icon class="w-4 h-4" name="pencil-alt" />
      </Link>
    </div>
  </div>
  <footer-actions>
    <div class="w-full flex items-center justify-end">
      <Link
        v-if="$page.props.auth.user.owner"
        class="btn-menu-red"
        name="new_subject"
        :href="$routes.new_subject()"
      >
        <icon class="w-6 h-6" name="plus" />
      </Link>
    </div>
  </footer-actions>
</template>

<script>
import orderBy from 'lodash/orderBy'

export default {
  props: {
    subjects: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data: () => {
    return {
      theme: 2,
    }
  },
  computed: {
    items_sorted() {
      return orderBy(
        this.subjects,
        [(subject) => subject.name.toLowerCase()],
        ['asc'],
      )
    },
  },
}
</script>
