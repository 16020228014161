<i18n>
{
  "en": {
    "users": "Users",
    "user": "User",
    "user-accounts": "User Accounts",
    "export": "Export User list",
    "family_name": "Name",
    "first_name": "First Name",
    "user_id": "Id",
    "last_seen": "last seen",
    "locked": "locked",
    "can_upload": "upload",
    "owner": "teacher",
    "role": "role",
    "viewer": "pupil",
    "editor": "co-teacher",
    "no-entries": "no entries"
  },
  "de": {
    "users": "Anwender",
    "user": "Anwender",
    "user-accounts": "Anwendergruppen",
    "export": "Export Anwenderliste",
    "family_name": "Nachname",
    "first_name": "Vorname",
    "user_id": "Kennung",
    "last_seen": "zuletzt gesehen",
    "locked": "gesperrt",
    "can_upload": "upload",
    "role": "Rolle",
    "owner": "Lehrer",
    "viewer": "Schulkind",
    "editor": "Co-Lehrer",
    "no-entries": "keine Einträge"
  }
}
</i18n>
<template>
  <page-title :title="$t('users')">
    <template #action>
      <div class="flex items-center">
        <div class="">
          <div class="relative">
            <input
              v-model="form.search"
              class="rounded-full border border-yellow-dark w-20 md:w-32 px-4 py-1"
              placeholder="Suche "
            />
            <button
              v-if="form.search && form.search.length > 0"
              class="absolute right-0 top-0 mt-1 mr-4"
              @click="form.search = ''"
            >
              x
            </button>
          </div>
        </div>
        <dropdown>
          <div
            class="md:ml-4 border border-yellow-dark hover:bg-yellow-dark text-yellow-dark hover:text-white w-8 h-8 rounded-full flex justify-center items-center"
          >
            <icon class="w-5 h-5" name="ellipsis-v" />
          </div>
          <template #dropdown>
            <div class="bg-white border border-yellow-dark mt-2 rounded">
              <a
                class="block px-4 py-2 hover:bg-yellow-dark hover:text-white"
                :href="$routes.users() + '.csv'"
                target="_blank"
                >{{ $t('export') }}</a
              >
            </div>
          </template>
        </dropdown>
      </div>
    </template>
  </page-title>
  <div class="overflow-x-auto">
    <table class="w-full whitespace-no-wrap">
      <thead>
        <tr class="text-left font-bold">
          <th class="p-2" />
          <th class="p-2">
            {{ $t('family_name') }}
          </th>
          <th class="p-2">
            {{ $t('first_name') }}
          </th>
          <th class="p-2">
            {{ $t('user_id') }}
          </th>
          <th class="p-2">
            {{ $t('user-accounts') }}
          </th>
          <th class="p-2 text-sm">
            {{ $t('last_seen') }}
          </th>
          <th class="p-2 text-sm">
            {{ $t('locked') }}
          </th>
          <th class="p-2 text-sm">
            {{ $t('can_upload') }}
          </th>
          <th class="p-2" />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="user in users"
          :key="user.slug"
          class="group"
        >
          <td
            class="border-t border-yellow-dark hover:cursor-pointer group-hover:bg-primary-yellow"
            @click="onRowClick(user)"
          >
            <img
              v-if="user.avatar_url"
              class="w-12 h-12 rounded-full"
              :src="user.avatar_url"
              alt="Avatar"
            />
          </td>
          <td
            class="border-t border-yellow-dark hover:cursor-pointer group-hover:bg-primary-yellow"
            @click="onRowClick(user)"
          >
            {{ user.last_name }}
          </td>
          <td
            class="border-t border-yellow-dark hover:cursor-pointer group-hover:bg-primary-yellow px-2 py-4"
            @click="onRowClick(user)"
          >
            {{ user.first_name }}
          </td>
          <td
            class="border-t border-yellow-dark hover:cursor-pointer group-hover:bg-primary-yellow px-2 py-4"
            @click="onRowClick(user)"
          >
            {{ user.slug.toUpperCase() }}
          </td>
          <td
            class="border-t border-yellow-dark hover:cursor-pointer group-hover:bg-primary-yellow px-2"
            @click="onRowClick(user)"
          >
            <div class="flex flex-wrap items-center gap-2">
              <template v-for="user_permission in user.user_permissions" :key="user_permission.id">
                <img
                  v-if="user_permission.avatar_url"
                  class="w-8 h-8 rounded-full"
                  :src="user_permission.avatar_url"
                  alt="Avatar"
                />
                <div v-else class="text-sm">{{user_permission.name }}</div>
              </template>
            </div>
          </td>
          <td
            class="border-t border-yellow-dark px-2 py-4 hover:cursor-pointer group-hover:bg-primary-yellow text-sm"
            @click="onRowClick(user)"
          >
            {{ user.last_seen_at }}
          </td>

          <td
            class="border-t border-yellow-dark text-center hover:cursor-pointer group-hover:bg-primary-yellow px-2 py-4"
            @click="onRowClick(user)"
          >
            {{ user.is_locked ? 'X' : '' }}
          </td>
          <td class="border-t border-yellow-dark hover:cursor-pointer group-hover:bg-primary-yellow text-center px-2 py-4">
            {{ user.can_upload ? 'X' : '' }}
          </td>
          <td class="border-t border-yellow-dark group-hover:bg-primary-yellow py-4">
            <div class="flex items-center justify-end gap-x-2">
              <button
                v-if="user.role === 'viewer'"
                name="edit_user"
                @click="$inertia.visit($routes.user(user.slug))"
                class="hover:bg-gray-100 rounded-full"
              >
                <icon class="w-6 p-1" name="qr-code" />
              </button>
              <a
                v-if="user.masquerade_path"
                v-tippy
                :href="user.masquerade_path"
                :content="`Anmelden als ${user.first_name}`"
              >
                <icon name="user-circle-black" class="w-6 h-6 rounded-full" />
              </a>
            </div>
          </td>
        </tr>
        <tr v-if="users.length === 0">
          <td class="border-t border-yellow-dark px-2 py-4" colspan="8">
            {{ $t('no-entries') }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <footer-actions>
    <Link
      class="ml-auto btn-menu-red"
      name="new_user"
      :href="$routes.new_user()"
    >
      <icon class="w-6 h-6" name="plus" />
    </Link>
  </footer-actions>
</template>

<script>
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'
import throttle from 'lodash/throttle'

export default {
  props: {
    users: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        role: this.filters.role,
        trashed: this.filters.trashed,
      },
      theme: 2,
    }
  },
  computed: {
    countLabel() {
      if (this.users.length === 1) {
        return `1 ${this.$t('user')}`
      } else {
        return `${this.users.length} ${this.$t('users')}`
      }
    },
  },
  watch: {
    form: {
      handler: throttle(function () {
        let query = pickBy(this.form)
        this.$inertia.get(
          this.$routes.users(
            Object.keys(query).length ? query : { remember: 'forget' },
          ), {},
          {
            preserveState: true,
            preserveScroll: true,
          },
        )
      }, 150),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
    onRowClick(user) {
      this.$inertia.visit(this.$routes.edit_user(user.slug))
    },
  },
}
</script>
