<i18n>
{
  "en": {
    "title": "Change password",
    "password": "New password",
    "password-confirmation": "Confirm password",
    "cancel": "Cancel",
    "change": "Change"
  },
  "de": {
    "title": "Passwort ändern",
    "password": "Neues Passwort",
    "password-confirmation": "Passwort bestätigen",
    "cancel": "Abbrechen",
    "change": "Ändern"
  }
}
</i18n>

<template>
  <form
    class="md:mt-8 overflow-hidden text-gray-900 bg-yellow-dark border rounded max-w-md mx-auto"
    @submit.prevent="submit"
  >
    <div class="px-4 md:px-10 py-6 md:py-12">
      <h1 class="text-3xl font-bold text-center">
        {{ $t('title') }}
      </h1>
      <img class="w-40 mx-auto mt-4" :src="imagePath('prima.schule_wr.svg')" />
      <div class="w-24 mx-auto my-4 border-white border-b-2" />
      <text-input
        v-model="form.password"
        class="mt-4 md:mt-10"
        :label="$t('password')"
        :errors="$page.props.errors && $page.props.errors.password"
        type="password"
        autocomplete="new-password"
        autofocus
        required
      />
      <text-input
        v-model="form.password_confirmation"
        class="mt-4 md:mt-10"
        :label="$t('password-confirmation')"
        :errors="$page.props.errors && $page.props.errors.password_confirmation"
        type="password"
        autocomplete="new-password"
        required
      />
    </div>
    <div
      class="flex items-center justify-between px-10 py-4 bg-gray-100 border-t border-gray-200"
    >
      <Link
        class="hover:underline"
        tabindex="-1"
        :href="$routes.new_user_session()"
      >
        {{ $t('cancel') }}
      </Link>
      <loading-button
        :loading="sending"
        class="btn-red"
        type="submit"
        :disabled="!isPresent"
      >
        {{ $t('change') }}
      </loading-button>
    </div>
  </form>
  <footer-actions>
    <a class="py-2 text-sm sm:text-base" :href="$getStaticUrl('')"
      >© prima.schule</a
    >
    <ul class="ml-auto flex">
      <li class="sm:mr-2 p-2 rounded hover:bg-yellow-dark hover:text-white">
        <a :href="$getStaticUrl('terms')">Impressum</a>
      </li>
      <li class="p-2 rounded hover:bg-yellow-dark hover:text-white">
        <a :href="$getStaticUrl('privacy')">Datenschutz</a>
      </li>
    </ul>
  </footer-actions>
</template>

<script>
export default {
  data() {
    return {
      sending: false,
      form: {
        password: null,
        password_confirmation: null,
      },
    }
  },
  computed: {
    resetPasswordToken() {
      let uri = window.location.search.substring(1)
      let params = new URLSearchParams(uri)
      return params.get('reset_password_token')
    },
    isPresent() {
      return this.form.password && this.form.password_confirmation
    },
  },
  methods: {
    submit() {
      this.$inertia.put(
        this.$routes.user_password(),
        {
          user: {
            ...this.form,
            reset_password_token: this.resetPasswordToken,
          },
        },
        {
          onStart: () => (this.sending = true),
          onFinish: () => (this.sending = false),
        },
      )
    },
  },
}
</script>
