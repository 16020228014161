<i18n>
{
  "en": {
    "close": "Close"
  },
  "de": {
    "close": "Schließen"
  }
}
</i18n>

<template>
  <div
    v-if="hasContent && show"
    class="fixed bottom-0 mb-1 text-lg z-20"
    style="min-width: 300px; left: 50%; transform: translateX(-50%);"
  >
    <div
      v-if="$page.props.flash.success"
      class="max-w-md mx-auto flex items-center justify-between w-full bg-green-dark rounded"
    >
      <div class="flex items-center">
        <div class="pl-4 py-4 text-white">
          {{ $page.props.flash.success }}
        </div>
      </div>
      <button
        type="button"
        class="p-2 mr-2 group"
        :aria-label="$t('close')"
        @click="show = false"
      >
        <icon
          name="x"
          class="block w-3 h-3 text-white"
        />
      </button>
    </div>
    <div
      v-else-if="$page.props.flash.alert"
      class="max-w-md mx-auto flex items-center justify-between w-full bg-red-dark rounded"
    >
      <div class="flex items-center">
        <div class="pl-4 py-4 text-white">
          {{ $page.props.flash.alert }}
        </div>
      </div>
      <button
        type="button"
        class="p-2 mr-2 group"
        :aria-label="$t('close')"
        @click="show = false"
      >
        <icon
          name="x"
          class="block w-3 h-3 text-white"
        />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
    }
  },
  computed: {
    hasContent() {
      return (
        this.$page.props.flash &&
        (this.$page.props.flash.alert || this.$page.props.flash.success)
      )
    },
  },
  watch: {
    '$page.props.flash': {
      handler() {
        this.show = true
        setTimeout(() => {
          this.show = false
        }, 3000)
      },
      deep: true,
    },
  },
  // mounted() {
  //   if(this.show){
  //     setTimeout(() => {
  //       this.show = false
  //       console.log("finish 1")
  //     }, 5000)
  //   }
  // }
}
</script>
