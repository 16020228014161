<i18n>
{
  "en": {
    "title": "Error",
    "403-header": "Error 403",
    "404-header": "Error 404",
    "500-header": "Error 500",
    "503-header": "Error 503",
    "403-text": "Sorry, you are forbidden from accessing this page.",
    "404-text": "Sorry, the page you are looking for could not be found.",
    "500-text": "Whoops, something went wrong on our servers.",
    "503-text": "Sorry, we are doing some maintenance. Please check back soon.",
    "link_back": "back to prima.schule"
  },
  "de": {
    "title": "Fehler",
    "403-header": "Fehler 403",
    "404-header": "Fehler 404",
    "500-header": "Fehler 500",
    "503-header": "Fehler 503",
    "403-text": "Es tut uns leid, aber Ihnen ist der Zugriff auf die angeforderte Seite untersagt.",
    "404-text": "Die angeforderte Seite gibt es hier leider nicht.",
    "500-text": "Hoppla, auf dem Server ist etwas schiefgelaufen.",
    "503-text": "Es werden Wartungsarbeiten durchgeführt. Bitte versuchen Sie es später nochmal.",
    "link_back": "zurück zu prima.schule"
  }
}
</i18n>

<template>
  <Head :title="getTitle()" />
  <div class="p-5 bg-green-dark text-white min-h-screen flex justify-center items-center">
    <div class="w-full max-w-lg">
      <a href="/">
        <img
          v-tippy
          class="w-64 mb-4 pb-4 border-b border-transparent hover:border-white"
          :src="imagePath('prima.schule_w.svg')"
          :content="$t('link_back')"
        >
      </a>
      <h1 class="text-3xl">
        {{ header }}
      </h1>

      <p class="mt-3 text-lg leading-tight">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
import Minimal from '@/Layouts/Minimal.vue'
import { Head } from '@inertiajs/inertia-vue3'

export default {
  components: {
    Head
  },
  layout: Minimal,
  props: {
    status: {
      type: Number,
      required: true,
    },
  },
  computed: {
    header() {
      return this.$t(`${this.status}-header`)
    },
    text() {
      return this.$t(`${this.status}-text`)
    },
  },
  methods: {
    getTitle() {
      return [document.querySelector('meta[name="application-name"]').content, this.header].join(' | ')
    }
  }
}
</script>
