<template>
  <div class="relative h-0" :style="`padding-bottom: ${aspectRatio * 100}%`">
    <canvas
      ref="canvas"
      class="absolute top-0 left-0 right-0 bottom-0 w-full h-full"
      width="32"
      height="32"
    />
  </div>
</template>

<script>
import { decode } from 'blurhash';

export default {
  props: {
    hash: {
      type: String,
      required: true,
    },

    aspectRatio: {
      type: Number,
      default: 1,
    },
  },

  mounted() {
    const pixels = decode(this.hash, 32, 32);
    const imageData = new ImageData(pixels, 32, 32);
    const ctx = this.$refs.canvas.getContext('2d');
    ctx.putImageData(imageData, 0, 0);
  },
};
</script>
