<i18n>
{
  "en": {
    "placeholder": "Select option or type in and press [enter]"
  },
  "de": {
    "placeholder": "Auswählen oder neues Schlagwort eingeben [enter]"
  }
}
</i18n>

<template>
  <div
    v-bind="$attrs"
  >
    <label
      v-if="label"
      class="form-label"
      :for="id"
    >{{ label }}</label>

    <multiselect
      :id="id"
      :ref="`multiselect-${id}`"
      v-autofocus="autofocus"
      class="mt-2"
      :model-value="currentValue"
      :options="tagNames"
      :loading="loading"
      :limit="10"
      :internal-search="false"
      :multiple="true"
      :taggable="true"
      :placeholder="$t('placeholder')"
      tag-placeholder=""
      select-label=""
      :hide-selected="true"
      @search-change="debounceFind"
      @select="addSelection"
      @remove="removeSelection"
      @tag="addTag"
    >
    </multiselect>

    <div
      v-if="errors.length"
      class="form-error"
    >
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import debounce from 'lodash/debounce'

export default {
  components: {
    Multiselect
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `tag-select-${parseInt(Math.random() * 10000)}`
      },
    },
    value: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    autofocus: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      tagNames: [],
      loading: false,
      currentValue: this.value,
    }
  },

  created() {
    this.debounceFind = debounce(this.asyncFind.bind(this), 300)
  },

  methods: {
    asyncFind(query) {
      if (!query) return

      this.loading = true
      this.$axios.get(this.$routes.tags({query: query}), {
        headers: { Accept: 'application/json' },
      })
      .then(response => {
        this.tagNames = response.data
        this.loading = false
      })
    },

    addSelection(option) {
      if (!~this.currentValue.indexOf(option)) {
        this.currentValue.push(option)
      }

      this.$emit('update:modelValue', this.currentValue)
    },

    removeSelection(option) {
      let index = this.currentValue.indexOf(option)

      if (~index) {
        this.currentValue.splice(index, 1)
      }

      this.$emit('update:modelValue', this.currentValue)
    },

    addTag(newTag) {
      this.currentValue.push(newTag)
      this.$emit('update:modelValue', this.currentValue)
    },

    focus() {
      this.$refs.input.focus()
    },

    select() {
      this.$refs.input.select()
    },

    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end)
    },
  },
}
</script>

<style lang="css">
.multiselect {
  --accent-color: theme('colors.gray.500');
}

.multiselect__spinner:before,
.multiselect__spinner:after {
  border-color: var(--accent-color) transparent transparent;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 1rem;
}
.multiselect {
  color: theme('colors.gray.700');
}
.multiselect__input::placeholder {
  color: theme('colors.gray.400');
}
.multiselect__tags {
  font-size: 1rem;
}
.multiselect__tag {
  background: var(--accent-color);
}
.multiselect__tag-icon {
  border-radius: 0;
}
.multiselect__tag-icon:after {
  color: theme('colors.gray.400');
  font-size: 1rem;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: theme('colors.gray.600');
}
.multiselect__option:after {
  font-size: 1rem;
}
.multiselect__option--highlight {
  background: var(--accent-color);
}
.multiselect__option--highlight:after {
  background: var(--accent-color);
}
</style>
