<template>
  <section
    class="w-full flex flex-wrap p-2 md:px-4 md:pt-4 cursor-pointer border rounded"
    :style="{borderColor: getBorderColor(task)}"
  >
    <div
      v-if="task.shares && task.shares.length > 0"
      class="w-full flex flex-wrap mb-2"
    >
      <div
        v-for="share in task.shares"
        :key="share.name"
        class="mr-1 p-1 border border-white rounded"
      >
        {{ share.name }}
      </div>
    </div>
    <div
      class="w-full md:w-1/6 md:mt-2 pb-2 md:pb-4 flex flex-wrap items-start"
    >
      <div
        v-if="task.subject_id"
        class="px-4 py-2 border rounded"
        :style="{backgroundColor: getSubjectBgColor(task.subject_id), color: getSubjectFgColor(task.subject_id), borderColor: getBorderColor(task) }"
      >
        {{ getSubjectName(task.subject_id) }}
      </div>
      <div
        v-if="task.is_homework"
        class="ml-2 mt-1 w-8 h-8 flex items-center justify-center border border-white rounded-full"
      >
        <span>HA</span>
      </div>
    </div>
    <div class="w-full md:w-2/3 pb-2">
      <div class="flex">
        <!-- <img
          v-if="
            task.tasks_clips_attributes.some(
              (item) => item.is_task_thumb === true
            )
          "
          class="w-16 mx-2 mt-0 mb-auto"
          :src="getThumbUrl(task)"
        > -->
        <div
          v-if="task.content"
          v-dompurify-html="task.content"
          class="ql-editor w-full mt-2"
        />
      </div>
      <section
        class="clips w-full"
      >
        <div
          class="w-full mt-2 flex flex-wrap"
        >
          <div
            v-for="tasks_clip in task.tasks_clips_attributes"
            :key="tasks_clip.id"
            class="clip w-24 mr-2 border border-yellow-dark rounded"
          >
            <img
              :src="getClipImageUrl(tasks_clip)"
            >
          </div>
        </div>
      </section>
      <section
        class="memos w-full"
      >
        <div
          class="w-full mt-2 flex flex-wrap"
        >
          <div
            v-for="tasks_memo in task.tasks_memos_attributes"
            :key="tasks_memo.id"
            class="clip w-24 mr-2 border border-yellow-dark rounded"
          >
            <img
              :src="getMemoImageUrl(tasks_memo)"
            >
          </div>
        </div>
      </section>
    </div>
    <div class="w-full md:w-1/6 py-2 flex items-start justify-end">
      <ul class="w-full mt-2 flex flex-wrap items-start justify-end">
        <li
          v-for="(tag, index) in task.tags"
          :key="index"
          class="mr-2"
        >
          <Link
            :href="$routes.subject_tasks(task.subject_id, { 'tags': tag})"
          >
            #{{ tag }}
          </Link>
        </li>
      </ul>
    </div>
    <slot />
  </section>
</template>
<script>

export default {
  components: {
  },
  props: {
    plan: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    clips: {
      type:Array,
      required: true,
      default: () => []
    },
    memos: {
      type:Array,
      required: true,
      default: () => []
    },
    subjects: {
      type:Array,
      required: true,
      default: () => []
    },
  },
  methods: {
    getBorderColor(task) {
      if(task.bg_color) {
        return task.bg_color
      }
      return '#6B7280'
    },
    getSubjectName(id) {
      let subject = this.subjects.find(item => item.id === id)
      if(subject)
        return subject.short_name
    },
    getSubjectBgColor(id) {
      let subject = this.subjects.find(item => item.id === id)
      if(subject)
        return subject.bg_color
    },
    getSubjectFgColor(id) {
      let subject = this.subjects.find(item => item.id === id)
      if(subject)
        return subject.fg_color
    },
    // newMemo(clip) {
    //   this.$inertia.get(
    //     this.$routes.new_plan_memo(this.plan.id),
    //     { task_id: this.task.id, clip_id: clip.id }
    //   )
    // },
    // getClipType(tasks_clip) {
    //   let clip = this.clips.find(item => item.id === tasks_clip.clip_id)
    //   if(clip) {
    //     return clip.content_type
    //   }
    // },
    getClipImageUrl(tasks_clip) {
      let clip = this.clips.find(item => item.id === tasks_clip.clip_id)
      if(clip)
        return clip.image_url
    },
    getMemoImageUrl(tasks_memo) {
      let memo = this.memos.find(item => item.id === tasks_memo.memo_id)
      if(memo)
        return memo.image_url
    },
    getThumbUrl() {
      let task_clip = this.task.tasks_clips_attributes.find(item => item.is_task_thumb === true)
      if(task_clip)
        return this.getimage_url(task_clip)
    },
    // getDownloadUrl(tasks_clip) {
    //   let clip = this.clips.find(item => item.id === tasks_clip.clip_id)
    //   if(clip)
    //     return clip.download_url
    // },
    // navigateTo(tasks_clip) {
    //   let url = this.getDownloadUrl(tasks_clip)
    //   console.log(url)
    //   if(url)
    //     window.open(url, '_blank')
    // },
    // showClip(tasks_clip) {
    //   let clip = this.clips.find(item => item.id === tasks_clip.clip_id)
    //   if(clip)
    //     window.open(clip.download_url, '_blank')
    // },
  }
}
</script>
