<i18n>
{
  "en": {
    "title": "My Icon"
  },
  "de": {
    "title": "Mein Bild"
  }
}
</i18n>
<template>
  <page-title :nav-back-url="$routes.root()" :title="$t('title')">
    <template #action>
      <Link
        name="edit_presentation"
        as="button"
        class="ml-auto btn-menu flex w-8 h-8 md:w-10 md:h-10 rounded-full"
        :href="$routes.edit_presentation()"
        replace
      >
        <icon name="pencil-alt" class="w-8 h-8" />
      </Link>
    </template>
  </page-title>
  <section class="w-full flex flex-wrap justify-center mt-4">
    <div class="mx-auto max-w-xl mb-4 md:mb-8 text-center">
      <img
        v-if="user.avatar_url"
        class="border-4 border-yellow-dark rounded-full"
        :src="user.avatar_url"
        alt="Avatar"
      />
      <img v-else class="" :src="imagePath('avatar.svg')" />
    </div>
  </section>
</template>
<script>
export default {
  components: {},
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
}
</script>
