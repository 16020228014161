<template>
  <Head :title="getTitle()" />
  <section class="w-full flex items-center">
    <button
      name="nav_back"
      class="transform hover:scale-110"
      @click="nav_back()"
    >
      <img
        class="w-12 h-12"
        :src="imagePath('arrow_left.svg')"
      >
    </button>
    <h1 class="flex-grow ml-4 md:ml-6 text-3xl md:text-4xl font-sans text-gray-900">
      <span v-if="title">{{ title }}</span>
      <slot />
    </h1>
    <div class="justify-end flex gap-2">
      <slot name="action" />
    </div>
  </section>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'

  export default {
    components: {
      Head
    },
    props: {
      navBackUrl: {
        type: String,
        required: false,
        default: null,
      },
      title: {
        type: String,
        required: false,
        default: null,
      },
      viewerNavBackUrl: {
        type: String,
        required: false,
        default: null,
      },
      replace: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    methods: {
      nav_back() {
        if(this.navBackUrl) {
          this.$inertia.get(this.navBackUrl, {}, { replace: this.replace })
          return
        }
        if(this.viewerNavBackUrl && this.$page.props.auth && this.$page.props.auth.user && !this.$page.props.auth.user.owner) {
          this.$inertia.get(this.viewerNavBackUrl, {}, { replace: this.replace })
        } else {
          this.$inertia.get(this.$routes.root(), {}, { replace: this.replace })
        }
      },
      getTitle() {
        if(this.title) {
          return [document.querySelector('meta[name="application-name"]').content, this.title].join(' | ')
        } else {
          return document.querySelector('meta[name="application-name"]').content
        }
      }
    }
  }
</script>
