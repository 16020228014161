<i18n>
{
  "en": {
    "title": "Edit User",
    "title-me": "Edit Me",
    "users": "Users",
    "password-change": "To change password please fill out both password fields",
    "invalid-email": "Invalid Email",
    "delete": "Delete User",
    "save": "save"
  },
  "de": {
    "title": "Anwender bearbeiten",
    "title-me": "Mein Konto bearbeiten",
    "users": "Anwender",
    "password-change": "Zum Ändern des Passwortes müssen beide Passwort-Felder ausgefüllt werden",
    "invalid-email": "Ungültige Email",
    "delete": "Anwender löschen",
    "save": "Speichern"
  }
}
</i18n>
<template>
  <page-title :nav-back-url="getBackUrl()" :title="getTitle()" />
  <user-form
    class="mt-4 md:mt-8"
    v-model="form"
    :user_accounts="user_accounts"
    :can="can"
  >
    <template #actionAddPermission>
      <div class="flex items-end gap-x-4">
        <ul class="flex-grow flex flex-wrap gap-2 md:gap-4">
          <li class="" v-for="user_permission in form.user_permissions" :key="user_permission.id">
            <div class="bg-green-700 text-white rounded p-2">
              <span>{{ user_permission.name }}</span>
              <button v-tippy content="entfernen" @click="removeUserAccount(user_permission)">
                <icon
                  class="ml-3 w-3 h-3"
                  name="cross"
                />
              </button>
            </div>
          </li>
        </ul>
        <button class="" @click="show_user_accounts_modal = true">
          <icon name="plus-circle" class="w-10 h-10" />
        </button>
      </div>
    </template>
  </user-form>
  <footer-actions>
    <button
      v-if="!user.owner"
      class="btn-delete"
      tabindex="-1"
      type="button"
      @click="show_confirm_dialog=true"
    >
      löschen
    </button>
    <loading-button
      :loading="sending"
      class="btn ml-auto"
      type="submit"
      @click="submit"
    >
      {{ $t('save') }}
    </loading-button>
  </footer-actions>
  <confirm-delete
    v-show="show_confirm_dialog"
    :modalHeadline="$t('delete')"
    @deleteRecordEvent="destroy"
    @close="show_confirm_dialog = false"
  />
  <modal
    :open="show_user_accounts_modal"
    :colored-header="true"
    title="Teilen"
    @close="show_user_accounts_modal = false"
  >
    <ul class="bg-white p-4 flex flex-wrap gap-2 md:gap-4">
      <li v-for="user_account in user_accounts" :key="user_account.id">
        <button class="btn" @click="addUserAccount(user_account)">{{ user_account.name }}</button>
      </li>
    </ul>
  </modal>
</template>

<script>
import Layout from '@/Layouts/Full.vue'
import UserForm from './Form.vue'

export default {
  components: {
    UserForm,
  },
  layout: Layout,
  props: {
    user: {
      type: Object,
      required: true,
    },
    user_accounts: {
      type: Array,
      required: true,
    },
    can: {
      type: Object,
      required: true,
    },
  },
  remember: 'form',
  data() {
    return {
      show_confirm_dialog: false,
      show_user_accounts_modal: false,
      sending: false,
      form: this.user,
      contact_emails: [],
    }
  },
  watch: {
    user: function() {
      this.form = this.user
    }
  },
  methods: {
    getBackUrl() {
      if(document.location.pathname.startsWith("/user_accounts")) {
        return this.$routes.user_accounts()
      } else {
        return this.$routes.users()
      }
    },
    getTitle() {
      if(this.can.edit_me) {
        return this.$t('title-me')
      } else {
        return `${this.$t('title')} ${this.user.id}`
      }
    },
    submit() {
      let check = true
      if(this.form.contact_emails && this.form.contact_emails.length > 0) {
        this.form.contact_emails.forEach(item => {
          var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          if(!re.test(item)) {
            this.$notify({
              title: 'ungültige Email',
              type: 'error',
            })

            check = false
          }
        })
      }

      if(!check)
        return

      let data = new FormData()
      data.append('user[first_name]', this.form.first_name || '')
      data.append('user[last_name]', this.form.last_name || '')
      data.append('user[can_upload]', this.form.can_upload || '')
      data.append('user[is_locked]', this.form.is_locked || '')
      data.append('user[password_token]', this.form.password_token)
      data.append('user[avatar]', this.form.avatar || '')
      data.append('_method', 'put')

      let emails = this.form.contact_emails
      if(emails && emails.length > 0) {
        emails.forEach(item => {
          data.append('user[contact_emails][]', item)
        })
      } else {
        data.append('user[contact_emails][]', '')
      }
      // data.append('_method', 'put')

      this.$inertia.post(this.$routes.user(this.user.id), data, {
        onStart: () => this.sending = true,
        onFinish: () => {
          this.sending = false
          if (!this.$page.props.errors) {
            this.form.avatar = null
            this.form.password = null
            this.form.password_confirmation = null
          }
        },
      },
      {
        preserveState: false
      })
    },
    destroy() {
      this.$inertia.delete(this.$routes.user(this.user.id))
    },
    addUserAccount(user_account) {
      let data = new FormData()
      data.append('user_permission[viewable_id]', user_account.id || '')
      data.append('user_permission[user_id]', this.user.id || '')

      this.$inertia.post(this.$routes.user_permissions(), data, {
        onFinish: () => {
          this.show_user_accounts_modal = false
        }
      })
    },
    removeUserAccount(user_permission) {
      this.$inertia.delete(this.$routes.user_permission(user_permission.id), {
      })
    },
  },
}
</script>
