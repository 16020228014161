<i18n>
{
  "en": {
    "title": "Media",
    "tags": "Tags",
    "upload": "Upload",
    "take": "take",
    "criteria": "search criteria",
    "cancel": "cancel",
    "save": "save"
  },
  "de": {
    "title": "Medien",
    "tags": "Schlagworte",
    "upload": "Hochladen",
    "take": "übernehmen",
    "criteria": "Suchkriterien",
    "cancel": "abbrechen",
    "save": "speichern"
  }
}
</i18n>
<template>
  <page-title :viewer-nav-back-url="$routes.knowledge()" :title="$t('title')">
    <template #action>
      <button
        v-if="$page.props.auth.user.owner"
        name="filter-shared-clips"
        v-tippy
        content="Filter freigegeben"
        class="flex items-center justify-center rounded-full w-10 h-10"
        :class="{'bg-white text-gray-600': !this.form.shared, 'bg-blue-dark text-white': this.form.shared}"
        @click="toggleFilter"
      >
        <icon name="share" class="w-5 h-5" />
      </button>
      <button
        v-if="$page.props.auth.user.owner && tags.length > 0"
        v-tippy
        :content="$t('tags')"
        class="flex items-center justify-center w-10 h-10 border bg-white rounded-full"
        :class="{ 'bg-blue-dark text-white': tag_mode }"
        name="toggleTagMode"
        @click="toggleTagMode"
      >
        <span>#</span>
      </button>
      <button
        v-if="$page.props.auth.user.owner"
        v-tippy
        :content="$t('upload')"
        class="flex items-center justify-center w-10 h-10 border bg-white rounded-full"
        :class="{ 'bg-blue-dark text-white': show_upload_area }"
        name="toggleUploadArea"
        @click="show_upload_area = !show_upload_area"
      >
        <icon name="upload" class="w-5 h-5" />
      </button>
      <div class="relative">
        <input
          id="search"
          v-model="form.search"
          class="rounded-full border w-20 lg:w-40 px-4 py-2"
          placeholder="Suche "
        />
        <button
          v-if="form.search.length > 0"
          class="absolute right-0 mt-2 mr-4"
          @click="form.search = ''"
        >
          x
        </button>
      </div>
    </template>
  </page-title>
  <ul v-if="tag_mode" class="tags w-full flex flex-wrap justify-end mt-2">
    <li
      v-for="(tag, index) in tags"
      :key="index"
      class="p-2"
      :class="{ 'font-bold border-b border-gray-700': tag === current_tag }"
    >
      <Link :href="$routes.clips({ tags: tag })" preserve-state>
        #{{ tag }}
      </Link>
    </li>
  </ul>
  <file-upload
    v-if="show_upload_area"
    id="file-upload"
    class="mt-2"
    @createdClip="addClip"
  />
  <section
    class="clips mt-2 md:mt-4 lg:mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 md:gap-4 lg:gap-8"
  >
    <div
      v-for="(clip, index) in items"
      :key="index"
      class="clip relative w-full  mb-2 md:mb-8 lg:mb-16"
    >
      <div class="image bg-white text-gray-800 border-4 border-primary-yellow rounded-lg px-4 pt-4 pb-1">
        <img
          :src="clip.image_url"
          :alt="clip.filetype"
          class="cursor-pointer"
          :class="{ 'w-24 mx-auto': clip.filetype === 'Audio' }"
          @click="selectClip(clip)"
        />
      <div v-if="clip.caption" class="w-full text-right mt-1 pb-1 truncate">
        {{ clip.caption }}
      </div>
      <ul class="mt-1 w-full flex flex-wrap md:text-sm">
        <li v-for="(tag, ti) in clip.tags" :key="ti" class="mr-1">
          #{{ tag }}
        </li>
        <li class="ml-auto md:text-sm">
          {{ $filters.toDate(clip.updated_at, 'DD.MM.YYYY HH:mm') }}
        </li>
      </ul>
      </div>
      <div
        v-if="$page.props.auth.user.owner"
        class="absolute top-0 right-0 flex mt-2"
      >
        <button
          name="edit-clip"
          class="m-1 border-2 border-white bg-green-dark text-white rounded-full p-2 hidden"
          @click="editClip(clip)"
        >
          <icon name="pencil-alt" class="w-4 h-4" />
        </button>
        <button
          name="destroy-clip"
          class="m-1 border-2 border-white bg-red-light text-white rounded-full p-2 hidden"
          @click="destroyClip(clip)"
        >
          <icon name="trash" class="w-4 h-4" />
        </button>
        <button
          name="share-clip"
          class="m-1 border-2 border-white bg-yellow-dark text-white rounded-full p-2"
          :class="{ hidden: !clip.shared, 'bg-blue-dark': clip.shared }"
          @click="shareClip(clip)"
        >
          <icon name="share" class="w-4 h-4" />
        </button>
      </div>
    </div>
  </section>
  <pagination prop-name="clips" />
  <modal
    :open="show_edit_modal"
    :colored-header="true"
    :title="$t('criteria')"
    @close="show_edit_modal = false"
  >
    <div v-if="currentClip" class="bg-white p-4">
      <user-form v-model="currentClip" :subjects="subjects" :tags="tags">
        <div class="w-full py-2 flex gap-4 justify-center">
          <button class="btn-gray" @click="show_edit_modal = false">
            {{ $t('cancel') }}
          </button>
          <button class="btn" @click="submit">
            {{ $t('save') }}
          </button>
        </div>
      </user-form>
    </div>
  </modal>
  <modal
    :open="show_share_modal"
    :colored-header="true"
    title="Teilen"
    @close="show_share_modal = false"
  >
    <div v-if="currentClip" class="bg-white p-4">
      <shares
        :shareable="{ id: currentClip.id, type: 'Clip' }"
        :viewables="viewables"
        @sharesCountChanged="sharesCountChanged"
      />
    </div>
  </modal>
  <modal
    :open="show_audio"
    :colored-header="true"
    title="Audio"
    @close="show_audio = false"
  >
    <div v-if="currentClip" class="bg-white p-4">
      <audio
        :src="currentClip.download_url"
        controls
        :type="currentClip.clip_type"
        class="mx-auto"
      />
      <div
        v-if="currentClip.caption"
        class="w-full text-right mt-1 pb-1 truncate"
      >
        {{ currentClip.caption }}
      </div>
      <ul class="mt-1 w-full flex flex-wrap md:text-sm">
        <li v-for="(tag, ti) in currentClip.tags" :key="ti" class="mr-1">
          #{{ tag }}
        </li>
        <li class="ml-auto md:text-sm">
          {{ $filters.toDate(currentClip.updated_at, 'DD.MM.YYYY HH:mm') }}
        </li>
      </ul>
    </div>
  </modal>
  <modal
    :open="show_video"
    :colored-header="true"
    title="Video"
    @close="show_video = false"
  >
    <div v-if="currentClip" class="bg-white p-4">
      <video
        :src="currentClip.download_url"
        controls
        :type="currentClip.clip_type"
        class="mx-auto"
      />
      <div
        v-if="currentClip.caption"
        class="w-full text-right mt-1 pb-1 truncate"
      >
        {{ currentClip.caption }}
      </div>
      <ul class="mt-1 w-full flex flex-wrap md:text-sm">
        <li v-for="(tag, ti) in currentClip.tags" :key="ti" class="mr-1">
          #{{ tag }}
        </li>
        <li class="ml-auto md:text-sm">
          {{ $filters.toDate(currentClip.updated_at, 'DD.MM.YYYY HH:mm') }}
        </li>
      </ul>
    </div>
  </modal>
</template>

<script>
import Layout from '@/Layouts/Full.vue'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'
import UserForm from './Form.vue'

export default {
  components: {
    UserForm,
  },
  layout: Layout,
  props: {
    clips: {
      type: Object,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
      default: () => [],
    },
    viewables: {
      type: Array,
      required: true,
      default: () => [],
    },
    subjects: {
      type: Array,
      required: true,
      default: () => [],
    },
    filters: {
      type: Object,
      required: true,
    }
  },
  data: () => {
    return {
      items: [],
      currentClip: null,
      current_tag: '',
      form: {
        search: '',
        shared: false,
      },
      sending: false,
      show_edit_modal: false,
      show_share_modal: false,
      show_audio: false,
      show_video: false,
      show_upload_area: false,
      tag_mode: false,
      image_index: null,
    }
  },
  watch: {
    form: {
      handler: throttle(function () {
        let query = pickBy(this.form)
        this.$inertia.get(
          this.$routes.clips(query),
          {},
          { preserveState: true },
        )
      }, 150),
      deep: true,
    },
    clips: function () {
      this.items = this.clips.data
    },
  },
  mounted() {
    this.items = this.clips.data
    if(this.filters.search)
      this.form.search = this.filters.search
    if(this.filters.shared)
      this.form.shared = this.filters.shared
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
    toggleTagMode() {
      this.tag_mode = !this.tag_mode
      if (!this.tag_mode) {
        this.$inertia.get(this.$routes.clips({ preserveState: false }))
      }
    },
    editClip(clip) {
      this.currentClip = clip
      this.show_edit_modal = true
    },
    submit() {
      let data = new FormData()
      data.append('clip[caption]', this.currentClip.caption || '')
      data.append('clip[subject]', this.currentClip.subject || '')
      if (this.currentClip.tags && this.currentClip.tags.length > 0) {
        for (var i = 0; i < this.currentClip.tags.length; i++) {
          data.append('clip[tags][]', this.currentClip.tags[i])
        }
      } else {
        data.append('clip[tags][]', [])
      }

      this.$inertia.patch(this.$routes.clip(this.currentClip.id), data, {
        preserveState: false,
        onStart: () => (this.sending = true),
        onFinish: () => {
          this.currentClip = null
          this.show_edit_modal = false
          this.sending = false
          this.$notify({
            title: 'Clip aktualisiert',
            type: 'success',
          })
        },
      })
    },
    destroyClip(clip) {
      if (confirm('Löschen von Medienelement?')) {
        this.$inertia.delete(this.$routes.clip(clip.id))
      }
    },
    shareClip(clip) {
      this.currentClip = clip
      this.show_share_modal = true
    },
    addClip(clip) {
      this.items.splice(0, 0, clip)
    },
    selectClip(clip) {
      switch (clip.filetype) {
        case 'Audio':
          this.currentClip = clip
          this.show_audio = true
          break
        case 'Video':
          this.currentClip = clip
          this.show_video = true
          break
        default:
          window.open(clip.download_url)
      }
    },
    sharesCountChanged(sharesCount) {
      if (sharesCount > 0) {
        this.currentClip.shared = true
      } else {
        this.currentClip.shared = false
      }
    },
    toggleFilter() {
      this.form.shared = !this.form.shared
    }
  },
}
</script>
<style scoped>
.clip:hover button,
.clip:active button {
  display: block !important;
}
</style>
