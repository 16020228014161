<i18n>
{
  "en": {
    "title": "My Icon",
    "title-me": "create your own picture",
    "save": "save"
  },
  "de": {
    "title": "Mein Bild",
    "title-me": "hier kannst du dein eigenes Bild erstellen",
    "save": "Speichern"
  }
}
</i18n>
<template>
  <Layout :bg-color="true">
    <section class="w-full h-full max-w-screen-xl mx-auto px-2 pt-1 md:pt-2 ">
      <page-title :nav-back-url="$routes.presentation()" :replace="true" :title="getTitle()" />
      <div class="w-full flex">
        <div class="w-20 hidden md:block" />
        <div class="w-full">
          <div class="w-full mb-2 text-2xl">
            {{ $t('title-me') }}
          </div>
          <editor
            ref="graphicEditor"
            :value="avatar_content"
            :width="550"
            :height="550"
            :show-hand="false"
            :show-text-tool="false"
            :show-remove-all="true"
          >
            <button
              name="btnSave"
              class="ml-2 w-10 h-8 md:h-10 bg-green-dark text-white rounded-full flex items-center justify-center"
              @click="save"
            >
              <img
                class="w-6 h-6"
                :src="imagePath('check_w.svg')"
              >
            </button>
          </editor>
        </div>
      </div>
    </section>
  </Layout>
</template>

<script>
import Layout from '@/Layouts/Minimal.vue'
import Editor from '@/Shared/graphic_editor/Editor.vue'

export default {
  components: {
    Layout,
    Editor
  },
  layout: null,
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      avatar_content: {}
    }
  },
  // watch: {
  //   user: function() {
  //     this.form = this.user
  //   }
  // },
  mounted() {
    if(this.user.avatar_content) {
      this.avatar_content = { content: this.user.avatar_content }
    }
  },
  methods: {
    getTitle() {
      return this.$t('title')
    },
    save() {
      let content = this.$refs.graphicEditor.toObject()
      let data = new FormData()
      if(content.length > 0) {
        data.append('user[avatar_content]', JSON.stringify(content))
        let blob = this.$refs.graphicEditor.toBlob()
        data.append('user[avatar]', blob)
      } else {
        data.append('user[avatar_content]', '')
      }

      this.$inertia.patch(this.$routes.presentation(), data, {
        onStart: () => this.sending = true,
        onFinish: () => {
          this.sending = false
        },
      })
    },
  },
}
</script>
