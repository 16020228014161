<i18n>
{
  "en": {
    "title": "New Post",
    "save": "save"
  },
  "de": {
    "title": "Neue Elternpost",
    "save": "speichern"
  }
}
</i18n>
<template>
  <page-title :nav-back-url="$routes.posts()" :title="$t('title')" />
  <div class="mt-4 md:mt-8 flex flex-wrap lg:pl-6">
    <user-form
      v-if="form"
      :value="form"
    />
  </div>
  <footer-actions>
    <loading-button
      :loading="sending"
      class="btn ml-auto"
      type="submit"
      @click="submit"
    >
      {{ $t('save') }}
    </loading-button>
  </footer-actions>
</template>

<script>
import UserForm from './Form.vue'
import _ from 'lodash'

export default {
  components: {
    UserForm,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      sending: false,
      form: null, //_.omit(this.post, 'id', 'deleted_at'),
      theme: 2,
    }
  },
  computed: {},
  mounted() {
    this.form = _.omit(this.post, 'id')
  },
  methods: {
    submit() {
      this.$inertia.post(
        this.$routes.posts(),
        this.form,
        {
          replace: true,
          onStart: () => (this.sending = true),
          onSuccess: () => (this.sending = false),
        },
      )
    },
  },
}
</script>
