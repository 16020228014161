<i18n>
{
  "en": {
    "plans": "Weekly Plans",
    "posts": "Posts",
    "tasks": "Tasks",
    "media": "Media",
    "memos": "digiFactory",
    "users": "Users",
    "rooms": "Rooms",
    "subjects": "Subjects",
    "user_accounts": "User Accounts",
    "reflections": "Reflections",
    "news": "News",
    "imprint" : "Imprint",
    "privacy" : "Privacy"
  },
  "de": {
    "plans": "Wochenpläne",
    "posts": "Elternpost",
    "tasks": "Aufgaben - HA-Korb",
    "media": "Medien",
    "memos": "digiWerkstatt",
    "users": "Anwender",
    "rooms": "Räume",
    "subjects": "Schulfächer",
    "user_accounts": "Benutzergruppen",
    "reflections": "Selbstreflexion",
    "news": "Neuigkeiten",
    "imprint" : "Impressum",
    "privacy" : "Datenschutz"
  }
}
</i18n>
<template>
  <nav class="mt-2 md:mt-4 lg:mt-8 grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 text-lg md:text-2xl">
    <Link
      class="menu-card md:py-4"
      role="navigation"
      :href="$routes.plans()"
    >
      <div class="flex items-center">
        <img
          class="w-16 h-16 mx-8"
          :src="imagePath('calendar_sm.svg')"
        >
        <div>{{ $t('plans') }}</div>
      </div>
    </Link>
    <Link
      class="menu-card md:py-4"
      role="navigation"
      :href="$routes.clips()"
    >
      <div class="flex items-center">
        <img
          class="w-16 h-16 mx-8"
          :src="imagePath('multimedia_sm.svg')"
        >
        <div>{{ $t('media') }}</div>
      </div>
    </Link>
    <Link
      class="menu-card md:py-4 relative"
      role="navigation"
      :href="$routes.posts()"
    >
      <div class="flex items-center">
        <img
          class="w-16 h-16 mx-8"
          :src="imagePath('postDog_sm.svg')"
        >
        <div>{{ $t('posts') }}</div>
      </div>
    </Link>
    <template v-for="(menu, index) in $page.props.menu.main_menu" :key="index">
      <Link
        class="menu-card md:py-4"
        role="navigation"
        :href="menu.href"
      >
        <div class="flex items-center">
          <img
            class="w-16 h-16 mx-8"
            :src="imagePath(menu.icon)"
          >
          <div>{{ $t(menu.caption) }}</div>
        </div>
      </Link>
    </template>
    <Link
      v-if="$page.props.auth.user.owner"
      class="menu-card md:py-4"
      role="navigation"
      :href="$routes.subjects()"
    >
      <div class="flex items-center">
        <img
          class="w-16 h-16 mx-8"
          :src="imagePath('subjects_sm.svg')"
        >
        <div>{{ $t('subjects') }}</div>
      </div>
    </Link>
    <Link
      v-if="$page.props.auth.user.owner"
      class="menu-card md:py-4"
      role="navigation"
      :href="$routes.users()"
    >
      <div class="flex items-center">
        <img
          class="w-16 h-16 mx-8"
          :src="imagePath('pupil_sm.svg')"
        >
        <div>{{ $t('users') }}</div>
      </div>
    </Link>
    <Link
      v-if="$page.props.auth.user.owner"
      class="menu-card md:py-4"
      role="navigation"
      :href="$routes.rooms()"
    >
      <div class="flex items-center">
        <img
          class="w-16 h-16 mx-8"
          :src="imagePath('video_chat_sm.svg')"
        >
        <div>{{ $t('rooms') }}</div>
      </div>
    </Link>
    <Link
      v-if="$page.props.auth.user.owner"
      class="menu-card md:py-4"
      role="navigation"
      :href="$routes.user_accounts()"
    >
      <div class="flex items-center">
        <img
          class="w-16 h-16 mx-8 p-2"
          :src="imagePath('settings_sm.svg')"
        >
        <div>{{ $t('user_accounts') }}</div>
      </div>
    </Link>
  </nav>
</template>
<script>

export default {
  props: {
  },
  methods: {
  },
}
</script>
<style scoped>
.day_label { margin-left: 1.25rem; margin-top: -0.25rem; width: 0.8rem; text-align: center; }
.unread_counter { color: #D63521; }
</style>
