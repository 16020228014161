<template>
  <header
    class="fixed w-full z-20 transition transform ease-out duration-500 bg-primary-red"
    :class="showNavbar ? 'shadow translate-y-0' : '-translate-y-full'"
  >
    <main-nav />
  </header>
  <main
    class="relative w-full flex flex-grow bg-secondary-yellow"
    style="min-height: 100vh;"
  >
    <section
      class="w-full flex max-w-screen-xl mx-auto"
      v-bind="$attrs"
    >
      <div class="w-full px-2 md:px-4 pt-18 xl:pt-20 pb-16">
        <slot />
      </div>
    </section>
  </main>
  <notifications
    position="bottom center"
    classes="flash mb-1"
  />
  <flash-messages />
</template>

<script>
import throttle from 'lodash/throttle'

export default {
  inheritAttrs: false,
  data() {
    return {
      showNavbar: true,
      lastScrollPosition: 0
    }
  },
  mounted() {
    document.addEventListener(
      'scroll',
      throttle(this.onScroll, 100),
    )

    if(this.$page.props.auth && this.$page.props.auth.user)
      localStorage.setItem('token', this.$page.props.auth.user.token)
  },

  beforeDestroy() {
    document.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    url() {
      return location.pathname.substr(1)
    },

    onScroll() {
      const currentScrollPosition = document.scrollingElement.scrollTop

      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) return

      // Don't continue for very small scrolling
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 15) return

      // Show NavBar on scrolling UP only
      this.showNavbar = currentScrollPosition < this.lastScrollPosition

      // Remember current scroll position
      this.lastScrollPosition = currentScrollPosition
    }
  }
}
</script>
