<i18n>
{
  "en": {
    "caption": "Caption",
    "subject": "Subject",
    "file": "File",
    "tags": "Tags"
  },
  "de": {
    "caption": "Beschriftung",
    "subject": "Fach",
    "file": "Datei",
    "tags": "Schlagworte"
  }
}
</i18n>

<template>
  <section
    class="w-full"
  >
    <tag-select
      :value="form.tags"
      :errors="$page.props.errors && $page.props.errors.tags"
      class="w-full py-3"
      :label="$t('tags')"
    />
    <select-box
      v-model="form.subject"
      :options="subjects"
      :errors="$page.props.errors && $page.props.errors.subject"
      class="pb-4"
      :label="$t('subject')"
    />
    <text-input
      v-model="form.caption"
      :errors="$page.props.errors && $page.props.errors.caption"
      class="w-full py-3"
      :label="$t('caption')"
    />
    <slot />
  </section>
</template>

<script>

export default {
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    subjects: {
      type: Array,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      replace: false,
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
}
</script>
